import * as React from "react";
import { Typography, ButtonIcon } from "@freeda/react-components";
import { Colors } from "@freeda/react-components/lib/theme";

interface Props {
  title: string;
  onClose: () => void;
}

const Header: React.FC<Props> = ({ title, onClose }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: 20,
        width: "100%",
      }}
    >
      <Typography variantName="subtitle" textColor={Colors.PURPLE} style={{ position: "relative", top: -1 }}>
        {title}
      </Typography>
      <ButtonIcon onClick={onClose} iconName="close" />
    </div>
  );
};
export { Header };
