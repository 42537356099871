import * as React from "react";

import PostListPage from "./PostListPage";
import { FilterContextT } from "../../providers/FilterProvider";
import { BrandedEditorialContent } from "../../types/BrandedEditorialContent";
import { capitalize, orderBy } from "lodash";
import { brandedByContentType } from "../../utils/brandedByContentType";
import { Select } from "@freeda/react-components";
import { Project } from "../../types/Project";
import { ColumnSetType } from "../../providers/MetricColumnSetsProvider";
import { YtPostListTableContainer } from "../../container/YtPostListTable";
import { YtPostListFilter } from "../layout/PostListFilter/YtPostListFilter";
import AllPublisherInfosQueryT from "../../types/graphql/ListPublisherQuery";
import { trimAndReplaceSpaceToUpper } from "../../utils/trimAndReplaceSpaceToUpper";
import { useQuery } from "@apollo/react-hooks";
import { ALL_PUBLISHER_INFOS } from "../../apollo/queries/AllPublisherInfos";

const getContent = (
  project: Project,
  postFilterContext: React.Context<FilterContextT>,
  ytColumnSetSelect: React.FC<{
    onOpenCustomPanel: () => void;
    width?: string | number;
  }>,
  columnSetType: ColumnSetType,
  withBrand: boolean,
  withPublisher: boolean
) => [
  {
    Table: () => {
      return (
        <YtPostListTableContainer
          project={project}
          ytColumnSetSelect={ytColumnSetSelect}
          ytPostFilterContext={postFilterContext}
          columnSetType={columnSetType}
        />
      );
    },
    Filter: () => {
      const { filterState, callbacks } = React.useContext(postFilterContext);
      const publisherResult = useQuery<AllPublisherInfosQueryT>(
        ALL_PUBLISHER_INFOS
      );

      const optionalComponents = {
        brandedComponent: withBrand
          ? (_: boolean) => {
              const options = [
                {
                  value: BrandedEditorialContent.branded,
                  label: capitalize(BrandedEditorialContent.branded),
                },
                {
                  value: BrandedEditorialContent.editorial,
                  label: capitalize(BrandedEditorialContent.editorial),
                },
              ];

              const selectedValue = options.find(
                (o) => brandedByContentType(o.value) === filterState.branded
              );

              return (
                <Select
                  placeholder="Select type"
                  label="Branded or Editorial"
                  style={{ padding: "10px 0px" }}
                  onChange={(option: any) =>
                    callbacks.onChangeBranded(
                      option ? brandedByContentType(option.value) : null
                    )
                  }
                  options={options}
                  value={selectedValue ? selectedValue : null}
                />
              );
            }
          : undefined,
        publisherSelectComponent: withPublisher
          ? (_: boolean) => {
          
            let projectIn:any = publisherResult.data && publisherResult.data.allPublisherInfos ? publisherResult.data.allPublisherInfos
                .filter(
                  (p) =>
                    trimAndReplaceSpaceToUpper(p.name).indexOf(project.id.toUpperCase()) >= 0 && p.platformId === "YT") : null 
            
            projectIn = projectIn !== null ? projectIn[0] : null
          
            let options = projectIn === null
              ? []
              : 
              projectIn.crossCountry != null ? (
                projectIn.crossCountry.split('|').map((c:string) => ({
                        label: capitalize(c),
                        value: projectIn.name.slice(0, -2).concat(c)
                      }))
                ) : 
                [{
                  label: capitalize(projectIn.name.slice(-2)),
                  value: projectIn.name
                }];

              /*let options =
                publisherResult.loading ||
                !publisherResult.data ||
                Object.keys(publisherResult.data).length === 0
                  ? []
                  : 
                  publisherResult.data.allPublisherInfos
                      .filter(
                        (p) =>
                          trimAndReplaceSpaceToUpper(p.name).indexOf(project.id.toUpperCase()) >= 0 && p.platformId === "YT"
                      )
                      .map((p) => ({
                        label: capitalize(p.name.slice(-2)),
                        value: p.name,
                      }));*/
             
              const optionsWithAll = [
                ...orderBy(options, "label"),
                { label: "All", value: null  },
              ];
             
              let selectedPublisherOption = projectIn && projectIn.crossCountry===null ?
                  options.find(
                    (o:any) => o.value === filterState.selectedPublisher
                  ):
                  options.find(
                    (o:any) => o.label.toLowerCase() === filterState.selectedDistributionCountry
                  )
             
              return (
                <Select
                  label="language"
                  placeholder="Select publisher"
                  style={{ padding: "10px 0px" }}
                  isClearable={false}
                  onChange={(option: any) =>
                    (callbacks.onChangePublisher(option ? projectIn.crossCountry !== null ? projectIn.name : option.value : null),
                    callbacks.onChangeDistributionCountry(option && option.label !== 'All' ? option.label.toLowerCase() : null))
                  }
                  options={optionsWithAll}
                  value={
                    selectedPublisherOption
                      ? selectedPublisherOption
                      : { value: null, label: "All" }
                  }
                />
              );
            }
          : undefined,
      };

      return (
        <YtPostListFilter
          project={project}
          ytPostFilterContext={postFilterContext}
          {...optionalComponents}
        />
      );
    },
    tabLabel: "YtPost",
  },
];

interface Props {
  postFilterContext: React.Context<FilterContextT>;
  columnSetSelect: React.FC<{
    onOpenCustomPanel: () => void;
    width?: string | number;
  }>;
  columnSetType: ColumnSetType;
  project: Project;
  withBrand: boolean;
  withPublisher: boolean;
}

const YtPostListPage: React.FC<Props> = ({
  postFilterContext,
  columnSetSelect,
  columnSetType,
  project,
  withBrand,
  withPublisher,
}) => {
  return (
    <PostListPage
      content={getContent(
        project,
        postFilterContext,
        columnSetSelect,
        columnSetType,
        withBrand,
        withPublisher
      )}
    />
  );
};

export default YtPostListPage;
