import * as React from "react";

import PostListPage from "./PostListPage";
import { FilterContextT } from "../../providers/FilterProvider";
import { BrandedEditorialContent } from "../../types/BrandedEditorialContent";
import { capitalize, orderBy } from "lodash";
import { brandedByContentType } from "../../utils/brandedByContentType";
import { Select } from "@freeda/react-components";
import { Project } from "../../types/Project";
import { ColumnSetType } from "../../providers/MetricColumnSetsProvider";
import AllPublisherInfosQueryT from "../../types/graphql/ListPublisherQuery";
import { trimAndReplaceSpaceToUpper } from "../../utils/trimAndReplaceSpaceToUpper";
import { useQuery } from "@apollo/react-hooks";
import { ALL_PUBLISHER_INFOS } from "../../apollo/queries/AllPublisherInfos";
import { TkPostListTableContainer } from "../../container/TkPostListTable";
import { TkPostListFilter } from "../layout/PostListFilter/TkPostListFilter";
import { UploadTKDialog } from "../layout/UploadTKDialog";
import { SidebarContext } from "../../providers/SidebarProvider";

const getContent = (
  project: Project,
  postFilterContext: React.Context<FilterContextT>,
  tkColumnSetSelect: React.FC<{
    onOpenCustomPanel: () => void;
    width?: string | number;
  }>,
  columnSetType: ColumnSetType,
  withBrand: boolean,
  withPublisher: boolean
) => [
  {
    Table: () => {
      const {
        openUploadTiktokDialog,
        onCloseUploadTiktokDialog,
      } = React.useContext(SidebarContext);

      return (
        <>
          {openUploadTiktokDialog && (
            <UploadTKDialog
              open={openUploadTiktokDialog}
              onClose={onCloseUploadTiktokDialog}
            />
          )}
          <TkPostListTableContainer
            project={project}
            tkColumnSetSelect={tkColumnSetSelect}
            tkPostFilterContext={postFilterContext}
            columnSetType={columnSetType}
          />
        </>
      );
    },
    Filter: () => {
      const { filterState, callbacks } = React.useContext(postFilterContext);
      const publisherResult = useQuery<AllPublisherInfosQueryT>(
        ALL_PUBLISHER_INFOS
      );

      const optionalComponents = {
        brandedComponent: withBrand
          ? (_: boolean) => {
              const options = [
                {
                  value: BrandedEditorialContent.branded,
                  label: capitalize(BrandedEditorialContent.branded),
                },
                {
                  value: BrandedEditorialContent.editorial,
                  label: capitalize(BrandedEditorialContent.editorial),
                },
              ];

              const selectedValue = options.find(
                (o) => brandedByContentType(o.value) === filterState.branded
              );

              return (
                <Select
                  placeholder="Select type"
                  label="Branded or Editorial"
                  style={{ padding: "10px 0px" }}
                  onChange={(option: any) =>
                    callbacks.onChangeBranded(
                      option ? brandedByContentType(option.value) : null
                    )
                  }
                  options={options}
                  value={selectedValue ? selectedValue : null}
                />
              );
            }
          : undefined,
        publisherSelectComponent: withPublisher
          ? (_: boolean) => {
              const options =
                publisherResult.loading ||
                !publisherResult.data ||
                Object.keys(publisherResult.data).length === 0
                  ? []
                  : publisherResult.data.allPublisherInfos
                      .filter(
                        (p) =>
                          trimAndReplaceSpaceToUpper(p.name).indexOf(
                            project.id.toUpperCase()
                          ) >= 0 && p.platformId === "TK"
                      )
                      .map((p) => ({
                        label: capitalize(p.name.slice(-2)),
                        value: p.name,
                      }));

              const optionsWithAll = [
                ...orderBy(options, "label"),
                { value: null, label: "All" },
              ];

              const selectedPublisherOption = options.find(
                (o) => o.value === filterState.selectedPublisher
              );

              return (
                <Select
                  label="language"
                  placeholder="Select publisher"
                  style={{ padding: "10px 0px" }}
                  isClearable={false}
                  onChange={(option: any) =>
                    callbacks.onChangePublisher(option ? option.value : null)
                  }
                  options={optionsWithAll}
                  value={
                    selectedPublisherOption
                      ? selectedPublisherOption
                      : { value: null, label: "All" }
                  }
                />
              );
            }
          : undefined,
      };

      return (
        <TkPostListFilter
          project={project}
          tkPostFilterContext={postFilterContext}
          {...optionalComponents}
        />
      );
    },
    tabLabel: "TkPost",
  },
];

interface Props {
  postFilterContext: React.Context<FilterContextT>;
  columnSetSelect: React.FC<{
    onOpenCustomPanel: () => void;
    width?: string | number;
  }>;
  columnSetType: ColumnSetType;
  project: Project;
  withBrand: boolean;
  withPublisher: boolean;
}

const TkPostListPage: React.FC<Props> = ({
  postFilterContext,
  columnSetSelect,
  columnSetType,
  project,
  withBrand,
  withPublisher,
}) => {
  return (
    <PostListPage
      content={getContent(
        project,
        postFilterContext,
        columnSetSelect,
        columnSetType,
        withBrand,
        withPublisher
      )}
    />
  );
};

export default TkPostListPage;
