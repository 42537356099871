import { COLUMN_SET_FRAGMENT } from "./../fragments/ColumnSetFragment";
import gql from "graphql-tag";

const ALL_COLUMN_SETS = gql`
  query allColumnSets($where: ColumnSetWhere) {
    allColumnSets(where: $where) {
      ...columnSet
    }
  }
  ${COLUMN_SET_FRAGMENT}
`;

export { ALL_COLUMN_SETS };
