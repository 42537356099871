import * as React from "react";

import PostListPage from "./PostListPage";
import { FilterContextT } from "../../providers/FilterProvider";
import { capitalize } from "lodash";
import { Select } from "@freeda/react-components";
import { Project } from "../../types/Project";
import { ColumnSetType } from "../../providers/MetricColumnSetsProvider";
import { FbGroupsPostListFilter } from "../layout/PostListFilter/FbGroupsPostListFilter";
import { UserType } from "../../types/UserType";
import { inclusionPolicyByUserType } from "../../utils/inclusionPolicyByUserType";
import { FbGroupsPostListTableContainer } from "../../container/FbGroupsPostListTable";
import { isDataAvailable } from "../../apollo/utils";
import { useQuery } from "@apollo/react-hooks";
import { ALL_PUBLISHER_INFOS } from "../../apollo/queries/AllPublisherInfos";
import AllPublisherInfosQueryT from "../../types/graphql/ListPublisherQuery";

const useGetContent = (
  publisherNamePage: string,
  project: Project,
  postFilterContext: React.Context<FilterContextT>,
  fbGroupsColumnSetSelect: React.FC<{
    onOpenCustomPanel: () => void;
    width?: string | number;
  }>,
  columnSetType: ColumnSetType
) => {
  const publisherResult = useQuery<AllPublisherInfosQueryT>(
    ALL_PUBLISHER_INFOS
  );
  const isInfosAvailable: boolean = isDataAvailable(publisherResult);

  if (isInfosAvailable) {
    const fbgInfos = publisherResult.data!.allPublisherInfos.filter(
      (p) => p.platformId === "FBG"
    );
    const publisher = publisherResult.data!.allPublisherInfos.find(
      (p) => p.name === publisherNamePage
    )!;

    const content = fbgInfos.map((fbgInfo) => ({
      Table: () => {
        return (
          <FbGroupsPostListTableContainer
            publisherId={publisher.publisherId}
            publisherName={fbgInfo.name}
            project={project}
            fbGroupsPostFilterContext={postFilterContext}
            fbGroupsColumnSetSelect={fbGroupsColumnSetSelect}
            columnSetType={columnSetType}
          />
        );
      },
      Filter: () => {
        const { filterState, callbacks } = React.useContext(postFilterContext);
        return (
          <FbGroupsPostListFilter
            project={project}
            fbGroupsPostFilterContext={postFilterContext}
            userTypeComponent={() => {
              const options = [
                { value: UserType.page, label: capitalize(UserType.page) },
                { value: UserType.user, label: capitalize(UserType.user) },
              ];

              const selectedUserType = options.find(
                (u) =>
                  inclusionPolicyByUserType(u.value) ===
                  filterState.inclusionPolicyUser
              );

              return (
                <Select
                  placeholder="Select user type"
                  label="User type"
                  style={{ padding: "10px 0px" }}
                  onChange={(option: any) =>
                    callbacks.onChangeInclusionPolicyUser(
                      option ? inclusionPolicyByUserType(option.value) : null
                    )
                  }
                  options={options}
                  value={selectedUserType}
                />
              );
            }}
          />
        );
      },
      tabLabel: fbgInfo.name,
    }));

    return <PostListPage content={content} />;
  }

  return <>Loading</>;
};

interface Props {
  publisherNamePage: string;
  postFilterContext: React.Context<FilterContextT>;
  columnSetSelect: React.FC<{
    onOpenCustomPanel: () => void;
    width?: string | number;
  }>;
  columnSetType: ColumnSetType;
  project: Project;
}

const FbGroupsPostListPage: React.FC<Props> = ({
  publisherNamePage,
  postFilterContext,
  columnSetSelect,
  columnSetType,
  project,
}) => {
  const content = useGetContent(
    publisherNamePage,
    project,
    postFilterContext,
    columnSetSelect,
    columnSetType
  );
  return content;
};

export default FbGroupsPostListPage;
