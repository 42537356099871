import styled, { css } from "styled-components";
import { Button } from "@freeda/react-components";
import { Button as ButtonProps } from "@freeda/react-components/lib/Button";
import { Colors } from "@freeda/react-components/lib/theme";

const InfoSection = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-between;
`;

const InfoRow = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 0px;
  position: relative;
`;

const MetricsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  & > div {
    width: 46%;
    margin-bottom: 10px;
  }
  @media (max-width: 480px) {
    & > div {
      width: 100%;
    }
  }
`;

const InputWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 5px;
  padding-top: 5px;
  box-sizing: border-box;
  & > div > input {
    border-color: #999999;
    color: ${(props) => props.theme.primary};
  }
  &.selectedInput {
    & > div > input {
      border-color: ${(props) => props.theme.primary};
    }
  }
`;

const ButtonStyled = styled(Button)<ButtonProps.Props>`
  && {
    color: ${(props) => (props.disabled ? "#C3C3C3" : Colors.GRIGIO)};
    & > span {
      color: ${(props) => (props.disabled ? "#C3C3C3" : Colors.GRIGIO)};
    }
  }
`;

const ImgFrame = styled.img<{ selected?: boolean }>`
  max-height: 80px;
  width: auto;
  border: rgb(242, 242, 242) solid 2px
    ${(props) =>
      props.selected &&
      css`
        border: ${props.theme.azzurrino} solid 2px;
      `};
`;

const FramesWrapper = styled.div`
  display: flex;
  align-items: center;
  margin & > div:not(:last-child) {
    margin-right: 10px;
  }
  /* & > div:last-child {
    padding-right: 33px;
  } */
`;

const ErrorCircle = styled.div<
  {
    badgeBackground?: string;
    badgeFontColor?: string;
    style?: React.CSSProperties;
  } & React.HTMLProps<HTMLDivElement>
>`
  user-select: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #ffffff;
  font-size: 13px;
  background: ${(props) => props.theme.rossino};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  position: absolute;
  top: -4px;
  right: -4px;
`;

export { InfoRow, InfoSection, MetricsWrapper, InputWrapperStyled, ButtonStyled, ImgFrame, FramesWrapper, ErrorCircle };
