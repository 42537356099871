import {
  Box,
  Column,
  Columns,
  SelectField,
  Stack,
  Title,
} from "@freeda/design-system";
import { Platform } from "../../../../types/Platform";
import { Project } from "../../../../types/Project";
import * as React from "react";
import { PaidPerformanceMetricsSectionContainer as PaidPerformanceMetricsSection } from "./PaidPerformanceMetricsSection";
import { useQuery } from "@apollo/react-hooks";
import ListFMKPositionsQuery from "../../../../types/graphql/ListFMKPositions";
import ListFMKMetricObjectives from "../../../../types/graphql/ListFMKMetricObjectives";
import { LIST_FMK_METRIC_OBJECTIVES } from "../../../../apollo/queries/ListFMKMetricObjectives";
import { LIST_FMK_METRIC_POSITIONS } from "../../../../apollo/queries/ListFMKMetricPositions";
import { PaidResultsOverTimeSection } from "./PaidResultsOverTimeSection";

type Props = {
  project: Project;
  platform: Platform;
  languages: { value: string; label: string }[];
  positions: { value: string; label: string }[];
  objectives: { value: string; label: string }[];
};

function PaidMetricsPage(props: Props) {
  const [selLanguages, setSelLanguages] = React.useState<string[]>([]);
  const [selPosition, setSelPosition] = React.useState<string>();
  const [selObjective, setSelObjective] = React.useState<string>();

  React.useEffect(() => {
    setSelLanguages([props.languages[0]].map((language) => language.value));
  }, [props.languages]);

  const langOptions = props.languages.map((language) => ({
    label: language.label,
    value: language.value,
    kind: "single-line" as const,
  }));

  const posOptions = props.positions.map((position) => ({
    label: position.label,
    value: position.value,
    kind: "single-line" as const,
  }));

  const objOptions = props.objectives.map((objective) => ({
    label: objective.label,
    value: objective.value,
    kind: "single-line" as const,
  }));

  const selectLanguages = (languages: string[]) => {
    if (languages.length === 0) return
    setSelLanguages(languages)
  }

  const globalFilters = (
    <Box padding={16} background="brandTertiary">
      <Columns space={24}>
        {langOptions.length > 1 && (
          <Column width="1/4">
            <SelectField
              isMulti
              name="language-filter"
              label="Languages"
              placeholder="Select languages"
              value={selLanguages}
              options={langOptions}
              onChange={selectLanguages}
              hint="When multiple publishers are selected, their respective performance metrics are summed up, except for “Results over time” section where performance metrics are compared."
            />
          </Column>
        )}
        <Column width="1/4">
          <SelectField
            name="position-filter"
            label="Position"
            placeholder="Select a position"
            value={selPosition}
            options={posOptions}
            onChange={setSelPosition}
          />
        </Column>
        <Column width="1/4">
          <SelectField
            name="objectives-filter"
            label="objectives"
            placeholder="Select an objective"
            value={selObjective}
            options={objOptions}
            onChange={setSelObjective}
          />
        </Column>
      </Columns>
    </Box>
  );

  return (
    <Stack dividers space={24}>
      {globalFilters}
      <PaidPerformanceMetricsSection
        selectedPublisherNames={selLanguages}
        project={props.project}
        platform={props.platform}
        position={selPosition}
        objective={selObjective}
      />
      <PaidResultsOverTimeSection
        selectedPublisherNames={selLanguages}
        project={props.project}
        platform={props.platform}
        position={selPosition}
        objective={selObjective}
      />
    </Stack>
  );
}

type ContainerProps = {
  project: Project;
  platform: Platform;
  languages: { value: string; label: string }[];
};

export function PaidMetricsPageContainer(props: ContainerProps) {
  const listPositions = useQuery<ListFMKPositionsQuery>(
    LIST_FMK_METRIC_POSITIONS,
    {
      fetchPolicy: "network-only",
      variables: {
        where: {
          projectId: props.project.id,
          platformId: props.platform,
        },
      },
    }
  );
  const listObjectives = useQuery<ListFMKMetricObjectives>(
    LIST_FMK_METRIC_OBJECTIVES,
    {
      fetchPolicy: "network-only",
      variables: {
        where: {
          projectId: props.project.id,
        },
      },
    }
  );

  if (
    !listObjectives.loading &&
    !!listObjectives.data &&
    !listPositions.loading &&
    !!listPositions.data
  ) {
    const positions = listPositions.data.listMetricPositions
      .filter((position) =>
        Object.keys(positionsLabels[props.platform]).includes(position.id)
      )
      .map((position) => ({
        value: position.id,
        label: positionsLabels[props.platform][position.id],
      }));
    const objectives = listObjectives.data.listMetricObjectives
      .filter((objective) =>
        Object.keys(objectivesLabels).includes(objective.id)
      )
      .map((objective) => ({
        value: objective.id,
        label: objectivesLabels[objective.id],
      }));
    return (
      <PaidMetricsPage
        positions={positions}
        objectives={objectives}
        {...props}
      />
    );
  } else {
    return <Title size="medium">Loading ...</Title>;
  }
}

const positionsLabels = {
  IG: {
    instagram_feed: "Feed",
    instagram_instagram_explore: "Explore",
    instagram_instagram_explore_grid_home: "Explore Grid Home",
    instagram_instagram_profile_feed: "Profile Feed",
    instagram_instagram_reels: "Reels",
    instagram_instream_shop: "Shop", // it look lie it is available only up until 2022-04-14
    instagram_instagram_stories: "Stories",
  },
  FB: {
    facebook_ads_on_facebook_reels: "Ads On Facebook Reels",
    facebook_facebook_groups_feed: "Groups feed",
    facebook_facebook_reels_overlay: "Reels Overlay",
    facebook_facebook_stories: "Stories",
    facebook_feed: "Feed",
    facebook_instant_article: "Instant Article",
    facebook_instream_video: "Instream Video",
    facebook_marketplace: "Marketplace",
    facebook_right_hand_column: "Right Hand Column"
  },
};

const objectivesLabels = {
  BRAND_AWARENESS: "Brand Awereness",
  CONVERSIONS: "Conversions",
  EVENT_RESPONSES: "Event Responses",
  LEAD_GENERATION: "Lead Generation",
  LINK_CLICKS: "Link Clicks",
  MESSAGES: "Messages",
  OUTCOME_AWARENESS: "Outcome Awareness",
  OUTCOME_ENGAGEMENT: "Outcome Engagement",
  OUTCOME_SALES: "Outcome Sales",
  OUTCOME_TRAFFIC: "Outcome Traffic",
  PAGE_LIKES: "Page Likes",
  POST_ENGAGEMENT: "Post Engagement",
  PRODUCT_CATALOG_SALES: "Product Catalog Sales",
  REACH: "Reach",
  VIDEO_VIEWS: "Video Views",
};

export const paidMetricsByPlatform = {
  spend: "Spend",
  impressions: "Impressions",
  link_click: "Link Click",
  page_engagement: "Page Engagement",
  video_view: "Video View",
  cpm: "CPM",
  cpc: "CPC",
  cpi: "CPI",
  cpv: "CPV",
};

export const paidResultsOverTimeMetricsByPlatform = {
  spend: "Spend",
  impressions: "Impressions",
  link_click: "Link Click",
  page_engagement: "Page Engagement",
  video_view: "Video View",
  cpm: "CPM",
  cpc: "CPC",
  cpi: "CPI",
  cpv: "CPV",
};
