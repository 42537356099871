import * as React from "react";

import Media from "react-media";
import { AdvancedFilterMetadata } from "./AdvancedFilterMetadata";
import { CSSTransition } from "react-transition-group";
import { SearchFilter } from "../../ui/SearchFilter/SearchFilter";

interface Props {
  searchValue: string;
  metadataActiveOptions: Array<{ value: string; label: string }>;
  onChangeActiveOptions: (option: Array<{ value: string; label: string }>) => void;
  selectedCategoryId: string | null;
  clearFilter: () => void;
  onTextFilterChange: (value: string) => void;
  onCategorySelection: (categoryId: string) => void;
  showFilter: () => void;
  showFilterMobile: boolean;
  selectedProjects: Array<{ value: string; label: string }>;
  onChangeProject: (project: { value: string; label: string }) => void;
}

const MetadataFilter: React.FC<Props> = ({
  searchValue,
  metadataActiveOptions,
  onChangeActiveOptions,
  selectedCategoryId,
  clearFilter,
  onTextFilterChange,
  onCategorySelection,
  showFilter,
  showFilterMobile,
  selectedProjects,
  onChangeProject
}) => {
  return (
    <Media query={{ maxWidth: 768 }}>
      {(matches: boolean) => {
        if (!matches) {
          return (
            <>
              <SearchFilter
                clearFilter={clearFilter}
                onTextFilterChange={onTextFilterChange}
                searchValue={searchValue}
                placeholder="Search by label"
              />
              <AdvancedFilterMetadata
                metadataActiveOptions={metadataActiveOptions}
                onChangeActiveOptions={onChangeActiveOptions}
                selectedCategoryId={selectedCategoryId}
                onCategorySelection={onCategorySelection}
                onChangeProject={onChangeProject}
                selectedProjects={selectedProjects}
              />
            </>
          );
        }
        return (
          <div>
            <SearchFilter
              clearFilter={clearFilter}
              onTextFilterChange={onTextFilterChange}
              searchValue={searchValue}
              showFilter={() => showFilter && showFilter()}
              placeholder="Search by label"
            />
            <CSSTransition in={showFilterMobile} timeout={300} classNames="" unmountOnExit>
              {status => (
                <AdvancedFilterMetadata
                  metadataActiveOptions={metadataActiveOptions}
                  onChangeActiveOptions={onChangeActiveOptions}
                  selectedCategoryId={selectedCategoryId}
                  onCategorySelection={onCategorySelection}
                  transitionStatus={status}
                  onChangeProject={onChangeProject}
                  selectedProjects={selectedProjects}
                />
              )}
            </CSSTransition>
          </div>
        );
      }}
    </Media>
  );
};

export default MetadataFilter;
