import * as React from "react";

const FeedbackContext = React.createContext<{
  open: boolean;
  message: string | React.ReactElement<any>;
  messageType: MessageType;
  openSnackbar: (message: string | React.ReactElement<any>, type: MessageType) => void;
  onClose: () => void;
}>({
  open: false,
  message: "",
  messageType: "notification",
  openSnackbar: (message: string | React.ReactNode, type: MessageType) =>
    console.warn("Please implement FeedbackProvider.openSnackbar"),
  onClose: () => console.warn("Please implement FeedbackProvider.onClose")
});

export type MessageType = "notification" | "error" | "download";

interface State {
  open: boolean;
  message: string | React.ReactElement<any>;
  messageType: MessageType;
}

const FeedbackProvider: React.FC = ({ children }) => {
  const [state, setState] = React.useState<State>({
    open: false,
    message: "",
    messageType: "notification"
  });

  const openSnackbar = (message: string | React.ReactElement<any>, type: MessageType) => {
    setState({ open: true, message, messageType: type });
  };

  const onClose = () => {
    setState({ open: false, message: "", messageType: "notification" });
  };

  return (
    <FeedbackContext.Provider
      value={{
        ...state,
        openSnackbar: openSnackbar,
        onClose: onClose
      }}
    >
      {children}
    </FeedbackContext.Provider>
  );
};

export { FeedbackProvider, FeedbackContext };
