import * as React from "react";
import Media from "react-media";
import { HeaderLogoQuote } from "../ui/HeaderLogoQuote";
import { DividerSection } from "@freeda/react-components";
import { StateButton } from "../ui/StateButton";
import { parse } from "query-string";
import styled from "styled-components";
import { transparentScrollbar } from "../ui/ScrollBarStyle";

const WrapperStateButton = styled.div`
  overflow-x: scroll;
  ${transparentScrollbar}
`;

interface State {
  activeTab: number;
  tabLabel: string | null;
}

interface Props {
  content: Array<{
    Table: React.FC;
    Filter: React.FC;
    tabLabel: string;
  }>;
}

export const changePath = (tabLabel: string): void => {
  const initialPathname = window.location.pathname;
  window.history.replaceState(
    window.history.state,
    initialPathname,
    `${initialPathname}?tab=${tabLabel.toLowerCase()}`
  );
};

const PostListPage: React.FC<Props> = ({ content }) => {
  const [state, setState] = React.useState<State>({
    activeTab: 0,
    tabLabel: null,
  });


  React.useEffect(() => {
    const currentTab = parse(window.location.search).tab;

    if (content.length > 1) {
      const activeTab = currentTab
        ? content.findIndex((c) => c.tabLabel.toLowerCase() === currentTab.toLowerCase())
        : 0;
      if (activeTab > 0) {
        setState((state) => ({ ...state, activeTab }));
      } else {
        const firstAvailableTab = content[0];
        changePath(firstAvailableTab.tabLabel);
      }
    }
  }, []);

  const handleTabChange = (activeTab: number, tabLabel: string) => {
    setState((state) => ({ ...state, activeTab, tabLabel }));
  };

  React.useEffect(() => {
    if (state.tabLabel) {
      changePath(state.tabLabel);
    }
  }, [state.tabLabel]);

  const ActiveFilter = content[state.activeTab].Filter;
  const ActiveTable = content[state.activeTab].Table;

  return (
    <div style={{ height: "100%" }}>
      <Media query={{ minWidth: 769 }}>{(desktop: boolean) => <>{desktop && <HeaderLogoQuote />}</>}</Media>
      <ActiveFilter />
      {content.length > 1 && (
        <div>
          <DividerSection style={{ position: "relative", top: 42 }} />
          <WrapperStateButton style={{ display: "flex", alignItems: "center", position: "relative", zIndex: 2 }}>
            {content.map((c, index) => {
              return (
                <StateButton
                  key={index}
                  isactive={state.activeTab === index}
                  flat
                  onClick={() => handleTabChange(index, c.tabLabel)}
                >
                  {c.tabLabel}
                </StateButton>
              );
            })}
          </WrapperStateButton>
        </div>
      )}
      <ActiveTable />
    </div>
  );
};

export default PostListPage;
