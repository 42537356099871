import * as React from "react";
import { ApolloProvider } from "@apollo/react-hooks";
import { FreedaThemeProvider } from "@freeda/react-components";
import client from "../apollo/client";
import { FeedbackProvider } from "./FeedbackProvider";
import { MetricColumnSetsProvider } from "./MetricColumnSetsProvider";
import { SidebarProvider } from "./SidebarProvider";
import { FreedaDesignSystem } from "../utils/DesignSystemProvider";
import { ProjectPageContext, ProjectPageProvider } from "./ProjectPageProvider";


const PostFilterProvider: React.FC = ({ children }) => {

    const { pageUnits } = React.useContext(ProjectPageContext);

    const filterProviders = pageUnits.map(
      ({ pagesFilterProvidersAndContexts }) =>
        pagesFilterProvidersAndContexts.Provider
    );

    return filterProviders.reduce(
      (Acc, Next) => <Next>{Acc}</Next>,
      <>{children}</>
    );
 // }
  
};

const ProvidersWrapper: React.FC = ({ children }) => {

  
  return (
    <FreedaDesignSystem>
      <FreedaThemeProvider>
        <FeedbackProvider>
          <ApolloProvider client={client}>
          <ProjectPageProvider>
            <SidebarProvider>
              <PostFilterProvider>
                <MetricColumnSetsProvider>{children}</MetricColumnSetsProvider>
              </PostFilterProvider>
            </SidebarProvider>
            </ProjectPageProvider>
          </ApolloProvider>
        </FeedbackProvider>
      </FreedaThemeProvider>
    </FreedaDesignSystem>
  );
  
};

export { ProvidersWrapper };
