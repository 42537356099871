export enum ProjectId {
  FREEDA = "FREEDA",
  GOOVI = "GOOVI",
  FLORENA = "FLORENA",
  TRUSSARDI = "TRUSSARDI",
  PRADA = "PRADA",
  SUPERFLUID = "SUPERFLUID",
  REPOWER = "REPOWER",
  KFC = "KFC",
  LAVAZZA1895 = "LAVAZZA1895",
  GOLDEN_GOOSE = "GOLDEN_GOOSE",
  LEMONSODA = "LEMONSODA",
  SPRINTER = "SPRINTER",
  TECHNOGYM = "TECHNOGYM",
  LAVAZZA = "LAVAZZA",
  TRIUMPH = 'TRIUMPH',
  ARMANI = 'ARMANI',
  COOP = "COOP",
  JEEP = 'JEEP',
  EMPORIOARMANI = 'EMPORIOARMANI',
  MOONBOOT = 'MOONBOOT',
  KIATEINSPIRA = 'KIATEINSPIRA',
  DIESEL = 'DIESEL',
  MEDIOLANUM = 'MEDIOLANUM',
  ULE = 'ULE',
  MARCA = 'MARCA',
  TODS = 'TODS',
  NORDICA = 'NORDICA',
  ASSOS = 'ASSOS',
  ROLLERBLADE = 'ROLLERBLADE',
  GUCCIEQUILIBRIUM = 'GUCCIEQUILIBRIUM',
  ODSTORE = 'ODSTORE',
  ING = "ING",
  BLIZZARDSKI = "BLIZZARDSKI",
  TECNICA = "TECNICA",
  PANFE = "PANFE",
  AUTRY = "AUTRY",
  SOMATOLINESKIN = "SOMATOLINESKIN",
  KIA = "KIA",
  NOVAMAS = "NOVAMAS",
  FLOOXERNOW = "FLOOXERNOW",
  ANTENA3 = "ANTENA3",
  ATRESPLAYER = "ATRESPLAYER",
  AIRC = "AIRC",
  TELEFONICA="TELEFONICA",
  GCDS="GCDS",
  PINKO="PINKO",
  HUMANAITALIA="HUMANAITALIA",
  CHILLY="CHILLY",
  ENTJAPAN = "ENTJAPAN",
  LAVIKA="LAVIKA"
}

export enum ProjectType {
  ENGAGE = "ENGAGE",
  LISTEN = "LISTEN", //tag in ELinor
  FAN = "FAN", //tag in ELinor (Ule)
  HYBRID_PARTIAL = "HYBRID_PARTIAL", //tipo ENGAGE (jeep emporio)
  HYBRID_FULL = "HYBRID_FULL", //tag in ELinor (goldengoose)
  TRANSACT = "TRANSACT" //tipo ENGAGE
}

export enum UserGroupType {
  FREEDA = "FREEDA",
  PLATFORM = "PLATFORM", 
  EXECUTIVE = "EXECUTIVE"
  
}
