import * as React from "react";

import { FilterContextT } from "../../../providers/FilterProvider";
import { AutocompleteMetadata } from "./AutocompleteMetadata";
import { Project } from "../../../types/Project";

export const AutocompleteMetadataFilter: React.FC<{
  FilterContext: React.Context<FilterContextT>;
  categoryId: string | null;
  project: Project;
}> = ({ FilterContext, categoryId, project }) => {
  const { callbacks, filterState } = React.useContext(FilterContext);

  const { onChangeMetadata, onChangeLogicalOperator } = callbacks;
  const { selectedMetadata, logicalOperator } = filterState;

  return (
    <AutocompleteMetadata
      selectedMetadata={selectedMetadata}
      logicalOperator={logicalOperator}
      onChangeMetadata={onChangeMetadata}
      onChangeLogicalOperator={onChangeLogicalOperator}
      categoryId={categoryId}
      project={project}
    />
  );
};
