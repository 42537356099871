import * as React from "react";
import { Dialogable } from "../../../types/Dialogable";
import Media from "react-media";
import { Dialog, ButtonIcon, Typography, Button } from "@freeda/react-components";

type Props = Dialogable & {
  storyTitle: string;
  loadingDelete: boolean;
  onDeleteStory: () => void;
};

const DeleteStoryDialog: React.FC<Props> = ({ storyTitle, open, onClose, loadingDelete, onDeleteStory }) => {
  const confirmWithKeyboard = async (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      await onDeleteStory();
      onClose();
    }
  };

  React.useEffect(() => {
    window.addEventListener("keydown", confirmWithKeyboard);

    return () => window.removeEventListener("keydown", confirmWithKeyboard);
  });

  return (
    <Media query={{ maxWidth: 768 }}>
      {(matches: boolean) => (
        <Dialog
          rootIdAttributes="root"
          openDialog={open}
          onClose={onClose}
          fullScreen={matches}
          style={{
            width: !matches ? 450 : ""
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 15
            }}
          >
            <Typography variantName="subtitle" textAlign="center" textColor="#6B20DA">
              {storyTitle}
            </Typography>
            <ButtonIcon onClick={onClose} iconName="close" />
          </div>
          <Typography variantName="subtitle" textAlign="center" textColor="#6B20DA" style={{ padding: "25px 0px" }}>
            You are deleting the story, you will not be able to recover this.
          </Typography>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button action="undo" onClick={onClose} style={{ marginRight: 20 }}>
              Don't do it
            </Button>
            <Button
              loading={loadingDelete}
              onClick={async () => {
                await onDeleteStory();
                onClose();
              }}
            >
              I'm sure, delete it
            </Button>
          </div>
        </Dialog>
      )}
    </Media>
  );
};

export { DeleteStoryDialog };
