import gql from "graphql-tag";

export const LIST_STAND_ALONE_METRICS = gql`
  query listStandAloneMetrics(
    $where: StandAloneMetricsWhere!
  ) {
    listStandAloneMetrics(where: $where) {
            name,
            value,
    }
  }
`;