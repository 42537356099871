import * as React from "react";
import {
  Card,
  Headline,
  Inline,
  Stack,
  Title,
  IconArrowUpSolid,
  Body,
  IconArrowDownSolid,
} from "@freeda/design-system";
import { PerfMetricsTimeSpan } from "../../types/PerfMetricsTimeSpan";
import { formatNumber } from "../../utils/formatNumber";
import { ConversionUnit } from "../../types/ConversionUnit";

type Props = {
  title: string;
  value: number;
  performace?: Performance;
};

type Performance = {
  diff: number;
  span: PerfMetricsTimeSpan;
};

export function MetricCard(props: Props) {
  return (
    <Card padding={24} elevation="small">
      <Stack space={4} align="left">
        <Title size="medium">{props.title}</Title>
        <Headline size="medium">
          {formatNumber(props.value, ConversionUnit.RAW)}
        </Headline>
        {props.performace && (
          <PerformanceReport
            diff={props.performace.diff}
            span={props.performace.span}
          />
        )}
      </Stack>
    </Card>
  );
}

function PerformanceReport(props: Performance) {
  const icon =
    props.diff < 0 ? (
      <IconArrowDownSolid size={16} color="negative" />
    ) : (
      <IconArrowUpSolid size={16} color="positive" />
    );

  const color = props.diff < 0 ? ("negative" as const) : ("positive" as const);

  const sign = props.diff < 0 ? "" : "+";

  const periodLabel = ((period: Performance["span"]) => {
    switch (period) {
      case "last_week":
        return "from last week";
      case "last_month":
        return "from last month";
      case "last_3_months":
        return "from last 3 months";
    }
  })(props.span);

  return (
    <Inline space={4} align="left" alignY="center">
      {icon}
      <Body size="medium" color={color} weight="strong">
        {[sign, formatNumber(props.diff, ConversionUnit.PERCENTAGE)].join("")}
      </Body>
      <Body size="small" color="secondary">
        {periodLabel}
      </Body>
    </Inline>
  );
}
