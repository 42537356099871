import gql from "graphql-tag";

const ALL_PROJECT = gql`
  {
    allProject {
      id
      idLegacy
      label
    }
  }
`;

export { ALL_PROJECT };
