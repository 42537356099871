import gql from "graphql-tag";
import { SOCIAL_CONTENT_ID_FRAGMENT } from "../fragments/SocialContentIdFragment";

const EXPORT_FB_CONTENT_METADATA = gql`
  query exportContentMetadata(
    $orderBy: FacebookContentOrderBy
    $where: FacebookContentWhere
    $metricWhere: MeasuredMetricWhere
  ) {
    export: allFacebookContents(orderBy: $orderBy, where: $where, metricWhere: $metricWhere) {
      id {
        ...socialContentId
      }
      permalink
      caption
      title
      type
      publicationDate
      userDefinedMetadata {
        id
        label
        category {
          id
          label
          area
        }
      }
      measuredMetrics {
        id {
          platformId
          publisherId
          contentId
          granularity
          name
          key
          timestamp
        }
        value
        conversionUnit
        position
      }
    }
  }
  ${SOCIAL_CONTENT_ID_FRAGMENT}
`;

export { EXPORT_FB_CONTENT_METADATA };
