import gql from "graphql-tag";

const DELETE_COLUMN_SET = gql`
  mutation deleteColumnSet($input: DeleteColumnSetInput!) {
    deleteColumnSet(input: $input) {
      id
    }
  }
`;

export { DELETE_COLUMN_SET };
