import styled, { css } from "styled-components";
import { Table } from "@freeda/react-components";

const ActionsIcon = styled.div`
  position: relative;
`;

const TableStyled = styled(Table)<{ paddingLeftFirstTh?: string }>`
  && {
    & > thead > tr > th:first-child {
      padding-left: ${(props) => (props.paddingLeftFirstTh ? props.paddingLeftFirstTh : "50px")};
    }
    & > thead > tr > th {
      white-space: initial;
    }
    & > tbody > tr > td:first-child {
      height: 80px;
    }
  }
`;

const ImgPost = styled.img`
  max-width: 80px;
  height: auto;
`;

const FrameImgPost = styled.img<{ selected?: boolean }>`
  max-height: 80px;
  width: auto;
  border: rgb(242, 242, 242) solid 2px
    ${(props) =>
      props.selected &&
      css`
        border: ${props.theme.azzurrino} solid 2px;
      `};
`;

const ImgStory = styled.img`
  max-height: 80px;
  width: auto;
  z-index: 2;
`;

const BkgStory = styled.div`
  width: 45px;
  height: 80px;
  background: ${(props) => props.theme.primary};
  position: absolute;
  left: 21px;
  top: 14px;
  z-index: -1;
`;

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  transition: right 0.4s;
  &.wrapper-entering {
    right: -30px;
  }
  &.wrapper-entered {
    right: 30px;
  }
  &.wrapper-exiting {
    right: -30px;
  }
  &.wrapper-exited {
    right: -30px;
  }
`;

export { ActionsIcon, TableStyled, ImgPost, SelectWrapper, FrameImgPost, ImgStory, BkgStory, ActionsWrapper };
