import styled from "styled-components";

const ComponentsWrapper = styled.div`
  box-sizing: border-box;
  position: relative;

  @media (min-width: 769px) {
    margin-left: 100px;
    margin-right: 15px;
    padding-top: 15px;
  }

  @media (max-width: 768px) {
    padding-top: 55px;
    box-sizing: border-box;
  }
`;

export { ComponentsWrapper };
