import * as React from "react";
import { useMutation, useQuery } from "react-apollo";
import AllColumnSetQueryT from "../types/graphql/AllColumnSetsQuery";
import { Dialogable } from "../types/Dialogable";
import { DeleteStoryDialog } from "../components/layout/DeleteStoryDialog";
import { FeedbackContext } from "../providers/FeedbackProvider";
import { InstagramStoryContent } from "../types/InstagramStoryContent";
import { FilterContextT } from "../providers/FilterProvider";
import { LIST_INSTAGRAM_STORIES } from "../apollo/queries/ListInstagramStories";
import { PublicationType } from "../types/Publication";
import {
  isDataAvailable,
  buildOrderByClause,
  buildIgStoryContentFilter,
} from "../apollo/utils";
import {
  ColumnSetType,
  MetricColumnSetsContext,
} from "../providers/MetricColumnSetsProvider";
import { ProjectId } from "../types/ProjectId";
import { DELETE_INSTAGRAM_STORY } from "../apollo/mutations/DeleteInstagramStory";
import { ALL_COLUMN_SETS } from "../apollo/queries/AllColumnSets";
import { SocialContentId } from "../types/graphql/SocialContentId";
import { StaticIdsContext } from "../providers/StaticIdsProvider";

const DeleteStoryDialogContainer: React.FC<
  Dialogable & {
    selectedStory: InstagramStoryContent;
    FilterContext: React.Context<FilterContextT>;
    columnSetType: ColumnSetType;
    projectId: ProjectId;
  }
> = ({
  open,
  onClose,
  selectedStory,
  FilterContext,
  columnSetType,
  projectId,
}) => {
  const { selectedSets } = React.useContext(MetricColumnSetsContext);
  const { openSnackbar } = React.useContext(FeedbackContext);
  const { filterState } = React.useContext(FilterContext);

  const {
    currentPage,
    postOrderBy,
    metricOrderBy,
    pageSize,
    order,
  } = filterState;
  const columnSetName = selectedSets[columnSetType] || "IG_STORIES";

  const allColumnSetResult = useQuery<AllColumnSetQueryT>(ALL_COLUMN_SETS, {
    variables: {
      where: {
        publicationId: PublicationType.IG_STORY,
        name: columnSetName,
        custom: Boolean(selectedSets[columnSetType]),
      },
    },
  });

  const areColumnSetsLoaded = isDataAvailable(allColumnSetResult);
  const columnSetId =
    areColumnSetsLoaded && allColumnSetResult.data!.allColumnSets.length > 0
      ? allColumnSetResult.data!.allColumnSets[0].id
      : undefined;

  const staticIdsContext = React.useContext(StaticIdsContext);

  const wheresFilter = buildIgStoryContentFilter(
    filterState,
    projectId,
    columnSetId,
    staticIdsContext.brandedId
  );
  const orderBy = buildOrderByClause(metricOrderBy, postOrderBy, order);

  const [deleteInstagramStory, deleteInstagramStoryResult] = useMutation<{
    deleteInstagramStory: { id: SocialContentId };
  }>(DELETE_INSTAGRAM_STORY, {
    refetchQueries: [
      {
        query: LIST_INSTAGRAM_STORIES,
        variables: {
          offset: currentPage * pageSize,
          limit: pageSize,
          orderBy,
          ...wheresFilter,
        },
      },
    ],
  });

  return (
    <DeleteStoryDialog
      storyTitle={selectedStory.title}
      open={open}
      onClose={onClose}
      loadingDelete={deleteInstagramStoryResult.loading}
      onDeleteStory={async () => {
        await deleteInstagramStory({
          variables: {
            input: {
              id: {
                publisherId: selectedStory.id.publisherId,
                contentId: selectedStory.id.contentId,
              },
            },
          },
        });

        onClose();
        openSnackbar("Delete story correctly!", "notification");
      }}
    />
  );
};

export { DeleteStoryDialogContainer };
