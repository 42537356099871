import gql from "graphql-tag";

export const LABEL_BY_NAME = gql`
  query labelByName($name: String!) {
    labelByName(name: $name) {
      id
      label
      active
      category {
        id
        label
        area
      }
      visibleFor {
        id
        label
        active
        category {
          id
          label
          area
        }
      }
      projects {
        id
        label
      }
    }
  }
`;
