import * as React from "react";
import styled from "styled-components";
import { HeaderLogoQuote } from "./HeaderLogoQuote";
import { BackButton } from "./BackButton";

const HeaderWrapper = styled.div`
  position: fixed;
  background: #ffffff;
  width: 100vw;
  height: 65px;
  display: flex;
  align-items: center;
  color: rgb(107, 32, 218);
  font-size: 14px;
  font-weight: 400;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 6px 0px;
  padding: 10px;
  box-sizing: border-box;
`;

interface Props {
  onClose: () => void;
  style?: React.CSSProperties;
}

const HeaderPage: React.SFC<Props> = ({ onClose, style }) => {
  return (
    <HeaderWrapper style={style}>
      <BackButton onClick={onClose} />
      <HeaderLogoQuote style={{ position: "relative", marginLeft: "auto", top: 0 }} />
    </HeaderWrapper>
  );
};

export { HeaderPage };
