import * as React from "react";
import { FilterContextT } from "../providers/FilterProvider";
import { FacebookContent } from "../types/FacebookContent";
import { FacebookPostType } from "../types/FacebookPostType";
import { NotFound } from "../components/ui/NotFound";
import {
  isDataAvailable,
  buildOrderByClause,
  buildFbContentFilter,
} from "../apollo/utils";
import { PublicationType } from "../types/Publication";
import { PlaceholderPostList } from "../components/ui/Placeholders/PlaceholderPostList";
import { Average } from "../types/Average";
import {
  ColumnSetType,
  MetricColumnSetsContext,
} from "../providers/MetricColumnSetsProvider";
import { EXPORT_FB_CONTENT_METADATA } from "../apollo/queries/ExportFbContentMetadata";
import {
  buildFacebookTableColumns,
  defaultFacebookTableHead,
} from "../utils/postTableUtils";
import PostListTable from "../components/layout/PostListTable";
import { LIST_FB_CONTENT_METADATA } from "../apollo/queries/ListFbContentMetadata";
import { Project } from "../types/Project";
import { Platform } from "../types/Platform";
import AllColumnSetQueryT from "../types/graphql/AllColumnSetsQuery";
import { ALL_COLUMN_SETS } from "../apollo/queries/AllColumnSets";
import { useQuery, useMutation } from "@apollo/react-hooks";
import ListFbContentQueryT from "../types/graphql/ListFbContentQuery";
import { UPDATE_FB_CONTENT } from "../apollo/mutations/UpdateFbContent";
import { StaticIdsContext } from "../providers/StaticIdsProvider";
import { stat } from "fs";
import { ProjectId } from "../types/ProjectId";

interface Props {
  project: Project;
  fbPostFilterContext: React.Context<FilterContextT>;
  fbColumnSetSelect: React.FC<{
    onOpenCustomPanel: () => void;
    width?: string | number;
  }>;
  columnSetType: ColumnSetType;
}

const FbPostListTableContainer: React.FC<Props> = ({
  fbPostFilterContext,
  project,
  columnSetType,
  fbColumnSetSelect,
}) => {
  const { callbacks, filterState } = React.useContext(fbPostFilterContext);
  const {
    onChangePage,
    onChangePageSize,
    onBackPage,
    onNextPage,
    onChangePostOrder,
    onChangeMetricOrder,
  } = callbacks;
  const {
    selectedFormat,
    currentPage,
    pageSize,
    metricOrderBy,
    postOrderBy,
    order,
  } = filterState;

  const { selectedSets } = React.useContext(MetricColumnSetsContext);
  const selectedSet = selectedSets[columnSetType];
  const columnSetName =
    selectedSet || `${selectedFormat || "ALL"}_${PublicationType.FB_POST}`;
  const custom = Boolean(selectedSet);

  const allColumnSetResult = useQuery<AllColumnSetQueryT>(ALL_COLUMN_SETS, {
    variables: {
      where: {
        publicationId: PublicationType.FB_POST,
        name: columnSetName,
        custom,
      },
    },
  });

  const areColumnSetsLoaded = isDataAvailable(allColumnSetResult);
  const columnSetId =
    areColumnSetsLoaded && allColumnSetResult.data!.allColumnSets.length > 0
      ? allColumnSetResult.data!.allColumnSets[0].id
      : undefined;

  const staticIdsContext = React.useContext(StaticIdsContext);

  const wheresFilter = buildFbContentFilter(
    filterState,
    selectedFormat && FacebookPostType[selectedFormat],
    project.idLegacy,
    columnSetId,
    staticIdsContext.brandedId
  );
  const orderBy = buildOrderByClause(metricOrderBy, postOrderBy, order);

  const listFbContentResult = useQuery<ListFbContentQueryT>(
    LIST_FB_CONTENT_METADATA,
    {
      fetchPolicy: "network-only",
      variables: {
        offset: currentPage * pageSize,
        limit: pageSize,
        orderBy,
        ...wheresFilter,
      },
    }
  );

  let posts: Array<FacebookContent> = [];
  let averages: Array<Average> = [];
  let count: number = 0;
  let totalPages: number = 0;
  const loading = listFbContentResult.loading;

  const updateFbContent = useMutation(UPDATE_FB_CONTENT);

  if (
    listFbContentResult.data &&
    Object.keys(listFbContentResult.data).length > 0
  ) {
    posts = listFbContentResult.data!.listFacebookContents.content;
    averages = listFbContentResult.data!.listFacebookContents.subAggregate
      .averages;
    count = listFbContentResult.data!.listFacebookContents.aggregate.count;
    totalPages = Math.ceil(count / pageSize);
  }

  if (!areColumnSetsLoaded || (loading && posts.length === 0)) {
    return <PlaceholderPostList />;
  }

  if (posts.length === 0 && !loading) {
    return <NotFound>No post found! :(</NotFound>;
  }

  return (
    <PostListTable
      defaultHead={defaultFacebookTableHead}
      buildColumns={buildFacebookTableColumns}
      posts={posts}
      averages={averages}
      loading={loading}
      currentPage={currentPage}
      pageSize={pageSize}
      totalPages={totalPages}
      onChangePageSize={onChangePageSize}
      onChangePage={onChangePage}
      onBackPage={onBackPage}
      onNextPage={() => onNextPage(totalPages)}
      onChangePostOrder={onChangePostOrder}
      onChangeMetricOrder={onChangeMetricOrder}
      count={count}
      publicationType={PublicationType.FB_POST}
      platform={Platform.FB}
      PostFilterContext={fbPostFilterContext}
      updateSocialContentMetadataMutation={updateFbContent}
      ColumnSetSelect={fbColumnSetSelect}
      exportQuery={EXPORT_FB_CONTENT_METADATA}
      columnSetType={columnSetType}
      project={project}
      listContentMetadataQuery={LIST_FB_CONTENT_METADATA}
      wheresFilter={wheresFilter}
      orderBy={metricOrderBy || postOrderBy}
      order={order}
    />
  );
};

export { FbPostListTableContainer };
