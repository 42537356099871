import * as React from "react";
import { InstagramStoryContent } from "../../../types/InstagramStoryContent";
import { Average } from "../../../types/Average";
import { PublicationType } from "../../../types/Publication";
import { PostOrderBy } from "../../../types/graphql/PostOrderBy";
import {
  MetricOrderBy,
  FilterContextT,
} from "../../../providers/FilterProvider";
import {
  buildStoriesColumns,
  storyDefaultHead,
} from "../../../utils/framesAndStoriesTableUtils";
import { EditStoryContainer } from "../../../container/EditStory";
import { CSSTransition } from "react-transition-group";
import { DeleteStoryDialogContainer } from "../../../container/DeleteStoryDialog";
import { StoryTable } from "./StoryTable";
import { getRealSizeAndOptionsSelect } from "../../../utils/getRealSizeAndOptionsSelect";
import { EditPostMetadataContainer } from "../../../container/EditPostMetadata";
import { DetailsFramesPanelContainer } from "../../../container/DetailsFramesPanel";
import { buildStoryMetricTableHeader } from "../../../utils/buildPostTableHeader";
import {
  ColumnSetType,
  MetricColumnSetsContext,
} from "../../../providers/MetricColumnSetsProvider";
import { CustomMetricsContainer } from "../../../container/CustomMetrics";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { Project } from "../../../types/Project";
import { buildOrderByClause } from "../../../apollo/utils";
import { LIST_INSTAGRAM_STORIES } from "../../../apollo/queries/ListInstagramStories";
import { Platform } from "../../../types/Platform";
import { useMutation } from "react-apollo";
import { UPDATE_INSTAGRAM_STORY } from "../../../apollo/mutations/UpdateInstagramStory";
import { Order } from "../../../types/Order";
import { ProjectType } from "../../../types/ProjectId";

interface Props {
  stories: Array<InstagramStoryContent>;
  averages: Array<Average>;
  loading: boolean;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  style?: React.CSSProperties;
  count: number;
  onChangePageSize: (numberPage: string) => void;
  onChangePage: (page: number) => void;
  onBackPage: () => void;
  onNextPage: () => void;
  onChangePostOrder: (orderBy: PostOrderBy, order: Order) => void;
  onChangeMetricOrder: (metricOrder: MetricOrderBy, order: Order) => void;
  FilterContext: React.Context<FilterContextT>;
  exportQuery: any;
  StoriesColumnSetSelect: React.FC<{
    onOpenCustomPanel: () => void;
    width?: string | number;
  }>;
  columnSetType: ColumnSetType;
  project: Project;
  wheresFilter: {};
  orderBy: PostOrderBy | MetricOrderBy | null;
  order: Order;
}

interface State {
  storyId: string | null;
  isEditStoryMetadataOpen: boolean;
  isEditStoryPanelOpen: boolean;
  isDeleteStoryDialogOpen: boolean;
  isDetailsFramesPanelOpen: boolean;
  showCustomMetrics: boolean;
}

const IGStoriesListTable: React.FC<Props> = ({
  averages,
  currentPage,
  pageSize,
  totalPages,
  onChangePageSize,
  onChangePage,
  onBackPage,
  onNextPage,
  onChangePostOrder,
  onChangeMetricOrder,
  style,
  stories,
  loading,
  count,
  FilterContext,
  exportQuery,
  StoriesColumnSetSelect,
  columnSetType,
  project,
  wheresFilter,
  orderBy,
  order,
}) => {
  const [state, setState] = React.useState<State>({
    storyId: null,
    isEditStoryMetadataOpen: false,
    isEditStoryPanelOpen: false,
    isDeleteStoryDialogOpen: false,
    isDetailsFramesPanelOpen: false,
    showCustomMetrics: false,
  });
  

  const { filterState } = React.useContext(FilterContext);
  const { selectedSets } = React.useContext(MetricColumnSetsContext);

  const maxWidth = useWindowSize().windowWidth;

  const isDisabled = [ProjectType.ENGAGE, ProjectType.TRANSACT, ProjectType.HYBRID_PARTIAL].includes(project.projectType!) ? true : false;


  const closeEditStoryMetadataPanel = () =>
    setState((state) => ({ ...state, isEditStoryMetadataOpen: false }));
  const closeEditStoryPanel = () =>
    setState((state) => ({ ...state, isEditStoryPanelOpen: false }));
  const closeDeleteStoryDialog = () =>
    setState((state) => ({ ...state, isDeleteStoryDialogOpen: false }));
  const closeChartFramesPage = () =>
    setState((state) => ({ ...state, isDetailsFramesPanelOpen: false }));
  const closeCustomMetricsPanel = () =>
    setState((state) => ({ ...state, showCustomMetrics: false }));

  const openEditStoryMetadataPanel = (storyId: string) =>
    setState((state) => ({ ...state, isEditStoryMetadataOpen: true, storyId }));
  const openEditStoryPanel = (storyId: string) => {
    setState((state) => ({ ...state, isEditStoryPanelOpen: true, storyId }));
  };
  const openDeleteStoryDialog = (storyId: string) =>
    setState((state) => ({ ...state, isDeleteStoryDialogOpen: true, storyId }));
  const openChartFramesPanel = (storyId: string) =>
    setState((state) => ({
      ...state,
      isDetailsFramesPanelOpen: true,
      storyId,
    }));

  const {
    storyId,
    isEditStoryMetadataOpen,
    isEditStoryPanelOpen,
    isDeleteStoryDialogOpen,
    isDetailsFramesPanelOpen,
    showCustomMetrics,
  } = state;

  const selectedStory = stories.find((s) => s.id.contentId === storyId);
  const { optionsSelect, realSize } = getRealSizeAndOptionsSelect(
    count,
    pageSize
  );

  const orderByParam = buildOrderByClause(
    filterState.metricOrderBy,
    filterState.postOrderBy,
    order
  );
  const updateStoryMutation = useMutation(UPDATE_INSTAGRAM_STORY, {
    refetchQueries: [
      {
        query: LIST_INSTAGRAM_STORIES,
        variables: {
          offset: currentPage * pageSize,
          limit: pageSize,
          orderBy: orderByParam,
          ...wheresFilter,
        },
      },
    ],
  });

  const buildColumns = buildStoriesColumns(
    maxWidth,
    openEditStoryMetadataPanel,
    openEditStoryPanel,
    openDeleteStoryDialog,
    openChartFramesPanel,
    selectedSets[columnSetType],
    project,
    updateStoryMutation
  );
  const metricsTableHeader = buildStoryMetricTableHeader(
    averages,
    selectedSets[columnSetType]
  );
  const head =
    maxWidth > 768 ? storyDefaultHead.desktop : storyDefaultHead.mobile;
  const headWithMetrics =
    maxWidth > 768 ? [...head, ...metricsTableHeader] : head;

  return (
    <>
      <CSSTransition
        in={showCustomMetrics}
        timeout={{ enter: 300, exit: 500 }}
        classNames=""
        onExited={closeCustomMetricsPanel}
        unmountOnExit
      >
        {(status) => (
          <CustomMetricsContainer
            transitionStatus={status}
            open={showCustomMetrics}
            onClose={closeCustomMetricsPanel}
            publicationId={PublicationType.IG_STORY}
            columnSetType={columnSetType}
            showCustomMetrics={false}
          />
        )}
      </CSSTransition>
      <CSSTransition
        in={isEditStoryMetadataOpen}
        timeout={{ enter: 300, exit: 500 }}
        classNames=""
        onExited={closeEditStoryMetadataPanel}
        unmountOnExit
      >
        {(status) =>
          selectedStory ? (
            <EditPostMetadataContainer
              project={project}
              transitionStatus={status}
              open={isEditStoryMetadataOpen}
              onClose={closeEditStoryMetadataPanel}
              selectedPost={{
                type: "INSTAGRAM_STORY",
                userDefinedMetadata: selectedStory.userDefinedMetadata,
                permalink: selectedStory.thumbnailUrl
                  ? selectedStory.thumbnailUrl
                  : selectedStory.mediaUrl,
                originalThumbnailUrl: null,
                thumbnailurlbackup: selectedStory.thumbnailUrlBackup,
                postPreview: "#",
                title: selectedStory.title,
                peggyUrl:selectedStory.peggyUrl,
                caption: null,
                publisherId: selectedStory.id.publisherId,
              }}
              platform={Platform.IG}
              loadingSave={updateStoryMutation[1].loading}
              onSave={async ({
                connect,
                disconnect,
              }: {
                connect?: Array<string>;
                disconnect?: Array<string>;
              }) => {
                await updateStoryMutation[0]({
                  variables: {
                    input: {
                      id: {
                        publisherId: selectedStory.id.publisherId,
                        contentId: selectedStory.id.contentId,
                      },
                      connect,
                      disconnect,
                    },
                  },
                });
              }}
            />
          ) : null
        }
      </CSSTransition>
      <CSSTransition
        in={isEditStoryPanelOpen}
        timeout={{ enter: 300, exit: 500 }}
        classNames=""
        onExited={closeEditStoryPanel}
        unmountOnExit
      >
        {(status) =>
          selectedStory && (
            <EditStoryContainer
              isDisabled={isDisabled}
              transitionStatus={status}
              open={isEditStoryPanelOpen}
              onClose={closeEditStoryPanel}
              selectedStory={selectedStory}
              publisherName={filterState.selectedPublisher}
            />
          )
        }
      </CSSTransition>
      {isDeleteStoryDialogOpen && selectedStory && (
        <DeleteStoryDialogContainer
          open={isDeleteStoryDialogOpen}
          onClose={closeDeleteStoryDialog}
          selectedStory={selectedStory}
          FilterContext={FilterContext}
          columnSetType={columnSetType}
          projectId={project.idLegacy}
        />
      )}
      {isDetailsFramesPanelOpen && selectedStory && (
        <DetailsFramesPanelContainer
          open={isDetailsFramesPanelOpen}
          onClose={closeChartFramesPage}
          frames={selectedStory.frames}
          maxWidth={maxWidth}
          title={selectedStory.title}
        />
        
      )}

      <StoryTable
        columns={buildColumns}
        stories={stories}
        style={style}
        loading={loading}
        maxWidth={maxWidth}
        realSize={realSize}
        count={count}
        currentPage={currentPage}
        totalPages={totalPages}
        optionsSelect={optionsSelect}
        headWithMetrics={headWithMetrics}
        metricsTableHeader={metricsTableHeader}
        onChangePageSize={onChangePageSize}
        onChangePage={onChangePage}
        onBackPage={onBackPage}
        onNextPage={onNextPage}
        onChangeMetricOrder={onChangeMetricOrder}
        onChangePostOrder={onChangePostOrder}
        StoryFilterContext={FilterContext}
        exportQuery={exportQuery}
        ColumnSetSelect={StoriesColumnSetSelect}
        onOpenCustomPanel={() =>
          setState((state) => ({ ...state, showCustomMetrics: true }))
        }
        columnSetType={columnSetType}
        orderBy={orderBy}
        order={order}
        project={project}
      />
    </>
  );
};

export { IGStoriesListTable };
