import * as React from "react";
import { PostType } from "../types/PostType";
import { Platform } from "../types/Platform";
import { Project } from "../types/Project";
import { Button } from "@freeda/react-components";
import { paste } from "../utils/metadataCopyPaste";
import { PublicationType } from "../types/Publication";
import { getFormatByPostType } from "../ui-specs/specs";
import { Metadata } from "../types/Metadata";
import { differenceBy } from "lodash";
import { FeedbackContext } from "../providers/FeedbackProvider";

interface Props {
  postType: PostType;
  platform: Platform | string;
  project: Project;
  closeCard: () => void;
  updateSocialContentMetadataMutation: [any, any];
  publisherId: string;
  contentId: string;
  userDefinedMetadata: Array<Metadata>;
  isStory?: boolean;
  style?: React.CSSProperties;
}

export const PasteSaveMetadataButton: React.FC<Props> = ({
  postType,
  platform,
  project,
  closeCard,
  updateSocialContentMetadataMutation,
  publisherId,
  contentId,
  userDefinedMetadata,
  isStory,
  style,
}) => {
  const { openSnackbar } = React.useContext(FeedbackContext);

  const social = isStory ? PublicationType.IG_STORY : platform;

  const copiedMetadata = paste(social, project.idLegacy);

  const categoryIdPostType = getFormatByPostType(postType);

  const categoryIdIsEqualToLocalStorage = copiedMetadata
    ? Boolean(
        copiedMetadata.find((cm) => cm.categoryId === categoryIdPostType.id)
      )
    : false;

  const isFbGroup = platform === "FBG";

  const disabledPasteButton = isFbGroup
    ? !Boolean(copiedMetadata)
    : !Boolean(copiedMetadata && categoryIdIsEqualToLocalStorage);

  if (copiedMetadata) {
    const booleanMetadataId = copiedMetadata
      .filter((m) => m.categoryId === "BOOLEAN" && Boolean(m.value))
      .map((m) => m.metadataId);

    const keywordsMetadataId = copiedMetadata
      .filter((m) => m.categoryId === "KEYWORDS")
      .map((m) => m.metadataId);

    const metadataId = copiedMetadata
      .filter(
        (m) =>
          m.categoryId !== "BOOLEAN" &&
          m.categoryId !== "KEYWORDS" &&
          Boolean(m.metadataId)
      )
      .map((m) => m.metadataId);

    const copiedMetadataId = [
      ...booleanMetadataId,
      ...keywordsMetadataId,
      ...metadataId,
    ];

    const oldMetadataId = userDefinedMetadata.map((m) => m.id);

    const connectMetadata = differenceBy(copiedMetadataId, oldMetadataId);
    const disconnectMetadata = differenceBy(oldMetadataId, copiedMetadataId);

    return (
      <Button
        style={{ width: "100%", ...style }}
        flat
        disabled={
          disabledPasteButton || updateSocialContentMetadataMutation[1].loading
        }
        onClick={async () => {
          await updateSocialContentMetadataMutation[0]({
            variables: {
              input: {
                id: {
                  publisherId,
                  contentId,
                },
                connect: connectMetadata,
                disconnect: disconnectMetadata,
              },
            },
          });

          closeCard();
          openSnackbar("Labels pasted correctly", "notification");
        }}
      >
        Paste tag
      </Button>
    );
  }
  return (
    <Button
      flat
      style={{ width: "100%", ...style }}
      disabled={disabledPasteButton}
    >
      Paste tag
    </Button>
  );
};
