import gql from "graphql-tag";
import { SOCIAL_CONTENT_ID_FRAGMENT } from "../fragments/SocialContentIdFragment";

const LIST_TK_CONTENT_METADATA = gql`
  query listTikTokContents(
    $offset: Int
    $limit: Int
    $orderBy: TikTokContentOrderBy
    $where: TikTokContentWhere
    $metricWhere: MeasuredMetricWhere
  ) {
    listTikTokContents(offset: $offset, limit: $limit, orderBy: $orderBy, where: $where, metricWhere: $metricWhere) {
      content {
        id {
          ...socialContentId
        }
        description
        url
        photo
        originalVideoLength
        originalThumbnailUrl
        post_video_length
        peggyUrl
        username
        publicationDate
        userDefinedMetadata {
          id
          label
          category {
            id
            label
          }
        }
        measuredMetrics {
          id {
            platformId
            publisherId
            contentId
            granularity
            name
            key
            timestamp
          }
          value
          conversionUnit
          position
        }
      }
      subAggregate {
        averages {
          ... on AggregatedMeasuredMetric {
            id {
              granularity
              name
              key
            }
            value
            conversionUnit
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      aggregate {
        count
      }
    }
  }
  ${SOCIAL_CONTENT_ID_FRAGMENT}
`;

export { LIST_TK_CONTENT_METADATA };
