import gql from "graphql-tag";
import { SOCIAL_CONTENT_ID_FRAGMENT } from "../fragments/SocialContentIdFragment";

const INSTAGRAM_STORY_OOF = gql`
  query instagramStory($id: InstagramStoryIdInput!) {
    instagramStory(id: $id) {
      id {
        ...socialContentId
      }
      outOfSync
    }
  }
  ${SOCIAL_CONTENT_ID_FRAGMENT}
`;

export { INSTAGRAM_STORY_OOF };
