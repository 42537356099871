import * as React from "react";

type SidebarContext = {
  openUploadIGTVDialog: boolean;
  openUploadREELDialog: boolean;
  openUploadTiktokDialog: boolean;
  onOpenUploadIGTVDialog: () => void;
  onCloseUploadIGTVDialog: () => void;
  onOpenUploadREELDialog: () => void;
  onCloseUploadREELDialog: () => void;
  onOpenUploadTiktokDialog: () => void;
  onCloseUploadTiktokDialog: () => void;
};

const SidebarContext = React.createContext<SidebarContext>(
  {} as SidebarContext
);

const SidebarProvider: React.FC = ({ children }) => {
  const [openUploadIGTVDialog, setOpenUploadIGTVDialog] = React.useState<
    boolean
  >(false);
  const [openUploadREELDialog, setOpenUploadREELDialog] = React.useState<
    boolean
  >(false);
  const [openUploadTiktokDialog, setOpenUploadTiktokDialog] = React.useState<
    boolean
  >(false);

  const onOpenUploadIGTVDialog = () => setOpenUploadIGTVDialog(true);
  const onCloseUploadIGTVDialog = () => setOpenUploadIGTVDialog(false);

  const onOpenUploadREELDialog = () => setOpenUploadREELDialog(true);
  const onCloseUploadREELDialog = () => setOpenUploadREELDialog(false);

  const onOpenUploadTiktokDialog = () => setOpenUploadTiktokDialog(true);
  const onCloseUploadTiktokDialog = () => setOpenUploadTiktokDialog(false);

  return (
    <SidebarContext.Provider
      value={{
        openUploadIGTVDialog,
        openUploadREELDialog,
        openUploadTiktokDialog,
        onOpenUploadIGTVDialog,
        onCloseUploadIGTVDialog,
        onOpenUploadREELDialog,
        onCloseUploadREELDialog,
        onOpenUploadTiktokDialog,
        onCloseUploadTiktokDialog,
      }}
    >
      {children}
    </SidebarContext.Provider>
  );
};

export { SidebarProvider, SidebarContext };
