import styled from "styled-components";

const RowForm = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

interface PropsSelectWrapper {
  basis?: string | number;
  mobileBasis?: string | number;
  style?: React.CSSProperties;
}

const AutocompleteWrapper = styled.div<PropsSelectWrapper>`
  margin: 0;
  border: 0;
  padding: 0;
  display: flex;
  align-items: center;
  position: relative;
  min-width: 0;
  margin-left: 0px;
  flex-direction: row;
  padding: 10px 0px;

  flex-basis: ${props => props.basis};
  @media (min-width: 769px) {
    align-self: center;
  }

  @media (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

export { RowForm, AutocompleteWrapper };
