import * as React from "react";

import { FilterContextT } from "../../../providers/FilterProvider";
import PostListFilter from ".";
import { AdvancedFilterPost } from "./AdvancedFilterPost";
import { FilteredAutocompleteMetadata } from "../../../container/FilteredAutocompleteMetadata";
import { useFilterMobile } from "../../../hooks/useFilterMobile";
import { Project } from "../../../types/Project";

interface Props {
  fbGroupsPostFilterContext: React.Context<FilterContextT>;
  project: Project;
  userTypeComponent?: () => React.ReactElement<any>;
}

export const FbGroupsPostListFilter: React.FC<Props> = ({ fbGroupsPostFilterContext, project, userTypeComponent }) => {
  const { onCloseFilterMobile, toggleShowFilter, showFilterMobile } = useFilterMobile();
  const { callbacks, filterState } = React.useContext(fbGroupsPostFilterContext);
  const { searchValue, dateRange } = filterState;
  const { onTextFilterChange, clearFilter, onChangeUpdateDateRange } = callbacks;

  return (
    <PostListFilter
      searchValue={searchValue}
      clearFilter={clearFilter}
      onTextFilterChange={onTextFilterChange}
      showFilter={toggleShowFilter}
      showFilterMobile={showFilterMobile}
      renderAdvancedFilter={status => (
        <AdvancedFilterPost
          showFilterMobile={showFilterMobile}
          onCloseFilterMobile={onCloseFilterMobile}
          onClearFilterMobile={clearFilter}
          transitionStatus={status}
          dateRange={dateRange}
          onChangeUpdateDateRange={onChangeUpdateDateRange}
          userTypeComponent={userTypeComponent}
          autocompleteMetadataComponent={
            <FilteredAutocompleteMetadata FilterContext={fbGroupsPostFilterContext} project={project} />
          }
        />
      )}
    />
  );
};
