import styled from "styled-components";

const ErrorWrapper = styled.div`
  opacity: 0;
  transition: opacity 0.4s;
  &.wrapper-entering {
    opacity: 0;
  }
  &.wrapper-entered {
    opacity: 1;
  }
  &.wrapper-exiting {
    opacity: 0;
  }
  &.wrapper-exited {
    opacity: 0;
  }
`;
export { ErrorWrapper };
