import * as React from "react";
import { Typography, Button, ButtonIcon } from "@freeda/react-components";
import { BarWrapper, DeselectButton } from "./styles";
import Media from "react-media";
import { TransitionStatus } from "react-transition-group/Transition";

interface Props {
  selectedFrames: Array<string>;
  onOpenCreateStoryDialog: () => void;
  deselect: () => void;
  transitionStatus: TransitionStatus;
}

const ActionsBar: React.FC<Props> = ({ selectedFrames, onOpenCreateStoryDialog, deselect, transitionStatus }) => {
  return (
    <Media query={{ maxWidth: 768 }}>
      {(matches: boolean) => (
        <BarWrapper className={`wrapper-${transitionStatus}`}>
          <div style={{ display: "flex", alignItems: "center" }}>
            {matches && <ButtonIcon onClick={deselect} iconName="remove" iconColor="#ffffff" />}
            <Typography variantName="subtitle" textColor="#ffffff">
              <>{`${selectedFrames.length} Frames selected`}</>
            </Typography>
            <Button onClick={onOpenCreateStoryDialog} style={{ marginLeft: 20 }}>
              Create story
            </Button>
          </div>
          {!matches && (
            <div style={{ marginLeft: "auto", marginRight: 20 }}>
              <DeselectButton onClick={deselect}>Deselect all</DeselectButton>
            </div>
          )}
        </BarWrapper>
      )}
    </Media>
  );
};

export { ActionsBar };
