import * as React from "react";
import styled from "styled-components";
import { Typography, Chip } from "@freeda/react-components";

const wrapper = styled.div<{ lateralChip?: boolean }>`
  height: 32px;
  width: 80px;
  margin-right: 5px;
  margin-bottom: ${(props) => (!props.lateralChip ? "5px" : "0px")};
  opacity: 0.4;
  background: ${(props) => props.theme.secondaryLight};
`;

const PlaceholderChip: React.FC<{ label?: string }> = ({ label }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", opacity: 0.5 }}>
      <Typography variantName="label" style={{ marginRight: 5, userSelect: "none" }}>
        e.g:
      </Typography>
      <Chip label={label ? label : "Beauty"} isClearable style={{ userSelect: "none" }} />
    </div>
  );
};

export { PlaceholderChip };
