import * as React from "react";
import { Colors } from "@freeda/react-components/lib/theme";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const DashboardIcon: React.FC<Props> = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 28"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M18.4,18 L22.4,18 C24.3882251,18 26,19.6117749 26,21.6 L26,22.4 C26,24.3882251 24.3882251,26 22.4,26 L18.4,26 C16.4117749,26 14.8,24.3882251 14.8,22.4 L14.8,21.6 C14.8,19.6117749 16.4117749,18 18.4,18 Z M9.6,11.6 C11.5882251,11.6 13.2,13.2117749 13.2,15.2 L13.2,22.4 C13.2,24.3882251 11.5882251,26 9.6,26 L5.6,26 C3.6117749,26 2,24.3882251 2,22.4 L2,15.2 C2,13.2117749 3.6117749,11.6 5.6,11.6 L9.6,11.6 Z M22.4,20.4 L18.4,20.4 C17.7372583,20.4 17.2,20.9372583 17.2,21.6 L17.2,22.4 C17.2,23.0627417 17.7372583,23.6 18.4,23.6 L22.4,23.6 C23.0627417,23.6 23.6,23.0627417 23.6,22.4 L23.6,21.6 C23.6,20.9372583 23.0627417,20.4 22.4,20.4 Z M9.6,14 L5.6,14 C4.9372583,14 4.4,14.5372583 4.4,15.2 L4.4,22.4 C4.4,23.0627417 4.9372583,23.6 5.6,23.6 L9.6,23.6 C10.2627417,23.6 10.8,23.0627417 10.8,22.4 L10.8,15.2 C10.8,14.5372583 10.2627417,14 9.6,14 Z M22.4,2 C24.3882251,2 26,3.6117749 26,5.6 L26,12.8 C26,14.7882251 24.3882251,16.4 22.4,16.4 L18.4,16.4 C16.4117749,16.4 14.8,14.7882251 14.8,12.8 L14.8,5.6 C14.8,3.6117749 16.4117749,2 18.4,2 L22.4,2 Z M22.4,4.4 L18.4,4.4 C17.7372583,4.4 17.2,4.9372583 17.2,5.6 L17.2,12.8 C17.2,13.4627417 17.7372583,14 18.4,14 L22.4,14 C23.0627417,14 23.6,13.4627417 23.6,12.8 L23.6,5.6 C23.6,4.9372583 23.0627417,4.4 22.4,4.4 Z M9.6,2 C11.5882251,2 13.2,3.6117749 13.2,5.6 L13.2,6.4 C13.2,8.3882251 11.5882251,10 9.6,10 L5.6,10 C3.6117749,10 2,8.3882251 2,6.4 L2,5.6 C2,3.6117749 3.6117749,2 5.6,2 L9.6,2 Z M9.6,4.4 L5.6,4.4 C4.9372583,4.4 4.4,4.9372583 4.4,5.6 L4.4,6.4 C4.4,7.0627417 4.9372583,7.6 5.6,7.6 L9.6,7.6 C10.2627417,7.6 10.8,7.0627417 10.8,6.4 L10.8,5.6 C10.8,4.9372583 10.2627417,4.4 9.6,4.4 Z"
        id="path-dashboard-icon"
      />
    </defs>
    <g id="dashboard-icon" stroke="none" fill="none" fillRule="evenodd">
      <mask id="mask-dashboard-icon" fill="white">
        <use xlinkHref="#path-dashboard-icon" />
      </mask>
      <use id="Combined-Shape" fillRule="nonzero" xlinkHref="#path-dashboard-icon" />
      <g id="color-dashboard-icon" mask="url(#mask-dashboard-icon)" fill={color}>
        <rect id="Rectangle" x="0" y="0" width={width} height={height} />
      </g>
    </g>
  </svg>
);

DashboardIcon.defaultProps = {
  width: 30,
  height: 30,
  color: Colors.DARKpurple,
};

export default DashboardIcon;
