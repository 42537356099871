import * as React from "react";
import {
  Typography,
  Select,
  ButtonIcon,
  Icon,
  Paginator,
} from "@freeda/react-components";
import { FieldWrapper } from "../../ui/FieldWrapper";
import { Colors } from "@freeda/react-components/lib/theme";
import { RelationDialog } from "../RelationDialog";
import { TableFilterWrapper, InfoResults } from "../../ui/TableFilterWrapper";
import { Metadata } from "../../../types/Metadata";
import { MetadataOrderBy } from "../../../types/graphql/MetadataOrderBy";
import { getRealSizeAndOptionsSelect } from "../../../utils/getRealSizeAndOptionsSelect";
import { MetadataTableStyled } from "./styles";
import { PaginatorStyled } from "../../ui/PaginatorStyled";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { Order } from "../../../types/Order";
import { TableHeaderP } from "@freeda/react-components/lib/Table/TableHeader";

interface Props {
  loading: boolean;
  metadata: Array<Metadata>;
  currentPage: number;
  pageSize: number;
  orderBy: MetadataOrderBy | null;
  order: Order;
  totalPages: number;
  count: number;
  onChangePageSize: (numberPage: string) => void;
  onChangePage: (page: number) => void;
  onBackPage: () => void;
  onNextPage: () => void;
  onChangeOrder: (orderBy: MetadataOrderBy | null, order: Order) => void;
  searchValue: string;
  selectedCategoryId: string | null;
  metadataActive: boolean | null;
  selectedProjects: Array<{ value: string; label: string }>;
  style?: React.CSSProperties;
}

interface State {
  showRelationDialog: boolean;
  metadataId: string | null;
}

const MetadataTable: React.FC<Props> = ({
  loading,
  metadata,
  currentPage,
  pageSize,
  totalPages,
  count,
  onChangePageSize,
  onChangePage,
  onBackPage,
  onNextPage,
  onChangeOrder,
  orderBy,
  order,
  searchValue,
  selectedCategoryId,
  metadataActive,
  selectedProjects,
  style,
}) => {
  const [state, setState] = React.useState<State>({
    showRelationDialog: false,
    metadataId: null,
  });

  const maxWidth = useWindowSize().windowWidth;

  const onCloseRelationDialog = () =>
    setState({ ...state, showRelationDialog: false });

  const desktopHeadTable: Array<TableHeaderP.HeaderT> = [
    { id: "label", label: "Label", sortable: true },
    { id: "project", label: "Project", sortable: false },
    { id: "category_id", label: "Category", sortable: true },
    { id: "active", label: "Active", sortable: false, width: "90px" },
  ];
  const desktopBrandHead = { id: "brand", label: "Industry", sortable: false };
  const desktopCampaignHead = {
    id: "campaign",
    label: "Brand",
    sortable: false,
  };
  const desktopTopicHead = {
    id: "topic",
    label: "Macro Topic",
    sortable: false,
  };

  const mobileHeadTable: Array<TableHeaderP.HeaderT> = [
    {
      id: "label",
      label: "Label",
      sortable: true,
      width: "150px",
      resizable: true,
    },
    {
      id: "project",
      label: "Project",
      sortable: false,
      width: "150px",
      resizable: true,
    },
    {
      id: "category_id",
      label: "Category",
      sortable: true,
      width: "150px",
      resizable: true,
    },
    { id: "active", label: "Active", sortable: false, width: "90px" },
  ];

  const mobileBrandHead = {
    id: "brand",
    label: "Industry",
    sortable: false,
    width: "150px",
    resizable: true,
  };
  const mobileCampaignHead = {
    id: "campaign",
    label: "Brand",
    sortable: false,
    width: "150px",
    resizable: true,
  };
  const mobileTopicHead = {
    id: "topic",
    label: "Macro Topic",
    sortable: false,
    width: "150px",
    resizable: true,
  };

  const getHeadTable = () => {
    if (maxWidth > 768) {
      switch (selectedCategoryId) {
        case "BRAND":
          desktopHeadTable.splice(3, 0, desktopBrandHead);
          return desktopHeadTable;
        case "CAMPAIGN":
          desktopHeadTable.splice(3, 0, desktopCampaignHead);
          return desktopHeadTable;
        case "TOPIC":
          desktopHeadTable.splice(3, 0, desktopTopicHead);
          return desktopHeadTable;
        default:
          return desktopHeadTable;
      }
    } else {
      switch (selectedCategoryId) {
        case "BRAND":
          mobileHeadTable.splice(3, 0, desktopBrandHead);
          return mobileHeadTable;
        case "CAMPAIGN":
          mobileHeadTable.splice(3, 0, mobileCampaignHead);
          return mobileHeadTable;
        case "TOPIC":
          mobileHeadTable.splice(3, 0, mobileTopicHead);
          return mobileHeadTable;
        default:
          return mobileHeadTable;
      }
    }
  };

  const columnLabel = {
    id: "label",
    render: (metadata: Metadata) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        {metadata.category.id === "BRAND" && (
          <ButtonIcon
            iconName="link"
            iconColor={Colors.ROSSINO}
            style={{ position: "absolute", left: 10 }}
            onClick={() => {
              setState({
                ...state,
                metadataId: metadata.id,
                showRelationDialog: true,
              });
            }}
          />
        )}
        <Typography variantName="table-body">{metadata.label}</Typography>
      </div>
    ),
  };

  const columnProject = {
    id: "project",
    render: (metadata: Metadata) => {
      const isAssignedAllProjects = metadata.projects.length === 0;

      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variantName="table-body">
            {isAssignedAllProjects
              ? `Assigned to all Projects`
              : metadata.projects.map((p) => p.label).join(", ")}
          </Typography>
        </div>
      );
    },
  };

  //if BRAND filter is on
  const columnBrand = {
    id: "brand",
    render: (metadata: Metadata) => {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variantName="table-body">
            {metadata.brandCategory}
          </Typography>
        </div>
      );
    },
  };
  //if CAMPAIGN filter is on
  const columnCampaign = {
    id: "campaign",
    render: (metadata: Metadata) => {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variantName="table-body">
            {metadata.visibleFor.map((vf) => vf.label).join(", ")}
          </Typography>
        </div>
      );
    },
  };
  //if TOPIC filter is on
  const columnTopic = {
    id: "topic",
    render: (metadata: Metadata) => {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variantName="table-body">
            {metadata.macroTopic}
          </Typography>
        </div>
      );
    },
  };

  const columnCategory = {
    id: "category",
    render: (metadata: Metadata) => (
      <Typography variantName="table-body">
        {metadata.category.label}
      </Typography>
    ),
  };

  const columnActive = {
    id: "active",
    render: (metadata: Metadata) => (
      <div>
        {metadata.active ? (
          <Icon iconName="check" iconColor={Colors.PURPLE} />
        ) : (
          <Icon
            iconName="close"
            style={{ fontSize: 25 }}
            iconColor={Colors.GRIGINO}
          />
        )}
      </div>
    ),
  };

  const baseColumns: Array<{
    id: string;
    render?: (item: any) => JSX.Element;
    content?: JSX.Element;
  }> = [columnLabel, columnProject, columnCategory, columnActive];

  const getColumns = () => {
    switch (selectedCategoryId) {
      case "BRAND":
        baseColumns.splice(3, 0, columnBrand);
        return baseColumns;
      case "CAMPAIGN":
        baseColumns.splice(3, 0, columnCampaign);
        return baseColumns;
      case "TOPIC":
        baseColumns.splice(3, 0, columnTopic);
        return baseColumns;
      default:
        return baseColumns;
    }
  };

  const { showRelationDialog, metadataId } = state;
  const { optionsSelect, realSize } = getRealSizeAndOptionsSelect(
    count,
    pageSize
  );
  const selectedMetadata = metadata.find((m) => m.id === metadataId)!;

  const tableFilter =
    maxWidth > 480 ? (
      <TableFilterWrapper style={{ position: "relative" }}>
        <FieldWrapper>
          <Typography variantName="label" style={{ marginRight: 10 }}>
            Rows
          </Typography>
          <Select
            style={{ width: 70, marginRight: 10 }}
            isSearchable={false}
            isClearable={false}
            onChange={(pageSize: any) =>
              onChangePageSize(pageSize ? pageSize.value : null)
            }
            options={optionsSelect}
            value={{ label: String(realSize), value: String(realSize) }}
          />
          <InfoResults>
            {realSize} of {count}
          </InfoResults>
        </FieldWrapper>
        <Paginator
          currentPage={currentPage}
          totalPage={totalPages}
          pageSize={realSize}
          onChangePage={onChangePage}
          onBack={onBackPage}
          onNext={onNextPage}
        />
      </TableFilterWrapper>
    ) : (
      <div style={{ margin: "15px 0px" }}>
        <FieldWrapper style={{ display: "flex" }}>
          <Typography
            variantName="label"
            style={{ marginRight: 10, width: "110px" }}
          >
            Rows
          </Typography>
          <Select
            style={{ marginRight: 10 }}
            isSearchable={false}
            isClearable={false}
            onChange={(pageSize: any) =>
              onChangePageSize(pageSize ? pageSize.value : null)
            }
            options={optionsSelect}
            value={{ label: String(realSize), value: String(realSize) }}
          />
          <InfoResults style={{ marginRight: 0 }}>
            {realSize} of {count}
          </InfoResults>
        </FieldWrapper>
        <PaginatorStyled
          currentPage={currentPage}
          totalPage={totalPages}
          pageSize={realSize}
          onChangePage={onChangePage}
          onBack={onBackPage}
          onNext={onNextPage}
          selectWidth={"100%"}
        />
      </div>
    );

  return (
    <>
      {showRelationDialog && (
        <RelationDialog
          open={showRelationDialog}
          onClose={onCloseRelationDialog}
          selectedMetadata={selectedMetadata}
        />
      )}
      <div
        style={{
          position: "relative",
          padding: maxWidth < 768 ? "0px 15px" : 0,
          marginBottom: 25,
          ...style,
        }}
      >
        <MetadataTableStyled
          columns={getColumns()}
          tableData={metadata}
          rowIdGenerator={(metadata) => String(metadata.id)}
          loading={loading}
          itemsPerPage={realSize}
          head={getHeadTable()}
          changeOrder={{
            orderBy,
            order,
            onChangeOrder: (orderBy, order) => {
              if (orderBy) {
                onChangeOrder(MetadataOrderBy[orderBy], order);
              }
            },
          }}
          fixedColumnNumbers={maxWidth > 768 ? 1 : 0}
          tableFilter={tableFilter}
          fixedHeader={maxWidth > 768}
        />
      </div>
    </>
  );
};

export default MetadataTable;
