import gql from "graphql-tag";
import { SOCIAL_CONTENT_ID_FRAGMENT } from "../fragments/SocialContentIdFragment";

const UPDATE_FB_CONTENT = gql`
  mutation updateFbContent($input: UpdateFacebookContentInput!) {
    updateFacebookContent(input: $input) {
      id {
        ...socialContentId
      }
      userDefinedMetadata {
        id
        label
        active
        category {
          id
          label
          area
        }
      }
    }
  }
  ${SOCIAL_CONTENT_ID_FRAGMENT}
`;

export { UPDATE_FB_CONTENT };
