import * as React from "react";

import { useQuery } from "@apollo/react-hooks";
import { Dialogable } from "../../../types/Dialogable";
import { Dialog, ButtonIcon, Icon, Typography, Table } from "@freeda/react-components";
import { Colors } from "@freeda/react-components/lib/theme";
import { ItalicLabel } from "../../ui/ItalicLabel";
import { VisibleLabel, Metadata } from "../../../types/Metadata";
import { sortBy } from "lodash";
import Media from "react-media";
import ListLinkedLabelsQueryT from "../../../types/graphql/ListLinkedLabelsQuery";
import {LIST_LINKED_LABELS} from "../../../apollo/queries/ListLinkedLabels";

type Props = Dialogable & {
  selectedMetadata: Metadata;
};

const RelationDialog: React.FC<Props> = ({ open, onClose, selectedMetadata }) => {
  const linkedLabelsResult = useQuery<ListLinkedLabelsQueryT>(LIST_LINKED_LABELS, {
    variables: {
      id: selectedMetadata.id
    }
  });

  const linkedLabels =
    linkedLabelsResult.loading || !linkedLabelsResult.data
      ? []
      : linkedLabelsResult.data?.listLinkedLabels;

  const orderChildren = sortBy(linkedLabels, "label");

  const columns = [
    {
      id: "labelRelation",
      render: (label: VisibleLabel) => <Typography variantName="table-body">{label.label}</Typography>,
    },
    {
      id: "categoryRelation",
      render: (label: VisibleLabel) => <Typography variantName="table-body">{label.category.label}</Typography>,
    },
    {
      id: "activeRelation",
      render: (label: VisibleLabel) => (
        <div>
          {label.active ? (
            <Icon iconName="check" iconColor={Colors.GRIGIO} />
          ) : (
            <Icon iconName="close" iconColor={Colors.GRIGIO} />
          )}
        </div>
      ),
    },
  ];

  return (
    <Media query={{ maxWidth: 768 }}>
      {(matches: boolean) => (
        <Dialog
          rootIdAttributes="root"
          openDialog={open}
          onClose={onClose}
          fullScreen={matches}
          style={{ width: !matches ? 550 : "", height: !matches ? 650 : "" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 15,
            }}
          >
            <Typography variantName="subtitle" textColor="#6B20DA">
              Linked labels
            </Typography>
            <ButtonIcon onClick={onClose} iconName="close" />
          </div>
          <div>
            <ItalicLabel style={{ marginBottom: 25 }}>
              {selectedMetadata.category.label} {">"} {selectedMetadata.label}
            </ItalicLabel>
          </div>
          <Table
            columns={columns}
            tableData={orderChildren}
            rowIdGenerator={(label) => String(label.id)}
            wrapperStyle={{
              height: !matches ? "calc(650px - 130px)" : "100%",
              overflowY: "scroll",
            }}
            itemsPerPage={orderChildren.length}
            head={[
              { id: "labelRelation", label: "Label", sortable: false, resizable: true },
              { id: "categoryRelation", label: "Category", sortable: false, resizable: true },
              { id: "activeRelation", label: "Active", sortable: false, width: "80px" },
            ]}
          />
        </Dialog>
      )}
    </Media>
  );
};

export { RelationDialog };
