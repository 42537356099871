import * as React from "react";
import { InstagramContent } from "../../../types/InstagramContent";
import { difference, sortBy } from "lodash";
import { Average } from "../../../types/Average";
import { PublicationType } from "../../../types/Publication";
import { PostOrderBy } from "../../../types/graphql/PostOrderBy";
import { MetricOrderBy, FilterContextT } from "../../../providers/FilterProvider";
import { buildIgStoryFrameTableColumns, framesDefaultHead } from "../../../utils/framesAndStoriesTableUtils";
import PostListTable from "../PostListTable";
import { CSSTransition } from "react-transition-group";
import { ActionsBar } from "../ActionsBar";
import { CreateStoryDialogContainer } from "../../../container/CreateStoryDialog";
import { Project } from "../../../types/Project";
import { ColumnSetType } from "../../../providers/MetricColumnSetsProvider";
import { Platform } from "../../../types/Platform";
import { Order } from "../../../types/Order";
/*import {
  ButtonIcon
} from "@freeda/react-components";
import { Colors } from "@freeda/react-components/lib/theme";*/



interface Props {
  frames: Array<InstagramContent>;
  averages: Array<Average>;
  columnSetType: ColumnSetType;
  exportQuery: any;
  publicationType: PublicationType;
  loading: boolean;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  style?: React.CSSProperties;
  count: number;
  onChangePageSize: (numberPage: string) => void;
  onChangePage: (page: number) => void;
  onBackPage: () => void;
  onNextPage: () => void;
  onChangePostOrder: (orderBy: PostOrderBy, order: Order) => void;
  onChangeMetricOrder: (metricOrder: MetricOrderBy, order: Order) => void;
  FilterContext: React.Context<FilterContextT>;
  ColumnSetSelect: React.FC<{ onOpenCustomPanel: () => void; width?: string | number }>;
  project: Project;
  orderBy: MetricOrderBy | PostOrderBy | null;
  order: Order;
}

interface State {
  selectedFramesId: Array<string>;
  isCreateStoryDialogOpen: boolean;
}

export const IGFramesListTable: React.FC<Props> = ({
  currentPage,
  pageSize,
  totalPages,
  columnSetType,
  onChangePageSize,
  onChangePage,
  onBackPage,
  onNextPage,
  onChangePostOrder,
  onChangeMetricOrder,
  style,
  frames,
  averages,
  loading,
  count,
  exportQuery,
  publicationType,
  FilterContext,
  ColumnSetSelect,
  project,
  orderBy,
  order
}) => {
  const indexStartRef = React.useRef<number | null>(null);

  const [state, setState] = React.useState<State>({
    selectedFramesId: [],
    isCreateStoryDialogOpen: false
  });

  const closeCreateStoryDialog = (emptyFrames: boolean) =>
    setState(state => ({
      ...state,
      isCreateStoryDialogOpen: false,
      selectedFramesId: emptyFrames ? [] : state.selectedFramesId
    }));

  const openCreateStoryDialog = () => setState({ ...state, isCreateStoryDialogOpen: true });

  const onSelect = (selectedFrameId: string) => {
    setState(state => {
      const exists = state.selectedFramesId.find(sf => sf === selectedFrameId);
      return {
        ...state,
        selectedFramesId: exists
          ? state.selectedFramesId.filter(f => f !== selectedFrameId)
          : [selectedFrameId, ...state.selectedFramesId]
      };
    });
  };

  const onSelectWithShiftKey = (e: React.MouseEvent, selectedFrameId: string) => {
    const selectedPost = frames.find(p => p.id.contentId === selectedFrameId)!;

    if (!e.shiftKey) {
      indexStartRef.current = frames.indexOf(selectedPost);
      onSelect(selectedFrameId);
    }

    if (e.shiftKey && state.selectedFramesId.length > 0 && indexStartRef.current !== null) {
      const start = indexStartRef.current;
      const indexEnd = frames.indexOf(selectedPost);

      setState(state => {
        const exists = state.selectedFramesId.find(sf => sf === selectedFrameId);
        let selectedMultiFrames: Array<InstagramContent> = [];

        if (start > indexEnd) {
          selectedMultiFrames = frames.slice(indexEnd, exists ? start : start + 1);
        } else {
          selectedMultiFrames = frames.slice(exists ? start + 1 : start, indexEnd + 1);
        }
        const selectedMultiFramesId = selectedMultiFrames.map(p => p.id.contentId);
        const stateWithoutSelectedFrames = difference(state.selectedFramesId, selectedMultiFramesId);
        const selectedFramesWithoutState = difference(selectedMultiFramesId, state.selectedFramesId);

        if (selectedFramesWithoutState.length > 0) {
          return {
            ...state,
            selectedFramesId: [...state.selectedFramesId, ...selectedFramesWithoutState]
          };
        } else {
          return {
            ...state,
            selectedFramesId: stateWithoutSelectedFrames
          };
        }
      });
      indexStartRef.current = indexEnd;
    }
  };

  const { selectedFramesId, isCreateStoryDialogOpen } = state;

  const buildColumns = buildIgStoryFrameTableColumns(
    project,
    state.selectedFramesId,
    onSelectWithShiftKey,
    indexStartRef.current,
    frames
  );

  const selectedFrames =
    selectedFramesId.length > 0 ? selectedFramesId.map(fId => frames.find(post => post.id.contentId === fId)!) : null;

  const orderedSelectedFrames = sortBy(selectedFrames, "publicationDate");

  return (
    <>

      <CSSTransition in={selectedFramesId.length > 0} timeout={{ enter: 200, exit: 300 }} classNames="" unmountOnExit>
        {status => (
          
          <ActionsBar
            transitionStatus={status}
            selectedFrames={selectedFramesId}
            onOpenCreateStoryDialog={openCreateStoryDialog}
            deselect={() => {
              setState({ ...state, selectedFramesId: [] });
              indexStartRef.current = null;
            }}
          />
          
        )}
      </CSSTransition>

      {isCreateStoryDialogOpen && (
        <CreateStoryDialogContainer
          open={isCreateStoryDialogOpen}
          onClose={closeCreateStoryDialog}
          frames={selectedFramesId}
          publisherId={orderedSelectedFrames[0].id.publisherId}
          FilterContext={FilterContext}
          columnSetType={columnSetType}
          projectId={project.idLegacy}
        />
      )}
      <PostListTable
        defaultHead={framesDefaultHead}
        buildColumns={buildColumns}
        posts={frames}
        averages={averages}
        loading={loading}
        currentPage={currentPage}
        pageSize={pageSize}
        totalPages={totalPages}
        onChangePageSize={onChangePageSize}
        onChangePage={onChangePage}
        onBackPage={onBackPage}
        onNextPage={onNextPage}
        onChangePostOrder={onChangePostOrder}
        onChangeMetricOrder={onChangeMetricOrder}
        style={style}
        count={count}
        publicationType={publicationType}
        platform={Platform.IG}
        PostFilterContext={FilterContext}
        ColumnSetSelect={ColumnSetSelect}
        exportQuery={exportQuery}
        columnSetType={columnSetType}
        project={project}
        orderBy={orderBy}
        order={order}
      />
    </>
  );
};
