import { UserType } from "../types/UserType";
import { InclusionPolicy } from "../types/InclusionPolicy";

export const inclusionPolicyByUserType = (userType: UserType) => {
  const selectedUser = {
    [UserType.page]: InclusionPolicy.Include,
    [UserType.user]: InclusionPolicy.Exclude
  };

  return selectedUser[userType];
};
