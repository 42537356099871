import * as React from "react";
import { ButtonIcon, Typography } from "@freeda/react-components";
import { BackWrapper } from "./LateralPanel";

interface Props {
  iconColor?: string;
}

export const BackButton: React.FC<React.HTMLProps<HTMLDivElement> & Props> = ({ onClick, style, iconColor }) => {
  return (
    <BackWrapper onClick={onClick} style={style}>
      <ButtonIcon iconName="arrow-left" iconSize="small" iconColor={iconColor} />
      <Typography variantName="label" textColor={iconColor || "#6B20DA"} style={{ fontSize: 14, cursor: "pointer" }}>
        Back
      </Typography>
    </BackWrapper>
  );
};
