import gql from "graphql-tag";

const LIST_PAID_PERFORMANCE_METRICS = gql`
  query listPaidPerformanceMetrics($where: PaidPerformanceMetricsWhere!) {
    listPaidPerformanceMetrics(where: $where) {
      name
      value
      trend
    }
  }
`;

export { LIST_PAID_PERFORMANCE_METRICS };
