import * as React from "react";
import moment from "moment";

import { DateRange } from "../../../types/DateRange";
import { DateRangeFilterDialog } from "./DateRangeFilterDialog";
import { FixedDateRange } from "../../../types/FixedDateRange";
import { Select } from "@freeda/react-components";

const labels = {
  [FixedDateRange.ANY_TIME]: "Any time",
  [FixedDateRange.LAST_24H]: "Last 24h",
  [FixedDateRange.LAST_WEEK]: "Last week",
  [FixedDateRange.LAST_MONTH]: "Last month",
  [FixedDateRange.LAST_28_DAYS]: "Last 28 days",
  [FixedDateRange.LAST_YEAR]: "Last year",
  [FixedDateRange.CUSTOM_RANGE]: "Custom range"
};

interface Props {
  onChangeUpdateDateRange: (dateRange: DateRange) => void;
  dateRange: DateRange;
}

interface State {
  isDialogOpen: boolean;
  from: Date | null;
  to: Date | null;
}

const DateFilter: React.FC<Props> = ({ onChangeUpdateDateRange, dateRange }) => {
  const initialState = {
    isDialogOpen: false,
    from: null,
    to: null
  };

  const [state, setState] = React.useState<State>({
    ...initialState
  });

  React.useEffect(() => {
    if (dateRange && typeof dateRange === "object") {
      const fromAndTo = Object.assign({}, initialState, {
        from: dateRange.from,
        to: dateRange.to
      });
      setState({ ...state, ...fromAndTo });
    }
  }, []);

  React.useEffect(() => {
    if (dateRange && typeof dateRange === "object") {
      setState({
        ...state,
        from: dateRange.from,
        to: dateRange.to
      });
    }
  }, [dateRange]);

  const onChangeDate = (startDate: moment.Moment | null, endDate: moment.Moment | null) => {
    const from = startDate ? startDate.toDate() : null;
    const to = endDate ? endDate.toDate() : null;
    setState({
      ...state,
      from,
      to
    });
  };

  const getSelectedValue = (dateRange: DateRange) => {
    if (dateRange) {
      if (typeof dateRange === "object") {
        if (!dateRange.from) {
          return {
            value: FixedDateRange.CUSTOM_RANGE,
            label: `Until ${moment(dateRange.to!).format("DD/MM/YYYY")}`
          };
        }
        if (!dateRange.to) {
          return {
            value: FixedDateRange.CUSTOM_RANGE,
            label: `From ${moment(dateRange.from!).format("DD/MM/YYYY")}`
          };
        }

        return {
          value: FixedDateRange.CUSTOM_RANGE,
          label: `${moment(dateRange.from).format("DD/MM/YYYY")} - ${moment(dateRange.to).format("DD/MM/YYYY")}`
        };
      } else {
        return {
          value: FixedDateRange[dateRange],
          label: labels[FixedDateRange[dateRange]]
        };
      }
    }

    return { value: FixedDateRange.ANY_TIME, label: labels[FixedDateRange.ANY_TIME] };
  };

  return (
    <>
      <Select
        label="publication date"
        isClearable={false}
        style={{ padding: "10px 0px" }}
        onChange={(option: any) => {
          if (option.value === FixedDateRange.CUSTOM_RANGE) {
            setState({ ...state, isDialogOpen: true });
          } else {
            onChangeUpdateDateRange(option.value);
          }
        }}
        options={[
          { value: FixedDateRange.ANY_TIME, label: labels[FixedDateRange.ANY_TIME] },
          { value: FixedDateRange.LAST_24H, label: labels[FixedDateRange.LAST_24H] },
          { value: FixedDateRange.LAST_WEEK, label: labels[FixedDateRange.LAST_WEEK] },
          {
            value: FixedDateRange.LAST_MONTH,
            label: labels[FixedDateRange.LAST_MONTH]
          },
          {
            value: FixedDateRange.LAST_28_DAYS,
            label: labels[FixedDateRange.LAST_28_DAYS]
          },
          { value: FixedDateRange.LAST_YEAR, label: labels[FixedDateRange.LAST_YEAR] },
          {
            value: FixedDateRange.CUSTOM_RANGE,
            label: labels[FixedDateRange.CUSTOM_RANGE]
          }
        ]}
        value={getSelectedValue(dateRange)}
        isSearchable={false}
      />
      <DateRangeFilterDialog
        open={state.isDialogOpen}
        onClose={() => setState({ ...state, isDialogOpen: false })}
        from={state.from}
        to={state.to}
        onChangeDate={onChangeDate}
        onApplyFilter={onChangeUpdateDateRange}
      />
    </>
  );
};

export { DateFilter };
