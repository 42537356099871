import gql from "graphql-tag";
import { SOCIAL_CONTENT_ID_FRAGMENT } from "../fragments/SocialContentIdFragment";

const LIST_FB_GROUP_CONTENT_METADATA = gql`
  query listFacebookGroupContents(
    $offset: Int
    $limit: Int
    $orderBy: FacebookGroupContentOrderBy
    $where: FacebookGroupContentWhere
    $metricWhere: MeasuredMetricWhere
  ) {
    listFacebookGroupContents(
      offset: $offset
      limit: $limit
      orderBy: $orderBy
      where: $where
      metricWhere: $metricWhere
    ) {
      content {
        id {
          ...socialContentId
        }
        message
        mediaUrl
        permalink
        publicationDate
        type
        title
        link
        from
        userDefinedMetadata {
          id
          label
          category {
            id
            label
          }
        }
        measuredMetrics {
          id {
            platformId
            publisherId
            contentId
            granularity
            name
            key
            timestamp
          }
          value
          conversionUnit
          position
        }
      }
      subAggregate {
        averages {
          ... on AggregatedMeasuredMetric {
            id {
              granularity
              name
              key
            }
            value
            conversionUnit
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      aggregate {
        count
      }
    }
  }
  ${SOCIAL_CONTENT_ID_FRAGMENT}
`;

export { LIST_FB_GROUP_CONTENT_METADATA };
