import styled from "styled-components";
import { Icon, Input } from "@freeda/react-components";

const InputStyled = styled(Input)`
  && {
    & > div > input {
      border-color: ${(props) => props.theme.primary};
    }
  }
`;

const InputSearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 15px 0px;

  @media (max-width: 768px) {
    padding: 15px 20px;
    margin-bottom: 15px;
    background: ${(props) => props.theme.azzurrino};
    justify-content: space-between;
    margin: 0px;
    z-index: 21;
    position: relative;
  }
`;

const ButtonIconStyled = styled(Icon)`
  && {
    width: 48px;
    height: 48px;
    font-size: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export { InputSearchWrapper, ButtonIconStyled, InputStyled };
