import gql from "graphql-tag";
import { CUSTOM_CONTENT_METRIC_ID_FRAGMENT } from "../fragments/CustomContentMetricIdFragment";

export const PATCH_CUSTOM_CONTENT_METRICS = gql`
  mutation patchCustomContentMetrics($input: PatchCustomContentMetricsInput!) {
    patchCustomContentMetrics(input: $input) {
      upsertedEntities {
        id {
          ...customContentMetricId
        }
        value
      }
      deletedIds {
        ...customContentMetricId
      }
    }
  }
  ${CUSTOM_CONTENT_METRIC_ID_FRAGMENT}
`;
