import * as React from "react";
import styled from "styled-components";
import { Typography, Icon, Table, DividerSection } from "@freeda/react-components";
import { HeaderLogoQuote } from "../ui/HeaderLogoQuote";
import { Colors } from "@freeda/react-components/lib/theme";
import { helpPageColumns, helpPageData, helpPageHead } from "../../utils/csvHelpPageData";
import { scrollbarVerticalStyle } from "../ui/ScrollBarStyle";

const WrapperPage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 3000;
  width: 100vw;
  height: 100vh;
  background: #f9f9f9;
  overflow-x: hidden;
`;

const Header = styled.div`
  background: #ffffff;
  width: 100vw;
  height: 65px;
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 6px 0px;
  padding: 0px 20px;
  padding-right: 10px;
  z-index: 1;
`;

const WrapperInstructions = styled.div`
  padding: 20px;
  height: 100vh;
  overflow-y: overlay;
  overflow-x: hidden;
  ${scrollbarVerticalStyle};
`;

const StepNumber = styled.span`
  color: ${(props) => props.theme.primary};
`;

const UploadCSVHelpPage: React.FC = () => {
  return (
    <WrapperPage>
      <Header>
        <HeaderLogoQuote style={{ position: "relative", marginLeft: "auto", top: 0 }} />
      </Header>
      <WrapperInstructions>
        <Typography variantName="subtitle" textColor={Colors.PURPLE} style={{ marginBottom: 20 }}>
          Prepare to import
        </Typography>
        <Typography variantName="text" style={{ letterSpacing: 0.3, marginBottom: 20 }}>
          To prepare your file to import, follow these steps.
        </Typography>
        <Typography variantName="text" style={{ lineHeight: "20px", letterSpacing: 0.3 }}>
          <>
            <h3><StepNumber>1.</StepNumber> Create a new .csv file and give it today date, in the form YYYY-MM-DD-hh-mm-ss-YOUR_TIME_ZONE.csv, as file name</h3>          
            <b>YOUR_TIME_ZONE must be one of the following:</b>
            <br />
            <br /><b>CEST:</b> Central European Summer Time: this is the time zone of counties like Italy and Spain during daylight saving (usually from end March to end October)
            <br /><b>CET:</b> Central European Time: this is the time zone of counties like Italy and Spain when daylight saving is off (usually from end October to end March)
            <br /><b>BST:</b> British Summer Time (when daylight saving is on)
            <br /><b>GMT:</b> British Time (when daylight saving if off)
            <br />
            <br/>
            <b>Example file name: 2021-04-02-15-00-00-CEST.csv</b>
            <br/>
            <br/>
            <h3><StepNumber>2.</StepNumber>File first line must always be:</h3>
            <b>Account,User Name,Created,Type,Likes,Comments,Reach,URL,Description,Sponsor Id,Sponsor Name,Length,Impressions,Saved,Photo</b>
            <br/>
            <div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
              <Icon iconName="info" iconSize="small" iconColor={Colors.GRIGINO} />
              <Typography variantName="label" textColor={Colors.GRIGINO} style={{ marginLeft: 5 }}>
                  (Please don't change order of columns!)
              </Typography>
            </div>
            <h3><StepNumber>3.</StepNumber> All following lines are comma separated recorded metrics.</h3>
            <b>Example: Freeda,freeda,2021-04-02 15:00:00 CEST,IGREEL,666,100,9999,https://www.instagram.com/p/CHLYoAOAwGQ/,Test del venerdì 9,,,0:09,1110,55,https://instagram.fblq1-1.fna.fbcdn.net/v/t51.2885-15/e35/172023579_121174656718551_3008931251152203864_n.jpg?tp=1&_nc_ht=instagram.fblq1-1.fna.fbcdn.net&_nc_cat=103&_nc_ohc=v4QDv7hl3BoAX-X1Pik&edm=ACHbZRIAAAAA&ccb=7-4&oh=ff6ea040d95daca6fdd590eeffbe7a0d&oe=609D32DF&_nc_sid=4a9e64&ig_cache_key=MjU1MTAxNTAzMDg2NzgwMzQ1Mw%3D%3D.2-ccb7-4</b>
            <br />
            <br />
            Field 'type' is always 'IGREEL'. 
            <br/>
            Fields 'Sponsor Id','Sponsor Name' and Photo are optional
            <br />
            Field 'Created' must have the same time zone as the file name. This is the date and time the content was published
            <br />
            Field 'User Name' must be one of the following (case sensitive)
            <br/>
            freeda
            <br/>
            freeda_es
            <br/>
            freeda_en
            <br />
            gooviworld
            <br/>
            florena_skincare_it
            <br />
            prada
            <br />
            trussardi
            <br />
            superfluid___
            <br />
            <h3><StepNumber>4.</StepNumber>Complete example (Italy summer time):</h3>
            <b> Account,User Name,Created,Type,Likes,Comments,Reach,URL,Description,Sponsor Id,Sponsor Name,Length,Saved,Impressions,Photo
              <br />
            Freeda,freeda,2021-04-02 15:00:00 CEST,IGREEL,666,100,9999,https://www.instagram.com/p/CHLYoAOAwGQ/,Test del venerdì 8,,,0:09,1110,55,https://instagram.fblq1-1.fna.fbcdn.net/v/t51.2885-15/e35/172023579_121174656718551_3008931251152203864_n.jpg?tp=1&_nc_ht=instagram.fblq1-1.fna.fbcdn.net&_nc_cat=103&_nc_ohc=v4QDv7hl3BoAX-X1Pik&edm=ACHbZRIAAAAA&ccb=7-4&oh=ff6ea040d95daca6fdd590eeffbe7a0d&oe=609D32DF&_nc_sid=4a9e64&ig_cache_key=MjU1MTAxNTAzMDg2NzgwMzQ1Mw%3D%3D.2-ccb7-4
            <br/>
            Freeda,freeda,2021-04-03 19:00:00 CEST,IGREEL,444,100,500,https://www.instagram.com/reel/CNTJXyqI2nN//,Test del venerdì 9,,,0:10,1110,55,
            </b>
            <br />
          </>
        </Typography>
        <div style={{ width: 220, padding: 10 }}>
          <Typography
            variantName="text"
            textColor={Colors.PURPLE}
            style={{ lineHeight: "20px", letterSpacing: 0.3, fontWeight: 500 }}
          >
            Formatting data:
          </Typography>
          <Typography variantName="label" style={{ lineHeight: "20px", letterSpacing: 0.3 }}>
            <>
              <strong>Length</strong>
              <br />
              ✌🏻 Correct: 0:53
              <br />
              ⛔️ Wrong: 53
              <br />
              {/* <DividerSection />
              <strong>AVG Watched</strong>
              <br />
              ✌🏻 Correct: 0,23
              <br />
              ⛔️ Wrong: 23%
              <br /> */}
            </>
          </Typography>
        </div>
        <Typography variantName="text" style={{ lineHeight: "20px", letterSpacing: 0.3, marginBottom: 40 }}>
          <>
            <h3><StepNumber>5.</StepNumber> Save the file in .CSV format with comma like separator</h3>
            Nice job! Now that you've prepared your .CSV file, you're ready to import it to Elinor.
          </>
        </Typography>
        <h3>Some Examples</h3>
        <div>
          <a href="/2021-04-06-14-13-00-CEST.csv" download>Italy CSV Example File (Summer Time)</a>
        </div>
        <br />
        <div>
          <a href="/2021-04-07-14-13-00-CET.csv" download>Spain CSV Example File (Winter Time)</a>
        </div>
        <br />
        <div>
          <a href="/2021-04-09-14-13-00-GMT.csv" download>UK CSV Example File</a>
        </div>
        <br />
        <div>
          <a href="/2021-04-08-14-13-00-BST.csv" download>UK CSV Example File (Summer Time)</a>
        </div>
        {/* <Typography variantName="subtitle" textColor={Colors.PURPLE} style={{ marginBottom: 20 }}>
          Columns example
        </Typography>
        <Table
          columns={helpPageColumns}
          tableData={helpPageData}
          rowIdGenerator={(value) => String(value.id)}
          itemsPerPage={helpPageData.length}
          head={helpPageHead}
        /> */}
      </WrapperInstructions>
    </WrapperPage>
  );
};

export { UploadCSVHelpPage };
