import gql from "graphql-tag";

const ALL_INSTAGRAM_STORIES_TITLE = gql`
  query allInstagramStories($where: InstagramStoryWhere, $metricWhere: MeasuredMetricWhere) {
    allInstagramStories(where: $where, metricWhere: $metricWhere) {
      title
    }
  }
`;

export { ALL_INSTAGRAM_STORIES_TITLE };
