import numeral from "numeral";
import "numeral/locales";
import moment from "moment";

import { ConversionUnit } from "./../types/ConversionUnit";

numeral.locale("it");

const conversionMap = {
  [ConversionUnit.HOUR]: (value: Numeral) =>
    numeral(Math.floor(moment.duration(value.value(), "milliseconds").asHours())).format("0,0.[00]"),
  [ConversionUnit.MINUTE]: (value: Numeral) =>
    numeral(Math.floor(moment.duration(value.value(), "milliseconds").asMinutes())).format("0,0.[00]"),
  [ConversionUnit.SECOND]: (value: Numeral) =>
    numeral(Math.floor(moment.duration(value.value(), "milliseconds").asSeconds())).format("0,0.[00]"),
  [ConversionUnit.TIME]: (value: Numeral) =>
    numeral(value.value())
      .format("0:00:00")
      .slice(2, 7),
  [ConversionUnit.TIMEHOUR]: (value: Numeral) => numeral(value.value()) .format("00:00:00"),
  [ConversionUnit.PERCENTAGE]: (value: Numeral) => `${value.multiply(100).format("0,0.00")}%`,
  [ConversionUnit.RAW]: (value: Numeral) => {
    const v = value.value();
    return numeral(v).format("0,0.[00]");
  },
};

const formatNumber = (v: number, conversionUnit: ConversionUnit) => {
  return conversionMap[conversionUnit](numeral(v));
};

export { formatNumber };
