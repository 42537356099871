import * as React from "react";
import { Route } from "react-router-dom";
import { Switch, Redirect } from "react-router";
import MetadataPage from "./components/pages/MetadataPage";
import { ProvidersWrapper } from "./providers/ProvidersWrapper";
import Sidebar from "./components/layout/Sidebar";
import { ComponentsWrapper } from "./components/ui/ComponentsWrapper";
import LoginPage from "./components/pages/LoginPage";
import auth from "./utils/auth";
import PrivateRoute from "./components/PrivateRoute";
import { FeedbackSnackbar } from "./components/layout/FeedbackSnackbar";
import FbPostListPage from "./components/pages/FbPostListPage";
import IgStoriesPostListPage from "./components/pages/IgStoriesPostListPage";
import { Platform } from "./types/Platform";
import { groupBy, filter } from "lodash";
import FbGroupsPostListPage from "./components/pages/FbGroupsPostListPage";
import IgPostListPage from "./components/pages/IgPostListPage";
import YtPostListPage from "./components/pages/YtPostListPage";
import { useScrollToTop } from "./hooks/useScrollToTop";
import MetricsDocPage from "./components/pages/MetricsDocPage";
import { UploadCSVHelpPage } from "./components/pages/UploadCSVHelpPage";
import { UploadCSVTikTokHelpPage } from "./components/pages/UploadCSVTikTokHelpPage";
import TkPostListPage from "./components/pages/TkPostListPage";
import { useQuery } from "react-apollo";
import { Metadata } from "./types/Metadata";
import { LABEL_BY_NAME } from "./components/queries/LabelByNameQuery";
import { State, StaticIdsContext } from "./providers/StaticIdsProvider";
import { userGroup} from "./utils/postTableUtils";
import {  UserGroupType } from "./types/ProjectId";


import {MetricsPageContainer as MetricsPage } from "./components/pages/MetricsPage/MetricsPage";
import { ProjectPageContext } from "./providers/ProjectPageProvider";

const AppContent: React.FC = () => {
  useScrollToTop();

  const { pageUnits } = React.useContext(ProjectPageContext);

  const result = useQuery<{ labelByName: Metadata }>(LABEL_BY_NAME, {
    fetchPolicy: "network-only",
    variables: {
      name: "Branded",
    },
  });

  let user = userGroup()

  const staticIdsContextState: State = {
    brandedId: result.loading ? undefined : result.data?.labelByName.id,
  };

  let pagesUnitsGroupedByPlatform = groupBy(pageUnits, "path");

  if(user==UserGroupType.FREEDA){
      let pathOut = filter(pageUnits, function(o) { 
        return o.path.startsWith('/freeda'); 
      });
      pagesUnitsGroupedByPlatform = groupBy(pathOut, "path");
    }
    else if(user==UserGroupType.PLATFORM){
      let pathOut = filter(pageUnits, function(o) { 
        return !o.path.startsWith('/freeda'); 
      });
      pagesUnitsGroupedByPlatform = groupBy(pathOut, "path");
  
    }


  return (
    <>
      <FeedbackSnackbar />
      <Switch>
        <Route
          exact
          path="/login"
          render={() =>
            auth.isAuthenticated() ? (
              <Redirect to="/" />
            ) : (
              <LoginPage login={auth.login} />
            )
          }
        />
        <PrivateRoute
          exact
          path="/upload-csv-help-page"
          isAuthenticated={auth.isAuthenticated}
          Component={<UploadCSVHelpPage />}
        />
        <PrivateRoute
          exact
          path="/upload-tiktok-csv-help-page"
          isAuthenticated={auth.isAuthenticated}
          Component={<UploadCSVTikTokHelpPage />}
        />
        <>
          <ComponentsWrapper>
            <PrivateRoute
              exact
              path="/"
              isAuthenticated={auth.isAuthenticated}
              Component={<MetadataPage />}
            />
            <PrivateRoute
              exact
              path="/metrics"
              isAuthenticated={auth.isAuthenticated}
              Component={<MetricsDocPage />}
            />

            {Object.entries(pagesUnitsGroupedByPlatform).map(
              ([path, pages], i) => {
                const { project, platform } = pages[0];

                return (
                  <PrivateRoute
                    key={i}
                    path={path + "/performance-metrics"}
                    isAuthenticated={auth.isAuthenticated}
                    Component={
                      <MetricsPage platform={platform} project={project} />
                    }
                  />
                );
              }
            )}

            <StaticIdsContext.Provider value={staticIdsContextState}>
              {Object.entries(pagesUnitsGroupedByPlatform).map(
                ([path, pages], i) => {
                  if (pages.length === 1) {
                    const {
                      project,
                      id,
                      platform,
                      pagesFilterProvidersAndContexts,
                      postColumnSetSelects,
                      withBrand,
                      withPublisher,
                    } = pages[0];

                    let PostListPage;

                    switch (platform) {
                      case Platform.FB: {
                        PostListPage = (
                          <FbPostListPage
                            project={project}
                            withBrand={withBrand}
                            withPublisher={withPublisher}
                            postFilterContext={
                              pagesFilterProvidersAndContexts.ctx
                            }
                            columnSetSelect={postColumnSetSelects}
                            columnSetType={id}
                          />
                        );
                        break;
                      }
                      case Platform.FBG: {
                        PostListPage = (
                          <FbGroupsPostListPage
                            publisherNamePage={
                              pagesFilterProvidersAndContexts.state
                                .selectedPublisher
                            }
                            project={project}
                            postFilterContext={
                              pagesFilterProvidersAndContexts.ctx
                            }
                            columnSetSelect={postColumnSetSelects}
                            columnSetType={id}
                          />
                        );
                        break;
                      }
                      case Platform.IG: {
                        PostListPage = (
                          <IgPostListPage
                            project={project}
                            withBrand={withBrand}
                            withPublisher={withPublisher}
                            postFilterContext={
                              pagesFilterProvidersAndContexts.ctx
                            }
                            columnSetSelect={postColumnSetSelects}
                            columnSetType={id}
                          />
                        );

                        break;
                      }
                      case Platform.YT: {
                        PostListPage = (
                          <YtPostListPage
                            project={project}
                            withBrand={withBrand}
                            withPublisher={withPublisher}
                            postFilterContext={
                              pagesFilterProvidersAndContexts.ctx
                            }
                            columnSetSelect={postColumnSetSelects}
                            columnSetType={id}
                          />
                        );
                        break;
                      }
                      case Platform.TK: {
                        PostListPage = (
                          <TkPostListPage
                            project={project}
                            withBrand={withBrand}
                            withPublisher={withPublisher}
                            postFilterContext={
                              pagesFilterProvidersAndContexts.ctx
                            }
                            columnSetSelect={postColumnSetSelects}
                            columnSetType={id}
                          />
                        );
                        break;
                      }
                      default:
                        PostListPage = () => <span>Unknown platform</span>;
                    }
                    return (
                      <PrivateRoute
                        exact
                        key={i}
                        path={path}
                        isAuthenticated={auth.isAuthenticated}
                        Component={PostListPage}
                      />
                    );
                  }

                  if (pages.length === 2) {
                    const framesPage = pages[0];
                    const storiesPage = pages[1];

                    return (
                      <PrivateRoute
                        key={i}
                        path={path}
                        isAuthenticated={auth.isAuthenticated}
                        Component={
                          <IgStoriesPostListPage
                            project={framesPage.project}
                            withBrand={storiesPage.withBrand}
                            withPublisher={storiesPage.withPublisher}
                            columnSetType={{
                              igFrames: framesPage.id,
                              igStories: storiesPage.id,
                            }}
                            igFramesColumnSetSelect={
                              framesPage.postColumnSetSelects
                            }
                            igStoriesColumnSetSelect={
                              storiesPage.postColumnSetSelects
                            }
                            igFramesPostFilterContext={
                              framesPage.pagesFilterProvidersAndContexts.ctx
                            }
                            igStoriesPostFilterContext={
                              storiesPage.pagesFilterProvidersAndContexts.ctx
                            }
                          />
                        }
                      />
                    );
                  }

                  return <span>Unknown number of pages!</span>;
                }
              )}
            </StaticIdsContext.Provider>
          </ComponentsWrapper>
          <Sidebar/>
        </>
      </Switch>
    </>
  );
  
};

const App: React.FC = () => {
  useScrollToTop();

  return (

    <ProvidersWrapper>
      <AppContent/>
    </ProvidersWrapper>
  );
  
};

export default App;
