import styled, { css } from "styled-components";
import { Button } from "@freeda/react-components";

export const StateButton = styled(Button)<{ isactive: boolean }>`
  && {
    min-width: unset;
    white-space: nowrap;
  }
  ${(props) =>
    props.isactive
      ? css`
          border-bottom: 2px solid rgb(255, 10, 67);
        `
      : css`
          color: ${props.theme.secondary};
        `}
`;
