import * as React from "react";
import Media from "react-media";
import styled from "styled-components";
import { PlaceholderInput } from "./PlaceholderInput";
import { Table } from "@freeda/react-components";

const SelectRow = styled.div`
  display: flex;
  align-items: center;
  margin: 21px 0px;
  justify-content: space-between;
`;

const TableStyled = styled(Table)`
  && {
    & > thead {
      background: rgba(107, 32, 218, 0.3);
    }
    & > thead > tr {
      background: rgba(107, 32, 218, 0.3);
    }
    & > thead > tr > th {
      background: rgba(107, 32, 218, 0.3);
      padding-left: 40px;
      opacity: 0;
    }
    & > thead > tr > th > div {
      padding: 18px !important;
    }
    & > tbody > tr > td {
      height: 80px;
    }
  }
`;

export const PlaceholderPostList: React.FC<{ style?: React.CSSProperties; showFilter?: boolean }> = ({
  style,
  showFilter
}) => {
  return (
    <Media query={{ maxWidth: 768 }}>
      {(matches: boolean) => (
        <div style={{ position: "relative", padding: matches ? "0px 15px" : 0, marginBottom: 25, ...style }}>
          {showFilter && (
            <SelectRow>
              <PlaceholderInput style={{ flexBasis: "20%" }} />
              <PlaceholderInput style={{ flexBasis: "45%" }} />
            </SelectRow>
          )}
          <TableStyled
            columns={[]}
            tableData={[]}
            rowIdGenerator={post => "nothing"}
            itemsPerPage={10}
            head={[{ id: "", label: "Loading...", width: "100%", sortable: false }]}
          />
        </div>
      )}
    </Media>
  );
};

PlaceholderPostList.defaultProps = {
  showFilter: true
};
