import { includes } from "lodash";

const toCSV = (items: Array<any>, omits: Array<string>) => {
  const header = Object.keys(items[0])
    .filter(k => !includes(omits, k))
    .map(i => `${i}`);
  let csv = items.map(row =>
    header.map(fieldName => `"${String(row[fieldName]).replace(new RegExp('"', "g"), '""')}"`).join(",")
  );
  csv.unshift(header.join(","));
  return csv.join("\r\n");
};

const csvDownload = (content: Array<any>, omits: Array<string>) => {
  const data = new Blob([toCSV(content, omits)], {
    type: "text/csv"
  });
  const csvURL = window.URL.createObjectURL(data);
  const tempLink = document.createElement("a");
  tempLink.href = csvURL;
  tempLink.setAttribute("download", "filename.csv");
  tempLink.click();
};

export { csvDownload };
