import * as React from "react";
import {
  Drawer,
  DividerSection,
  Typography,
  List,
  ListItemText,
  ButtonIcon,
  Icon,
} from "@freeda/react-components";
import { zIndex } from "../../../utils/zIndex";
import Media from "react-media";
import { RouterNavLink as NavLink } from "../../ui/RouterLink";
import SidebarMobile from "./SidebarMobile";
import { isEmpty} from "lodash";
import {
  FirstPart,
  WrapperLabel,
  SidebarListItem,
  SubMenuIconTransition,
  ProjectPlatformWrapper,
  LinkWrapper,
} from "./styles";
import LogoutButton from "../../ui/LogoutButton";
import { Colors } from "@freeda/react-components/lib/theme";
import { ProjectId, UserGroupType } from "../../../types/ProjectId";
import MetadataIcon from "../../ui/SvgIcon/MetadataIcon";
import { CSSTransition } from "react-transition-group";
import DashboardIcon from "../../ui/SvgIcon/DashboardIcon";
import { CardProjects } from "../../../container/CardProjects";
import { capitalize, groupBy } from "lodash";
import {
  iconByPlatform,
  buildPathByPlatform,
 
} from "../../../ui-specs/posts-pages-specs";
import { emojiList, randomNumber } from "./emoji";
import MetricIcon from "../../ui/SvgIcon/MetricIcon";
import { Platform } from "../../../types/Platform";
import { SidebarContext } from "../../../providers/SidebarProvider";
import { userGroup} from "../../../utils/postTableUtils";
import { useHistory } from "react-router-dom";
import { ProjectPageContext } from "../../../providers/ProjectPageProvider";

export const getLabelColor = (isClicked: boolean, mobileColor?: string) => {
  if (mobileColor) {
    return isClicked ? "#fff" : mobileColor;
  } else {
    return isClicked ? "#fff" : Colors.DARKpurple;
  }
};

const Sidebar: React.FC = () => {

  const { onOpenUploadREELDialog, onOpenUploadTiktokDialog } = React.useContext(
    SidebarContext
  );

  const { pageUnits } = React.useContext(ProjectPageContext);

  const indexEmojiList = React.useRef<number>(randomNumber());

  const initialPathname = decodeURIComponent(window.location.pathname);

  let initialProject = initialPathname
    .substring(1)
    .split("/")[0]
    .toUpperCase() as ProjectId;


  const [openSidebar, setOpenSidebar] = React.useState<boolean>(false);

  let user = userGroup()
  if(initialPathname=='/'){
    initialProject = user === UserGroupType.PLATFORM ? Object.values(ProjectId)[1] : Object.values(ProjectId)[0]
  }
  
  const [selectedProject, setSelectedProject] = React.useState<ProjectId>(
            user == UserGroupType.FREEDA ? ProjectId.FREEDA   : 
            user == UserGroupType.PLATFORM ? ProjectId[initialProject] : 
            (ProjectId[initialProject] || ProjectId.FREEDA)
  );

  //parte vecchia
  /*const selectedProjectWithRelativePlatform = groupBy(
    pagesUnits.filter((page) => page.project.idLegacy === selectedProject),
    "path"
  );*/

  //parte nuova con nuovo array progetti + sort sul path
  const selectedProjectWithRelativePlatform = groupBy(
    pageUnits.filter((page) => page.project.idLegacy === selectedProject),
    "path"
  );

  //console.log('selectedProjectWithRelativePlatform ', selectedProjectWithRelativePlatform)
  //console.log('selectedProjectWithRelativePlatform2', selectedProjectWithRelativePlatform2)
 
  /*const firstAvailablePath = Object.entries(
    selectedProjectWithRelativePlatform
  )[0][0];*/

  //mi sono accorta che firstAvailablePath era uguale all'array creato con nostro array progetti
  const firstAvailablePath = isEmpty(selectedProjectWithRelativePlatform) ? 
                    selectedProjectWithRelativePlatform 
                    :
                    Object.entries(selectedProjectWithRelativePlatform)[0][0]

  console.log('firstAvailablePath', firstAvailablePath)
  

  const isMetadataClicked = window.location.pathname === "/";
  const isMetricsClicked = window.location.pathname === "/metrics";
  const isInstagramClicked =
    window.location.pathname ===
    `/${selectedProject.toLocaleLowerCase()}/instagram`;
  const isTiktokClicked =
    window.location.pathname ===
    `/${selectedProject.toLocaleLowerCase()}/tiktok`;

  const onChangeProject = (projectId: ProjectId) =>
    setSelectedProject(projectId);

  const history = useHistory();

  return (
    <Media query={{ maxWidth: 768 }}>
      {(matches: boolean) => {
        return !matches ? (
          <Drawer
            closeWidth={72}
            openWidth={240}
            open={openSidebar}
            changeOpen={(isOpen: boolean) => setOpenSidebar(isOpen)}
            style={{
              position: "fixed",
              zIndex: zIndex.Sidebar,
              top: 0,
              flex: "1 0 auto",
              left: 0,
              right: "auto",
            }}
          >
            <CSSTransition in={openSidebar} timeout={{ enter: 200, exit: 500 }}>
              {(openStatus) => (
                <>
                  <FirstPart>
                    <SidebarListItem
                      backgroundHover={Colors.PURPLE}
                      open={openSidebar}
                      style={{ height: 80, marginTop: 25, userSelect: "none" }}
                    >
                      <Typography variantName="title" style={{ fontSize: 30 }}>
                        {emojiList[indexEmojiList.current]}
                      </Typography>
                      {openSidebar && (
                        <WrapperLabel className={`wrapper-${openStatus}`}>
                          <ListItemText
                            primary={
                              <>
                                <Typography
                                  variantName="text"
                                  textColor={Colors.DARKpurple}
                                  style={{ paddingBottom: 2 }}
                                >
                                  Welcome,
                                </Typography>
                                <Typography
                                  variantName="text"
                                  textColor={Colors.DARKpurple}
                                  style={{
                                    marginRight: 15,
                                    fontSize: 16,
                                    fontWeight: 600,
                                    paddingTop: 2,
                                  }}
                                >
                                  {localStorage.getItem("name")!}
                                </Typography>
                              </>
                            }
                          />
                        </WrapperLabel>
                      )}
                    </SidebarListItem>
                    <SidebarListItem
                      open={openSidebar}
                      style={{ marginBottom: 10 }}
                    >
                      <LogoutButton>
                        <ButtonIcon
                          iconName="logout"
                          iconColor={Colors.DARKpurple}
                          style={{ cursor: "pointer", margin: 0, padding: 0 }}
                        />
                        {openSidebar && (
                          <WrapperLabel className={`wrapper-${openStatus}`}>
                            <ListItemText
                              primary={
                                <Typography
                                  variantName="text"
                                  textColor={Colors.DARKpurple}
                                  style={{ cursor: "pointer" }}
                                >
                                  Logout
                                </Typography>
                              }
                            />
                          </WrapperLabel>
                        )}
                      </LogoutButton>
                    </SidebarListItem>
                    <DividerSection
                      dividerColor={Colors.WHITE}
                      style={{ width: "calc(100% - 30px)" }}
                    />
                  </FirstPart>
                  <List>
                    <LinkWrapper>
                      <NavLink to="/">
                        <SidebarListItem
                          open={openSidebar}
                          style={{ zIndex: 1 }}
                        >
                          <MetadataIcon
                            color={getLabelColor(isMetadataClicked)}
                          />
                          {openSidebar && (
                            <WrapperLabel className={`wrapper-${openStatus}`}>
                              <ListItemText
                                primary={
                                  <Typography
                                    variantName="text"
                                    textColor={getLabelColor(isMetadataClicked)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Labels
                                  </Typography>
                                }
                              />
                            </WrapperLabel>
                          )}
                        </SidebarListItem>
                      </NavLink>
                    </LinkWrapper>
                    
                    <ProjectPlatformWrapper
                      style={{ top: window.location.pathname === "/" ? 5 : 0 }}
                    >
                      <SidebarListItem open={openSidebar}>
                        <NavLink
                          to={firstAvailablePath}
                          style={{ width: "auto" }}
                        >
                          <DashboardIcon
                            color={getLabelColor(
                              !isMetadataClicked && !isMetricsClicked
                            )}
                          />
                        </NavLink>
                        
                        {openSidebar && (
                          
                          <WrapperLabel className={`wrapper-${openStatus}`}>
                            <ListItemText
                              primary={
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <NavLink to={firstAvailablePath}>
                                        <Typography
                                          variantName="text"
                                          textColor={getLabelColor(
                                            !isMetadataClicked && !isMetricsClicked
                                          )}
                                          style={{
                                            marginRight: 15,
                                            fontSize: 16,
                                            fontWeight: 600,
                                            cursor: "pointer",
                                          }}
                                        >
                                        {capitalize(selectedProject)}
                                    </Typography>
                                  </NavLink>
                                 
                                  {user!=UserGroupType.FREEDA && 
                                      <CardProjects
                                          selectedProject={selectedProject}
                                          onChangeProject={(project) =>
                                            onChangeProject(project)
                                          }
                                          iconColor={getLabelColor(
                                            !isMetadataClicked && !isMetricsClicked
                                          )}
                                          //pageUnitsForCard = {pageUnitsForSidebar}
                                      /> 
                                  }
                                </div>
                              }
                            />
                          </WrapperLabel>
                        )}
                      </SidebarListItem>

                      {Object.entries(selectedProjectWithRelativePlatform).map(
                        ([path, pages], i) => {
                          const { platform } = pages[0];
                          const isIG = platform === Platform.IG;
                          const isTK = platform === Platform.TK;
                          const isFB = platform === Platform.FB;
                          const isYT = platform === Platform.YT;
                          

                          const activeLabelColor = getLabelColor(
                            initialPathname.includes(path)
                          );

                          const currentPath = `/${pages[0].project.id.toLowerCase()}/${buildPathByPlatform(
                            pages[0].platform
                          )
                            .toLowerCase()
                            .replace("_", "")
                            .trim()}`;

                          return (
                            <LinkWrapper key={i}>
                              <NavLink to={path}>
                                <SidebarListItem
                                  open={openSidebar}
                                  style={{ zIndex: 1 }}
                                >
                                  {iconByPlatform(platform, activeLabelColor)}
                                  {openSidebar && (
                                    <WrapperLabel
                                      className={`wrapper-${openStatus}`}
                                    >
                                      <ListItemText
                                        primary={
                                          <Typography
                                            variantName="text"
                                            style={{ cursor: "pointer" }}
                                            textColor={activeLabelColor}
                                          >
                                            {capitalize(
                                              buildPathByPlatform(
                                                platform
                                              ).replace("-", " ")
                                            )}
                                          </Typography>
                                        }
                                      />
                                    </WrapperLabel>
                                  )}
                                </SidebarListItem>
                                {openSidebar && initialPathname.includes(path) && (isFB || isIG || isYT || isTK) && (
                                <>
                                  <CSSTransition
                                    in={true}
                                    timeout={0}
                                    unmountOnExit
                                  >
                                    {(status) => (
                                      <NavLink
                                        to={
                                          currentPath + "/performance-metrics"
                                        }
                                      >
                                        <SubMenuIconTransition
                                          className={status}
                                        >
                                          {openSidebar && (
                                            <WrapperLabel
                                              className={`wrapper-${openStatus}`}
                                            >
                                              <ListItemText
                                                primary={
                                                  <Typography
                                                    variantName="text"
                                                    textColor={getLabelColor(
                                                      isInstagramClicked
                                                    )}
                                                    style={{
                                                      cursor: "pointer",
                                                      position: "relative",
                                                      left: -5,
                                                    }}
                                                  >
                                                    Page Metrics
                                                  </Typography>
                                                }
                                              />
                                            </WrapperLabel>
                                          )}
                                        </SubMenuIconTransition>
                                      </NavLink>
                                    )}
                                  </CSSTransition>
                                </>
                              )}
                              </NavLink>
                            </LinkWrapper>
                          );
                        }
                      )}
                      <NavLink to="/metrics">
                        <SidebarListItem
                          open={openSidebar}
                          style={{ zIndex: 1 }}
                        >
                          <MetricIcon color={getLabelColor(isMetricsClicked)} />
                          {openSidebar && (
                            <WrapperLabel className={`wrapper-${openStatus}`}>
                              <ListItemText
                                primary={
                                  <Typography
                                    variantName="text"
                                    textColor={getLabelColor(isMetricsClicked)}
                                    style={{ cursor: "pointer" }}
                                  >
                                    Metrics
                                  </Typography>
                                }
                              />
                            </WrapperLabel>
                          )}
                        </SidebarListItem>
                      </NavLink>
                    </ProjectPlatformWrapper>

                    <LinkWrapper style={{ alignItems: "center" }}>
                      <a
                        href="https://help.freeda.tech/elinor/index.html"
                        target="_blank"
                      >
                        <Icon
                          iconName="help"
                          style={{
                            cursor: "pointer",
                            position: "relative",
                          }}
                          iconColor={Colors.DARKpurple}
                        />
                      </a>
                    </LinkWrapper>
                  </List>
                </>
              )}
            </CSSTransition>
          </Drawer>
        ) : (
          <SidebarMobile
            open={openSidebar}
            onOpen={() => setOpenSidebar((prevOpen) => !prevOpen)}
            changeOpenSidebar={(isOpen) => setOpenSidebar(isOpen)}
            selectedProject={selectedProject}
            onChangeProject={(project) => onChangeProject(project)}
            indexEmojiList={indexEmojiList.current}
            //pageUnitsForSidebarMobile = {pageUnitsForSidebar}
          />
        );
      }}
    </Media>
  );
};

export default Sidebar;
