import * as React from "react";
import { Platform } from "../types/Platform";
import { PublicationType } from "../types/Publication";
import { buildFilterProvidersContext } from "../providers/FilterProvider";
import { getPostColumnSetSelect } from "../container/PostColumnSetSelects";
import { Project } from "../types/Project";
import FbIcon from "../components/ui/SvgIcon/FbIcon";
import GroupsIcon from "../components/ui/SvgIcon/GroupsIcon";
import IgIcon from "../components/ui/SvgIcon/IgIcon";
import IgStoriesIcon from "../components/ui/SvgIcon/IgStoriesIcon";
import YtIcon from "../components/ui/SvgIcon/YtIcon";
import { useQuery } from "@apollo/react-hooks";
import TkIcon from "../components/ui/SvgIcon/TkIcon";
import { PROJECTS_TO_SHOW } from "../apollo/queries/ProjectsToShow";
import projectsToShowT from "../types/graphql/ProjectsToShowQuery";
import { PageUnit } from "../types/PageUnit";
import { ProjectId, ProjectType } from "../types/ProjectId";

export enum PlatformName {
  FB = "Facebook",
  FBG = "Facebook",
  IG = "Instagram",
  IG_FRAME = "Instagram",
  IG_STORY = "Instagram",
  YT = "Youtube",
  TK = "TikTok",
}

export const getPublicationTypeByPlatform = (
  platform: Platform
): PublicationType => {
  const publicationType = {
    [Platform.FB]: PublicationType.FB_POST,
    [Platform.FBG]: PublicationType.FBG_POST,
    [Platform.IG]: PublicationType.IG_POST,
    [Platform.IG_FRAME]: PublicationType.IG_FRAME,
    [Platform.IG_STORY]: PublicationType.IG_STORY,
    [Platform.YT]: PublicationType.YT_POST,
    [Platform.TK]: PublicationType.TK_POST,
  };

  return publicationType[platform];
};

export const getPlatformByPublicationType = (
  publicationType: PublicationType
): Platform => {
  const platform = {
    [PublicationType.FB_POST]: Platform.FB,
    [PublicationType.FBG_POST]: Platform.FBG,
    [PublicationType.IG_POST]: Platform.IG,
    [PublicationType.IG_FRAME]: Platform.IG,
    [PublicationType.IG_STORY]: Platform.IG_STORY,
    [PublicationType.YT_POST]: Platform.YT,
    [PublicationType.TK_POST]: Platform.TK,
  };

  return platform[publicationType];
};

export const buildPathByPlatform = (platform: Platform) => {
  const slugs = {
    [Platform.FB]: "facebook",
    [Platform.FBG]: "facebook-groups",
    [Platform.IG]: "instagram",
    [Platform.IG_FRAME]: "instagram-story",
    [Platform.IG_STORY]: "instagram-story",
    [Platform.YT]: "youtube",
    [Platform.TK]: "tiktok",
  };

  return slugs[platform];
};

export const iconByPlatform = (platform: Platform, colorIcon: string) => {
  const icon = {
    [Platform.FB]: <FbIcon color={colorIcon} />,
    [Platform.FBG]: <GroupsIcon color={colorIcon} />,
    [Platform.IG]: <IgIcon color={colorIcon} />,
    [Platform.IG_FRAME]: <IgStoriesIcon color={colorIcon} />,
    [Platform.IG_STORY]: <IgStoriesIcon color={colorIcon} />,
    [Platform.YT]: <YtIcon color={colorIcon} />,
    [Platform.TK]: <TkIcon color={colorIcon} />,
  };
  return icon[platform];
};

export type Page = {
  project: Project;
  platform: Platform;
  hasCountries: boolean;
  withBrand: boolean;
  withPublisher: boolean;
  uniqueLanguage?: String;
};


const freedaProject: Project = {
  id: "Freeda",
  idLegacy: ProjectId.FREEDA,
  label: "Freeda",
  projectType: ProjectType.ENGAGE
};
const gooviProject: Project = {
  id: "Goovi",
  idLegacy: ProjectId.GOOVI,
  label: "Goovi",
  projectType: ProjectType.ENGAGE
};
const florenaProject: Project = {
  id: "Florena",
  idLegacy: ProjectId.FLORENA,
  label: "Florena",
  projectType: ProjectType.ENGAGE
};
const trussardiProject: Project = {
  id: "Trussardi",
  idLegacy: ProjectId.TRUSSARDI,
  label: "Trussardi",
  projectType: ProjectType.LISTEN
};
const pradaProject: Project = {
  id: "Prada",
  idLegacy: ProjectId.PRADA,
  label: "Prada",
  projectType: ProjectType.LISTEN
};
const superfluidProject: Project = {
  id: "Superfluid",
  idLegacy: ProjectId.SUPERFLUID,
  label: "Superfluid",
  projectType: ProjectType.LISTEN
};
const repowerProject: Project = {
  id: "Repower",
  idLegacy: ProjectId.REPOWER,
  label: "Repower",
  projectType: ProjectType.ENGAGE
};
const kfcProject: Project = {
  id: "Kfc",
  idLegacy: ProjectId.KFC,
  label: "Kfc",
  projectType: ProjectType.ENGAGE
};
const lavazza1895Project: Project = {
  id: "Lavazza1895",
  idLegacy: ProjectId.LAVAZZA1895,
  label: "Lavazza1895",
  projectType: ProjectType.TRANSACT
};
const goldenGooseProject: Project = {
  id: "Golden_Goose",
  idLegacy: ProjectId.GOLDEN_GOOSE,
  label: "Golden Goose",
  projectType: ProjectType.HYBRID_FULL
};
const lemonsodaProject: Project = {
  id: "Lemonsoda",
  idLegacy: ProjectId.LEMONSODA,
  label: "Lemonsoda",
  projectType: ProjectType.ENGAGE
};
const sprinterProject: Project = {
  id: "Sprinter",
  idLegacy: ProjectId.SPRINTER,
  label: "Sprinter",
  projectType: ProjectType.ENGAGE
};
const technoGymProject: Project = {
  id: "Technogym",
  idLegacy: ProjectId.TECHNOGYM,
  label: "Technogym",
  projectType: ProjectType.LISTEN
};
const lavazzaProject: Project = {
  id: "Lavazza",
  idLegacy: ProjectId.LAVAZZA,
  label: "Lavazza",
  projectType: ProjectType.ENGAGE
};

const triumphProject: Project = {
  id: "Triumph",
  idLegacy: ProjectId.TRIUMPH,
  label: "Triumph",
  projectType: ProjectType.LISTEN
};

const jeepProject: Project = {
  id: "Jeep",
  idLegacy: ProjectId.JEEP,
  label: "Jeep",
  projectType: ProjectType.HYBRID_PARTIAL
};


const coopProject: Project = {
  id: "Coop",
  idLegacy: ProjectId.COOP,
  label: "Coop",
  projectType: ProjectType.ENGAGE
};

const armaniProject: Project = {
  id: "Armani",
  idLegacy: ProjectId.ARMANI,
  label: "Armani",
  projectType: ProjectType.LISTEN
};

const emporioArmaniProject: Project = {
  id: "EmporioArmani",
  idLegacy: ProjectId.EMPORIOARMANI,
  label: "Emporio Armani",
  projectType: ProjectType.HYBRID_PARTIAL
};

const moonBootProject: Project = {
  id: "MoonBoot",
  idLegacy: ProjectId.MOONBOOT,
  label: "Moon Boot",
  projectType: ProjectType.LISTEN
};

const kiaTeInspiraProject: Project = {
  id: "Kiateinspira",
  idLegacy: ProjectId.KIATEINSPIRA,
  label: "KiaTeInspira",
  projectType: ProjectType.ENGAGE
};

const dieselProject: Project = {
  id: "Diesel", idLegacy:
    ProjectId.DIESEL,
  label: "Diesel",
  projectType: ProjectType.LISTEN
};

const mediolanumProject: Project = {
  id: "Mediolanum",
  idLegacy: ProjectId.MEDIOLANUM,
  label: "Mediolanum",
  projectType: ProjectType.LISTEN
};

const uleProject: Project = { id: "Ulè", idLegacy: ProjectId.ULE, label: "Ulè", projectType: ProjectType.FAN };

const marcaProject: Project = {
  id: "Marca",
  idLegacy: ProjectId.MARCA,
  label: "Marca",
  projectType: ProjectType.HYBRID_FULL
};

const todsProject: Project = {
  id: "Tod's",
  idLegacy: ProjectId.TODS,
  label: "Tod's",
  projectType: ProjectType.LISTEN
};

const nordicaProject: Project = {
  id: "Nordica",
  idLegacy: ProjectId.NORDICA,
  label: "Nordica",
  projectType: ProjectType.LISTEN
};

const assosProject: Project = {
  id: "Assos",
  idLegacy: ProjectId.ASSOS,
  label: "Assos",
  projectType: ProjectType.LISTEN
};

const rollerbladeProject: Project = {
  id: "Rollerblade",
  idLegacy: ProjectId.ROLLERBLADE,
  label: "Rollerblade",
  projectType: ProjectType.LISTEN
};

const gucciequilibriumProject: Project = {
  id: "Gucciequilibrium",
  idLegacy: ProjectId.GUCCIEQUILIBRIUM,
  label: "Gucciequilibrium",
  projectType: ProjectType.LISTEN
};

const odStoreProject: Project = {
  id: "ODStore",
  idLegacy: ProjectId.ODSTORE,
  label: "ODStore",
  projectType: ProjectType.LISTEN
};

const ingProject: Project = {
  id: "ING",
  idLegacy: ProjectId.ING,
  label: "Ing",
  projectType: ProjectType.LISTEN
};

const blizzardSkiProject: Project = {
  id: "BlizzardSki",
  idLegacy: ProjectId.BLIZZARDSKI,
  label: "Blizzard Ski",
  projectType: ProjectType.LISTEN
};

const tecnicaProject: Project = {
  id: "TECNICA",
  idLegacy: ProjectId.TECNICA,
  label: "Tecnica Sports",
  projectType: ProjectType.LISTEN
};

const panfeProject: Project = {
  id: "PANFE",
  idLegacy: ProjectId.PANFE,
  label: "Panfe",
  projectType: ProjectType.ENGAGE
};

const autryProject: Project = {
  id: "AUTRY",
  idLegacy: ProjectId.AUTRY,
  label: "Autry",
  projectType: ProjectType.LISTEN
};

const somatolineSkinExpertProject: Project = {
  id: "Somatolineskin",
  idLegacy: ProjectId.SOMATOLINESKIN,
  label: "Somatoline SkinExpert",
  projectType: ProjectType.LISTEN
};

const kiaProject: Project = {
  id: "Kia",
  idLegacy: ProjectId.KIA,
  label: "Kia",
  projectType: ProjectType.ENGAGE
};

const novaMasProject: Project = {
  id: "Novamas",
  idLegacy: ProjectId.NOVAMAS,
  label: "NovaMas A3",
  projectType: ProjectType.LISTEN
};

const flooxerNowProject: Project = {
  id: "Flooxernow",
  idLegacy: ProjectId.FLOOXERNOW,
  label: "FlooxerNow",
  projectType: ProjectType.LISTEN
};

const antena3Project: Project = {
  id: "ANTENA3",
  idLegacy: ProjectId.ANTENA3,
  label: "Antena3",
  projectType: ProjectType.LISTEN
};

const atresPlayerProject: Project = {
  id: "ATRESplayer",
  idLegacy: ProjectId.ATRESPLAYER,
  label: "AtresPlayer",
  projectType: ProjectType.LISTEN
};

const aircProject: Project = {
  id: "AIRC",
  idLegacy: ProjectId.AIRC,
  label: "Airc",
  projectType: ProjectType.LISTEN
};
const telefonicaProject: Project = {
  id: "TELEFONICA",
  idLegacy: ProjectId.TELEFONICA,
  label: "Telefonica",
  projectType: ProjectType.LISTEN
};
const gcdsProject: Project = {
  id: "GCDS",
  idLegacy: ProjectId.GCDS,
  label: "GCDSWear",
  projectType: ProjectType.LISTEN
};

const pinkoProject: Project = {
  id: "PINKO",
  idLegacy: ProjectId.PINKO,
  label: "Pinko",
  projectType: ProjectType.LISTEN
};

const humanaitaliaProject: Project = {
  id: "HUMANAITALIA",
  idLegacy: ProjectId.HUMANAITALIA,
  label: "Humanaitalia",
  projectType: ProjectType.LISTEN
};

const chillyProject: Project = {
  id: "CHILLY",
  idLegacy: ProjectId.CHILLY,
  label: "Chilly",
  projectType: ProjectType.ENGAGE
};

const enterpriseJapanProject: Project = {
  id: "EntJapan",
  idLegacy: ProjectId.ENTJAPAN,
  label: "Enterprise Japan",
  projectType: ProjectType.LISTEN
};

const lavikaProject: Project = {
  id: "Lavika",
  idLegacy: ProjectId.LAVIKA,
  label: "Lavika",
  projectType: ProjectType.TRANSACT
};

export const pages: Array<Page> = [
  {
    project: freedaProject,
    platform: Platform.FB,
    hasCountries: true,
    withBrand: true,
    withPublisher: true,
  },
  {
    project: freedaProject,
    platform: Platform.FBG,
    hasCountries: true,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: freedaProject,
    platform: Platform.IG,
    hasCountries: true,
    withBrand: true,
    withPublisher: true,
  },
  {
    project: freedaProject,
    platform: Platform.IG_FRAME,
    hasCountries: true,
    withBrand: false,
    withPublisher: true,
  },
  {
    project: freedaProject,
    platform: Platform.IG_STORY,
    hasCountries: true,
    withBrand: true,
    withPublisher: true,
  },
  {
    project: freedaProject,
    platform: Platform.YT,
    hasCountries: true,
    withBrand: true,
    withPublisher: true,
  },
  {
    project: gooviProject,
    platform: Platform.FB,
    hasCountries: true,
    withBrand: false,
    withPublisher: true,
  },
  {
    project: gooviProject,
    platform: Platform.IG,
    hasCountries: true,
    withBrand: false,
    withPublisher: true,
  },
  {
    project: gooviProject,
    platform: Platform.IG_FRAME,
    hasCountries: true,
    withBrand: false,
    withPublisher: true,
  },
  {
    project: gooviProject,
    platform: Platform.IG_STORY,
    hasCountries: true,
    withBrand: false,
    withPublisher: true,
  },
  {
    project: gooviProject,
    platform: Platform.TK,
    hasCountries: true,
    withBrand: false,
    withPublisher: true,
  },
  {
    project: florenaProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: florenaProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: florenaProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: florenaProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: trussardiProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: trussardiProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: trussardiProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: trussardiProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: pradaProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: pradaProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: pradaProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: pradaProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: pradaProject,
    platform: Platform.TK,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: superfluidProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: superfluidProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: superfluidProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: superfluidProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: repowerProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: repowerProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: repowerProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: repowerProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: freedaProject,
    platform: Platform.TK,
    hasCountries: true,
    withBrand: true,
    withPublisher: true,
  },
  {
    project: kfcProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: kfcProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: kfcProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: kfcProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: kfcProject,
    platform: Platform.TK,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: lavazza1895Project,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: lavazza1895Project,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: lavazza1895Project,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: lavazza1895Project,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: goldenGooseProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: goldenGooseProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: goldenGooseProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: goldenGooseProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: goldenGooseProject,
    platform: Platform.TK,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: lemonsodaProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: lemonsodaProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: lemonsodaProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: lemonsodaProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: lemonsodaProject,
    platform: Platform.TK,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: sprinterProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: sprinterProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: sprinterProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: sprinterProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: technoGymProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: technoGymProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: technoGymProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: technoGymProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: technoGymProject,
    platform: Platform.TK,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: lavazzaProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
    // uniqueLanguage: "UK",
  },
  {
    project: lavazzaProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
    // uniqueLanguage: "UK",
  },
  {
    project: lavazzaProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
    // uniqueLanguage: "UK",
  },
  {
    project: lavazzaProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
    // uniqueLanguage: "UK",
  },
  {
    project: triumphProject,
    platform: Platform.FB,
    hasCountries: true,
    withBrand: false,
    withPublisher: true,
  },
  {
    project: triumphProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: triumphProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: triumphProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: coopProject,
    platform: Platform.FB,
    hasCountries: true,
    withBrand: false,
    withPublisher: true,
  },
  {
    project: coopProject,
    platform: Platform.IG,
    hasCountries: true,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: coopProject,
    platform: Platform.IG_FRAME,
    hasCountries: true,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: coopProject,
    platform: Platform.IG_STORY,
    hasCountries: true,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: armaniProject,
    platform: Platform.FB,
    hasCountries: true,
    withBrand: false,
    withPublisher: true,
  },
  {
    project: armaniProject,
    platform: Platform.IG,
    hasCountries: true,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: armaniProject,
    platform: Platform.IG_FRAME,
    hasCountries: true,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: armaniProject,
    platform: Platform.IG_STORY,
    hasCountries: true,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: jeepProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: jeepProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: jeepProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: jeepProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: emporioArmaniProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: emporioArmaniProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: emporioArmaniProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: emporioArmaniProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: moonBootProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: moonBootProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: moonBootProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: moonBootProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: kiaTeInspiraProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: kiaTeInspiraProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: kiaTeInspiraProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: kiaTeInspiraProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: kiaTeInspiraProject,
    platform: Platform.TK,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: dieselProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: dieselProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: dieselProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: dieselProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: mediolanumProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: mediolanumProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: mediolanumProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: mediolanumProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: uleProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: uleProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: uleProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: marcaProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: marcaProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: marcaProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: marcaProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: marcaProject,
    platform: Platform.TK,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: todsProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: todsProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: todsProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: todsProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: nordicaProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: nordicaProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: nordicaProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: nordicaProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: assosProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: assosProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: assosProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: assosProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: rollerbladeProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: rollerbladeProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: rollerbladeProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: rollerbladeProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: gucciequilibriumProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: gucciequilibriumProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: gucciequilibriumProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: gucciequilibriumProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: odStoreProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: odStoreProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: odStoreProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: odStoreProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: odStoreProject,
    platform: Platform.TK,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: ingProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: ingProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: ingProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: ingProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: blizzardSkiProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: blizzardSkiProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: blizzardSkiProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: blizzardSkiProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: tecnicaProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: tecnicaProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: tecnicaProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: tecnicaProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: panfeProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: panfeProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: panfeProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: panfeProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: autryProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: autryProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: autryProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: autryProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: somatolineSkinExpertProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: somatolineSkinExpertProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: somatolineSkinExpertProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: somatolineSkinExpertProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: kiaProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: kiaProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: kiaProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: kiaProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: kiaProject,
    platform: Platform.TK,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: novaMasProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: novaMasProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: novaMasProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: novaMasProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: flooxerNowProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: flooxerNowProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: flooxerNowProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: flooxerNowProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: antena3Project,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: antena3Project,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: antena3Project,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: antena3Project,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: antena3Project,
    platform: Platform.TK,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: atresPlayerProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: atresPlayerProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: atresPlayerProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: atresPlayerProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: atresPlayerProject,
    platform: Platform.TK,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: aircProject,
    platform: Platform.FB,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: aircProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: aircProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: aircProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },

  {
    project: telefonicaProject,
    platform: Platform.IG,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: telefonicaProject,
    platform: Platform.IG_FRAME,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: telefonicaProject,
    platform: Platform.IG_STORY,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },
  {
    project: telefonicaProject,
    platform: Platform.TK,
    hasCountries: false,
    withBrand: false,
    withPublisher: false,
  },


 {
   project: gcdsProject,
   platform: Platform.IG,
   hasCountries: false,
   withBrand: false,
   withPublisher: false,
 },
 {
   project: gcdsProject,
   platform: Platform.IG_FRAME,
   hasCountries: false,
   withBrand: false,
   withPublisher: false,
 },
 {
   project: gcdsProject,
   platform: Platform.IG_STORY,
   hasCountries: false,
   withBrand: false,
   withPublisher: false,
 },

 {
  project: pinkoProject,
  platform: Platform.IG,
  hasCountries: false,
  withBrand: false,
  withPublisher: false,
},
{
  project: pinkoProject,
  platform: Platform.IG_FRAME,
  hasCountries: false,
  withBrand: false,
  withPublisher: false,
},
{
  project: pinkoProject,
  platform: Platform.IG_STORY,
  hasCountries: false,
  withBrand: false,
  withPublisher: false,
},
{
  project: humanaitaliaProject,
  platform: Platform.FB,
  hasCountries: false,
  withBrand: false,
  withPublisher: false,
},
{
  project: humanaitaliaProject,
  platform: Platform.IG,
  hasCountries: false,
  withBrand: false,
  withPublisher: false,
},
{
  project: humanaitaliaProject,
  platform: Platform.IG_FRAME,
  hasCountries: false,
  withBrand: false,
  withPublisher: false,
},
{
  project: humanaitaliaProject,
  platform: Platform.IG_STORY,
  hasCountries: false,
  withBrand: false,
  withPublisher: false,
},
{
  project: chillyProject,
  platform: Platform.FB,
  hasCountries: false,
  withBrand: false,
  withPublisher: false,
},
{
  project: chillyProject,
  platform: Platform.IG,
  hasCountries: false,
  withBrand: false,
  withPublisher: false,
},
{
  project: chillyProject,
  platform: Platform.IG_FRAME,
  hasCountries: false,
  withBrand: false,
  withPublisher: false,
},
{
  project: chillyProject,
  platform: Platform.IG_STORY,
  hasCountries: false,
  withBrand: false,
  withPublisher: false,
},
 {
   project: enterpriseJapanProject,
   platform: Platform.FB,
   hasCountries: false,
   withBrand: false,
   withPublisher: false,
 },
 {
   project: enterpriseJapanProject,
   platform: Platform.IG,
   hasCountries: false,
   withBrand: false,
   withPublisher: false,
 },
 {
   project: enterpriseJapanProject,
   platform: Platform.IG_FRAME,
   hasCountries: false,
   withBrand: false,
   withPublisher: false,
 },
 {
   project: enterpriseJapanProject,
   platform: Platform.IG_STORY,
   hasCountries: false,
   withBrand: false,
   withPublisher: false,
 },



 {
  project: lavikaProject,
  platform: Platform.FB,
  hasCountries: false,
  withBrand: false,
  withPublisher: false,
},
{
  project: lavikaProject,
  platform: Platform.IG,
  hasCountries: false,
  withBrand: false,
  withPublisher: false,
},
{
  project: lavikaProject,
  platform: Platform.IG_FRAME,
  hasCountries: false,
  withBrand: false,
  withPublisher: false,
},
{
  project: lavikaProject,
  platform: Platform.IG_STORY,
  hasCountries: false,
  withBrand: false,
  withPublisher: false,
},
{
  project: lavikaProject,
  platform: Platform.TK,
  hasCountries: false,
  withBrand: false,
  withPublisher: false,
},
];


const pagesUnits = pages.map((page) => {
  const path = `/${page.project.id.toLowerCase()}/${buildPathByPlatform(
    page.platform
  )
    .toLowerCase()
    .replace("_", "")
    .trim()}`;
  const id = `${page.project.id}_${page.platform}`;
  const project = page.project;
  const withBrand = page.withBrand;
  const withPublisher = page.withPublisher;
  const pagesFilterProvidersAndContexts = buildFilterProvidersContext(page);
  const postColumnSetSelects = getPostColumnSetSelect(
    page.project,
    page.platform
  );
  const platform = page.platform;
  return {
    path,
    id,
    project,
    withBrand,
    withPublisher,
    pagesFilterProvidersAndContexts,
    postColumnSetSelects,
    platform
  };
});



const pagesUnitsProject = (pages: Array<Page>) => 
  
  pages.map((page) => { 

    const path = `/${page.project.id.toLowerCase()}/${buildPathByPlatform(
      page.platform
    )
      .toLowerCase()
      .replace("_", "")
      .trim()}`;
    const id = `${page.project.id}_${page.platform}`.toUpperCase();
    page.project.id=page.project.id.toUpperCase()
    const project = page.project;
    const withBrand = page.withBrand;
    const withPublisher = page.withPublisher;
    const hasCountries = page.hasCountries;

    const pagesFilterProvidersAndContexts = buildFilterProvidersContext(page);
    const postColumnSetSelects = getPostColumnSetSelect(
      page.project,
      page.platform
    );
    const platform = page.platform;
   
    let pageUnit: PageUnit = 
     {
      path,
      id,
      project,
      hasCountries,
      withBrand,
      withPublisher,
      pagesFilterProvidersAndContexts,
      postColumnSetSelects,
      platform
    };

    return pageUnit;
  });



export { /*pagesUnits,*/ pagesUnitsProject };
