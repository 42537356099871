import gql from "graphql-tag";

export const CUSTOM_CONTENT_METRIC_ID_FRAGMENT = gql`
  fragment customContentMetricId on CustomContentMetricId {
    platformId
    publisherId
    contentId
    granularity
    name
    key
    timestamp
  }
`;
