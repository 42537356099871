import { getPlatformByPublicationType } from "./../ui-specs/posts-pages-specs";
import { getColumnSetByName } from "./../apollo/utils";
import { PostType } from "./../types/PostType";
import { PublicationType } from "../types/Publication";
import { sortBy } from "lodash";
import { Average } from "../types/Average";
import { formatNumber } from "./formatNumber";
import { TableHeaderP } from "@freeda/react-components/lib/Table/TableHeader";

export type TableHead = Array<TableHeaderP.HeaderT>;

export type ResponsiveTableHead = {
  desktop: TableHead;
  mobile: TableHead;
};

export const buildPostMetricTableHeader = (
  publication: PublicationType,
  customColumn: string | null,
  selectedFormat: PostType | null,
  averages: Array<Average>
) => {
  
  selectedFormat = publication ==='TK_POST' ? null : selectedFormat
  
  const columnSet = getColumnSetByName(
    publication,
    customColumn ? customColumn : `${selectedFormat || "ALL"}_${publication}`,
    Boolean(customColumn)
  );
  if (columnSet) {
    const metrics = sortBy(columnSet.metrics, "position");
    const metricsHeads = metrics.map(m => {
      const metricAvg = averages.find(a => a.id.name === m.name);
      const avgValue = metricAvg ? formatNumber(metricAvg.value, metricAvg.conversionUnit) : 0;
      return {
        id: m.name,
        label: m.label,
        sublabel: String(avgValue),
        sortable: true,
        resizable: true,
        width: 125,
        helpLink: `/metrics?platform=${getPlatformByPublicationType(publication)}&name=${m.name}`
      };
    });

    return metricsHeads;
  }

  return [];
};


export const buildStoryMetricTableHeader = (averages: Array<Average>, customColumn: string | null) => {
  const columnSet = getColumnSetByName(
    PublicationType.IG_STORY,
    customColumn ? customColumn : "IG_STORIES",
    Boolean(customColumn)
  );

  if (columnSet) {
    const metrics = sortBy(columnSet.metrics, "position");
    const metricsHeads = metrics.map(m => {
      const metricAvg = averages.find(a => a.id.name === m.name);
      const avgValue = metricAvg ? formatNumber(metricAvg.value, metricAvg.conversionUnit) : 0;
      return {
        id: m.name,
        label: m.label,
        sublabel: String(avgValue),
        sortable: true,
        resizable: true,
        width: 110,
        helpLink: `/metrics?platform=${getPlatformByPublicationType(PublicationType.IG_POST)}&name=${m.name}`
      };
    });

    return metricsHeads;
  }

  return [];
};

export const buildStoryDetailMetricTableHeader = (averages: Array<Average>) => {
  const columnSet = getColumnSetByName(PublicationType.IG_FRAME, "STORY_FRAME_DETAIL", false);

  if (columnSet) {
    const metrics = sortBy(columnSet.metrics, "position");
    const metricsHeads = metrics.map(m => {
      const metricAvg = averages.find(a => a.id.name === m.name);
      const avgValue = metricAvg ? formatNumber(metricAvg.value, metricAvg.conversionUnit) : 0;
      return {
        id: m.name,
        label: m.label,
        sublabel: String(avgValue),
        sortable: true,
        resizable: true,
        width: 110,
        helpLink: `/metrics?platform=${getPlatformByPublicationType(PublicationType.IG_FRAME)}&name=${m.name}`
      };
    });
    return metricsHeads;
  }

  return [];
};
