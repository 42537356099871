import styled from "styled-components";
import { zIndex } from "../../../utils/zIndex";
import { Button } from "@freeda/react-components";

const BarWrapper = styled.div`
  position: fixed;
  bottom: 0;
  height: 60px;
  left: 0;
  width: 100%;
  background: ${(props) => props.theme.darkPrimary};
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  z-index: ${zIndex.BarWrapper};

  transition: bottom 0.4s;

  &.wrapper-entering {
    bottom: -65px;
  }
  &.wrapper-entered {
    bottom: 0px;
  }
  &.wrapper-exiting {
    bottom: -65px;
  }
  &.wrapper-exited {
    bottom: -65px;
  }
`;

const DeselectButton = styled(Button)`
  && {
    background: transparent;
    height: 48px;
    margin: 0;
    /* font-size: 20px; */
    color: #7cffdd;
    font-weight: 500;
    &:hover {
      color: #ff0a43;
      background: rgba(0, 0, 0, 0.08);
    }
    &:active {
      background: transparent;
    }
  }
`;

export { BarWrapper, DeselectButton };
