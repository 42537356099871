import * as React from "react";
import moment from "moment";

import { Colors } from "@freeda/react-components/lib/theme";
import { indexOf } from "lodash";
import {
  CheckBox,
  Tooltip,
  Typography,
  ButtonIcon,
  DividerSection,
  Icon,
  DialogCard,
  Button,
} from "@freeda/react-components";
import { PublicationType } from "../types/Publication";
import { PostType } from "../types/PostType";
import { getColumnSetByName } from "../apollo/utils";
import { buildMetricColumns } from "./buildMetricColumns";
import { InstagramContent } from "../types/InstagramContent";
import { FrameImgPost, ActionsWrapper, ImgStory, BkgStory } from "../components/layout/PostListTable/styles";
import { InstagramStoryContent } from "../types/InstagramStoryContent";
//import { isComplete } from "../ui-specs/specs";
import { ImgPost } from "../components/layout/DetailsFramesPanel/styles";
import { Project } from "../types/Project";
import { ProjectType } from "../types/ProjectId";
import { CopyMetadataButton } from "../container/CopyMetadataButton";
import { PasteSaveMetadataButton } from "../container/PasteSaveMetadataButton";
import { Platform } from "../types/Platform";
import {checkLabelsTag} from "./postTableUtils"


const placeholderStory = require("../assets/placeholderStory.jpg");



export const framesDefaultHead = {
  desktop: [
    { id: "post", label: "Post", sortable: false, width: "120px" },
    { id: "caption", label: "Caption", sortable: true, resizable: true, width: "150px" },
    {
      id: "publicationDate",
      label: "Publication Date",
      sortable: true,
      width: "120px",
    },
  ],
  mobile: [
    { id: "post", label: "Post", sortable: false, width: "120px" },
    { id: "caption", label: "Caption", sortable: true, resizable: true },
    {
      id: "publicationDate",
      label: "Publication Date",
      sortable: true,
      width: "120px",
    },
  ],
};

export const storyDefaultHead = {
  desktop: [
    { id: "actions", label: "Actions", sortable: false, width: "120px" },
    { id: "story", label: "Story", sortable: false, width: "110px" },
    { id: "framesNumber", label: "# frames", sortable: false, width: "115px" },
    { id: "title", label: "Title", sortable: true, resizable: true, width: "150px" },
    {
      id: "publicationDate",
      label: "Publication Date",
      sortable: true,
      width: "120px",
    },
  ],
  mobile: [
    { id: "actions", label: "Actions", sortable: false, width: "100px" },
    { id: "story", label: "Story", sortable: false, width: "110px" },
    { id: "title", label: "Title", sortable: true, resizable: true },
    {
      id: "publicationDate",
      label: "Publication Date",
      sortable: true,
      width: "120px",
    },
  ],
};

export const framesStoryDefaultHead = {
  desktop: [
    { id: "frames", label: "Frames", sortable: false, width: "110px" },
    {
      id: "publicationDate",
      label: "Publication Date",
      sortable: false,
      width: "120px",
    },
  ],
  mobile: [
    { id: "frames", label: "Frames", sortable: false, width: "110px" },
    { id: "publicationDate", label: "publicationDate", sortable: false },
  ],
};

const getThumbnailIgFrame = (content: InstagramContent): string => {
  const { thumbnailUrlBackup, thumbnailUrl, mediaUrl } = content;
  if (thumbnailUrlBackup) {
    return thumbnailUrlBackup;
  }

  return thumbnailUrl || mediaUrl || placeholderStory;
};

const getThumbnailIgStory = (content: InstagramStoryContent): string => {
  const { thumbnailUrlBackup, thumbnailUrl } = content;
  return thumbnailUrlBackup || thumbnailUrl || placeholderStory;
};

export const buildIgStoryFrameTableColumns = (
  projectIn:Project,
  selectedFrameIds: Array<string>,
  onSelectWithShiftKey: (e: React.MouseEvent, selectedFrameId: string) => void,
  startIndex: number | null,
  posts: Array<InstagramContent>,
  
) => (
  maxWidth: number,
  publicationType: PublicationType,
  customColumn: string | null,
  toggleEditPostMetadata?: (postId: string) => void,
  project?: Project,
  updateSocialContentMetadataMutation?: [any, any],
  selectedFormat?: PostType | null
) => {
  const columnSet = getColumnSetByName(
    publicationType,
    customColumn ? customColumn : `${selectedFormat || "ALL"}_${publicationType}`,
    Boolean(customColumn)
  );


  const handleCopyClick = (link:string, contentId:string) => {
    
    copyToClipBoard(link)
      .then(() => {
        const el = document.getElementById(contentId);

        el!.style.display = 'block';

        setTimeout(() => {
          el!.style.display = 'none';
        }, 900);

      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function copyToClipBoard(link:string) {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(link);
      } else {
        return document.execCommand('copy', true, link);
      }
    }

  const columnSetMetrics = columnSet ? columnSet.metrics : [];
  const metricColumns = maxWidth > 768 ? buildMetricColumns(columnSetMetrics) : [];
  const isDisabled = [ProjectType.ENGAGE, ProjectType.TRANSACT, ProjectType.HYBRID_PARTIAL].includes(projectIn.projectType!) ? true : false;


  return [
    {
      id: "checkbox",
      render: (post: InstagramContent) => {
        const isChecked = Boolean(selectedFrameIds.find((sf) => sf === post.id.contentId));
        const selectedPostIndex = posts.indexOf(post);

        return (
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer"}}
          >
            <div
              onClick={(e) => isDisabled ? console.log('') : onSelectWithShiftKey(e, post.id.contentId) }
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                left: -3,
                height: 38,
                width: 38,
                borderRadius: 38,
                verticalAlign: "middle",
                background: startIndex === selectedPostIndex ? "rgba(0, 0, 0, 0.08)" : "transparent",
              }}
            >
              <CheckBox
                disabled={isDisabled}
                stopPropagation={false}
                onClick={() => console.log('')
                }
                checked={isChecked}
              />
            </div>

           <div>
              <Tooltip
                referenceChildren={
                  <ButtonIcon
                  onClick={ () => handleCopyClick(post.permalink, post.id.contentId) }
                  iconName="media"
                  iconSize="small"
                  iconColor={Colors.GRIGINO}
                  style={{ marginBottom: 0 , marginLeft:0}}
                >
                </ButtonIcon> 
                }
                tooltipChildren={
                  <Typography
                    variantName="text"
                    textColor={Colors.DARKpurple}
                    style={{ fontWeight: 10, cursor: "pointer" }}
                    textAlign="center"
                  >
                    Copy Url
                  </Typography>
                }
                referenceStyle={{ display: "flex", alignItems: "center" }}
                position="bottom"
              />
              <span id = {post.id.contentId}  style={{display:'none', fontSize:10, margin:0}}>Copied!</span>
            </div>

            <Tooltip
              position="right"
              tooltipStyle={{ boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 6px 0px" }}
              referenceStyle={{ left: 5 }}
              tooltipBackground="rgb(242, 242, 242)"
              referenceChildren={
                <FrameImgPost
                  src={getThumbnailIgFrame(post)}
                  onError={(e) => (e.currentTarget.src = placeholderStory)}
                  selected={isChecked}
                />
              }
              tooltipChildren={
                <FrameImgPost
                  style={{ maxHeight: 250 }}
                  src={getThumbnailIgFrame(post)}
                  onError={(e) => (e.currentTarget.src = placeholderStory)}
                />
              }
            />
          </div>
        );
      },
    },
    {
      id: "caption",
      render: (post: InstagramContent) => (
        <>
          {post.caption && (
            <Typography variantName="caption" style={{ fontSize: 11, paddingRight: 15 }}>
              {maxWidth > 420
                ? `${post.caption.substring(0, 200)}${post.caption.length >= 200 ? " ..." : ""}`
                : `${post.caption.substring(0, 60)}${post.caption.length >= 60 ? " ..." : ""}`}
            </Typography>
          )}
        </>
      ),
    },
    {
      id: "publicationDate",
      render: (post: InstagramContent) => (
        <Typography variantName="table-body" style={{ fontSize: 12 }}>
          <>
            {String(moment(post.publicationDate).format("ll"))}
            <br />
            {String(moment(post.publicationDate).format("HH:mm:ss"))}
          </>
        </Typography>
      ),
    },
    ...metricColumns,
  ];
};

export const buildFramesColumns = (maxWidth: number, orderedFrames: Array<InstagramContent>) => {
  const columnSet = getColumnSetByName(PublicationType.IG_FRAME, "STORY_FRAME_DETAIL", false);

  const columnSetMetrics = columnSet ? columnSet.metrics : [];
  const metricColumns = maxWidth > 768 ? buildMetricColumns(columnSetMetrics) : [];

  return [
    {
      id: "frame",
      render: (frame: InstagramContent) => {
        const indexFrame = indexOf(orderedFrames, frame);
        return (
          

          <div style={{ display: "flex", alignItems: "center" }}>


            <Typography variantName="label" style={{ marginRight: 15 }}>
              <>{indexFrame + 1}</>
            </Typography>

            <Tooltip
              position="right"
              tooltipStyle={{ boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 6px 0px" }}
              tooltipBackground="rgb(242, 242, 242)"
              tooltipZindex={3001}
              referenceChildren={
                <ImgPost src={getThumbnailIgFrame(frame)} onError={(e) => (e.currentTarget.src = frame.originalThumbnailUrl? frame.originalThumbnailUrl : placeholderStory)} />
              }
              tooltipChildren={
                <ImgPost
                  style={{ maxHeight: 200 }}
                  src={getThumbnailIgFrame(frame)}
                  onError={(e) => (e.currentTarget.src = frame.originalThumbnailUrl? frame.originalThumbnailUrl : placeholderStory)}
                />
              }
            />
          </div>
        );
      },
    },
    {
      id: "publicationDate",
      render: (frame: InstagramContent) => (
        <Typography variantName="table-body" style={{ fontSize: 12 }}>
          <>
            {String(moment(frame.publicationDate).format("ll"))}
            <br />
            {String(moment(frame.publicationDate).format("HH:mm:ss"))}
          </>
        </Typography>
      ),
    },
    ...metricColumns,
  ];
};

export const buildStoriesColumns = (
  maxWidth: number,
  openEditStoryMetadataPanel: (storyId: string) => void,
  openEditStoryPanel: (storyId: string) => void,
  openDeleteStoryDialog: (storyId: string) => void,
  openChartStoryPanel: (storyId: string) => void,
  customColumn: string | null,
  project: Project,
  updateSocialContentMetadataMutation: [any, any]
) => {
  const columnSet = getColumnSetByName(
    PublicationType.IG_STORY,
    customColumn ? customColumn : "IG_STORIES",
    Boolean(customColumn)
  );
  const columnSetMetrics = columnSet ? columnSet.metrics : [];
  const metricColumns = maxWidth > 768 ? buildMetricColumns(columnSetMetrics) : [];
  
  const isDisabled = [ProjectType.ENGAGE, ProjectType.TRANSACT, ProjectType.HYBRID_PARTIAL].includes(project.projectType!) ? true : false;
  const viewUrlPeggy = [ProjectType.ENGAGE, ProjectType.TRANSACT, ProjectType.HYBRID_PARTIAL].includes(project.projectType!) ? true : false;

  return [
    {
      id: "actionsButton",
      render: (story: InstagramStoryContent) => {
        return (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <ActionsWrapper>
              

              {viewUrlPeggy &&
                <Tooltip
                    referenceChildren={
                      <ButtonIcon
                      onClick={ () => story.peggyUrl ?  window.open( localStorage.getItem("peggyUrlEdit")!.concat(story.peggyUrl!), '_blank') : window.open( localStorage.getItem("peggyUrl")! , '_blank' ) }
                      iconName="reply"
                      iconSize="small"
                      iconColor={story.peggyUrl ? Colors.GRIGINO : Colors.ROSSINO}
                      style={{ marginBottom: 0 }}
                    >
                    </ButtonIcon>
                      
                    }
                    tooltipChildren={
                      <Typography
                        variantName="text"
                        textColor={Colors.DARKpurple}
                        style={{ fontWeight: 10, cursor: "pointer" }}
                        textAlign="center"
                      >
                        Go to Peggy
                      </Typography>
                    }
                    referenceStyle={{ display: "flex", alignItems: "center" }}
                    position="bottom"
                />}
            <ButtonIcon
                iconName="edit"
                iconColor={Colors.GRIGINO}
                onClick={() => openEditStoryPanel(story.id.contentId)}
              />


              {maxWidth > 768 && (
                <DialogCard
                  referenceStyle={{ pointerEvents: isDisabled ? 'none': 'all'}}
                  cardZindex={30}
                  referenceChildren={
                    <ButtonIcon iconName="more-vert" style={{ marginTop: 5 }} iconColor={Colors.GRIGINO} />
                  }
                  tooltipChildren={(closeCard) => (
                    <div>
                      <CopyMetadataButton
                        userDefinedMetadata={story.userDefinedMetadata}
                        postType={"INSTAGRAM_STORY"}
                        platform={Platform.IG}
                        isStory
                        project={project}
                        closeCard={closeCard}
                        style={{ marginBottom: 8 }}
                      />
                      <PasteSaveMetadataButton
                        userDefinedMetadata={story.userDefinedMetadata}
                        postType={"INSTAGRAM_STORY"}
                        platform={Platform.IG}
                        isStory
                        project={project}
                        closeCard={closeCard}
                        style={{ marginTop: 8 }}
                        updateSocialContentMetadataMutation={updateSocialContentMetadataMutation}
                        contentId={story.id.contentId}
                        publisherId={story.id.publisherId}
                      />
                      <Button
                        style={{ width: "100%", marginTop: 8 }}
                        flat
                        onClick={() => {
                          closeCard();
                          openDeleteStoryDialog(story.id.contentId);
                        }}
                      >
                        Delete story
                      </Button>
                    </div>
                  )}
                  position="right-start"
                />
              )}
            </ActionsWrapper>
            <DividerSection dividerColor={Colors.GRIGINOtransparentLight} style={{ width: "100%" }} />
            <ActionsWrapper>
              <ButtonIcon
                iconName="label"
                iconColor={
                  /*story.userDefinedMetadata.length > 0
                    ? Colors.GRIGINO
                    : Colors.ROSSINO*/
                  story.userDefinedMetadata.length > 0 ? (checkLabelsTag(story.userDefinedMetadata) ? Colors.GRIGINO : Colors.ROSSINO) : Colors.ROSSINO



                }
                onClick={() => openEditStoryMetadataPanel(story.id.contentId)}
              />
              {maxWidth > 768 && (
                <ButtonIcon
                  iconName="barchart"
                  iconColor={Colors.GRIGINO}
                  onClick={() => openChartStoryPanel(story.id.contentId)}
                />
              )}
            </ActionsWrapper>
          </div>
        );
      },
    },
    {
      id: "post",
      render: (story: InstagramStoryContent) => {
        const outOfSync = story.outOfSync ? <Icon iconName="update" style={{ marginLeft: 14 }} /> : null;
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Tooltip
                position="right"
                tooltipBackground="rgb(242, 242, 242)"
                tooltipZindex={3001}
                tooltipStyle={{ boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 6px 0px" }}
                referenceChildren={
                  <ImgStory
                    src={getThumbnailIgStory(story)}
                    onError={(e) => (e.currentTarget.src = placeholderStory)}
                  />
                }
                tooltipChildren={
                  <ImgStory
                    style={{ maxHeight: 200 }}
                    src={getThumbnailIgStory(story)}
                    onError={(e) => (e.currentTarget.src = placeholderStory)}
                  />
                }
              />
              <BkgStory />
            </div>
            {maxWidth > 768 ? outOfSync : null}
          </div>
        );
      },
    },
    {
      id: "frameNumber",
      render: (story: InstagramStoryContent) => (
        <Typography variantName="table-body">{String(story.frames.length)}</Typography>
      ),
    },
    {
      id: "title",
      render: (story: InstagramStoryContent) => <Typography variantName="table-body">{story.title}</Typography>,
    },
    {
      id: "publicationDate",
      render: (story: InstagramStoryContent) => (
        <Typography variantName="table-body" style={{ fontSize: 12 }}>
          <>
            {String(moment(story.publicationDate).format("ll"))}
            <br />
            {String(moment(story.publicationDate).format("HH:mm:ss"))}
          </>
        </Typography>
      ),
    },
    ...metricColumns,
  ];
};
