import gql from "graphql-tag";

export const FOLLOWERS_COMPOSITION = gql`
  query followersCompositions($where: FollowersCompositionWhere!) {
    followersComposition(where: $where) {  
        sex,
        age,
        value
    }
  }
`;
