import gql from "graphql-tag";

export const RESULTS_OVER_TIME = gql`
  query resultsOverTime($where: ResultsOverTimeWhere!) {
    resultsOverTime(where: $where) {
      publisher
      metrics {
        name
        values {
          value
          timestamp
        }
      }
    }
  }
`;
