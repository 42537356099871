import * as React from "react";
import styled from "styled-components";
import { TransitionStatus } from "react-transition-group/Transition";
import { PlaceholderInput } from "./PlaceholderInput";

const BackgroundLayer = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #160127ba;
  z-index: 2000;
  opacity: 0;

  transition: opacity 0.3s;

  &.background-entering {
    opacity: 0;
  }
  &.background-entered {
    opacity: 1;
  }
  &.background-exiting {
    opacity: 0;
  }
  &.background-exited {
    opacity: 0;
  }
`;

const WrapperLateralPanel = styled.div`
  position: fixed;
  background: #ffffff;
  height: 100vh;
  z-index: 2001;
  width: 475px;
  top: 0px;
  right: -480px;

  padding: 29px 20px;
  padding-bottom: 20px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  transition: right 0.4s;

  &.wrapper-entering {
    right: -480px;
  }
  &.wrapper-entered {
    right: 0px;
  }
  &.wrapper-exiting {
    right: -480px;
  }
  &.wrapper-exited {
    right: -480px;
  }

  @media (max-width: 768px) {
    width: 100vw;
    right: -768px;
    transition: right 0.5s;

    &.wrapper-entering {
      right: -768px;
    }
    &.wrapper-entered {
      right: 0px;
    }
    &.wrapper-exiting {
      right: -768px;
    }
    &.wrapper-exited {
      right: -768px;
    }
  }
`;

const BoldLine = styled.div`
  background: #eeeeee;
  width: 165px;
  height: 10px;
  border-radius: 10px;
`;

const InfoPlaceholder = styled.div`
  padding: 15px;
  box-sizing: border-box;
  margin-top: 45px;
`;

const PlaceholderLateralPanel: React.FC<{
  transitionStatus: TransitionStatus;
}> = ({ transitionStatus }) => (
  <>
    <BackgroundLayer className={`background-${transitionStatus}`} />
    <WrapperLateralPanel className={`wrapper-${transitionStatus}`}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <BoldLine style={{ width: "20%", opacity: 0.5 }} />
      </div>
      <InfoPlaceholder>
        <PlaceholderInput style={{ marginBottom: 25, flexBasis: "50%", opacity: 0.5 }} />
        <PlaceholderInput style={{ marginBottom: 29, flexBasis: "80%", opacity: 0.5 }} />
        <PlaceholderInput style={{ marginBottom: 28, flexBasis: "60%", opacity: 0.5 }} />
        <PlaceholderInput style={{ marginBottom: 43, flexBasis: "76%", opacity: 0.5 }} />
        <BoldLine style={{ width: "100%", opacity: 0.5 }} />
      </InfoPlaceholder>
    </WrapperLateralPanel>
  </>
);

export { PlaceholderLateralPanel };
