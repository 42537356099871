import * as React from "react";
import moment from "moment";
import { sortBy } from "lodash";
import { Button } from "@freeda/react-components";

import { FilterContextT } from "../providers/FilterProvider";
import { getCustomDateRange } from "../utils/getDateRange";
import { csvDownload } from "../utils/toCSV";
import { InstagramContent } from "../types/InstagramContent";
import { FacebookContent } from "../types/FacebookContent";
import { formatNumber } from "../utils/formatNumber";
import { PublicationType } from "../types/Publication";
import { Granularity } from "../types/Granularity";
import { ConversionUnit } from "../types/ConversionUnit";
import { ColumnSet } from "../types/ColumnSet";
import { PostType } from "../types/PostType";
import client from "../apollo/client";
import { FacebookGroupContent } from "../types/FacebookGroupContent";
import { InstagramStoryContent } from "../types/InstagramStoryContent";
import {Project} from "../types/Project";
import {StaticIdsContext} from "../providers/StaticIdsProvider";

export const isInstagramStoryContent = (
  post: FacebookContent | InstagramContent | FacebookGroupContent | InstagramStoryContent
): post is InstagramStoryContent => {
  return "frames" in post;
};

interface Props {
  FilterContext: React.Context<FilterContextT>;
  query: any;
  publicationId: PublicationType;
  getColumnSet: (publicationId: PublicationType, selectedFormat: PostType | null) => ColumnSet | null;
  story?: boolean;
  publisherName?: string;
  publisherId?: string;
  project: Project;
}

interface State {
  loading: boolean;
}

const PostExportCSVButton: React.FC<Props> = ({
  FilterContext,
  query,
  publicationId,
  getColumnSet,
  story,
  publisherName,
  publisherId,
  project,
}) => {
  const [state, setState] = React.useState<State>({ loading: false });

  const { filterState } = React.useContext(FilterContext);

  const staticIdsContext = React.useContext(StaticIdsContext);

  const {
    postOrderBy,
    searchValue,
    selectedFormat,
    selectedPublisher,
    selectedMetadata,
    logicalOperator,
    dateRange,
    branded,
    metricOrderBy,
    order,
    inclusionPolicyUser,
  } = filterState;
  const customDateRange = getCustomDateRange(dateRange);

  const metricOrderByParams = { name: metricOrderBy, ordering: order };

  return (
    <Button
      icon={{
        iconName: "download",
        position: "left",
        iconColor: "white",
      }}
      style={{ minWidth: 50 }}
      loading={state.loading}
      onClick={async () => {
        try {
          setState({ loading: true });
          const columnSet = getColumnSet(publicationId, selectedFormat)!;
          const orderBy = metricOrderBy
            ? {
                measuredMetricOrderBy: {
                  granularity: Granularity.Lifetime,
                  key: "value",
                  ...metricOrderByParams,
                },
              }
            : { postOrderBy: `${postOrderBy}_${order}` };

          let metadataIdsFilter = selectedMetadata.length
            ? {
                idInClause: { seq: selectedMetadata.map((m) => m.value), logicalOperator },
              }
            : {};

          let brandedFilter = {};
          if (branded !== null) {
            brandedFilter = branded
              ? {
                  idIn: [staticIdsContext.brandedId],
                }
              : { idNotIn: [staticIdsContext.brandedId] };
          }

          const userDefinedMetadataWhere = {
            ...metadataIdsFilter,
            ...brandedFilter,
          };

          let metricWhere: any = {
            granularityIn: [Granularity.Lifetime],
            keyIn: ["value"],
            columnSetId: columnSet.id,
          };

          const inclusionPolicyFbGroup = inclusionPolicyUser
            ? {
                fromPublisherId: {
                  publisherId,
                  inclusionPolicy: inclusionPolicyUser,
                },
              }
            : undefined;

          const result = await client.query<{
            export: any;
          }>({
            query,
            variables: {
              orderBy,
              where: {
                publisherName: publisherName || selectedPublisher,
                projectId: project.idLegacy,
                isStory: story,
                ...inclusionPolicyFbGroup,
                postType:
                  publicationId !== PublicationType.IG_STORY &&
                  publicationId !== PublicationType.YT_POST &&
                  publicationId !== PublicationType.TK_POST &&
                  publicationId !== PublicationType.FBG_POST
                    ? selectedFormat
                    : undefined,
                freeSearch: searchValue.length === 0 ? null : searchValue,
                fromDate: customDateRange.fromDate,
                toDate: customDateRange.toDate,
                userDefinedMetadata: Object.keys(userDefinedMetadataWhere).length
                  ? userDefinedMetadataWhere
                  : undefined,
              },
              metricWhere,
            },
          });

          // first map on columnsSet metrics to keep order
          const withFlattenedMetrics = result.data.export.map(
            (c: FacebookContent | InstagramContent | FacebookGroupContent | InstagramStoryContent) => {
              const postInfos = {
                id: `${c.id.platformId}-${c.id.publisherId}-${c.id.contentId}`,
                // ...framesNumber,
                labels: c.userDefinedMetadata.map((m) => `${m.category.id}-${m.label}`).join("|"),
                publicationDate: moment(c.publicationDate).format("YYYY-MM-DD HH:mm:ss"),
                ...sortBy(columnSet.metrics, "position")
                  .map((csm) => {
                    const measuredMetric = c.measuredMetrics.find((mm) => mm.id.name === csm.name);

                    const valueWithConversionUnit = !measuredMetric
                      ? { value: 0, conversionUnit: ConversionUnit.RAW }
                      : {
                          value: measuredMetric.value,
                          conversionUnit: measuredMetric.conversionUnit,
                        };

                    return {
                      label: csm.label,
                      name: csm.name,
                      ...valueWithConversionUnit,
                    };
                  })
                  .reduce((acc, next) => {
                    const label = next.label ? next.label : next.name;
                    return { ...acc, [label]: formatNumber(next.value, next.conversionUnit) };
                  }, {}),
              };

              let framesNumber = null
              if (isInstagramStoryContent(c)) {
                const igStory: InstagramStoryContent = c;

                /*const */framesNumber = {
                  [`# frames`]: igStory.frames.length,
                };
                //c['userDefinedMetadata'] = []
                /*return {
                  ...c,
                  ...framesNumber,
                  ...postInfos,
                };*/

              }
              const {userDefinedMetadata, ...content} = c;
              if (framesNumber) return { ...content, ...framesNumber, ...postInfos };
              return { ...content, ...postInfos };
            }
          );

          csvDownload(withFlattenedMetrics, ["__typename", "measuredMetrics", "frames"]);
        } finally {
          setState({ loading: false });
        }
      }}
    >
      CSV
    </Button>
  );
};

export { PostExportCSVButton };
