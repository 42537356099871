import * as React from "react";
import { useHistory } from "react-router-dom";

export const useScrollToTop = () => {
  const history = useHistory();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location]);
};
