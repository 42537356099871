import * as React from "react";
import { Autocomplete, Chip } from "@freeda/react-components";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import { PlaceholderChip } from "../components/ui/Placeholders/PlaceholderChip";
import { ALL_PROJECT } from "../apollo/queries/AllProject";
import AllProjectQueryT from "../types/graphql/AllProjectQuery";

const ChipWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

interface Props {
  selectedProjects: Array<{ value: string; label: string }>;
  onChangeProject: (project: { value: string; label: string }) => void;
  lateralChip?: boolean;
  widthWrapper?: string;
}

const AutocompleteProject: React.FC<Props> = ({ selectedProjects, onChangeProject, lateralChip, widthWrapper }) => {
  const { loading, data } = useQuery<AllProjectQueryT, any>(ALL_PROJECT);

  const allProject = loading || !data ? [] : data.allProject;

  return (
    <Autocomplete
      label="Project"
      loading={loading}
      style={{ width: widthWrapper ? widthWrapper : "100%" }}
      styleWrapper={{ width: widthWrapper ? widthWrapper : "100%" }}
      placeholder="Select project"
      flexBasis={widthWrapper ? widthWrapper : "100%"}
      internalOpen
      currentSelectedItem={selectedProjects}
      items={allProject.map((p) => ({ value: p.id, label: p.label }))}
      onSelect={(project) => onChangeProject(project)}
      onUnselect={(project) => onChangeProject(project)}
      renderSelections={(selectedProject: Array<{ value: string; label: string }>, handleDelete) => (
        <ChipWrapper style={{ flexWrap: lateralChip ? "nowrap" : "wrap", marginLeft: lateralChip ? 5 : 0 }}>
          {selectedProject.length > 0 ? (
            selectedProject.map((project: { value: string; label: string }) => (
              <Chip
                key={project.value}
                label={project.label}
                style={{ marginRight: 5, marginBottom: !lateralChip ? 5 : 0 }}
                onClick={() => {
                  handleDelete(project);
                }}
                isClearable
              />
            ))
          ) : (
            <PlaceholderChip label="Freeda" />
          )}
        </ChipWrapper>
      )}
    />
  );
};

export { AutocompleteProject };
