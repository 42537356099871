import styled from "styled-components";
import { Paginator } from "@freeda/react-components";

const PaginatorStyled = styled(Paginator)`
  && {
    justify-content: flex-start;

    & > div {
      width: 100%;
    }
    & > div > span:first-child {
      width: 125px;
    }
  }
`;

export { PaginatorStyled };
