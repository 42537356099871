import gql from "graphql-tag";
import { SOCIAL_CONTENT_ID_FRAGMENT } from "../fragments/SocialContentIdFragment";

const LIST_INSTAGRAM_STORIES = gql`
  query listInstagramStories(
    $offset: Int
    $limit: Int
    $orderBy: InstagramStoryOrderBy
    $where: InstagramStoryWhere
    $metricWhere: MeasuredMetricWhere
  ) {
    listInstagramStories(
      offset: $offset
      limit: $limit
      orderBy: $orderBy
      where: $where
      metricWhere: $metricWhere
    ) {
      content {
        id {
          ...socialContentId
        }
        title
        permalink
        mediaUrl
        thumbnailUrl
        thumbnailUrlBackup
        peggyUrl
        publicationDate
        outOfSync
        measuredMetrics {
          id {
            platformId
            publisherId
            contentId
            granularity
            name
            key
            timestamp
          }
          value
          conversionUnit
          position
        }
        userDefinedMetadata {
          id
          label
          active
          category {
            id
            label
          }
        }
        frames {
          id {
            platformId
            publisherId
            contentId
          }
          isStory
          caption
          thumbnailUrlBackup
          thumbnailUrl
          originalThumbnailUrl
          mediaUrl
          permalink
          type
          publicationDate
          measuredMetrics {
            id {
              platformId
              publisherId
              contentId
              granularity
              name
              key
              timestamp
            }
            value
            conversionUnit
            position
          }
          userDefinedMetadata {
            id
            label
            active
            category {
              id
              label
            }
          }
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
      }
      aggregate {
        count
      }
      subAggregate {
        averages {
          ... on AggregatedMeasuredMetric {
            id {
              granularity
              name
              key
            }
            value
            conversionUnit
          }
        }
      }
    }
  }
  ${SOCIAL_CONTENT_ID_FRAGMENT}
`;

export { LIST_INSTAGRAM_STORIES };
