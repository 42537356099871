import * as React from "react";
import styled from "styled-components";
import Media from "react-media";

import { Monogramma } from "../../components/ui/Monogramma";
import { Button, Typography, Input } from "@freeda/react-components";
import { Colors } from "@freeda/react-components/lib/theme";
import { useHistory } from "react-router-dom";

const freedaLogo = require("../../assets/freeda_logo.png");
const scritte = require("../../assets/scritte.png");
const lavagna = require("../../assets/lavagna.png");
const elinor = require("../../assets/elinor.png");

const PageWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #6b2ed6;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  background: #ffffff;
  padding-top: 80px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;

  @media (min-width: 768px) {
    width: 400px;
  }

  @media (max-width: 768px) {
    margin: 20px;
  }
`;

const Scritte = styled.img`
  position: fixed;
  width: 115%;
  z-index: 1;
  transition: width 0.6s ease;

  @media (min-width: 1190px) {
    width: 80%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const Lavagna = styled.img`
  position: fixed;
  width: 135%;
  transition: width 0.6s ease;

  @media (min-width: 1190px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const LoginBox = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: -webkit-center;
  z-index: 5;
`;

const Info = styled.div`
  font-size: 15px;
  color: ${Colors.GRIGINO};
  margin-top: 20px;
  margin-bottom: 30px;
`;

const FreedaLogo = styled.img`
  position: absolute;
  top: 51px;
  width: 176px;
  z-index: 100;

  @media (max-width: 768px) {
    width: 230px;
  }
`;

const LoginPage: React.FC<{ login: Function }> = ({ login }) => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [username, setUsername] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");
  const [error, setError] = React.useState<boolean>(false);
  const history = useHistory();

  return (
    <PageWrapper>
      <Media query={{ maxWidth: 768 }}>
        {(matches: boolean) => (matches ? <Monogramma /> : <FreedaLogo src={freedaLogo} />)}
      </Media>
      <Scritte src={scritte} />
      <Lavagna src={lavagna} />
      <Wrapper>
        <img style={{ position: "absolute", top: -55, width: 110, zIndex: 1 }} src={elinor} />
        <LoginBox
          onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            const result = await login(username, password);
            setLoading(false);

            if (result.result === "OK") {
              history.go(0);
            } else {
              setError(true);
            }
          }}
        >
          <Typography variantName="title">Welcome!</Typography>
          <Info>"Elinor" is the new Freeda's tool designed to categorize and analyze social content</Info>
          <Input
            style={{ marginBottom: 35 }}
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <Input
            style={{ marginBottom: 35 }}
            type="password"
            placeholder="Password"
            value={password}
            isValid={!error}
            errorMessage="Wrong username or password."
            onChange={(e) => setPassword(e.target.value)}
          />
          <div style={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
            <Button loading={loading} type="submit">
              Sign in
            </Button>
          </div>
        </LoginBox>
      </Wrapper>
    </PageWrapper>
  );
};

export default LoginPage;
