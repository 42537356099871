import * as React from "react";
import { useQuery } from "react-apollo";
import { PROJECTS_TO_SHOW } from "../apollo/queries/ProjectsToShow";
import { PageUnit } from "../types/PageUnit";
import { pagesUnitsProject } from "../ui-specs/posts-pages-specs";
import projectsToShowT from "../types/graphql/ProjectsToShowQuery";
import auth from "../utils/auth";
import { sortBy } from "lodash";



const ProjectPageContext = React.createContext<{
  pageUnits: Array<PageUnit>;
}>({
  pageUnits: [],
});


interface State {
  pageUnits: Array<PageUnit>;
}


const ProjectPageProvider: React.FC = ({ children }) => {
 
    const pagesResult = useQuery<projectsToShowT, any>(PROJECTS_TO_SHOW, { 
                                  fetchPolicy: "network-only" , 
                                  skip: !auth.isAuthenticated() });

    const [state, setState] = React.useState<State>({
      pageUnits: []
    });

    if( pagesResult.data  && pagesResult.data.projectsToShow && state.pageUnits.length==0 ){
      let allPages = pagesResult.data.projectsToShow.filter(p => p.project.active === true)
      let pageUnit= sortBy(pagesUnitsProject(allPages), "path")

      
      setState(state => ({
        ...state,
        pageUnits: pageUnit
      }));

    }

  return (
    <ProjectPageContext.Provider
      value={{
        ...state, pageUnits : state.pageUnits
      }}
    >
      {children}
    </ProjectPageContext.Provider>
  );

};

export { ProjectPageProvider, ProjectPageContext };
