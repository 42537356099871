import * as React from "react";
import { Button } from "@freeda/react-components";
import Media from "react-media";
import { ButtonIconStyled, InputSearchWrapper, InputStyled } from "./styles";

interface Props {
  showFilter?: () => void;
  placeholder: string;
  searchValue: string;
  onTextFilterChange: (value: string) => void;
  clearFilter: () => void;
}

const SearchFilter: React.FC<Props> = ({ showFilter, placeholder, searchValue, onTextFilterChange, clearFilter }) => (
  <Media query={{ maxWidth: 768 }}>
    {(matches: boolean) => (
      <InputSearchWrapper>
        <InputStyled
          placeholder={placeholder}
          iconName="search"
          style={{ flexBasis: !matches ? "30%" : "80%", borderColor: "red" }}
          onChange={e => onTextFilterChange(e.target.value)}
          value={searchValue}
          onClearValue={() => onTextFilterChange("")}
        />

        {!matches && (
          <Button
            flat
            style={{ marginLeft: matches ? 5 : 10, marginRight: matches ? 5 : 0 }}
            onClick={() => clearFilter()}
          >
            Clear filter
          </Button>
        )}

        {matches && (
          <ButtonIconStyled iconName="filter-list" onClick={() => showFilter && showFilter()} iconColor="#6B20DA" />
        )}
      </InputSearchWrapper>
    )}
  </Media>
);

export { SearchFilter };
