import { BrandedEditorialContent } from "../types/BrandedEditorialContent";

export const brandedByContentType = (brandedEditorialContent: BrandedEditorialContent) => {
  const selectedContent = {
    [BrandedEditorialContent.branded]: true,
    [BrandedEditorialContent.editorial]: false
  };

  return selectedContent[brandedEditorialContent];
};
