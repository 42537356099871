import * as React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Dialogable } from "../types/Dialogable";
import { PublicationType } from "../types/Publication";
import { InstagramContent } from "../types/InstagramContent";
import DetailsFramesPanel from "../components/layout/DetailsFramesPanel";
import { PositionalMetric } from "../types/PositionalMetric";
import { isDataAvailable } from "../apollo/utils";
import AllColumnSetQueryT from "../types/graphql/AllColumnSetsQuery";
import { ALL_COLUMN_SETS } from "../apollo/queries/AllColumnSets";

type Props = Dialogable & {
  frames: Array<InstagramContent>;
  title: string;
  maxWidth: number;
};

const DetailsFramesPanelContainer: React.FC<Props> = ({ open, onClose, frames, maxWidth, title }) => {
  const allColumnSetResult = useQuery<AllColumnSetQueryT>(ALL_COLUMN_SETS, {
    variables: {
      where: { publicationId: PublicationType.IG_FRAME, name: "STORY_FRAME_DETAIL", custom: false }
    }
  });

  const areColumnSetsLoaded: boolean = isDataAvailable(allColumnSetResult);

  const positionalMetrics: Array<PositionalMetric> = !areColumnSetsLoaded
    ? []
    : allColumnSetResult.data!.allColumnSets[0].metrics;

  return (
    <DetailsFramesPanel
      open={open}
      onClose={onClose}
      frames={frames}
      maxWidth={maxWidth}
      loading={!areColumnSetsLoaded}
      positionalMetrics={positionalMetrics}
      title={title}
    />
  );
};

export { DetailsFramesPanelContainer };
