import * as React from "react";
import { TransitionStatus } from "react-transition-group/Transition";
import { FilterWrapper } from "../../ui/AdvancedFilterWrapper";
import { FieldWrapper } from "../../ui/FieldWrapper";
import { Select } from "@freeda/react-components";
import { DateFilter } from "../DateFilter";
import { RowForm } from "./styles";
import Media from "react-media";
import { PostType } from "../../../types/PostType";
import { DateRange } from "../../../types/DateRange";
import { capitalize } from "lodash";
import { AdvancedFilterPostMobile } from "./Mobile/AdvancedFilterPostMobile";

interface Props {
  transitionStatus?: TransitionStatus;
  onChangeUpdateDateRange: (dateRange: DateRange) => void;
  dateRange: DateRange;
  publisherSelectComponent?: (matches: boolean) => React.ReactElement<any>;
  brandedComponent?: (matches: boolean) => React.ReactElement<any>;
  autocompleteMetadataComponent?: React.ReactElement<any>;
  userTypeComponent?: () => React.ReactElement<any>;
  format?: {
    onChangeFormat: (selectedFormat: PostType) => void;
    selectedFormat: PostType | null;
    availablePostFormat: Array<PostType>;
  };
  showFilterMobile: boolean;
  onCloseFilterMobile: () => void;
  onClearFilterMobile: () => void;
}

const AdvancedFilterPost: React.FC<Props> = ({
  transitionStatus,
  format,
  dateRange,
  brandedComponent,
  onChangeUpdateDateRange,
  publisherSelectComponent,
  autocompleteMetadataComponent,
  userTypeComponent,
  showFilterMobile,
  onCloseFilterMobile,
  onClearFilterMobile,
}) => {
  return (
    <Media query={{ maxWidth: 768 }}>
      {(matches: boolean) => {
        const filter = (
          <FilterWrapper>
            <RowForm style={{ marginBottom: !matches ? 0 : 55 }}>
              {format && (
                <FieldWrapper
                  style={{
                    marginRight: !matches ? 30 : 0,
                    width: !matches ? 150 : "100%",
                  }}
                >
                  <Select
                    placeholder="Select post type"
                    label="Post type"
                    style={{ padding: "10px 0px" }}
                    optionsSort="asc"
                    onChange={(option: any) => format.onChangeFormat(option ? option.value : null)}
                    options={format.availablePostFormat.map((l) => ({
                      value: l,
                      label: capitalize(l.replace(/_/g, " ")),
                    }))}
                    value={
                      format.selectedFormat
                        ? {
                            value: format.selectedFormat,
                            label: capitalize(format.selectedFormat.replace(/_/g, " ")),
                          }
                        : null
                    }
                  />
                </FieldWrapper>
              )}
              {publisherSelectComponent && (
                <FieldWrapper style={{ marginRight: !matches ? 30 : 0, width: !matches ? 80 : "100%" }}>
                  {publisherSelectComponent(matches)}
                </FieldWrapper>
              )}
              {userTypeComponent && (
                <FieldWrapper
                  style={{
                    width: !matches ? 150 : "100%",
                    marginRight: !matches ? 30 : 0,
                    marginBottom: matches ? 10 : 0,
                  }}
                >
                  {userTypeComponent()}
                </FieldWrapper>
              )}
              <FieldWrapper
                style={{
                  width: !matches ? 150 : "100%",
                  marginRight: !matches ? 30 : 0,
                  marginBottom: matches ? 10 : 0,
                }}
              >
                <DateFilter onChangeUpdateDateRange={onChangeUpdateDateRange} dateRange={dateRange} />
              </FieldWrapper>
              {brandedComponent && (
                <FieldWrapper
                  style={{
                    width: !matches ? 150 : "100%",
                    marginRight: !matches ? 30 : 0,
                  }}
                >
                  {brandedComponent(matches)}
                </FieldWrapper>
              )}
              {autocompleteMetadataComponent && autocompleteMetadataComponent}
            </RowForm>
          </FilterWrapper>
        );

        return (
          <>
            {!matches ? (
              filter
            ) : (
              <AdvancedFilterPostMobile
                showFilterMobile={showFilterMobile}
                onCloseFilter={onCloseFilterMobile}
                filter={filter}
                transitionStatus={transitionStatus}
                onClearFilter={onClearFilterMobile}
              />
            )}
          </>
        );
      }}
    </Media>
  );
};

export { AdvancedFilterPost };
