import * as React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { CreateStoryDialog } from "../components/layout/CreateStoryDialog";
import { FeedbackContext } from "../providers/FeedbackProvider";
import { FilterContextT } from "../providers/FilterProvider";
import { LIST_IG_CONTENT_METADATA } from "../apollo/queries/ListIgContentMetadata";
import { InstagramPostType } from "../types/InstagramPostType";
import {
  isDataAvailable,
  buildOrderByClause,
  buildIgContentFilter,
} from "../apollo/utils";
import { PublicationType } from "../types/Publication";
import {
  MetricColumnSetsContext,
  ColumnSetType,
} from "../providers/MetricColumnSetsProvider";
import { ProjectId } from "../types/ProjectId";
import AllColumnSetQueryT from "../types/graphql/AllColumnSetsQuery";
import { ALL_COLUMN_SETS } from "../apollo/queries/AllColumnSets";
import { InstagramStoryContent } from "../types/InstagramStoryContent";
import { CREATE_INSTAGRAM_STORY } from "../apollo/mutations/CreateInstagramStory";
import { StaticIdsContext } from "../providers/StaticIdsProvider";

type Props = {
  open: boolean;
  onClose: (emptyFrames: boolean) => any;
  publisherId: string;
  frames: Array<string>;
  FilterContext: React.Context<FilterContextT>;
  columnSetType: ColumnSetType;
  projectId: ProjectId;
};

const CreateStoryDialogContainer: React.FC<Props> = ({
  open,
  onClose,
  publisherId,
  frames,
  FilterContext,
  columnSetType,
  projectId,
}) => {
  const { selectedSets } = React.useContext(MetricColumnSetsContext);
  const { filterState } = React.useContext(FilterContext);
  const { openSnackbar } = React.useContext(FeedbackContext);

  const {
    currentPage,
    postOrderBy,
    metricOrderBy,
    pageSize,
    selectedFormat,
    selectedPublisher,
    order,
  } = filterState;

  const columnSetName =
    selectedSets[columnSetType] ||
    `${selectedFormat || "ALL"}_${PublicationType.IG_FRAME}`;

  const allColumnSetResult = useQuery<AllColumnSetQueryT>(ALL_COLUMN_SETS, {
    variables: {
      where: {
        publicationId: PublicationType.IG_FRAME,
        name: columnSetName,
        custom: Boolean(selectedSets[columnSetType]),
      },
    },
  });

  const areColumnSetsLoaded = isDataAvailable(allColumnSetResult);

  const columnSetId =
    areColumnSetsLoaded && allColumnSetResult.data!.allColumnSets.length > 0
      ? allColumnSetResult.data!.allColumnSets[0].id
      : undefined;
  const staticIdsContext = React.useContext(StaticIdsContext);
  const wheresFilter = buildIgContentFilter(
    filterState,
    selectedFormat && InstagramPostType[selectedFormat],
    projectId,
    columnSetId,
    true,
    staticIdsContext.brandedId
  );

  const orderBy = buildOrderByClause(metricOrderBy, postOrderBy, order);

  const [createInstagramStory, createInstagramStoryResult] = useMutation<{
    createInstagramStory: InstagramStoryContent;
  }>(CREATE_INSTAGRAM_STORY, {
    refetchQueries: [
      {
        query: LIST_IG_CONTENT_METADATA,
        variables: {
          offset: currentPage * pageSize,
          limit: pageSize,
          orderBy,
          ...wheresFilter,
        },
      },
    ],
  });

  return (
    <CreateStoryDialog
      open={open}
      onClose={() => onClose(false)}
      publisherName={selectedPublisher}
      loadingCreate={createInstagramStoryResult.loading}
      onCreateStory={async (title: string) => {
        await createInstagramStory({
          variables: {
            input: {
              publisherId,
              title: title.trim(),
              frames,
            },
          },
        });
        onClose(true);
        openSnackbar("Create story correctly!", "notification");
      }}
    />
  );
};

export { CreateStoryDialogContainer };
