import { Project } from "../../../../types/Project";
import { Platform } from "../../../../types/Platform";
import * as React from "react";
import { useQuery } from "@apollo/react-hooks";
import ResultsOverTimeQuery from "../../../../types/graphql/ResultsOverTimeQuery";
import { RESULTS_OVER_TIME } from "../../../../apollo/queries/ResultsOverTime";
import {
  Column,
  Columns,
  DateField,
  LocalizedString,
  SelectField,
  Stack,
  Title,
} from "@freeda/design-system";
import { endOfWeek, startOfWeek } from "date-fns";
import { ResultsOverTimeGraph } from "./ResultsOverTimeGraph";

type Props = {
  project: Project;
  platform: Platform;
  selectedPublisherNames: string[];
};

export function ResultsOverTimeSection(props: Props) {
  const platformMetrics: string[] = Object.keys(
    metricsByPlatform[props.platform]
  );

  const [selectedMetrics, setSelectedMetrics] = React.useState<string[]>([
    platformMetrics[0],
  ]);

  const selectMetrics = (metrics: string[]) => {
    // if only one language is selected then the user can select up to 2 different metrics
    // if more than one language is selected then the user can select just 1 metric
    if (
      metrics.length === 0 ||
      (selectedMetrics.length === 2 && metrics.length > 1)
    )
      return;
    if (props.selectedPublisherNames.length > 1) {
      const diff = metrics.filter((x) => !selectedMetrics.includes(x));
      setSelectedMetrics(diff);
    } else {
      setSelectedMetrics(metrics);
    }
  };

  React.useEffect(() => {
    setSelectedMetrics([Object.keys(metricsByPlatform[props.platform])[0]]); //reset selected metrics
  }, [props.selectedPublisherNames, props.platform]);

  const now = new Date();

  const oneMonthAgo = new Date(
    now.getFullYear(),
    now.getMonth() - 1,
    now.getDate()
  );
  const threeMonthsAgo = new Date(
    now.getFullYear(),
    now.getMonth() - 3,
    now.getDate()
  );
  const sixMonthsAgo = new Date(
    now.getFullYear(),
    now.getMonth() - 6,
    now.getDate()
  );

  const [period, setPeriod] = React.useState<[Date, Date]>([
    startOfWeek(sixMonthsAgo),
    startOfWeek(now),
  ]);

  const selectPeriod = (period: [Date, Date]) => {
    setPeriod([
      period[0] && startOfWeek(period[0]!),
      period[1] && startOfWeek(period[1]!),
    ]);
  };

  const resultsOverTime = useQuery<ResultsOverTimeQuery>(RESULTS_OVER_TIME, {
    fetchPolicy: "network-only",
    variables: {
      where: {
        metrics: selectedMetrics,
        spanFrom: period[0].valueOf(),
        spanTo: period[1].valueOf(),
        platformId: props.platform,
        publisherNames: props.selectedPublisherNames,
      },
    },
  });

  const buildContent = () => {
    if (!resultsOverTime.loading && resultsOverTime.data) {
      if (
        resultsOverTime.data.resultsOverTime.length > 0
      ) {
        const flattenedMetrics = resultsOverTime.data.resultsOverTime.flatMap(
          (r) =>
            r.metrics.flatMap((m) =>
              m.values.flatMap((v) => ({
                publisher: r.publisher,
                metric: m.name,
                value: v.value,
                timestamp: v.timestamp,
              }))
            )
        );
        return (
          <ResultsOverTimeGraph
            data={flattenedMetrics}
            kind={
              props.selectedPublisherNames.length > 1 ? "publisher" : "metric"
            }
            metricLabelMappingByPlatform={metricsByPlatform[props.platform]}
          />
        );
      } else {
        return <Title size="medium">No data to show!</Title>;
      }
    } else {
      return <Title size="medium">Loading...</Title>;
    }
  };

  const metricsOptions = platformMetrics.map((metric) => ({
    label: metricsByPlatform[props.platform][metric],
    value: metric,
    kind: "single-line" as const,
  }));

  const shortcuts: {
    label: LocalizedString;
    value: [Date, Date];
  }[] = [
    {
      label: "Last Month",
      value: [endOfWeek(oneMonthAgo), endOfWeek(now)],
    },
    {
      label: "Last Three Months",
      value: [endOfWeek(threeMonthsAgo), endOfWeek(now)],
    },
    {
      label: "Last Six Months",
      value: [endOfWeek(sixMonthsAgo), endOfWeek(now)],
    },
  ];

  const minDate = new Date(
    now.getFullYear() -1,
    0,
    1
  );

  return (
    <Stack space={24}>
      <Title size="large">Results over time</Title>
      <Columns space={0}>
        <Column width="1/3">
          <SelectField
            isMulti
            name="metrics-filter"
            label="Metrics"
            placeholder="languages placeholder"
            value={selectedMetrics}
            options={metricsOptions}
            onBlur={() => {}}
            onChange={selectMetrics}
            hint="Choose up to two metrics to compare. When multiple publishers are selected, just one metric can be chosen."
          />
        </Column>
        <Stack space={0}>
          <></>
        </Stack>
        <Column width="1/3">
          <DateField
            name="date"
            label="Period"
            type="range"
            value={period}
            onChange={(date: [Date | null, Date | null]) => {
              selectPeriod([
                date[0] ? endOfWeek(date[0]) : endOfWeek(oneMonthAgo),
                date[1] ? endOfWeek(date[1]) : endOfWeek(now),
              ]);
            }}
            shortcuts={shortcuts}
            minDate={minDate}
            maxDate={now}
          />
        </Column>
      </Columns>
      {buildContent()}
    </Stack>
  );
}

const metricsByPlatform = {
  FB: {
    page_follows: "Page Followers",
    page_impressions_organic_unique: "Reach organic",
    page_video_views_organic: "Page video views organic",
    page_post_engagements: "Total interactions",
    page_views_total: "Page views",
  },
  IG: {
    followers_count: "Followers count",
    reach: "Reach (Organic + Paid)",
    profile_views: "Profile views",
    website_clicks: "Website clicks",
  },
  YT: {
    subscribers: "Followers count",
    views: "Reach",
    er_on_views: "ER",
    averageViewDuration: "Average time watched",
    playbackBasedCpm: "Playbck Based CPM",
  },
  TK: {
    follower_count: "Followers",
    likes_count: "Likes",
  },
};
