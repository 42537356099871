import * as React from "react";
import Media from "react-media";
import { SearchFilter } from "../../ui/SearchFilter/SearchFilter";
import { CSSTransition } from "react-transition-group";
import { TransitionStatus } from "react-transition-group/Transition";

interface Props {
  showFilterMobile: boolean;
  searchValue: string;
  clearFilter: () => void;
  onTextFilterChange: (value: string) => void;
  showFilter: () => void;
  renderAdvancedFilter: (transitionStatus?: TransitionStatus) => void;
}

const PostListFilter: React.FC<Props> = ({
  showFilterMobile,
  searchValue,
  clearFilter,
  showFilter,
  onTextFilterChange,
  renderAdvancedFilter
}) => {
  return (
    <Media query={{ maxWidth: 768 }}>
      {(matches: boolean) => {
        if (!matches) {
          return (
            <>
              <SearchFilter
                placeholder="Search by title"
                clearFilter={clearFilter}
                onTextFilterChange={onTextFilterChange}
                searchValue={searchValue}
              />
              {renderAdvancedFilter()}
            </>
          );
        }
        return (
          <div>
            <SearchFilter
              showFilter={() => showFilter && showFilter()}
              placeholder="Search by title"
              clearFilter={clearFilter}
              onTextFilterChange={onTextFilterChange}
              searchValue={searchValue}
            />
            <CSSTransition in={showFilterMobile} timeout={300} classNames="" unmountOnExit>
              {status => renderAdvancedFilter(status)}
            </CSSTransition>
          </div>
        );
      }}
    </Media>
  );
};

export default PostListFilter;
