import * as React from "react";

import PostListFilter from ".";
import { AdvancedFilterPost } from "./AdvancedFilterPost";
import { FilteredAutocompleteMetadata } from "../../../container/FilteredAutocompleteMetadata";
import { useFilterMobile } from "../../../hooks/useFilterMobile";
import { FilterContextT } from "../../../providers/FilterProvider";
import { Project } from "../../../types/Project";

interface Props {
  ytPostFilterContext: React.Context<FilterContextT>;
  project: Project;
  publisherSelectComponent?: (matches: boolean) => React.ReactElement<any>;
  brandedComponent?: (matches: boolean) => React.ReactElement<any>;
}

export const YtPostListFilter: React.FC<Props> = ({
  ytPostFilterContext,
  project,
  publisherSelectComponent,
  brandedComponent
}) => {
  const { onCloseFilterMobile, toggleShowFilter, showFilterMobile } = useFilterMobile();
  const { callbacks, filterState } = React.useContext(ytPostFilterContext);
  const { searchValue, dateRange } = filterState;
  const { clearFilter, onTextFilterChange, onChangeUpdateDateRange } = callbacks;

  return (
    <PostListFilter
      searchValue={searchValue}
      clearFilter={clearFilter}
      onTextFilterChange={onTextFilterChange}
      showFilter={toggleShowFilter}
      showFilterMobile={showFilterMobile}
      renderAdvancedFilter={status => (
        <AdvancedFilterPost
          transitionStatus={status}
          showFilterMobile={showFilterMobile}
          onCloseFilterMobile={onCloseFilterMobile}
          onClearFilterMobile={clearFilter}
          dateRange={dateRange}
          onChangeUpdateDateRange={onChangeUpdateDateRange}
          brandedComponent={brandedComponent}
          publisherSelectComponent={publisherSelectComponent}
          autocompleteMetadataComponent={
            <FilteredAutocompleteMetadata FilterContext={ytPostFilterContext} project={project} />
          }
        />
      )}
    />
  );
};
