import gql from "graphql-tag";

const ALL_METRICS = gql`
  query allMetrics($where: MetricWhere) {
    allMetrics(where: $where) {
      id
      name
      label
      publications {
        id
      }
      custom
      description
      formula
    }
  }
`;

export { ALL_METRICS };
