import * as React from "react";
import { DataProxy } from "apollo-cache";
import styled from "styled-components";
import { MetricColumnSetsContext, ColumnSetType } from "../providers/MetricColumnSetsProvider";
import { Select, ButtonIcon, Typography, DialogCard, Button } from "@freeda/react-components";
import { Transition } from "react-transition-group";
import { sortBy } from "lodash";
import { Colors } from "@freeda/react-components/lib/theme";
import { ALL_COLUMN_SETS } from "../apollo/queries/AllColumnSets";
import ListColumnSetQuery from "../types/graphql/AllColumnSetsQuery";
import { FeedbackContext } from "../providers/FeedbackProvider";
import { getPublicationTypeByPlatform } from "../ui-specs/posts-pages-specs";
import { Platform } from "../types/Platform";
import { Project } from "../types/Project";
import { FetchResult } from "apollo-link";
import { ColumnSet } from "../types/ColumnSet";
import { useMutation, useQuery } from "react-apollo";
import AllColumnSetQueryT from "../types/graphql/AllColumnSetsQuery";
import { DELETE_COLUMN_SET } from "../apollo/mutations/DeleteColumnSet";

const SelectWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 15;
  transition: right 0.4s;

  &.wrapper-entering {
    right: -75px;
  }
  &.wrapper-entered {
    right: 0px;
  }
  &.wrapper-exiting {
    right: -75px;
  }
  &.wrapper-exited {
    right: -75px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  transition: right 0.4s;
  right: 0px;

  &.edit-entering {
    opacity: 0;
  }
  &.edit-entered {
    opacity: 1;
  }
  &.edit-exiting {
    opacity: 0;
  }
  &.edit-exited {
    opacity: 0;
  }
`;

type ColumnSetBuilderFn = (
  project: Project,
  platform: Platform
) => React.FC<{ onOpenCustomPanel: () => void; width?: string | number; style?: React.CSSProperties }>;

const getPostColumnSetSelect: ColumnSetBuilderFn = (project, platform) => ({ onOpenCustomPanel, width, style }) => {
  const { openSnackbar } = React.useContext(FeedbackContext);
  const { selectSet, selectedSets } = React.useContext(MetricColumnSetsContext);
  const columnSetType: ColumnSetType = `${project.id}_${platform}`;
  const publicationId = getPublicationTypeByPlatform(platform);

  const [deleteCustomColumnSet, deleteResult] = useMutation(DELETE_COLUMN_SET, {
    update: (cache: DataProxy, result: FetchResult<{ deleteColumnSet: ColumnSet }>) => {
      const q = {
        query: ALL_COLUMN_SETS,
        variables: { where: { publicationId, custom: true } },
      };
      const cachedQuery = cache.readQuery<ListColumnSetQuery>(q);
      const deletedCustomColumn = result.data!.deleteColumnSet;
      if (cachedQuery) {
        cache.writeQuery({
          query: ALL_COLUMN_SETS,
          variables: { where: { publicationId, custom: true } },
          data: {
            allColumnSets: cachedQuery.allColumnSets.filter((cs) => cs.id !== deletedCustomColumn.id),
          },
        });
      }
    },
  });

  const allColumnSetResult = useQuery<AllColumnSetQueryT>(ALL_COLUMN_SETS, {
    variables: { where: { publicationId, custom: true } },
  });
  const allColumnSets =
    allColumnSetResult.loading || !allColumnSetResult.data || Object.keys(allColumnSetResult.data).length === 0
      ? []
      : allColumnSetResult.data.allColumnSets;

  const selectedSetName = selectedSets[columnSetType];
  const selectedColumnSet = allColumnSets.find((cs) => cs.name === selectedSetName);

  const selectedValue = selectedColumnSet ? { value: selectedColumnSet.name, label: selectedColumnSet.label } : null;

  const customColumnSets = allColumnSets.map((cs) => ({
    value: cs.name,
    label: cs.label,
  }));
  
  const orderedOptions = sortBy(customColumnSets, "label");
  const optionsSelect = [...orderedOptions, { value: "create", label: "Create" }];

  return (
    <div style={{ display: "flex", alignItems: "center", marginRight: 20, ...style }}>
      <Transition in={Boolean(selectedColumnSet)} timeout={300}>
        {(status) => (
          <SelectWrapper className={`wrapper-${status}`}>
            <Typography variantName="label" style={{ marginRight: 10 }}>
              Metrics
            </Typography>
            <Select
              style={{ width }}
              options={optionsSelect}
              value={selectedValue}
              onChange={(option: any) => {
                selectSet(columnSetType, option ? option.value : null, onOpenCustomPanel);
              }}
              isSearchable={false}
              isClearable={true}
            />
          </SelectWrapper>
        )}
      </Transition>
      <Transition in={Boolean(selectedColumnSet)} timeout={500}>
        {(status) => {
          return (
            <ButtonWrapper className={`edit-${status}`}>
              <ButtonIcon
                iconName="edit"
                iconColor={Colors.GRIGINO}
                style={{ marginLeft: 5, marginRight: 5 }}
                onClick={onOpenCustomPanel}
                disabled={!Boolean(selectedColumnSet)}
              />
              <DialogCard
                position="bottom-end"
                referenceChildren={
                  <ButtonIcon
                    iconName="delete"
                    iconColor={Colors.GRIGINO}
                    loading={deleteResult.loading}
                    disabled={!Boolean(selectedColumnSet)}
                  />
                }
                tooltipChildren={(closeCard) => (
                  <div>
                    <Typography
                      variantName="subtitle"
                      textAlign="center"
                      textColor={Colors.PURPLE}
                      style={{ width: 200 }}
                    >
                      You are deleting the column set, you will not be able to recover this.
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: 20,
                      }}
                    >
                      <Button action="undo" style={{ marginRight: 5 }} onClick={() => closeCard()}>
                        Undo
                      </Button>
                      <Button
                        style={{ marginLeft: 5 }}
                        onClick={async () => {
                          closeCard();
                          selectedColumnSet &&
                            (await deleteCustomColumnSet({
                              variables: {
                                input: {
                                  id: selectedColumnSet.id,
                                },
                              },
                            }));
                          selectSet(columnSetType, "", closeCard);
                          openSnackbar("Custom column set deleted correctly!", "notification");
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                )}
              />
            </ButtonWrapper>
          );
        }}
      </Transition>
    </div>
  );
};

export { getPostColumnSetSelect };
