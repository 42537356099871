import * as React from "react";
import { Colors } from "@freeda/react-components/lib/theme";

interface Props {
  heightWidth?: number;
  color?: string;
}

const UserIcon: React.FC<Props> = ({ heightWidth, color }) => (
  <svg width={heightWidth} height={heightWidth} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="user" fill={color} fillRule="nonzero">
        <path d="M15,5 C15,7.76666667 12.7666667,10 10,10 C7.23333333,10 5,7.76666667 5,5 C5,2.23333333 7.23333333,0 10,0 C12.7666667,0 15,2.23333333 15,5 Z M0,18.3333333 C0,15 6.66666667,13.1666667 10,13.1666667 C13.3333333,13.1666667 20,15 20,18.3333333 L20,20 L0,20 L0,18.3333333 Z" />
      </g>
    </g>
  </svg>
);

UserIcon.defaultProps = {
  heightWidth: 20,
  color: Colors.GRIGINO,
};

export default UserIcon;
