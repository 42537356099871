import {
  Box,
  Column,
  Columns,
  Display,
  FreedaLogo,
  Headline,
  Inline,
  Stack,
  Tabs,
} from "@freeda/design-system";
import * as React from "react";
import { Project } from "../../../types/Project";
import { Platform } from "../../../types/Platform";
import { PlatformName } from "../../../ui-specs/posts-pages-specs";
import { useQuery } from "@apollo/react-hooks";
import AllPublisherInfosQueryT from "../../../types/graphql/ListPublisherQuery";
import { ALL_PUBLISHER_INFOS } from "../../../apollo/queries/AllPublisherInfos";
import { trimAndReplaceSpaceToUpper } from "../../../utils/trimAndReplaceSpaceToUpper";
import { OrganicMetricsPage } from "./OrganicMetrics/OrganicMetricsPage";
import { PaidMetricsPageContainer as PaidMetricsPage } from "./PaidMetrics/PaidMetricsPage";

type Props = {
  project: Project;
  platform: Platform;
  languages: { value: string; label: string }[];
  fmkPlatforms: { value: string; label: string }[];
};

type MetricsPageTabs = "organic" | "paid";

function MetricsPage(props: Props) {
  const tabs = ( (props.platform === "IG" || props.platform === "FB") && props.fmkPlatforms.length > 0)
      ? [allTabs.organic, allTabs.paid]
      : [allTabs.organic]

  const [selectedTab, setSelectedTab] =
    React.useState<MetricsPageTabs>("organic");

  const header = (
    <Columns space={24}>
      <Inline space={24} alignY="baseline">
        <Display size="medium">{props.project.label}</Display>
        <Headline size="medium" color="secondary">
          {PlatformName[props.platform] + " metrics"}
        </Headline>
      </Inline>
      <Column width="content">
        <FreedaLogo color="black" width={80} />
      </Column>
    </Columns>
  );

  return (
    <Stack space={0}>
      <Box paddingX={80} paddingY={40} display="flex" flexDirection="column">
        <Stack space={24}>
          <Stack space={24}>
            {header}
            <Tabs
              size="large"
              value={selectedTab}
              onChange={setSelectedTab}
              tabs={tabs}
            />
          </Stack>
          {selectedTab === "organic" ? (
            <OrganicMetricsPage
              project={props.project}
              platform={props.platform}
              languages={props.languages}
            />
          ) : (
            <PaidMetricsPage
              project={props.project}
              platform={props.platform}
              languages={props.fmkPlatforms}
            />
          )}
        </Stack>
      </Box>
    </Stack>
  );
}

export function MetricsPageContainer({
  project,
  platform,
}: {
  project: Project;
  platform: Platform;
}) {
  const publisherResult =
    useQuery<AllPublisherInfosQueryT>(ALL_PUBLISHER_INFOS);


  const [languages, fmkPlatforms] =
    publisherResult.loading ||
    !publisherResult.data ||
    Object.keys(publisherResult.data).length === 0
      ? [[], []]
      : [
          publisherResult.data.allPublisherInfos
            /*.filter(
              (p) =>
                trimAndReplaceSpaceToUpper(p.name).indexOf(
                  project.id.toUpperCase()
                ) >= 0 && p.platformId === platform
            )*/
            /*.filter(
              (p) =>
                trimAndReplaceSpaceToUpper(p.name.substring(0, p.name.indexOf(" "))) === project.id.toUpperCase() 
                    && p.platformId === platform
            )*/
            .filter(
              (p) => p.projectId === project.idLegacy && p.platformId === platform
            )
            .map( p=> ({
              name: p.name.split(' '),
              value: p.name
            }) )
            .map((p) => ({
              label: p.name.length === 3 ? p.name[2] : '',
              value: p.value,
            })),
          
        publisherResult.data.allPublisherInfos
            .filter( p => (p.name !== 'Freeda FMK ES Old' && p.name !== 'Freeda FMK UK EUR'))
            /*.filter(
              (p) =>
                trimAndReplaceSpaceToUpper(p.name).indexOf(
                  project.id.toUpperCase()
                ) >= 0 && p.platformId === "FMK"
            )*/
            .filter(
              (p) => p.projectId === project.idLegacy && p.platformId === "FMK"
            )
            .map((p) => ({
              label: p.name.slice(p.name.indexOf("FMK") + 3).toUpperCase(),
              value: p.name,
            })),
        ];

  return (
    <MetricsPage
      project={project}
      platform={platform}
      languages={languages}
      fmkPlatforms={fmkPlatforms}
    />
  );
}

const allTabs: { [key: string]: { value: MetricsPageTabs; label: string } } =
{
  organic: { value: "organic", label: "ORGANIC PAGE METRICS" },
  paid: { value: "paid", label: "PAID PAGE METRICS" },
};
