import * as React from "react";

import { FilterContextT } from "../providers/FilterProvider";
import ListIgContentQueryT from "../types/graphql/ListIgContentQuery";
import { InstagramContent } from "../types/InstagramContent";
import { InstagramPostType } from "../types/InstagramPostType";
import { NotFound } from "../components/ui/NotFound";
import { PublicationType } from "../types/Publication";
import AllColumnSetQueryT from "../types/graphql/AllColumnSetsQuery";
import { ALL_COLUMN_SETS } from "../apollo/queries/AllColumnSets";
import {
  isDataAvailable,
  buildOrderByClause,
  buildIgContentFilter,
} from "../apollo/utils";
import { PlaceholderPostList } from "../components/ui/Placeholders/PlaceholderPostList";
import { Average } from "../types/Average";
import {
  ColumnSetType,
  MetricColumnSetsContext,
} from "../providers/MetricColumnSetsProvider";
import { IGFramesListTable } from "../components/layout/IGFramesListTable";
import { EXPORT_IG_CONTENT_METADATA } from "../apollo/queries/ExportIgContentMetadata";

import { Project } from "../types/Project";
import { useQuery } from "@apollo/react-hooks";
import { LIST_IG_CONTENT_METADATA } from "../apollo/queries/ListIgContentMetadata";
import { StaticIdsContext } from "../providers/StaticIdsProvider";

interface Props {
  project: Project;
  igFramesPostFilterContext: React.Context<FilterContextT>;
  igFramesColumnSetSelect: React.FC<{
    onOpenCustomPanel: () => void;
    width?: string | number;
  }>;
  columnSetType: ColumnSetType;
}

const IgFramesPostListTableContainer: React.FC<Props> = ({
  project,
  igFramesColumnSetSelect,
  igFramesPostFilterContext,
  columnSetType,
}) => {
  const { callbacks, filterState } = React.useContext(
    igFramesPostFilterContext
  );
  const { selectedSets } = React.useContext(MetricColumnSetsContext);
  const selectedSet = selectedSets[columnSetType];
  const {
    onChangePage,
    onChangePageSize,
    onBackPage,
    onNextPage,
    onChangePostOrder,
    onChangeMetricOrder,
  } = callbacks;
  const {
    selectedFormat,
    currentPage,
    pageSize,
    metricOrderBy,
    postOrderBy,
    order,
  } = filterState;

  const columnSetName =
    selectedSet || `${selectedFormat || "ALL"}_${PublicationType.IG_FRAME}`;
  const custom = Boolean(selectedSet);

  const allColumnSetResult = useQuery<AllColumnSetQueryT>(ALL_COLUMN_SETS, {
    variables: {
      where: {
        publicationId: PublicationType.IG_FRAME,
        name: columnSetName,
        custom,
      },
    },
  });

  const areColumnSetsLoaded = isDataAvailable(allColumnSetResult);
  const columnSetId =
    areColumnSetsLoaded && allColumnSetResult.data!.allColumnSets.length > 0
      ? allColumnSetResult.data!.allColumnSets[0].id
      : undefined;

  const staticIdsContext = React.useContext(StaticIdsContext);

  const wheresFilter = buildIgContentFilter(
    filterState,
    selectedFormat && InstagramPostType[selectedFormat],
    project.idLegacy,
    columnSetId,
    true,
    staticIdsContext.brandedId
  );
  const orderBy = buildOrderByClause(metricOrderBy, postOrderBy, order);

  const listIgContentResult = useQuery<ListIgContentQueryT>(
    LIST_IG_CONTENT_METADATA,
    {
      fetchPolicy: "network-only",
      variables: {
        offset: currentPage * pageSize,
        limit: pageSize,
        orderBy,
        ...wheresFilter,
      },
    }
  );

  let posts: Array<InstagramContent> = [];
  let averages: Array<Average> = [];
  let count: number = 0;
  let totalPages: number = 0;
  const loading = listIgContentResult.loading;

  if (
    listIgContentResult.data &&
    Object.keys(listIgContentResult.data).length > 0
  ) {
    posts = listIgContentResult.data!.listInstagramContents.content;
    averages = listIgContentResult.data!.listInstagramContents.subAggregate
      .averages;
    count = listIgContentResult.data!.listInstagramContents.aggregate.count;
    totalPages = Math.ceil(count / pageSize);
  }

  if (!areColumnSetsLoaded || (loading && posts.length === 0)) {
    return <PlaceholderPostList />;
  }

  if (posts.length === 0 && !loading) {
    return <NotFound>No post found! :(</NotFound>;
  }

  return (
    <IGFramesListTable
      frames={posts}
      averages={averages}
      columnSetType={columnSetType}
      exportQuery={EXPORT_IG_CONTENT_METADATA}
      publicationType={PublicationType.IG_FRAME}
      loading={loading}
      currentPage={currentPage}
      pageSize={pageSize}
      totalPages={totalPages}
      count={count}
      onChangePageSize={onChangePageSize}
      onChangePage={onChangePage}
      onBackPage={onBackPage}
      onNextPage={() => onNextPage(totalPages)}
      onChangePostOrder={onChangePostOrder}
      onChangeMetricOrder={onChangeMetricOrder}
      FilterContext={igFramesPostFilterContext}
      ColumnSetSelect={igFramesColumnSetSelect}
      project={project}
      orderBy={metricOrderBy || postOrderBy}
      order={order}
    />
  );
};

export { IgFramesPostListTableContainer };
