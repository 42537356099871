import gql from "graphql-tag";

export const PAID_RESULTS_OVER_TIME = gql`
  query paidResultsOverTime($where: PaidResultsOverTimeWhere!) {
    paidResultsOverTime(where: $where) {
      publisher
      metrics {
        name
        values {
          value
          timestamp
        }
      }
    }
  }
`;
