import * as React from "react";
import { difference, includes } from "lodash";
import { Typography } from "@freeda/react-components";

export const randomTimeout = () => {
  const maxNum = 2000;
  const minNum = 500;
  return Math.floor(Math.random() * (maxNum - minNum) + minNum);
};

const checkingMessage = "Please wait, I’m checking file columns… 🕵🏻";
const matchMessage = "Perfect, all columns matched! 😎";
const notMatchMessage = "Umh, there's something not matched! 😵";
const uploadProcessMessage = "I'm uploading your CSV! 👩🏻‍💻";
const uploadCompletedMessage = "Upload completed successfully! ✌🏻";
const uploadErrorMessage = "Oops, something went wrong when try to upload CSV! 😢 Try again to upload file.";
const wrongFileNameMessage = "Hey, please verify the file name 🧑🏻‍✈️";
const reloadFileMessage = "Please update file and reload it.";
const disorderedColumnsMessage = "Please checking order of these columns:";
const asyncDataMessage = "Tiktok data will be available the next time the data warehouse is loaded";

export const csvColumnsMastro = [
  "Account",
  "User Name",
  "Followers at Posting",
  "Created",
  "Type",
  "Reach",
  "PaidReach",
  "Views",
  "Likes",
  "Comments",
  "Shares",
  "AVG Watch time",
  "Watch full video",
  "Total Play Time",
  "URL",
  "Description",
  "Sponsor Id",
  "Sponsor Name",
  "Length",
  "Saved",
  "Photo"
];

const getMissingColumnsMessage = (missingColumns: Array<string>): JSX.Element => {
  const columns = missingColumns.map((c) => `"${c}"`);
  const message = `Missing ${missingColumns.length > 1 ? "these" : "this"} column${
    missingColumns.length > 1 ? "s" : ""
  }: `;

  return (
    <div>
      <Typography variantName="text" style={{ fontWeight: 700, wordBreak: "break-word" }}>
        {message}
      </Typography>
      <Typography variantName="text" style={{ wordBreak: "break-word" }}>
        {columns.join(", ")}
      </Typography>
    </div>
  );
};

const getExtraColumnsMessage = (extraColumns: Array<string>): JSX.Element => {
  const columns = extraColumns.map((c) => `"${c}"`);
  const message = `Remove extra column${extraColumns.length > 1 ? "s" : ""}: `;

  return (
    <div>
      <Typography variantName="text" style={{ fontWeight: 700, wordBreak: "break-word" }}>
        {message}
      </Typography>
      <Typography variantName="text" style={{ wordBreak: "break-word" }}>
        {columns.join(", ")}
      </Typography>
    </div>
  );
};

const getDisorderedColumnsMessage = (disorderedColumns: Array<string>): JSX.Element => {
  const columns = disorderedColumns.map((c) => `"${c}"`);
  const messMessage = "Something is in a mess! 🧐";

  return (
    <div>
      <Typography variantName="text" style={{ fontWeight: 700, wordBreak: "break-word" }}>
        <>
          {messMessage}
          <br />
          {disorderedColumnsMessage}
        </>
      </Typography>
      <Typography variantName="text" style={{ wordBreak: "break-word" }}>
        {columns.join(", ")}
      </Typography>
    </div>
  );
};

export const getErrorMatchColumns = (fileColumns: Array<string>): Array<JSX.Element> => { //sdoppiare funzione per l'altro check del reel
  let errors: Array<JSX.Element> = [];

  const missingColumns = difference(csvColumnsMastro, fileColumns);
  const extraColumns = difference(fileColumns, csvColumnsMastro);

  const columnsWithoutMissingOrExtra = csvColumnsMastro.filter(
    (cM) => !includes([...missingColumns, ...extraColumns], cM)
  );

  const disorderedColumns = columnsWithoutMissingOrExtra.filter((cM, iM) => !Boolean(fileColumns[iM] === cM));

  if (missingColumns.length > 0) {
    errors = [...errors, getMissingColumnsMessage(missingColumns)];
  }
  if (extraColumns.length > 0) {
    errors = [...errors, getExtraColumnsMessage(extraColumns)];
  }
  if (disorderedColumns.length > 0) {
    errors = [...errors, getDisorderedColumnsMessage(disorderedColumns)];
  }

  return errors;
};

export const getMessageSteps = (
  loadingCheckFile: boolean,
  columnsMatch: boolean | null,
  validFileName: boolean | null,
  uploadCompleted: boolean | null,
  uploadError: boolean
): string => {
  if (uploadError) {
    return uploadErrorMessage;
  }

  if (!validFileName && validFileName !== null) {
    return wrongFileNameMessage;
  }

  if (loadingCheckFile) {
    return checkingMessage;
  }

  if (!loadingCheckFile) {
    if (!uploadCompleted && uploadCompleted !== null) {
      return uploadProcessMessage;
    }
    if (uploadCompleted) {
      return uploadCompletedMessage;
    }
    if (columnsMatch) {
      return matchMessage;
    }
    if (!columnsMatch) {
      return notMatchMessage;
    }
  }

  return "";
};

export { reloadFileMessage, uploadErrorMessage, asyncDataMessage };
