import * as React from "react";

import PostListPage from "./PostListPage";
import { FilterContextT } from "../../providers/FilterProvider";
import { BrandedEditorialContent } from "../../types/BrandedEditorialContent";
import { capitalize, orderBy } from "lodash";
import { brandedByContentType } from "../../utils/brandedByContentType";
import { Select } from "@freeda/react-components";
import { trimAndReplaceSpaceToUpper } from "../../utils/trimAndReplaceSpaceToUpper";
import { Project } from "../../types/Project";
import { ColumnSetType } from "../../providers/MetricColumnSetsProvider";
import { IgFramesPostListTableContainer } from "../../container/IgFramesPostListTable";
import { IgFramesPostListFilter } from "../layout/PostListFilter/IgFramesPostListFilter";
import { IgStoriesPostListTableContainer } from "../../container/IgStoriesPostListTable";
import { IgStoriesPostListFilter } from "../layout/PostListFilter/IgStoriesPostListFilter";
import { ALL_PUBLISHER_INFOS } from "../../apollo/queries/AllPublisherInfos";
import AllPublisherInfosQueryT from "../../types/graphql/ListPublisherQuery";
import { useQuery } from "@apollo/react-hooks";

const useBuildOptionalComponents = (
  project: Project,
  withBrand: boolean,
  withPublisher: boolean,
  postFilterContext: React.Context<FilterContextT>
) => {
  const { filterState, callbacks } = React.useContext(postFilterContext);

  const publisherResult = useQuery<AllPublisherInfosQueryT>(
    ALL_PUBLISHER_INFOS
  );
  const options =
    publisherResult.loading ||
    !publisherResult.data ||
    Object.keys(publisherResult.data).length === 0
      ? []
      : publisherResult.data.allPublisherInfos
          .filter(
            (p) =>
              trimAndReplaceSpaceToUpper(p.name).indexOf(
                project.id.toUpperCase()
              ) >= 0 && p.platformId === "IG"
          )
          .map((p) => ({ label: capitalize(p.name.slice(-2)), value: p.name }));

  const optionsWithAll = [
    ...orderBy(options, "label"),
    { value: null, label: "All" },
  ];
  const selectedPublisherOption = options.find(
    (o) => o.value === filterState.selectedPublisher
  );

  return {
    brandedComponent: withBrand
      ? (_: boolean) => {
          const options = [
            {
              value: BrandedEditorialContent.branded,
              label: capitalize(BrandedEditorialContent.branded),
            },
            {
              value: BrandedEditorialContent.editorial,
              label: capitalize(BrandedEditorialContent.editorial),
            },
          ];

          const selectedValue = options.find(
            (o) => brandedByContentType(o.value) === filterState.branded
          );

          return (
            <Select
              placeholder="Select type"
              label="Branded or Editorial"
              style={{ padding: "10px 0px" }}
              onChange={(option: any) =>
                callbacks.onChangeBranded(
                  option ? brandedByContentType(option.value) : null
                )
              }
              options={options}
              value={selectedValue ? selectedValue : null}
            />
          );
        }
      : undefined,
    publisherSelectComponent: withPublisher
      ? (_: boolean) => (
          <Select
            style={{ padding: "10px 0px" }}
            label="language"
            placeholder="Select publisher"
            isClearable={false}
            onChange={(option: any) =>
              callbacks.onChangePublisher(option ? option.value : null)
            }
            options={optionsWithAll}
            value={
              selectedPublisherOption
                ? selectedPublisherOption
                : { value: null, label: "All" }
            }
          />
        )
      : undefined,
  };
};

const getContent = (
  project: Project,
  igFramesPostFilterContext: React.Context<FilterContextT>,
  igStoriesPostFilterContext: React.Context<FilterContextT>,
  igFramesColumnSetSelect: React.FC<{
    onOpenCustomPanel: () => void;
    width?: string | number;
  }>,
  igStoriesColumnSetSelect: React.FC<{
    onOpenCustomPanel: () => void;
    width?: string | number;
  }>,
  columnSetType: { igFrames: ColumnSetType; igStories: ColumnSetType },
  withBrand: boolean,
  withPublisher: boolean
) => [
  {
    Table: () => {
      return (
        <IgFramesPostListTableContainer
          project={project}
          igFramesPostFilterContext={igFramesPostFilterContext}
          igFramesColumnSetSelect={igFramesColumnSetSelect}
          columnSetType={columnSetType.igFrames}
        />
      );
    },
    Filter: () => {
      const { publisherSelectComponent } = useBuildOptionalComponents(
        project,
        withBrand,
        withPublisher,
        igFramesPostFilterContext
      );
      return (
        <IgFramesPostListFilter
          igFrameFilterContext={igFramesPostFilterContext}
          publisherSelectComponent={publisherSelectComponent}
        />
      );
    },
    tabLabel: "Frames",
  },
  {
    Table: () => {
      return (
        <IgStoriesPostListTableContainer
          project={project}
          columnSetType={columnSetType.igStories}
          igStoriesColumnSetSelect={igStoriesColumnSetSelect}
          igStoriesPostFilterContext={igStoriesPostFilterContext}
        />
      );
    },
    Filter: () => {
      return (
        <IgStoriesPostListFilter
          igStoriesFilterContext={igStoriesPostFilterContext}
          project={project}
          {...useBuildOptionalComponents(
            project,
            withBrand,
            withPublisher,
            igStoriesPostFilterContext
          )}
        />
      );
    },
    tabLabel: "Stories",
  },
];

interface Props {
  igFramesPostFilterContext: React.Context<FilterContextT>;
  igStoriesPostFilterContext: React.Context<FilterContextT>;
  igFramesColumnSetSelect: React.FC<{
    onOpenCustomPanel: () => void;
    width?: string | number;
  }>;
  igStoriesColumnSetSelect: React.FC<{
    onOpenCustomPanel: () => void;
    width?: string | number;
  }>;
  columnSetType: { igFrames: ColumnSetType; igStories: ColumnSetType };
  project: Project;
  withBrand: boolean;
  withPublisher: boolean;
}

const IgStoriesPostListPage: React.FC<Props> = ({
  igFramesPostFilterContext,
  igStoriesPostFilterContext,
  igFramesColumnSetSelect,
  igStoriesColumnSetSelect,
  columnSetType,
  project,
  withBrand,
  withPublisher,
}) => {
  return (
    <PostListPage
      content={getContent(
        project,
        igFramesPostFilterContext,
        igStoriesPostFilterContext,
        igFramesColumnSetSelect,
        igStoriesColumnSetSelect,
        columnSetType,
        withBrand,
        withPublisher
      )}
    />
  );
};

export default IgStoriesPostListPage;
