import styled from "styled-components";

const ItalicLabel = styled.p`
  margin-right: 15px;
  font-size: 12px;
  font-style: italic;
  text-transform: uppercase;
`;

export { ItalicLabel };
