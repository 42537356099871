import { ProjectId } from "../types/ProjectId";

const paste = (
  publicationType: string,
  project: ProjectId
): Array<{ categoryId: string; metadataId: string | null; value?: boolean; label?: string }> | null => {
  let key: string;
  if (publicationType === "FB" || publicationType === "IG" || publicationType === "TK" || publicationType === "YT") {
    key = `FB_IG_${project}`;
  } else {
    key = `${publicationType}_${project}`;
  }

  const socialMetadata = localStorage.getItem(key);

  return socialMetadata ? JSON.parse(socialMetadata) : null;
};

const copy = (
  publicationType: string,
  project: ProjectId,
  metadataSet: Array<{ categoryId: string; metadataId: string | null; value?: boolean; label?: string }>
): void => {
  let key: string;
  if (publicationType === "FB" || publicationType === "IG" || publicationType === "TK" || publicationType === "TK") {
    key = `FB_IG_${project}`;
  } else {
    key = `${publicationType}_${project}`;
  }

  localStorage.setItem(key, JSON.stringify(metadataSet));
};

export { copy, paste };
