import * as React from "react";
import { SelectCategory } from "./SelectCategory";
import Media from "react-media";
import { AutocompleteMetadataFilter } from "../components/layout/PostListFilter/AutocompleteMetadataFilter";
import { FilterContextT } from "../providers/FilterProvider";
import { Project } from "../types/Project";

interface Props {
  FilterContext: React.Context<FilterContextT>;
  project: Project;
}

const FilteredAutocompleteMetadata: React.FC<Props> = ({ FilterContext, project }) => {
  const { callbacks, filterState } = React.useContext(FilterContext);
  const { onCategoryMetadataSelection } = callbacks;
  const { selectedCategoryId } = filterState;

  return (
    <Media query={{ maxWidth: 755 }}>
      {(matches: boolean) => (
        <>
          <SelectCategory
            selectedCategoryId={selectedCategoryId}
            onCategorySelection={onCategoryMetadataSelection}
            width={!matches ? "170px" : "100%"}
            style={{ marginRight: !matches ? 30 : 0, padding: "10px 0px" }}
          />
          <AutocompleteMetadataFilter categoryId={selectedCategoryId} FilterContext={FilterContext} project={project} />
        </>
      )}
    </Media>
  );
};

export { FilteredAutocompleteMetadata };
