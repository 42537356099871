import * as React from "react";
import { InstagramContent } from "../../../types/InstagramContent";
import { HeaderPage } from "../../ui/HeaderPage";
import { Dialogable } from "../../../types/Dialogable";
import { ChartFramesPanelWrapper, TableWrapper, TableStyled, RightSidebarPlaceholder } from "./styles";
import { ChartPanel } from "./ChartPanel";
import { Transition } from "react-transition-group";
import { zIndex } from "../../../utils/zIndex";
import { sortBy } from "lodash";
import { buildFramesColumns, framesStoryDefaultHead } from "../../../utils/framesAndStoriesTableUtils";
import { buildStoryDetailMetricTableHeader } from "../../../utils/buildPostTableHeader";
import { PlaceholderPostList } from "../../ui/Placeholders/PlaceholderPostList";
import { PositionalMetric } from "../../../types/PositionalMetric";
import { Typography, ButtonIcon } from "@freeda/react-components";
import { Average } from "../../../types/Average";
import { Granularity } from "../../../types/Granularity";

type Props = Dialogable & {
  frames: Array<InstagramContent>;
  title: string;
  positionalMetrics: Array<PositionalMetric>;
  maxWidth: number;
  loading: boolean;
};

interface State {
  openLateralChart: boolean;
}

const DetailsFramesPanel: React.FC<Props> = ({ onClose, maxWidth, frames, loading, title, positionalMetrics }) => {
  const [state, setState] = React.useState<State>({
    openLateralChart: false
  });

  const orderedFrames = sortBy(frames, "publicationDate");

  const averages: Array<Average> = positionalMetrics.map(pm => {
    const sum = orderedFrames.reduce((acc, next) => {
      const measuredMetric = next.measuredMetrics.find(mm => mm.id.name === pm.name);
      if (!measuredMetric) {
        return acc;
      }
      return acc + measuredMetric.value;
    }, 0);
    const averagesValue = sum / orderedFrames.length;
    const average = {
      id: {
        granularity: Granularity.Lifetime,
        name: pm.name
      },
      value: averagesValue,
      conversionUnit: pm.conversionUnit
    };
    return average;
  });

  const metricsTableHeader = buildStoryDetailMetricTableHeader(averages);
  const head = maxWidth > 768 ? framesStoryDefaultHead.desktop : framesStoryDefaultHead.mobile;

  const headWithMetrics = maxWidth > 768 ? [...head, ...metricsTableHeader] : head;

  const columns = buildFramesColumns(maxWidth, orderedFrames);

  return (
    <ChartFramesPanelWrapper>
      <HeaderPage onClose={onClose} style={{ zIndex: zIndex.Header }} />
      <TableWrapper onClick={() => setState({ openLateralChart: false })}>
        {loading ? (
          <PlaceholderPostList showFilter={false} />
        ) : (
          <>
            <Typography variantName="title" style={{ marginBottom: 20 }}>
              {title} 
            </Typography>
            <TableStyled
              columns={columns}
              tableData={orderedFrames}
              rowIdGenerator={post => post.id.contentId}
              itemsPerPage={orderedFrames.length}
              head={headWithMetrics}
              fixedColumnNumbers={1}
              wrapperStyle={{ marginBottom: 60 }}
              loading={loading}
            />
          </>
        )}
      </TableWrapper>
      {loading ? (
        <RightSidebarPlaceholder>
          <ButtonIcon disabled={true} iconName="arrow-left" style={{ marginBottom: 20 }} />
          <ButtonIcon iconName="chart" disabled={true} />
        </RightSidebarPlaceholder>
      ) : (
        <Transition in={state.openLateralChart} timeout={300}>
          {status => (
            <ChartPanel
              transitionStatus={status}
              open={state.openLateralChart}
              maxWidth={maxWidth}
              frames={orderedFrames}
              positionalMetrics={positionalMetrics}
              onClose={() => setState({ openLateralChart: false })}
              onToggleShow={() => {
                setState(state => ({ openLateralChart: !state.openLateralChart }));
              }}
            />
          )}
        </Transition>
      )}
    </ChartFramesPanelWrapper>
  );
};

export default DetailsFramesPanel;
