import gql from "graphql-tag";

const LIST_METADATA_CATEGORIES = gql`
  query listMetadataCategories {
    listMetadataCategories {
      content {
        id
        label
        area
      }
    }
  }
`;

export { LIST_METADATA_CATEGORIES };
