import styled, { css } from "styled-components";
import { Table } from "@freeda/react-components";

export const BkgStory = styled.div`
  width: 45px;
  height: 80px;
  background: ${(props) => props.theme.primary};
  z-index: 1;
  position: absolute;
  left: 20px;
  top: 13px;
`;

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
`;

export const ImgStory = styled.img`
  max-height: 80px;
  width: auto;
  z-index: 2;
`;

export const ImgPost = styled.img`
  max-width: 80px;
  height: auto;
`;

export const SyncLegend = styled.div<{ selectedColumnSet: boolean }>`
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 20px;
  right: -70px;
  transition: right 0.4s;
  ${(props) =>
    props.selectedColumnSet &&
    css`
      right: 0px;
      transition-delay: 0.3s;
    `}
`;

export const FrameImgPost = styled.img<{ selected?: boolean }>`
  max-height: 80px;
  width: auto;
  border: rgb(242, 242, 242) solid 2px
    ${(props) =>
      props.selected &&
      css`
        border: ${props.theme.azzurrino} solid 2px;
      `};
`;

export const IgStoriesTableStyled = styled(Table)`
  && {
    & > thead > tr > th:first-child {
      padding-left: 10px;
    }
    & > thead > tr > th {
      white-space: initial;
    }
    & > tbody > tr > td:first-child {
      height: 100px;
    }
    & > tbody > tr > td:first-child > div {
      padding: 0px;
    }
  }
`;
