import * as React from "react";
import PostListTable from "../components/layout/PostListTable";
import {
  ColumnSetType,
  MetricColumnSetsContext,
} from "../providers/MetricColumnSetsProvider";
import { PublicationType } from "../types/Publication";
import { Average } from "../types/Average";
import {
  isDataAvailable,
  buildOrderByClause,
  buildTiktokContentFilter,
} from "../apollo/utils";
import { PlaceholderPostList } from "../components/ui/Placeholders/PlaceholderPostList";
import { NotFound } from "../components/ui/NotFound";
import { FilterContextT } from "../providers/FilterProvider";
import { Project } from "../types/Project";
import { Platform } from "../types/Platform";
import { ALL_COLUMN_SETS } from "../apollo/queries/AllColumnSets";
import AllColumnSetQueryT from "../types/graphql/AllColumnSetsQuery";
import { useQuery, useMutation } from "@apollo/react-hooks";
import ListTkContentQuery from "../types/graphql/ListTkContentQuery";
import { LIST_TK_CONTENT_METADATA } from "../apollo/queries/ListTkContentMetadata";
import { TikTokContent } from "../types/TiktokContent";
import { UPDATE_TK_CONTENT } from "../apollo/mutations/UpdateTkContent";
import {
  buildTiktokTableColumns,
  defaultTiktokTableHead,
} from "../utils/postTableUtils";
import { EXPORT_TK_CONTENT_METADATA } from "../apollo/queries/ExportTkContentMetadata";
import { StaticIdsContext } from "../providers/StaticIdsProvider";
import { TikTokPostType } from "../types/TikTokPostType";

interface Props {
  project: Project;
  tkPostFilterContext: React.Context<FilterContextT>;
  tkColumnSetSelect: React.FC<{
    onOpenCustomPanel: () => void;
    width?: string | number;
  }>;
  columnSetType: ColumnSetType;
}

const TkPostListTableContainer: React.FC<Props> = ({
  project,
  tkPostFilterContext,
  tkColumnSetSelect,
  columnSetType,
}) => {
  const { callbacks, filterState } = React.useContext(tkPostFilterContext);

  const {
    selectedFormat,
    currentPage,
    pageSize,
    metricOrderBy,
    postOrderBy,
    order,
  } = filterState;

  const {
    onChangePage,
    onChangePageSize,
    onBackPage,
    onNextPage,
    onChangePostOrder,
    onChangeMetricOrder,
  } = callbacks;

  const { selectedSets } = React.useContext(MetricColumnSetsContext);
  const selectedSet = selectedSets[columnSetType];
  const columnSetName = selectedSet || `ALL_${PublicationType.TK_POST}`;
  const custom = Boolean(selectedSet);

  const allColumnSetResult = useQuery<AllColumnSetQueryT>(ALL_COLUMN_SETS, {
    variables: {
      where: {
        publicationId: PublicationType.TK_POST,
        name: columnSetName,
        custom,
      },
    },
  });

  const areColumnSetsLoaded = isDataAvailable(allColumnSetResult);

  const columnSetId =
    areColumnSetsLoaded && allColumnSetResult.data!.allColumnSets.length > 0
      ? allColumnSetResult.data!.allColumnSets[0].id
      : undefined;

  const staticIdsContext = React.useContext(StaticIdsContext);

  const wheresFilter = buildTiktokContentFilter(
    filterState,
    selectedFormat && TikTokPostType[selectedFormat],
    project.idLegacy,
    columnSetId,
    staticIdsContext.brandedId
  );

  const orderBy = buildOrderByClause(metricOrderBy, postOrderBy, order);

  const listTkContentResult = useQuery<ListTkContentQuery>(
    LIST_TK_CONTENT_METADATA,
    {
      fetchPolicy: "network-only",
      variables: {
        offset: currentPage * pageSize,
        limit: pageSize,
        orderBy,
        ...wheresFilter,
      },
    }
  );

  let posts: Array<TikTokContent> = [];
  let averages: Array<Average> = [];
  let count: number = 0;
  let totalPages: number = 0;
  const loading = listTkContentResult.loading;

  const updateTkContentMetadataMutation = useMutation(UPDATE_TK_CONTENT);

  if (
    listTkContentResult.data &&
    Object.keys(listTkContentResult.data).length > 0
  ) {
    posts = listTkContentResult.data!.listTikTokContents.content;
    averages = listTkContentResult.data!.listTikTokContents.subAggregate
      .averages;
    count = listTkContentResult.data!.listTikTokContents.aggregate.count;
    totalPages = Math.ceil(count / pageSize);
  }

  if (!areColumnSetsLoaded || (loading && posts.length === 0)) {
    return <PlaceholderPostList />;
  }

  if (posts.length === 0 && !loading) {
    return <NotFound>No post found! :(</NotFound>;
  }
  return (
    <PostListTable
      defaultHead={defaultTiktokTableHead}
      buildColumns={buildTiktokTableColumns}
      posts={posts}
      averages={averages}
      loading={loading}
      currentPage={currentPage}
      pageSize={pageSize}
      totalPages={totalPages}
      onChangePageSize={onChangePageSize}
      onChangePage={onChangePage}
      onBackPage={onBackPage}
      onNextPage={() => onNextPage(totalPages)}
      onChangePostOrder={onChangePostOrder}
      onChangeMetricOrder={onChangeMetricOrder}
      count={count}
      publicationType={PublicationType.TK_POST}
      platform={Platform.TK}
      PostFilterContext={tkPostFilterContext}
      updateSocialContentMetadataMutation={updateTkContentMetadataMutation}
      ColumnSetSelect={tkColumnSetSelect}
      exportQuery={EXPORT_TK_CONTENT_METADATA}
      columnSetType={columnSetType}
      project={project}
      listContentMetadataQuery={LIST_TK_CONTENT_METADATA}
      wheresFilter={wheresFilter}
      orderBy={metricOrderBy || postOrderBy}
      order={order}
    />
  );
};

export { TkPostListTableContainer };
