import * as React from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import Media from "react-media";
import { debounce } from "lodash";
import { Autocomplete, Chip, Radio } from "@freeda/react-components";
import { scrollbarHorizontalStyle } from "../../ui/ScrollBarStyle";
import { LIST_METADATA } from "../../../apollo/queries/ListMetadata";
import ListMetadataQueryT from "../../../types/graphql/ListMetadataQuery";
import { MetadataOrderBy } from "../../../types/graphql/MetadataOrderBy";
import { LogicalOperator } from "../../../types/LogicalOperator";
import { PlaceholderChip } from "../../ui/Placeholders/PlaceholderChip";
import { Project } from "../../../types/Project";

const ChipWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow-x: auto;
  @media (max-width: 900px) {
    margin-top: 10px;
    width: 100%;
  }
  ${scrollbarHorizontalStyle};
`;

interface Props {
  selectedMetadata: Array<{ value: string; label: string }>;
  categoryId: string | null;
  logicalOperator: LogicalOperator;
  onChangeMetadata: (metadata: { value: string; label: string }, clearFilter?: () => void) => void;
  onChangeLogicalOperator: (logicalOperator: LogicalOperator) => void;
  project: Project;
}

const AutocompleteMetadata: React.FC<Props> = ({
  selectedMetadata,
  logicalOperator,
  onChangeLogicalOperator,
  onChangeMetadata,
  categoryId,
  project,
}) => {
  const [textValue, setTextValue] = React.useState<string>("");

  const debouncedUpdatedInputValue = debounce((textValue: string) => setTextValue(textValue), 500);

  const listMetadataResult = useQuery<ListMetadataQueryT>(LIST_METADATA, {
    variables: {
      limit: 60,
      orderBy: `${MetadataOrderBy.label}_ASC`,
      where: {
        labelLike: textValue,
        categoryId,
        project: {
          idIn: [project.label],
          alsoWithoutProjects: true,
        },
      },
    },
  });

  const metadata =
    listMetadataResult.loading || !listMetadataResult.data ? [] : listMetadataResult.data.listMetadata.content;

  return (
    <Media query={{ maxWidth: 755 }}>
      {(matches: boolean) => (
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            width: "100%",
            paddingTop: 10,
            paddingBottom: 10,
            flexWrap: !matches ? "initial" : "wrap",
          }}
        >
          <Autocomplete
            label="Label"
            loading={listMetadataResult.loading}
            style={{ marginRight: 15, width: !matches ? 200 : 170 }}
            onInputChange={(textValue) => {
              debouncedUpdatedInputValue(textValue);
            }}
            placeholder="Select label"
            flexBasis="100%"
            currentSelectedItem={selectedMetadata.map((m) => ({ value: String(m.value), label: m.label }))}
            items={metadata.map((m) => {
              const label = categoryId ? m.label : `${m.label} - ${m.category.label}`;
              return {
                value: String(m.id),
                label,
              };
            })}
            onSelect={(metadata) => onChangeMetadata(metadata, () => setTextValue(""))}
            onUnselect={(metadata) => onChangeMetadata(metadata)}
            renderSelections={(selectedMetadata: any, handleDelete) => (
              <Media query={{ maxWidth: 360 }}>
                {(maxWidth360: boolean) => (
                  <>
                    <div
                      style={{
                        display: "flex",
                        marginTop: !maxWidth360 ? 0 : 10,
                        position: "relative",
                        top: !maxWidth360 ? -6 : 0,
                      }}
                    >
                      <Radio
                        label="And"
                        checked={logicalOperator === LogicalOperator.AND}
                        onClick={() => onChangeLogicalOperator(LogicalOperator.AND)}
                        style={{ marginRight: 16 }}
                      />
                      <Radio
                        label="Or"
                        checked={logicalOperator === LogicalOperator.OR}
                        onClick={() => onChangeLogicalOperator(LogicalOperator.OR)}
                        style={{ marginRight: 16 }}
                      />
                    </div>
                    <ChipWrapper>
                      {selectedMetadata.length > 0 ? (
                        selectedMetadata.map((metadata: any) => {
                          return (
                            <Chip
                              key={metadata.value}
                              label={metadata.label}
                              style={{ marginRight: 5 }}
                              onClick={() => {
                                handleDelete(metadata);
                              }}
                              isClearable
                            />
                          );
                        })
                      ) : (
                        <PlaceholderChip />
                      )}
                    </ChipWrapper>
                  </>
                )}
              </Media>
            )}
            internalOpen
          />
        </div>
      )}
    </Media>
  );
};

export { AutocompleteMetadata };
