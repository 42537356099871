import * as React from "react";
import { FilterContextT } from "../providers/FilterProvider";
import { IGStoriesListTable } from "../components/layout/IGStoriesListTable";
import { PublicationType } from "../types/Publication";
import { InstagramStoryContent } from "../types/InstagramStoryContent";
import { Average } from "../types/Average";
import { PlaceholderPostList } from "../components/ui/Placeholders/PlaceholderPostList";
import { NotFound } from "../components/ui/NotFound";
import {
  isDataAvailable,
  buildOrderByClause,
  buildIgStoryContentFilter,
} from "../apollo/utils";
import { EXPORT_INSTAGRAM_STORIES } from "../apollo/queries/ExportInstagramStoriesContent";
import {
  ColumnSetType,
  MetricColumnSetsContext,
} from "../providers/MetricColumnSetsProvider";
import { Project } from "../types/Project";
import AllColumnSetQueryT from "../types/graphql/AllColumnSetsQuery";
import { useQuery } from "@apollo/react-hooks";
import { ALL_COLUMN_SETS } from "../apollo/queries/AllColumnSets";
import { LIST_INSTAGRAM_STORIES } from "../apollo/queries/ListInstagramStories";
import ListIgStoryContentQueryT from "../types/graphql/ListInstagramStoriesQuery";
import { StaticIdsContext } from "../providers/StaticIdsProvider";

interface Props {
  project: Project;
  igStoriesPostFilterContext: React.Context<FilterContextT>;
  igStoriesColumnSetSelect: React.FC<{
    onOpenCustomPanel: () => void;
    width?: string | number;
  }>;
  columnSetType: ColumnSetType;
}

const IgStoriesPostListTableContainer: React.FC<Props> = ({
  project,
  igStoriesColumnSetSelect,
  igStoriesPostFilterContext,
  columnSetType,
}) => {
  const { callbacks, filterState } = React.useContext(
    igStoriesPostFilterContext
  );

  const {
    onChangePage,
    onChangePageSize,
    onBackPage,
    onNextPage,
    onChangePostOrder,
    onChangeMetricOrder,
  } = callbacks;
  const {
    currentPage,
    pageSize,
    metricOrderBy,
    postOrderBy,
    order,
  } = filterState;

  const { selectedSets } = React.useContext(MetricColumnSetsContext);
  const selectedSet = selectedSets[columnSetType];
  const columnSetName = selectedSet || "IG_STORIES";
  const custom = Boolean(selectedSet);

  const allColumnSetResult = useQuery<AllColumnSetQueryT>(ALL_COLUMN_SETS, {
    variables: {
      where: {
        publicationId: PublicationType.IG_STORY,
        name: columnSetName,
        custom,
      },
    },
  });

  const areColumnSetsLoaded = isDataAvailable(allColumnSetResult);
  const columnSetId =
    areColumnSetsLoaded && allColumnSetResult.data!.allColumnSets.length > 0
      ? allColumnSetResult.data!.allColumnSets[0].id
      : undefined;

  const staticIdsContext = React.useContext(StaticIdsContext);

  const wheresFilter = buildIgStoryContentFilter(
    filterState,
    project.idLegacy,
    columnSetId,
    staticIdsContext.brandedId
  );

  const orderBy = buildOrderByClause(metricOrderBy, postOrderBy, order);

  const listIgStoriesContentResult = useQuery<ListIgStoryContentQueryT>(
    LIST_INSTAGRAM_STORIES,
    {
      fetchPolicy: "network-only",
      variables: {
        offset: currentPage * pageSize,
        limit: pageSize,
        orderBy,
        ...wheresFilter,
      },
    }
  );

  let stories: Array<InstagramStoryContent> = [];
  let averages: Array<Average> = [];
  let count: number = 0;
  let totalPages: number = 0;
  const loading = listIgStoriesContentResult.loading;

  if (
    listIgStoriesContentResult.data &&
    Object.keys(listIgStoriesContentResult.data).length > 0
  ) {
    stories = listIgStoriesContentResult.data!.listInstagramStories.content;
    averages = listIgStoriesContentResult.data!.listInstagramStories
      .subAggregate.averages;
    count = listIgStoriesContentResult.data!.listInstagramStories.aggregate
      .count;
    totalPages = Math.ceil(count / pageSize);
  }

  if (loading && stories.length === 0) {
    return <PlaceholderPostList />;
  }

  if (stories.length === 0 && !loading) {
    return <NotFound>No post found! :(</NotFound>;
  }

  return (
    <IGStoriesListTable
      stories={stories}
      averages={averages}
      loading={loading}
      currentPage={currentPage}
      pageSize={pageSize}
      totalPages={totalPages}
      count={count}
      onChangePageSize={onChangePageSize}
      onChangePage={onChangePage}
      onBackPage={onBackPage}
      onNextPage={() => onNextPage(totalPages)}
      onChangePostOrder={onChangePostOrder}
      onChangeMetricOrder={onChangeMetricOrder}
      FilterContext={igStoriesPostFilterContext}
      StoriesColumnSetSelect={igStoriesColumnSetSelect}
      exportQuery={EXPORT_INSTAGRAM_STORIES}
      columnSetType={columnSetType}
      project={project}
      wheresFilter={wheresFilter}
      orderBy={postOrderBy || metricOrderBy}
      order={order}
    />
  );
};

export { IgStoriesPostListTableContainer };
