import styled from "styled-components";
import { scrollbarVerticalStyle } from "../../ui/ScrollBarStyle";

const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  & > div {
    width: 46%;
    margin-bottom: 10px;
  }
  @media (max-width: 480px) {
    & > div {
      width: 100%;
    }
  }
`;

const PostInfo = styled.div`
  display: flex;
  margin-bottom: 25px;
`;

const MessageFbGroupWrapper = styled.div`
  max-height: 130px;
  overflow-y: overlay;
  padding-right: 8px;

  ${scrollbarVerticalStyle};
`;

const SingleCheckbox = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0px;
  flex-wrap: wrap;
  & > div {
    padding: 10px 0px;
  }
  @media (max-width: 480px) {
    & > div {
      padding: 15px 0px;
      margin-right: 40px;
    }
  }
`;

export { SelectWrapper, PostInfo, SingleCheckbox, CheckboxWrapper, MessageFbGroupWrapper };
