import { DateRange } from "./../types/DateRange";
import { FixedDateRange } from "../types/FixedDateRange";
import moment from "moment";
import { DateRangeFilter } from "../types/Filter";

moment.defaultFormat = "YYYY-MM-DDTHH:mm:ss";

export const getDateRange = (fixedDateRange: FixedDateRange): { from: string; to: string } => {
  switch (fixedDateRange) {
    case FixedDateRange.LAST_WEEK:
      return {
        from: moment()
          .subtract(1, "weeks")
          .startOf("isoWeek")
          .format(),
        to: moment()
          .subtract(1, "weeks")
          .endOf("isoWeek")
          .format()
      };
    case FixedDateRange.LAST_MONTH:
      return {
        from: moment()
          .subtract(1, "months")
          .startOf("month")
          .format(),
        to: moment()
          .subtract(1, "months")
          .endOf("month")
          .format()
      };
    case FixedDateRange.LAST_28_DAYS:
      return {
        from: moment()
          .subtract(28, "days")
          .startOf("day")
          .format(),
        to: moment()
          .endOf("day")
          .format()
      };
    case FixedDateRange.LAST_YEAR:
      return {
        from: moment()
          .subtract(1, "years")
          .startOf("day")
          .format(),
        to: moment()
          .endOf("day")
          .format()
      };
    case FixedDateRange.LAST_24H:
    default:
      return {
        from: moment()
          .subtract(1, "days")
          .startOf("day")
          .format(),
        to: moment()
          .endOf("day")
          .format()
      };
  }
};

export const getCustomDateRange = (dateRange: DateRange): DateRangeFilter => {
  let fromDate: string | null = null;
  let toDate: string | null = null;
  if (dateRange) {
    if (dateRange !== FixedDateRange.ANY_TIME) {
      if (typeof dateRange === "object") {
        fromDate = dateRange.from
          ? moment(dateRange.from)
              .startOf("day")
              .format()
          : null;
        toDate = dateRange.to
          ? moment(dateRange.to)
              .endOf("day")
              .format()
          : null;
      } else {
        const range = getDateRange(dateRange);
        fromDate = range.from;
        toDate = range.to;
      }
    }
  }

  return { fromDate, toDate };
};
