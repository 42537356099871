import * as React from "react";
import { DragDropContext, DropResult, Droppable, Draggable } from "react-beautiful-dnd";
import { Item } from ".";

interface Props {
  updateOrder: (items: Array<Item>) => void;
  selectedMetrics: Array<Item>;
}

const reorder = (metrics: Array<Item>, startIndex: number, endIndex: number) => {
  const result = Array.from(metrics);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const onDragEnd = (updateOrder: (items: Array<Item>) => void, items: Array<Item>) => (result: DropResult) => {
  if (!result.destination) {
    return;
  }

  const reorderedItems = reorder(items, result.source.index, result.destination.index);
  updateOrder(reorderedItems);
};

const DragDropMetrics: React.FC<Props> = ({ updateOrder, selectedMetrics }) => {
  return (
    <DragDropContext onDragEnd={onDragEnd(updateOrder, selectedMetrics)}>
      <Droppable droppableId="droppable" direction="vertical">
        {(droppableProvided) => (
          <div ref={droppableProvided.innerRef as any} {...droppableProvided.droppableProps}>
            {selectedMetrics.map((m, index) => {
              return (
                <Draggable key={m.id} draggableId={String(m.id)} index={index}>
                  {(provided) => (
                    <div ref={provided.innerRef as any} {...provided.draggableProps} {...provided.dragHandleProps}>
                      {m.content}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {droppableProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DragDropMetrics;
