import * as React from "react";

import {
  Select,
  Typography,
  CheckBox,
  ButtonGroup,
  DividerSection,
} from "@freeda/react-components";

import { MetadataCategory } from "../../../types/MetadataCategory";
import { Omit } from "../../../types/utils/Omit";
import { Metadata } from "../../../types/Metadata";
import { getPostMetadataSpec } from "../../../ui-specs/specs";
import { SingleCheckbox } from "./styles";
import { PostType } from "../../../types/PostType";
import { includes, capitalize } from "lodash";
import { trimAndReplaceSpaceToUpper } from "../../../utils/trimAndReplaceSpaceToUpper";
import { Platform } from "../../../types/Platform";
import { Distribution } from "../../../types/Distribution";

type MetadataWithoutCategory = Omit<Metadata, "category">;

export type MetadataByCategory = {
  category: Omit<MetadataCategory, "area">;
  metadata: Array<MetadataWithoutCategory>;
};

export const getMetadataBooleanDependencies = (
  postType: PostType,
  booleanMetadataId: string,
  booleanMetadata: Array<MetadataWithoutCategory>,
  platform: Platform
): Array<string> => {
  const bm = booleanMetadata.find((bm) => bm.id === booleanMetadataId)!;
  const conditionalCategories = getPostMetadataSpec(postType, platform)
    .conditionalCategories;
  if (conditionalCategories) {
    const dependentMetadataCategories = conditionalCategories.find(
      (c) => c.which === trimAndReplaceSpaceToUpper(bm.label)
    );
    return dependentMetadataCategories
      ? dependentMetadataCategories.makeVisible.map((mv) => mv.id)
      : [];
  }

  return [];
};

export const getMetadataDependencies = (
  postType: PostType,
  categoryId: string,
  platform: Platform
): Array<string> =>
  getPostMetadataSpec(postType, platform)
    .categories.filter(
      (c) => c.takeMetadataFrom && c.takeMetadataFrom === categoryId
    )
    .map((c) => c.id);

export const getVisibilityConditions = (
  postType: PostType,
  platform: Platform,
  booleanMetadata: Array<MetadataWithoutCategory>,
  booleanMetadataValues: Array<{ metadataId: string; value: boolean }>
) => {
  const conditionalCategories =
    getPostMetadataSpec(postType, platform).conditionalCategories || [];
  return conditionalCategories.map((cc) => {
    const booleanValue = booleanMetadata.find(
      (bm) =>
        trimAndReplaceSpaceToUpper(bm.label) ===
        trimAndReplaceSpaceToUpper(cc.which)
    )!;

    return {
      value: booleanMetadataValues.find(
        (bm) => bm.metadataId === booleanValue.id
      )!.value,
      makeVisible: cc.makeVisible.map(({ id }) => id),
      hide: cc.hide.map(({ id }) => id),
    };
  });
};

export const buildDropdownMetadata = (
  postType: PostType,
  platform: Platform,
  groupedMetadata: Array<MetadataByCategory>,
  metadataValues: Array<{
    categoryId: string;
    projects: Array<{ id: string }>;
    value: string | null;
  }>,
  visibilityConditions: Array<{
    value: boolean;
    makeVisible: Array<string>;
    hide: Array<string>;
  }>,
  changeSelection: (option: any, groupedMetadata: MetadataByCategory) => void
) => {
  return groupedMetadata.map((gm) => {
    const selectedMetadataId = metadataValues.find(
      (c) => c.categoryId === gm.category.id
    )!.value;

    const selectedMetadata = gm.metadata.find(
      (m) => m.id === selectedMetadataId
    );

    const hasVisibilityCondition = visibilityConditions.find((condition) => {
      return (
        condition.makeVisible
          .concat(condition.hide)
          .findIndex((x) => x === gm.category.id) >= 0
      );
    });

    const shouldBeHidden =
      visibilityConditions.find((condition) => {
        return condition.hide.findIndex((x) => x === gm.category.id) >= 0;
      }) !== undefined;

    const shouldTakeFromParent = getPostMetadataSpec(
      postType,
      platform
    ).categories.find((c) => c.id === gm.category.id)!.takeMetadataFrom;

    const buildForSelect = (l: { id: string; label: string }) => ({
      label: l.label,
      value: l.id,
    });

    let options: Array<{ label: string; value: string }> = gm.metadata.map(
      buildForSelect
    );

    if (gm.category.id === "CAMPAIGN" && shouldTakeFromParent) {
      const parentId = metadataValues.find(
        (mv) => mv.categoryId === shouldTakeFromParent
      )!.value;
      if (!parentId) {
        options = [];
      } else {
        debugger;
        options = gm.metadata
          .filter(
            (m) =>
              m.visibleFor.find((vf) => {
                return vf.id === parentId;
              }) !== undefined
          )
          .map(buildForSelect);
      }
    } else {
      options = gm.metadata.map(buildForSelect);
    }

    const isVisible =
      (hasVisibilityCondition &&
        ((hasVisibilityCondition.value && !shouldBeHidden) ||
          (!hasVisibilityCondition.value && shouldBeHidden))) ||
      !hasVisibilityCondition;

    return isVisible ? (
      <div key={gm.category.id}>
        <Select
          onChange={(option: any) => changeSelection(option, gm)}
          label={gm.category.label}
          options={options}
          value={
            selectedMetadata
              ? { label: selectedMetadata.label, value: selectedMetadata.id }
              : null
          }
        />
      </div>
    ) : null;
  });
};

export const buildCheckboxMetadata = (
  postType: PostType,
  platform: Platform,
  booleanMetadata: Array<MetadataWithoutCategory>,
  booleanMetadataState: Array<{ metadataId: string; value: boolean }>,
  toggleCheckbox: (bm: MetadataWithoutCategory) => void
) => {
  const booleanMetadataSpec = getPostMetadataSpec(postType, platform)
    .booleanMetadata;

  return booleanMetadata
    .filter((bm) =>
      includes(booleanMetadataSpec, trimAndReplaceSpaceToUpper(bm.label))
    )
    .map((bm) => {
      const value = booleanMetadataState.find((b) => b.metadataId === bm.id)!
        .value;

      return (
        <SingleCheckbox key={bm.id}>
          <Typography variantName="label" style={{ marginRight: 7 }}>
            {bm.label}
          </Typography>
          <CheckBox
            checked={Boolean(value)}
            onClick={() => toggleCheckbox(bm)}
          />
        </SingleCheckbox>
      );
    });
};

export const buildToogleDistribution = (
  postType: PostType,
  platform: Platform,
  selectedDistribution: Array<Distribution>,
  onSelectedOption: (options: Array<Distribution>) => void
) => {
  const distributionMetadataSpec = getPostMetadataSpec(postType, platform)
    .distribution;
  if (distributionMetadataSpec) {
    return (
      <>
        <ButtonGroup
          label="Distribution"
          styleWrapper={{ margin: "15px 0px" }}
          options={Object.keys(Distribution).map((d) => ({
            value: d,
            label: capitalize(d),
          }))}
          selectedOptions={selectedDistribution.map((d) => ({
            value: d,
            label: capitalize(d),
          }))}
          isMulti
          onSelectedOption={(options) => {}}
          disabled={true}
        />
        <DividerSection />
      </>
    );
  }
  return null;
};
