import { upperCase } from "lodash";
import { Distribution } from "../types/Distribution";

export const idByDistribution = {
  [Distribution.IT]: process.env.REACT_APP_METADATA_IT!,
  [Distribution.ES]: process.env.REACT_APP_METADATA_ES!,
  [Distribution.UK]: process.env.REACT_APP_METADATA_UK!
};

export const distributionIdsByDistribution = (distribution: Array<Distribution>): Array<string> => {
  return distribution.map(d => idByDistribution[upperCase(d)]);
};

export const distributionIdByDistribution = (distribution: Distribution): string => {
  return idByDistribution[distribution];
};
