import gql from "graphql-tag";

const LIST_FMK_METRIC_OBJECTIVES = gql`
  query listMetricObjectives($where: MetricObjectiveWhere!) {
    listMetricObjectives(where: $where) {
      id
    }
  }
`;

export { LIST_FMK_METRIC_OBJECTIVES };
