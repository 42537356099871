import * as React from "react";
import { TransitionStatus } from "react-transition-group/Transition";
import {
  ButtonIcon,
  Icon,
  Typography,
  TitleInput,
  Tooltip,
  Input,
} from "@freeda/react-components";
import {
  sortBy,
  indexOf,
  flatMap,
  flatten,
  differenceBy,
  includes,
} from "lodash";
import Media from "react-media";
import { CSSTransition } from "react-transition-group";

import { Dialogable } from "../../../types/Dialogable";
import { BackgroundLayer, WrapperLateralPanel } from "../../ui/LateralPanel";
import {
  InfoSection,
  InfoRow,
  MetricsWrapper,
  ButtonStyled,
  ImgFrame,
  FramesWrapper,
  InputWrapperStyled,
  ErrorCircle,
} from "./styles";
import { InstagramContent } from "../../../types/InstagramContent";
import { Colors } from "@freeda/react-components/lib/theme";
import { InstagramStoryContent } from "../../../types/InstagramStoryContent";
import { Metric } from "../../../types/Metric";
import { getStoryTitleAvailable } from "../../../utils/getStoryTitleAvailable";
import { ErrorWrapper } from "../../ui/ErrorWrapper";
import client from "../../../apollo/client";
import { useKeyUp } from "../../../hooks/useKeyUp";
import { BackButton } from "../../ui/BackButton";

type CustomMetric = { name: string; value: string };
type CustomMetricWithValidation = CustomMetric & { isValid: boolean | null };

type Props = Dialogable & {
  isDisabled:Boolean,
  transitionStatus: TransitionStatus;
  publisherName: string;
  selectedStory: InstagramStoryContent;
  availableCustomMetrics: Array<Metric>;
  customMetricsValues: Array<{
    frameId: string;
    customMetrics: Array<CustomMetric>;
  }>;
  loadingUpdate: boolean;
  onSave: (
    title: string,
    frameMetrics: Array<{
      frameId: string;
      name: string;
      value: number | null;
    }>,
    removedFrames?: Array<string>
  ) => void;
};

interface State {
  titleStory: string;
  availableStoryTitle: boolean | null;
  selectedFrame: InstagramContent;
  removedFrames: Array<string>;
  permalink?: string;
  metricValues: Array<{
    frameId: string;
    customMetrics: Array<CustomMetricWithValidation>;
  }>;
}

const EditStory: React.FC<Props> = (props) => {
  const isValidValueRef = React.useRef<boolean | null>(null);
  const initialOrderedFrames = sortBy(
    props.selectedStory.frames,
    "publicationDate"
  );
  const { customMetricsValues, availableCustomMetrics , isDisabled} = props;
  const placeholderStory = require("../../../assets/placeholderStory.jpg");

  console.log(isDisabled)

  const [state, setState] = React.useState<State>({
    titleStory: props.selectedStory.title,
    availableStoryTitle: null,
    selectedFrame: initialOrderedFrames[0],
    removedFrames: [],
    metricValues: customMetricsValues.map((cmv) => {
      const withMissingMetricsAndValidation = availableCustomMetrics.map(
        (m) => {
          const metricWithValue = cmv.customMetrics.find(
            (cm) => cm.name === m.name
          );
          return metricWithValue
            ? { ...metricWithValue, isValid: null }
            : { name: m.name, value: "", isValid: null };
        }
      );

      return {
        ...cmv,
        customMetrics: withMissingMetricsAndValidation,
      };
    }),
  });

  useKeyUp(props.onClose);

  const onValueChange = (
    contentId: string,
    customMetric: CustomMetricWithValidation
  ) => {
    setState((state) => ({
      ...state,
      metricValues: state.metricValues.map((mv) => {
        if (mv.frameId === contentId) {
          return {
            ...mv,
            customMetrics: mv.customMetrics.map((cm) => {
              if (cm.name === customMetric.name) {
                return customMetric;
              }
              return cm;
            }),
          };
        }
        return mv;
      }),
    }));
  };

  const buildMetricsInputs = () => {
    const { selectedFrame, metricValues } = state;
    const currentMetricFrameValue = metricValues.find(
      (m) => m.frameId === selectedFrame.id.contentId
    );

    if (!currentMetricFrameValue) {
      return null;
    }

    return props.availableCustomMetrics.map((m) => {
      const metricWithValue = currentMetricFrameValue.customMetrics.find(
        (cm) => cm.name === m.name
      );

      if (!metricWithValue) {
        return null;
      }

      const metricValue = metricWithValue.value;
      const isValid = metricWithValue.isValid;

      return (
        <div key={m.id}>
          <Typography variantName="label">{m.label}</Typography>
          <InputWrapperStyled
            className={String(metricValue).length > 0 ? "selectedInput" : ""}
          >
            <Input
              placeholder="Insert value of metric"
              value={metricValue}
              errorMessage="This value isn't number!"
              isValid={isValid === true || isValid === null}
              onChange={(e) =>
                onValueChange(selectedFrame.id.contentId, {
                  name: m.name,
                  value: e.target.value,
                  isValid: null,
                })
              }
            />
          </InputWrapperStyled>
        </div>
      );
    });
  };

  const onSelectedFrame = (
    frames: Array<InstagramContent>,
    selectedFrame: InstagramContent
  ) => {
    const newSelectedFrame = frames.find(
      (frame) => frame.id.contentId === selectedFrame.id.contentId
    );

    setState((state) => ({
      ...state,
      selectedFrame: newSelectedFrame ? newSelectedFrame : state.selectedFrame,
    }));
  };

  const onDeleteFrame = (removedFrame: InstagramContent) => {
    const { selectedStory } = props;

    const removedFrameId = removedFrame.id.contentId;
    const removedFrames = [...state.removedFrames, removedFrameId];

    // all frames of selected story
    const initialAllFrames = sortBy(selectedStory.frames, "publicationDate");

    // id frames of all frames without this.state.removedFrames
    const idFrames = differenceBy(
      initialAllFrames.map((f) => f.id.contentId),
      state.removedFrames
    );

    // index of current removed frame, calculated on idFrames
    const indexRemovedFrame = indexOf(idFrames, removedFrameId);

    // all id frames without all removed frames (updated with current removed frame)
    const idFramesWithoutRemovedFrames = differenceBy(
      initialAllFrames.map((f) => f.id.contentId),
      removedFrames
    );

    // all frames without all removed frames
    const currentFrames = initialAllFrames.filter((f) =>
      includes(idFramesWithoutRemovedFrames, f.id.contentId)
    );

    // position of selected frames calculated with length of frames without removed frames
    const indexPosition =
      indexRemovedFrame === idFrames.length - 1
        ? idFramesWithoutRemovedFrames.length - 1
        : indexRemovedFrame;

    setState((state) => ({
      ...state,
      removedFrames,
      selectedFrame: currentFrames[indexPosition],
    }));
  };

  React.useEffect(() => {
    const isValidValue = flatMap(
      state.metricValues.map((mv) => mv.customMetrics)
    ).map((cm) => cm.isValid);

    const isValid = isValidValue.reduce((acc, next) => {
      return acc && next;
    }, true);

    isValidValueRef.current = isValid;
  }, [state.metricValues]);

  const onClickSave = async () => {
    const { publisherName, selectedStory } = props;

    const oldStoryTitle = selectedStory.title;
    const isTitleAvailable = await getStoryTitleAvailable(
      client,
      state.titleStory,
      publisherName,
      oldStoryTitle
    );

    setState((state) => {
      const metricValues = state.metricValues.map((mv) => {
        return {
          ...mv,
          customMetrics: mv.customMetrics.map((cm) => {
            const isValid =
              RegExp("^(?=[0-9])([0-9]{0,10}(?:,[0-9]{1,2})?)$").test(
                cm.value
              ) || cm.value.length === 0;
            return {
              name: cm.name,
              value: cm.value,
              isValid,
            };
          }),
        };
      });

      const isValidValue = flatMap(
        metricValues.map((mv) => mv.customMetrics)
      ).map((cm) => cm.isValid);

      const isValid = isValidValue.reduce((acc, next) => {
        return acc && next;
      }, true);

      isValidValueRef.current = isValid;

      return {
        ...state,
        metricValues,
        availableStoryTitle: isTitleAvailable,
      };
    });
  };

  React.useEffect(() => {
    const save = async () => {
      const frameMetrics = flatten(
        state.metricValues.map((mv) =>
          mv.customMetrics.map((m) => ({
            frameId: mv.frameId,
            name: m.name,
            value: m.value.length > 0 ? Number(m.value) : null,
          }))
        )
      );

      await props.onSave(state.titleStory, frameMetrics, state.removedFrames);
    };

    const isValidValue = flatMap(
      state.metricValues.map((mv) => mv.customMetrics)
    ).map((cm) => cm.isValid);

    const isValid = isValidValue.reduce((acc, next) => {
      return acc && next;
    }, true);

    if (state.availableStoryTitle && isValid) {
      save();
    }
  }, [state.availableStoryTitle, isValidValueRef.current]);

  const frames = props.selectedStory.frames;

  const orderedFrames = sortBy(frames, "publicationDate").map((of) => {
    const frameWithMetricValues = state.metricValues.find(
      (mv) => mv.frameId === of.id.contentId
    );

    if (frameWithMetricValues) {
      const isValidValue = flatMap(
        frameWithMetricValues.customMetrics.map((cm) => cm.isValid)
      );

      const isValid = isValidValue.reduce((acc, next) => {
        return acc && next;
      }, true);

      return {
        ...of,
        isValid,
      };
    }
    return {
      ...of,
      isValid: null,
    };
  });

  const framesWithoutRemoved = orderedFrames.filter(
    (f) => !state.removedFrames.find((rfId) => f.id.contentId === rfId)
  );

  const { open, onClose, transitionStatus, loadingUpdate } = props;

  return (
    <Media query={{ maxWidth: 768 }}>
      {(matches: boolean) => (
        <>
          <BackgroundLayer
            open={open}
            onClick={onClose}
            className={`background-${transitionStatus}`}
          />
          <WrapperLateralPanel
            open={open}
            className={`wrapper-${transitionStatus}`}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <BackButton onClick={onClose} />
              <ButtonIcon
                iconName="save"
                iconColor={Colors.ROSSINO}
                onClick={onClickSave}
                loading={loadingUpdate}
              />
            </div>
            <InfoSection
              style={{ height: !matches ? "calc(100% - 50px)" : "100%" }}
            >
              <div>
                <InfoRow style={{ marginTop: 0 }}>
                  <TitleInput
                    label="story title"
                    placeholder="Title"
                    value={state.titleStory}
                    onChange={(e) =>
                      setState({
                        ...state,
                        titleStory: e.currentTarget.value,
                        availableStoryTitle: null,
                      })
                    }
                  />
                </InfoRow>
                <CSSTransition
                  in={Boolean(
                    !state.availableStoryTitle &&
                      state.availableStoryTitle !== null
                  )}
                  timeout={{ enter: 300, exit: 500 }}
                  classNames=""
                >
                  {(status) => (
                    <ErrorWrapper
                      className={`wrapper-${status}`}
                      style={{ position: "relative", top: -15 }}
                    >
                      <Typography variantName="error" style={{ marginTop: 8 }}>
                        This title isn't available, it's already exists.
                      </Typography>
                    </ErrorWrapper>
                  )}
                </CSSTransition>
                <InfoRow style={{ marginTop: 5, marginBottom: 10 }}>
                  <div style={{ width: "100%" }}>
                    <Typography
                      variantName="label"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Frames
                    </Typography>
                    <FramesWrapper style={{ flexWrap: "wrap", marginTop: 10 }}>
                      {framesWithoutRemoved.map((f, i) => {
                        const isSelected = Boolean(
                          state.selectedFrame.id.contentId === f.id.contentId
                        );
                        return (
                          <div
                            key={f.id.contentId}
                            onClick={() =>
                              onSelectedFrame(framesWithoutRemoved, f)
                            }
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              cursor: "pointer",
                              marginBottom: 15,
                              marginRight: 8,
                              position: "relative",
                            }}
                          >
                            {f.isValid === false && (
                              <ErrorCircle>!</ErrorCircle>
                            )}
                            {(
                              <ImgFrame
                                src={
                                  f.thumbnailUrlBackup ? f.thumbnailUrlBackup
                                      : f.thumbnailUrl ?  f.thumbnailUrl
                                        : f.mediaUrl!

                                      
                                }
                                selected={isSelected}
                                onError={(e) => (e.currentTarget.src = f.originalThumbnailUrl ? f.originalThumbnailUrl : placeholderStory)}
                              />

                              
                            )}
                            <Typography
                              variantName="label"
                              style={{ marginTop: 5 }}
                            >
                              <>{i + 1}</>
                            </Typography>
                          </div>
                        );
                      })}
                    </FramesWrapper>
                  </div>
                </InfoRow>
                
                {!isDisabled &&
                <InfoRow style={{ marginBottom: 30, marginTop: 0 }}>
                  <ButtonStyled
                    flat
                    style={{ marginRight: 15 }}
                    onClick={() => onDeleteFrame(state.selectedFrame)}
                    disabled={framesWithoutRemoved.length === 1}
                    icon={{
                      iconName: "delete",
                      position: "left",
                      iconColor:
                        framesWithoutRemoved.length === 1
                          ? "#C3C3C3"
                          : Colors.GRIGIO,
                    }}
                  >
                    Delete frame
                  </ButtonStyled>
                  <Tooltip
                    referenceChildren={
                      <Icon iconName="info" iconColor={Colors.GRIGINOlight} />
                    }
                    tooltipChildren={
                      <div style={{ width: 150 }}>
                        If you remove the frame, you will not be able to recover
                        this frame.
                      </div>
                    }
                    referenceStyle={{ display: "flex", alignItems: "center" }}
                    position="right"
                  />
                </InfoRow>
                }


                <InfoRow style={{ width: "100%" }}>
                  <MetricsWrapper>{buildMetricsInputs()}</MetricsWrapper>
                </InfoRow>
              </div>
            </InfoSection>
          </WrapperLateralPanel>
        </>
      )}
    </Media>
  );
};

export default EditStory;
