import styled from "styled-components";
import { scrollbarVerticalStyle } from "../../ui/ScrollBarStyle";
import { CheckBox } from "@freeda/react-components";

const MetricsWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  height: calc(100vh - 65px);
  position: relative;
  top: -1px;
  height: calc(100vh - 165px);
`;

const MetricsLeftWrapper = styled.div`
  padding: 25px 0px;
  margin-right: 10px;
  padding-left: 35px;
  box-sizing: border-box;
  overflow-y: overlay;
  height: calc(100vh - 160px);

  & > div > div {
    padding-bottom: 20px;
  }

  ${scrollbarVerticalStyle};
`;

const OrderMetrics = styled.div`
  padding: 25px;
  box-sizing: border-box;
  background: #f8f8f8;
  box-shadow: 0 0px 10px rgba(0, 0, 0, 0.1), 0 0px 10px rgba(0, 0, 0, 0.1);
  overflow-y: overlay;
  height: calc(100vh - 160px);
`;

const SelectedWrapper = styled.div`
  height: calc(100vh - 325px);
  overflow-y: overlay;
  margin: 20px 0px;
  position: relative;
  left: -10px;
  ${scrollbarVerticalStyle}
`;

const SetNameWrapper = styled.div`
  background: #ffffff;
  padding: 15px 20px;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px, rgba(0, 0, 0, 0.1) 0px 0px 10px;
`;

const OrderIconWrapper = styled.div`
  cursor: grab;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 35px;
  width: 35px;
  &:hover {
    background: rgba(0, 0, 0, 0.08) radial-gradient(circle, transparent 1%, rgba(0, 0, 0, 0.08) 1%) center/15000%;
  }
`;

const CheckboxStyled = styled(CheckBox)`
  && {
    & > label {
      text-transform: capitalize;
      margin-left: 15px;
      white-space: pre-wrap;
    }
  }
`;

export {
  OrderMetrics,
  SelectedWrapper,
  SetNameWrapper,
  MetricsWrapper,
  MetricsLeftWrapper,
  OrderIconWrapper,
  CheckboxStyled
};
