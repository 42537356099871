import gql from "graphql-tag";

const LIST_FMK_METRIC_POSITIONS = gql`
  query listMetricPositions($where: MetricPositionWhere!) {
    listMetricPositions(where: $where) {
      id
    }
  }
`;

export { LIST_FMK_METRIC_POSITIONS };
