import * as React from "react";
import styled from "styled-components";
import { NavLink, NavLinkProps } from "react-router-dom";

const StyledNavLink = styled(NavLink)<NavLinkProps>`
  && {
    text-decoration: none;
    width: 100%;

    &.active {
      && {
        #color-fb-icon,
        #color-fbg-icon,
        #color-ig-icon,
        #color-igStory-icon,
        #color-yt-icon,
        #color-dashboard-icon {
          fill: #ffffff;
        }
      }
    }
  }
`;

const RouterNavLink: React.FC<NavLinkProps> = props => <StyledNavLink {...props as any} />;

export { RouterNavLink };
