import * as React from "react";

import { ButtonGroup } from "@freeda/react-components";
import { FieldWrapper } from "../../ui/FieldWrapper";
import { SelectCategory } from "../../../container/SelectCategory";
import { TransitionStatus } from "react-transition-group/Transition";
import { FilterWrapper } from "../../ui/AdvancedFilterWrapper";
import { AutocompleteProject } from "../../../container/AutocompleteProject";
import Media from "react-media";

interface Props {
  metadataActiveOptions: Array<{ value: string; label: string }>;
  onChangeActiveOptions: (option: Array<{ value: string; label: string }>) => void;
  selectedCategoryId: string | null;
  onCategorySelection: (categoryId: string) => void;
  selectedProjects: Array<{ value: string; label: string }>;
  onChangeProject: (project: { value: string; label: string }) => void;
  transitionStatus?: TransitionStatus;
}

const AdvancedFilterMetadata: React.FC<Props> = ({
  metadataActiveOptions,
  onChangeActiveOptions,
  selectedCategoryId,
  onCategorySelection,
  selectedProjects,
  onChangeProject,
  transitionStatus
}) => {
  const isMobile = Boolean(transitionStatus);

  return (
    <FilterWrapper id="filterWrapper" className={`filterWrapper-${transitionStatus}`}>
      <FieldWrapper style={{ marginRight: 30 }}>
        <SelectCategory
          onCategorySelection={categoryId => onCategorySelection(categoryId)}
          selectedCategoryId={selectedCategoryId}
          width={200}
        />
      </FieldWrapper>
      <FieldWrapper style={{ marginRight: 30 }}>
        <ButtonGroup
          options={[
            { value: "all", label: "all" },
            { value: "active", label: "active" },
            { value: "inactive", label: "inactive" }
          ]}
          selectedOptions={metadataActiveOptions}
          label="Active"
          onSelectedOption={options => onChangeActiveOptions(options)}
        />
      </FieldWrapper>
      <Media query={{ maxWidth: 1048 }}>
        {(matches: boolean) => {
          const marginTop = matches ? 15 : 0;
          const marginTopFilter = isMobile ? 30 : marginTop;
          return (
            <div
              style={{
                display: isMobile ? "block" : "flex",
                alignItems: "flex-end",
                marginTop: marginTopFilter,
                width: isMobile ? "100%" : ""
              }}
            >
              <AutocompleteProject
                selectedProjects={selectedProjects}
                onChangeProject={onChangeProject}
                lateralChip={!isMobile}
                widthWrapper={isMobile ? "100%" : "160px"}
              />
            </div>
          );
        }}
      </Media>
    </FilterWrapper>
  );
};

export { AdvancedFilterMetadata };
