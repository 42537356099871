import * as React from "react";
import { RightSidebar, WrapperLateralPanelStyled, WrapperChart } from "./styles";
import { ButtonIcon, Typography, Select } from "@freeda/react-components";
import { TransitionStatus } from "react-transition-group/Transition";
import { Linechart, ChartData } from "../../ui/Charts/Linechart";
import { Colors } from "@freeda/react-components/lib/theme";
import { FieldWrapper } from "../../ui/FieldWrapper";
import { InstagramContent } from "../../../types/InstagramContent";
import { PositionalMetric } from "../../../types/PositionalMetric";
import { Dialogable } from "../../../types/Dialogable";
import { useKeyUp } from "../../../hooks/useKeyUp";

type Props = Dialogable & {
  onToggleShow: () => void;
  transitionStatus: TransitionStatus;
  maxWidth: number;
  frames: Array<InstagramContent>;
  positionalMetrics: Array<PositionalMetric>;
};

type State = {
  chartData: Array<{ name: string; chartValues: Array<ChartData> }>;
  selectedMetric: string;
};

const ChartPanel: React.FC<Props> = ({
  frames,
  positionalMetrics,
  open,
  onClose,
  maxWidth,
  transitionStatus,
  onToggleShow,
}) => {
  const framesMeasuredMetrics = frames.map((f) => f.measuredMetrics);

  const firstPositionalMetricName = positionalMetrics[0].name;

  const chartDataState = positionalMetrics.map((pm) => {
    const chartValues = framesMeasuredMetrics.map((fmm, i) => {
      const measuredMetric = fmm.find((fm) => fm.id.name === pm.name);
      if (!measuredMetric) {
        return {
          label: `Frame ${i + 1}`,
          valueY: 0,
          conversionUnit: null,
        };
      }
      return {
        label: `Frame ${i + 1}`,
        valueY: measuredMetric.value,
        conversionUnit: measuredMetric.conversionUnit,
      };
    });

    return {
      name: pm.name,
      chartValues,
    };
  });

  const [state, setState] = React.useState<State>({
    chartData: chartDataState,
    selectedMetric: firstPositionalMetricName,
  });

  useKeyUp(onClose);

  const onChangeMetric = (metric: string) => {
    setState({ ...state, selectedMetric: metric });
  };

  const rightTransition = maxWidth < 950 ? `${maxWidth - 100}px` : "900px";

  const optionsSelect = positionalMetrics.map((pm) => ({
    value: pm.name,
    label: pm.label,
  }));

  const dataArray = state.chartData.find((cd) => cd.name === state.selectedMetric);
  const selectedLabel = positionalMetrics.find((pm) => pm.name === state.selectedMetric);
  const label = selectedLabel ? selectedLabel.label : "";

  return (
    <WrapperLateralPanelStyled
      open={open}
      className={`wrapper-${transitionStatus}`}
      style={{ zIndex: 3002, height: "calc(100vh - 65px)", top: 65, boxShadow: "" }}
      viewportWidth={maxWidth}
      width={rightTransition}
    >
      <>
        <RightSidebar onClick={onToggleShow}>
          <ButtonIcon
            iconName="arrow-left"
            iconColor="#ffffff"
            style={{ marginBottom: 20, transform: open ? "rotate(180deg)" : "", transition: "transform 0.4s" }}
          />
          <ButtonIcon iconName="chart" iconColor="#ffffff" />
        </RightSidebar>
        <WrapperChart>
          {open && (
            <>
              <Typography variantName="subtitle" style={{ color: Colors.PURPLE, marginBottom: 20 }}>
                Frames performance
              </Typography>
              <FieldWrapper style={{ display: "flex", marginBottom: 25 }}>
                <Select
                  style={{ width: maxWidth > 768 ? 200 : "100%" }}
                  label="metrics"
                  isSearchable={false}
                  isClearable={false}
                  placeholder="Select metric"
                  onChange={(metric: any) => onChangeMetric(metric ? metric.value : null)}
                  options={optionsSelect}
                  value={state.selectedMetric ? { label, value: state.selectedMetric } : null}
                />
              </FieldWrapper>
              {dataArray ? (
                <Linechart dataArray={dataArray.chartValues} />
              ) : (
                <span>There aren't values of this metric</span>
              )}
            </>
          )}
        </WrapperChart>
      </>
    </WrapperLateralPanelStyled>
  );
};

export { ChartPanel };
