import * as React from "react";
import Dropzone from "react-dropzone";
import { DragDropZoneWrapper } from "./styles";
import { Typography } from "@freeda/react-components";
import { Colors } from "@freeda/react-components/lib/theme";
import { getErrorMatchColumns } from "./specsCSVColumn";
import { NavLink } from "react-router-dom";

interface Props {
  onChangeSelectedFile: (file: File) => void;
  onChangeValidFileName: (isValid: boolean) => void;
  onChangeColumnsErrorMatch: (errors: Array<JSX.Element>) => void;
}

const DragDropArea: React.FC<Props> = ({ onChangeSelectedFile, onChangeValidFileName, onChangeColumnsErrorMatch }) => {
  const onDrop = React.useCallback((files: Array<File>) => {
    const file = files[0];
    onChangeSelectedFile(file);

    const regex = new RegExp(`^([0-9]{4})-([0-9]{2})-([0-9]{2})-([0-9]{2})-([0-9]{2})-([0-9]{2})((-CES?T.*)|(-BST.*)|(-GMT.*))$`);
    const isValidFileName = regex.test(file.name);
    onChangeValidFileName(isValidFileName);

    const reader = new FileReader();
    reader.readAsText(file);

    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");

    reader.onload = () => {
      const result = reader.result;
      if (result && typeof result === "string") {
        const firstRow: string = result.split("\n")[0];
        const columnsTitle: Array<string> = firstRow.split(",").map((c) => c.trim());

        if (isValidFileName) {
          const errorMessages = getErrorMatchColumns(columnsTitle);
          onChangeColumnsErrorMatch(errorMessages);
        }
      }
    };
  }, []);

  return (
    <>
      <Dropzone accept="text/csv" onDrop={onDrop} multiple={false}>
        {(props) => {
          const { getRootProps, getInputProps, isDragAccept, isDragReject, isDragActive, draggedFiles } = props;

          const dragMultipleFiles = draggedFiles.length > 1;

          return (
            <DragDropZoneWrapper {...getRootProps()} isDragDrop>
              <input {...getInputProps()} accept="text/csv" />
              {(isDragAccept || !isDragActive) && (
                <>
                  <Typography
                    variantName="subtitle"
                    textColor={Colors.GRIGINO}
                    style={{ fontWeight: 500, marginBottom: 4 }}
                  >
                    Drag & Drop
                  </Typography>
                  <Typography variantName="text" textColor={Colors.GRIGINO} style={{ fontWeight: 700, marginTop: 4 }}>
                    <>
                      or <span style={{ color: Colors.ROSSINO }}>browse</span> to upload
                    </>
                  </Typography>
                </>
              )}
              {dragMultipleFiles && (
                <Typography
                  variantName="text"
                  textAlign="center"
                  textColor={Colors.ROSSINO}
                  style={{ fontWeight: 700 }}
                >
                  Upload only one file please! 😉
                </Typography>
              )}
              {isDragReject && !dragMultipleFiles && (
                <Typography
                  variantName="text"
                  textAlign="center"
                  textColor={Colors.ROSSINO}
                  style={{ fontWeight: 700, display: "grid" }}
                >
                  <>
                    File type not accepted, sorry! <span>Upload only CSV 😊</span>
                  </>
                </Typography>
              )}
            </DragDropZoneWrapper>
          );
        }}
      </Dropzone>
      <Typography
        variantName="caption"
        textColor={Colors.GRIGINO}
        style={{ fontWeight: 500, marginTop: 15, fontSize: 11 }}
        textAlign="center"
      >
        Acceptable file type: CSV
      </Typography>
      <Typography
        variantName="caption"
        textColor={Colors.GRIGINO}
        style={{ fontWeight: 500, marginTop: 8, fontSize: 11 }}
        textAlign="center"
      >
        <>
          <NavLink to="/upload-csv-help-page" target="__blank" style={{ fontWeight: 700, color: Colors.GRIGINO }}>
            Click here
          </NavLink>
          &nbsp; before uploading file to check columns formatting
        </>
      </Typography>
    </>
  );
};

export { DragDropArea };
