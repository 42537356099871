import * as React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Button } from "@freeda/react-components";
import { includes } from "lodash";
import { buildQuery } from "../components/queries/GroupedMetadataByCatsQuery";
import { PostType } from "../types/PostType";
import { Platform } from "../types/Platform";
import { Project } from "../types/Project";
import { Metadata } from "../types/Metadata";
import ListMetadataCategoriesQueryT from "../types/graphql/ListMetadataCategoriesQuery";
import { copy } from "../utils/metadataCopyPaste";
import { PublicationType } from "../types/Publication";
import { FeedbackContext } from "../providers/FeedbackProvider";
import { idByDistribution } from "../utils/distributionIdsByDistribution";
import { LIST_METADATA_CATEGORIES } from "../apollo/queries/ListMetadataCategories";

interface Props {
  postType: PostType;
  platform: Platform;
  project: Project;
  userDefinedMetadata: Array<Metadata>;
  closeCard: () => void;
  isStory?: boolean;
  style?: React.CSSProperties;
}

export const CopyMetadataButton: React.FC<Props> = ({
  postType,
  platform,
  isStory,
  userDefinedMetadata,
  project,
  closeCard,
  style,
}) => {
  const { openSnackbar } = React.useContext(FeedbackContext);

  const QUERY = buildQuery(postType, platform, project.label);
  const groupedMetadataResult = useQuery<{
    [key: string]: Array<Omit<Metadata, "category">>;
  }>(QUERY, {
    fetchPolicy: "cache-and-network",
  });
  const listMetadataCatsResult = useQuery<ListMetadataCategoriesQueryT>(
    LIST_METADATA_CATEGORIES
  );

  const social = isStory ? PublicationType.IG_STORY : platform;

  let disabledCopyButton = Boolean(
    groupedMetadataResult.loading
  );


  if (
    !groupedMetadataResult.loading &&
    !listMetadataCatsResult.loading &&
    groupedMetadataResult.data &&
    listMetadataCatsResult.data
  ) {
    const metadataCategories =
      listMetadataCatsResult.data.listMetadataCategories.content;
    const groupedMetadata = groupedMetadataResult.data;

    const allMetadataByCategories = Object.keys(groupedMetadata)
      .filter(
        (categoryId) =>
          categoryId !== "BOOLEAN" &&
          metadataCategories.find((c) => c.id === categoryId) !== undefined
      )
      .map((categoryId) => {
        return {
          category: metadataCategories.find((c) => c.id === categoryId)!,
          metadata: groupedMetadata[categoryId],
        };
      });

    const metadataValues = allMetadataByCategories.map((gm) => {
      const metadata = userDefinedMetadata.find(
        (m) => m.category.id === gm.category.id
      );
      return {
        categoryId: gm.category.id,
        metadataId: metadata ? metadata.id : null,
      };
    });

    const booleanMetadata = groupedMetadata["BOOLEAN"];

    const booleanMetadataValues = booleanMetadata
      .map((m) => {
        const booleanMetadataExists = userDefinedMetadata.find(
          (uM) => m.id === uM.id
        );
        return {
          categoryId: "BOOLEAN",
          metadataId: m.id,
          value: Boolean(booleanMetadataExists),
        };
      })
      .filter((m) => !includes(Object.values(idByDistribution), m.metadataId));

    const keywords = userDefinedMetadata
      .filter((m) => m.category.id === "KEYWORDS")
      .map((m) => ({
        categoryId: m.category.id,
        metadataId: m.id,
        label: m.label,
      }));

   /* const distributionMetadataCopy = booleanMetadata
      .filter((m) => includes(Object.values(idByDistribution), m.id))
      .map((m) => {
        const distributionExists = userDefinedMetadata.find(
          (udm) => udm.id === m.id
        );
        return {
          categoryId: "BOOLEAN",
          metadataId: m.id,
          value: Boolean(distributionExists),
          label: m.label,
        };
      })
      .filter((m) => Boolean(m.value));*/
      
    

    const metadataCopied = [
      ...metadataValues,
      ...booleanMetadataValues,
      ...keywords,
      //...distributionMetadataCopy,
    ];

    const onClickCopy = () =>{
     
      copy(social, project.idLegacy, metadataCopied);
      closeCard();
      openSnackbar("Labels copied correctly!", "notification");
     
    }

    return (
      <Button
        style={{ width: "100%", ...style }}
        flat
        disabled={disabledCopyButton}
        onClick={onClickCopy}
        /*onClick={() => { 
          copy(social, project.idLegacy, metadataCopied);
          closeCard();
          openSnackbar("Metadata copied correctly!", "notification");
        }}*/
      >
        Copy tag
      </Button>
    );
  }
  return (
    <Button  disabled={true} flat style={{ width: "100%", ...style }}>
      Copy tag ( wait loading.. )
    </Button>
  );
};
