import * as React from "react";
import Media from "react-media";

import MetadataFilter from "../layout/MetadataFilter";
import { MetadataTableContainer } from "../../container/MetadataTable";
import { MetadataOrderBy } from "../../types/graphql/MetadataOrderBy";
import { HeaderLogoQuote } from "../ui/HeaderLogoQuote";
import { get } from "lodash";
import { Order } from "../../types/Order";

interface State {
  searchValue: string;
  metadataActiveOptions: Array<{ value: string; label: string }>;
  selectedCategoryId: string | null;
  showFilterMobile: boolean;
  currentPage: number;
  pageSize: number;
  orderBy: MetadataOrderBy | null;
  order: Order;
  selectedProjects: Array<{ value: string; label: string }>;
}

const initialState = {
  searchValue: "",
  metadataActiveOptions: [{ value: "all", label: "all" }],
  selectedCategoryId: null,
  currentPage: 0,
  orderBy: null,
  order: null,
  pageSize: 25,
  selectedProjects: [],
};

const MetadataPage: React.FC = () => {
  const [state, setState] = React.useState<State>({
    showFilterMobile: false,
    ...initialState,
  });

  const backToFirstPage = { currentPage: 0 };

  const onTextFilterChange = (searchValue: string) =>
    setState((state) => ({ ...state, searchValue, ...backToFirstPage }));

  const clearFilter = () =>
    setState((state) => ({ ...state, ...initialState }));

  const onChangeActiveOptions = (
    options: Array<{ value: string; label: string }>
  ) =>
    setState((state) => ({
      ...state,
      metadataActiveOptions: options,
      ...backToFirstPage,
    }));

  const onCategorySelection = (categoryId: string) =>
    setState((state) => ({
      ...state,
      selectedCategoryId: categoryId,
      ...backToFirstPage,
    }));

  const toggleShowFilter = () =>
    setState((state) => ({
      ...state,
      showFilterMobile: !state.showFilterMobile,
    }));

  const onChangePageSize = (numberPage: string) => {
    setState((state) => ({ ...state, pageSize: Number(numberPage) }));
  };

  const onChangePage = (page: number) =>
    setState((state) => ({ ...state, currentPage: page }));

  const onBackPage = () =>
    setState((state) => ({
      ...state,
      currentPage: state.currentPage === 0 ? 0 : state.currentPage - 1,
    }));

  const onNextPage = (totalPages: number) =>
    setState((state) => ({
      ...state,
      currentPage:
        state.currentPage === totalPages - 1
          ? state.currentPage
          : state.currentPage + 1,
    }));

  const onChangeOrder = (orderBy: MetadataOrderBy | null, order: Order) => {
    setState((state) => ({ ...state, orderBy, order }));
  };

  const onChangeProject = (project: { value: string; label: string }) => {
    const exists = state.selectedProjects.find(
      (p) => p.value === project.value
    );

    setState((state) => ({
      ...state,
      selectedProjects: exists
        ? state.selectedProjects.filter((sp) => sp.value !== project.value)
        : [project, ...state.selectedProjects],
      ...backToFirstPage,
    }));
  };

  const getMetadataActive = () => {
    const booleanMetadataActive = { all: null, active: true, inactive: false };
    const optionsValue = get(metadataActiveOptions[0], "value", "all");
    return booleanMetadataActive[optionsValue];
  };

  const {
    searchValue,
    metadataActiveOptions,
    selectedCategoryId,
    showFilterMobile,
    currentPage,
    pageSize,
    orderBy,
    order,
  } = state;

  const metadataActive = getMetadataActive();

  return (
    <>
      <Media query={{ minWidth: 769 }}>
        {(desktop: boolean) => <>{desktop && <HeaderLogoQuote />}</>}
      </Media>
      <MetadataFilter
        metadataActiveOptions={metadataActiveOptions}
        onChangeActiveOptions={(options) => onChangeActiveOptions(options)}
        searchValue={searchValue}
        selectedCategoryId={selectedCategoryId}
        clearFilter={clearFilter}
        onTextFilterChange={onTextFilterChange}
        onCategorySelection={onCategorySelection}
        showFilter={toggleShowFilter}
        showFilterMobile={showFilterMobile}
        selectedProjects={state.selectedProjects}
        onChangeProject={onChangeProject}
      />
      <MetadataTableContainer
        metadataActive={metadataActive}
        searchValue={searchValue}
        selectedCategoryId={selectedCategoryId}
        currentPage={currentPage}
        orderBy={orderBy}
        order={order}
        pageSize={pageSize}
        onChangePageSize={onChangePageSize}
        onChangePage={onChangePage}
        onBackPage={onBackPage}
        onNextPage={onNextPage}
        onChangeOrder={onChangeOrder}
        selectedProjects={state.selectedProjects}
      />
    </>
  );
};

export default MetadataPage;
