import gql from "graphql-tag";

const ALL_PUBLISHER_INFOS = gql`
  {
    allPublisherInfos {
      name
      publisherId
      platformId
      projectId
      crossCountry
    }
  }
`;

export { ALL_PUBLISHER_INFOS };
