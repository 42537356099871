import gql from "graphql-tag";
import { SOCIAL_CONTENT_ID_FRAGMENT } from "../fragments/SocialContentIdFragment";

const EXPORT_INSTAGRAM_STORIES = gql`
  query exportInstagramStories(
    $orderBy: InstagramStoryOrderBy
    $where: InstagramStoryWhere
    $metricWhere: MeasuredMetricWhere
  ) {
    export: allInstagramStories(orderBy: $orderBy, where: $where, metricWhere: $metricWhere) {
      id {
        ...socialContentId
      }
      title
      publicationDate
      
      frames {
        id {
          platformId
          publisherId
          contentId
        }
        isStory
        caption
        thumbnailUrlBackup
        thumbnailUrl
        originalThumbnailUrl
        mediaUrl
        permalink
        type
        publicationDate
       
       
      }
      userDefinedMetadata {
        id
        label
        category {
          id
          label
          area
        }
      }
      measuredMetrics {
        id {
          platformId
          publisherId
          contentId
          granularity
          name
          key
          timestamp
        }
        value
        conversionUnit
        position
      }
    }
  }
  ${SOCIAL_CONTENT_ID_FRAGMENT}
`;



export { EXPORT_INSTAGRAM_STORIES };
