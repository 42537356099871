import gql from "graphql-tag";
import { COLUMN_SET_FRAGMENT } from "./../fragments/ColumnSetFragment";

export const CREATE_CUSTOM_COLUMN_SET = gql`
  mutation createCustomColumnSet($input: CreateCustomColumnSetInput!) {
    createCustomColumnSet(input: $input) {
      ...columnSet
    }
  }
  ${COLUMN_SET_FRAGMENT}
`;
