import styled from "styled-components";

export const FilterWrapper = styled.div`
  width: 100%;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${(props) => props.theme.azzurrino};
  box-sizing: border-box;
`;
