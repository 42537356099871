import * as React from "react";
import { Colors } from "@freeda/react-components/lib/theme";
import FreedaIcon from "../components/ui/SvgIcon/FreedaIcon";
import UserIcon from "../components/ui/SvgIcon/UserIcon";

export const getUserIconOnlyFbGroup = (messageFbGroup: Array<string> | null, userPost: string | null) => {
  if (!messageFbGroup) {
    return null;
  }

  return (
    <>
      {userPost ? <FreedaIcon color={Colors.PURPLE} width={15} /> : <UserIcon color={Colors.PURPLE} heightWidth={15} />}
    </>
  );
};
