import * as React from "react";
import CustomMetrics from "../components/layout/CustomMetrics";
import { TransitionStatus } from "react-transition-group/Transition";
import { Dialogable } from "../types/Dialogable";
import { isDataAvailable } from "../apollo/utils";
import { PublicationType } from "../types/Publication";
import { ColumnSet } from "../types/ColumnSet";
import { includes } from "lodash";
import { PlaceholderLateralPanel } from "../components/ui/Placeholders/PlaceholderLateralPanel";
import { FeedbackContext } from "../providers/FeedbackProvider";
import { MetricColumnSetsContext, ColumnSetType } from "../providers/MetricColumnSetsProvider";
import { DataProxy } from "apollo-cache";
import { useMutation, useQuery } from "react-apollo";
import { ALL_COLUMN_SETS } from "../apollo/queries/AllColumnSets";
import ListColumnSetQuery from "../types/graphql/AllColumnSetsQuery";
import AllColumnSetQueryT from "../types/graphql/AllColumnSetsQuery";
import AllCategoriesQueryT from "../types/graphql/AllCategoriesQuery";
import { CREATE_CUSTOM_COLUMN_SET } from "../apollo/mutations/CreateCustomColumnSet";
import { FetchResult } from "apollo-link";
import { UPDATE_COLUMN_SET } from "../apollo/mutations/UpdateColumnSet";
import { DELETE_COLUMN_SET } from "../apollo/mutations/DeleteColumnSet";
import { ALL_CATEGORIES } from "../apollo/queries/AllCategories";

type Props = Dialogable & {
  transitionStatus: TransitionStatus;
  publicationId: PublicationType;
  columnSetType: ColumnSetType;
  zIndex?: number;
  showCustomMetrics?: boolean;
};

export const CustomMetricsContainer: React.FC<Props> = ({
  transitionStatus,
  open,
  onClose,
  publicationId,
  columnSetType,
  zIndex,
  showCustomMetrics
}) => {
  const { openSnackbar } = React.useContext(FeedbackContext);
  const { selectSet, selectedSets } = React.useContext(MetricColumnSetsContext);
    
  const [createCustomColumnSet, createResult] = useMutation<{ createCustomColumnSet: ColumnSet }>(
    CREATE_CUSTOM_COLUMN_SET,
    {
      update: (cache: DataProxy, result: FetchResult<{ createCustomColumnSet: ColumnSet }>) => {
        const q = {
          query: ALL_COLUMN_SETS,
          variables: { where: { publicationId, custom: true } }
        };
        const cachedQuery = cache.readQuery<ListColumnSetQuery>(q);
        const newCustomColumn = result.data!.createCustomColumnSet;
        if (cachedQuery) {
          cache.writeQuery({
            query: ALL_COLUMN_SETS,
            variables: { where: { publicationId, custom: true } },
            data: {
              allColumnSets: [...cachedQuery.allColumnSets, { ...newCustomColumn }]
            }
          });
        }
      },
      onCompleted: (data: { createCustomColumnSet: ColumnSet }) => {
        selectSet(columnSetType, data.createCustomColumnSet.name, onClose());
      }
    }
  );

  const [updateCustomColumnSet, updateResult] = useMutation<{ updateColumnSet: ColumnSet }>(UPDATE_COLUMN_SET, {
    onCompleted: (data: { updateColumnSet: ColumnSet }) => {
      selectSet(columnSetType, data.updateColumnSet.name, onClose());
    }
  });

  const [deleteCustomColumnSet, deleteResult] = useMutation<{ deleteColumnSet: ColumnSet }>(DELETE_COLUMN_SET, {
    update: (cache: DataProxy, result: FetchResult<{ deleteColumnSet: ColumnSet }>) => {
      const q = {
        query: ALL_COLUMN_SETS,
        variables: { where: { publicationId, custom: true } }
      };
      const cachedQuery = cache.readQuery<ListColumnSetQuery>(q);
      const deletedCustomColumn = result.data!.deleteColumnSet;
      if (cachedQuery) {
        cache.writeQuery({
          query: ALL_COLUMN_SETS,
          variables: { where: { publicationId, custom: true } },
          data: {
            allColumnSets: cachedQuery.allColumnSets.filter(cs => cs.id !== deletedCustomColumn.id)
          }
        });
      }
    }
  });

  const allColumnSetQueryResult = useQuery<AllColumnSetQueryT>(ALL_COLUMN_SETS, {
    variables: { where: { publicationId, custom: true } }
  });

  const allCategoriesQueryResult = useQuery<AllCategoriesQueryT>(ALL_CATEGORIES);

  const dataLoaded: boolean = isDataAvailable(allCategoriesQueryResult, allColumnSetQueryResult);

  if (!dataLoaded) {
    return <PlaceholderLateralPanel transitionStatus={transitionStatus} />;
  }

  const allMetricCategories = !dataLoaded
    ? []
    : allCategoriesQueryResult.data!.allMetricCategories.map(c => ({
        ...c,
        metrics: c.metrics.filter(m => {
          const includesPublication = includes(m.publications.map(p => p.id), publicationId);
          if (typeof showCustomMetrics !== "undefined") {
            return includesPublication && m.custom === showCustomMetrics;
          }
          return includesPublication;
        })
      }));

  const allColumnSets =
    allColumnSetQueryResult.loading ||
    !allColumnSetQueryResult.data ||
    Object.keys(allColumnSetQueryResult.data).length === 0
      ? []
      : allColumnSetQueryResult.data.allColumnSets;

  const selectedSet = selectedSets[columnSetType];

  const selectedColumnSet = allColumnSets.find(cs => cs.name === selectedSet);

  const loadingMutations = {
    createUpdate: createResult.loading || updateResult.loading,
    delete: deleteResult.loading
  };

  const clickedCreate = !Boolean(selectedSet);

  return (
    <CustomMetrics
      transitionStatus={transitionStatus}
      zIndexPanel={zIndex}
      open={open}
      clickedCreate={clickedCreate}
      allMetricCategories={allMetricCategories}
      columnSetLabel={selectedColumnSet ? selectedColumnSet.label : ""}
      metricsColumnSet={selectedColumnSet ? selectedColumnSet.metrics : []}
      publicationId={publicationId}
      onClose={onClose}
      onCreateColumnSet={async (label: string, metricIdsWithPositions: Array<{ id: number; position: number }>) => {
        if (!selectedColumnSet) {
          await createCustomColumnSet({
            variables: {
              input: {
                publicationId,
                label,
                metricIdsWithPositions
              }
            }
          });
          openSnackbar("Custom column set created correctly!", "notification");
        }
      }}
      onUpdateColumnSet={async (label: string, metricIdsWithPositions: Array<{ id: number; position: number }>) => {
        if (selectedColumnSet) {
          await updateCustomColumnSet({
            variables: {
              input: {
                id: selectedColumnSet.id,
                label,
                publicationId,
                metricIdsWithPositions
              }
            }
          });
          openSnackbar("Custom column set updated correctly!", "notification");
        }
      }}
      onDeleteColumnSet={async () => {
        if (selectedColumnSet) {
          await deleteCustomColumnSet({
            variables: {
              input: {
                id: selectedColumnSet.id
              }
            }
          });
          selectSet(columnSetType, "", onClose());
          openSnackbar("Custom column set deleted correctly!", "notification");
        }
      }}
      loadingMutations={loadingMutations}
    />
  );
};
