import * as React from "react";

import PostListFilter from ".";
import { AdvancedFilterPost } from "./AdvancedFilterPost";
import { FilteredAutocompleteMetadata } from "../../../container/FilteredAutocompleteMetadata";
import { useFilterMobile } from "../../../hooks/useFilterMobile";
import { FilterContextT } from "../../../providers/FilterProvider";
import { Project } from "../../../types/Project";
import { PostType } from "../../../types/PostType";
import { TikTokPostType } from "../../../types/TikTokPostType";

interface Props {
  tkPostFilterContext: React.Context<FilterContextT>;
  project: Project;
  publisherSelectComponent?: (matches: boolean) => React.ReactElement<any>;
  brandedComponent?: (matches: boolean) => React.ReactElement<any>;
}

export const TkPostListFilter: React.FC<Props> = ({
  tkPostFilterContext,
  project,
  publisherSelectComponent,
  brandedComponent
}) => {
  const { onCloseFilterMobile, toggleShowFilter, showFilterMobile } = useFilterMobile();
  const { callbacks, filterState } = React.useContext(tkPostFilterContext);
  const { searchValue, selectedFormat, dateRange } = filterState;
  const { clearFilter, onTextFilterChange, onChangeFormat, onChangeUpdateDateRange } = callbacks;

  const availablePostFormat: Array<PostType> = Object.keys(TikTokPostType).map((postType) => postType as PostType);


  return (
    <PostListFilter
      searchValue={searchValue}
      clearFilter={clearFilter}
      onTextFilterChange={onTextFilterChange}
      showFilter={toggleShowFilter}
      showFilterMobile={showFilterMobile}
      renderAdvancedFilter={status => (
        <AdvancedFilterPost
          transitionStatus={status}
          format={{
            onChangeFormat,
            availablePostFormat,
            selectedFormat,
          }}
          showFilterMobile={showFilterMobile}
          onCloseFilterMobile={onCloseFilterMobile}
          onClearFilterMobile={clearFilter}
          dateRange={dateRange}
          onChangeUpdateDateRange={onChangeUpdateDateRange}
          brandedComponent={brandedComponent}
          publisherSelectComponent={publisherSelectComponent}
          autocompleteMetadataComponent={
            <FilteredAutocompleteMetadata FilterContext={tkPostFilterContext} project={project} />
          }
        />
      )}
    />
  );
};
