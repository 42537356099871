import gql from "graphql-tag";
import { SOCIAL_CONTENT_ID_FRAGMENT } from "../fragments/SocialContentIdFragment";

const CREATE_INSTAGRAM_STORY = gql`
  mutation createInstagramStory($input: CreateInstagramStoryInput!) {
    createInstagramStory(input: $input) {
      id {
        ...socialContentId
      }
      title
      publicationDate
      frames {
        id {
          platformId
          publisherId
          contentId
        }
      }
    }
  }
  ${SOCIAL_CONTENT_ID_FRAGMENT}
`;

export { CREATE_INSTAGRAM_STORY };
