import gql from "graphql-tag";

const ALL_CUSTOM_CONTENT_METRICS = gql`
  query allCustomContentMetrics($where: CustomContentMetricWhere) {
    allCustomContentMetrics(where: $where) {
      id {
        name
        platformId
        publisherId
        contentId
        granularity
        name
        timestamp
        key
      }
      value
    }
  }
`;

export { ALL_CUSTOM_CONTENT_METRICS };
