import "core-js";
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import auth from "./utils/auth";
import "./index.css";


if (process.env.NODE_ENV === "production") {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: "https://4d8ce17d38f4495a97c44b1dadc69708@sentry.io/1422864",
  });
  Sentry.configureScope(function(scope) {
    const username = localStorage.getItem("username");
    if (username) {
      scope.setUser({ username });
    }
  });
}

if( window.location.href.includes( "dev.elinor.freeda" ) || window.location.href.includes( "http://localhost:3000/") ){
  localStorage.setItem("peggyUrl", "https://dev.peggy.freeda.tech/login")
  localStorage.setItem("peggyUrlEdit", "https://dev.peggy.freeda.tech/edit-media/")
}
else if(window.location.href.includes( "qa.elinor.freeda" ) ){
  localStorage.setItem("peggyUrl", "https://qa.peggy.freeda.tech/login")
  localStorage.setItem("peggyUrlEdit", "https://qa.peggy.freeda.tech/edit-media/")
}
else{
  localStorage.setItem("peggyUrl", "https://peggy.freeda.tech/login")
  localStorage.setItem("peggyUrlEdit", "https://peggy.freeda.tech/edit-media/")
}

const version = localStorage.getItem("version");
const VERSION = "1.6.24";

if ( !version || version !== VERSION ) {
    localStorage.clear();
    localStorage.setItem("version", VERSION);
}

(async () => {
  await auth.renewToken();
  ReactDOM.render(
      <Router>
        <App />
      </Router>,
    document.getElementById("root")
  );
})();
