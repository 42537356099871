import { Distribution } from "./../types/Distribution";
import { InstagramPostType } from "./../types/InstagramPostType";
import { FacebookPostType } from "./../types/FacebookPostType";
import { Category, PostMetadata } from "./edit-metadata.type";
import { PostType } from "../types/PostType";
import { Platform } from "../types/Platform";
import { Metadata } from "../types/Metadata";
import { trimAndReplaceSpaceToUpper } from "../utils/trimAndReplaceSpaceToUpper";

const video: Category = {
  id: "VIDEO",
};

const image: Category = {
  id: "IMAGE",
};

const instantArticle: Category = {
  id: "INSTANT_ARTICLES",
};

const status: Category = {
  id: "STATUS",
};

const carousel: Category = {
  id: "CAROUSEL",
};

const story: Category = {
  id: "INSTAGRAM_STORY",
};

const youtube: Category = {
  id: "YOUTUBE",
};

const topic: Category = {
  id: "TOPIC",
};

const temperature: Category = {
  id: "TEMPERATURE",
};

const toneOfVoice: Category = {
  id: "TONE_OF_VOICE",
};

const brand: Category = {
  id: "BRAND",
};

const product: Category = {
  id: "PRODUCT",
};

const genre: Category = {
  id: "GENRE",
};

const objective: Category = {
  id: "OBJECTIVE",
};

const campaign: Category = {
  id: "CAMPAIGN",
  takeMetadataFrom: "BRAND",
};

const editorialCampaign: Category = {
  id: "EDITORIAL_CAMPAIGN",
};

export const formatCategories: Array<Category> = [
  video,
  image,
  instantArticle,
  status,
  carousel,
  story,
  youtube,
  product,
  objective,
  genre,
];

export const getFormatByPostType = (postType: PostType): Category => {
  switch (postType) {
    case FacebookPostType.video:
    case InstagramPostType.VIDEO:
    case InstagramPostType.IGTV:
      return video;
    case FacebookPostType.photo:
    case FacebookPostType.event:
    case InstagramPostType.IMAGE:
      return image;
    case FacebookPostType.link:
      return instantArticle;
    case FacebookPostType.album:
    case InstagramPostType.CAROUSEL_ALBUM:
      return carousel;
    case "INSTAGRAM_STORY":
      return story;
    case "YOUTUBE":
      return video;
    case FacebookPostType.status:
      return status;
    default:
      return video;
  }
};

const getSerieCategory = (postType: PostType): Category => {
  return {
    id: "SERIES",
    takeMetadataFrom: getFormatByPostType(postType).id,
  };
};

export const isComplete = (
  postType: PostType,
  platform: Platform,
  userDefinedMetadata: Array<Metadata>
): boolean => {
  const spec = getPostMetadataSpec(postType, platform);
  return spec.categories.reduce<boolean>((acc, next) => {
    const isCategoryMetadataSelected = Boolean(
      userDefinedMetadata.find((udm) => udm.category.id === next.id)
    );

    if (spec.conditionalCategories) {
      const conditionalCategory = spec.conditionalCategories.find((cc) =>
        Boolean(cc.makeVisible.find((c) => c.id === next.id))
      );

      if (conditionalCategory) {
        const isChecked = userDefinedMetadata.find(
          (udm) =>
            trimAndReplaceSpaceToUpper(udm.label) === conditionalCategory.which
        );
        return isChecked ? isCategoryMetadataSelected && acc : acc;
      } else {
        return isCategoryMetadataSelected && acc;
      }
    }
    return isCategoryMetadataSelected && acc;
  }, true);
};

export const getPostMetadataSpec = (
  postType: PostType,
  platform: Platform
): PostMetadata => {
  const format: Category = getFormatByPostType(postType);
  const serie: Category = getSerieCategory(postType);
  const distribution = Object.keys(Distribution).map((d) => d as Distribution);

  const specs = {
    FB: {
      categories: [
        format,
        serie,
        genre,
        temperature,
        objective,
        topic,
        toneOfVoice,
        brand,
        editorialCampaign,
        campaign,
        product,
      ],
      booleanMetadata: [
        "REPOST",
        "BRANDED",
        "CTA",
        "LOCAL",
        "CROSS-COUNTRY",
        "PARTNERSHIP",
        "LONG_CAPTION",
        "SURVEY",
      ],
      conditionalCategories: [
        {
          which: "BRANDED",
          makeVisible: [brand, campaign],
          hide: [editorialCampaign],
        },
      ],
      distribution,
    },
    FBG: {
      categories: [topic],
      booleanMetadata: [
        "REPOST",
        "BRANDED",
        "CTA",
        "LOCAL",
        "CROSS-COUNTRY",
        "PARTNERSHIP",
        "LONG_CAPTION",
        "SURVEY",
      ],
      conditionalCategories: [
        {
          which: "BRANDED",
          makeVisible: [brand, campaign],
          hide: [editorialCampaign],
        },
      ],
    },
    IG: {
      categories: [
        format,
        serie,
        genre,
        temperature,
        objective,
        topic,
        toneOfVoice,
        brand,
        campaign,
        editorialCampaign,
        product
      ],
      booleanMetadata: [
        "REPOST",
        "BRANDED",
        "CTA",
        "LOCAL",
        "CROSS-COUNTRY",
        "PARTNERSHIP",
        "LONG_CAPTION",
        "SURVEY",
      ],
      conditionalCategories: [
        {
          which: "BRANDED",
          makeVisible: [brand, campaign],
          hide: [editorialCampaign],
        },
      ],
      distribution,
    },
    YT: {
      categories: [
        format,
        serie,
        genre,
        temperature,
        objective,
        topic,
        brand,
        campaign,
        editorialCampaign,
        product
      ],
      booleanMetadata: [
        "REPOST",
        "BRANDED",
        "CTA",
        "LOCAL",
        "CROSS-COUNTRY",
        "PARTNERSHIP",
        "LONG_CAPTION",
        "SURVEY",
      ],
      conditionalCategories: [
        {
          which: "BRANDED",
          makeVisible: [brand, campaign],
          hide: [editorialCampaign],
        },
      ],
      distribution,
    },
    TK: {
      categories: [
        format,
        serie,
        genre,
        temperature,
        objective,
        topic,
        toneOfVoice,
        brand,
        campaign,
        editorialCampaign,
        product
      ],
      booleanMetadata: [
        "REPOST",
        "BRANDED",
        "CTA",
        "LOCAL",
        "CROSS-COUNTRY",
        "PARTNERSHIP",
        "LONG_CAPTION",
        "SURVEY",
      ],
      conditionalCategories: [
        {
          which: "BRANDED",
          makeVisible: [brand, campaign],
          hide: [editorialCampaign],
        },
      ],
      distribution,
    },
  };

  return specs[platform];
};
