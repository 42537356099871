import styled from "styled-components";

const TableFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 0px;
  justify-content: space-between;
  z-index: 20;
  width: 100%;
`;

const InfoResults = styled.p`
  font-size: 12px;
  font-style: italic;
  color: ${(props) => props.theme.secondaryLight};
  margin-right: 15px;
  white-space: nowrap;
`;

export { TableFilterWrapper, InfoResults };
