import gql from "graphql-tag";

const ALL_CATEGORIES = gql`
  query allMetricCategories($where: MetricCategoryWhere) {
    allMetricCategories(where: $where) {
      id
      name
      label
      metrics {
        id
        name
        platformId
        label
        publications {
          id
        }
        custom
      }
    }
  }
`;

export { ALL_CATEGORIES };
