import * as React from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import ListFbGroupContentQueryT from "../types/graphql/ListFbGroupContentQuery";
import {
  ColumnSetType,
  MetricColumnSetsContext,
} from "../providers/MetricColumnSetsProvider";
import { FilterContextT } from "../providers/FilterProvider";
import { PublicationType } from "../types/Publication";
import PostListTable from "../components/layout/PostListTable";
import {
  defaultFacebookGroupsTableHead,
  buildFacebookGroupsTableColumns,
} from "../utils/postTableUtils";
import { Average } from "../types/Average";
import { PlaceholderPostList } from "../components/ui/Placeholders/PlaceholderPostList";
import { NotFound } from "../components/ui/NotFound";
import {
  isDataAvailable,
  buildOrderByClause,
  buildFbGroupContentFilter,
} from "../apollo/utils";
import { FacebookGroupContent } from "../types/FacebookGroupContent";
import { EXPORT_FB_GROUP_CONTENT_METADATA } from "../apollo/queries/ExportFbGroupContentMetadata";
import { LIST_FB_GROUP_CONTENT_METADATA } from "../apollo/queries/ListFbGroupContentMetadata";
import { Project } from "../types/Project";
import { Platform } from "../types/Platform";
import { ALL_COLUMN_SETS } from "../apollo/queries/AllColumnSets";
import AllColumnSetQueryT from "../types/graphql/AllColumnSetsQuery";
import { UPDATE_FB_GROUP_CONTENT } from "../apollo/mutations/UpdateFbGroupContent";
import { StaticIdsContext } from "../providers/StaticIdsProvider";

interface Props {
  publisherId: string;
  publisherName: string;
  project: Project;
  fbGroupsPostFilterContext: React.Context<FilterContextT>;
  fbGroupsColumnSetSelect: React.FC<{
    onOpenCustomPanel: () => void;
    width?: string | number;
  }>;
  columnSetType: ColumnSetType;
}

export const FbGroupsPostListTableContainer: React.FC<Props> = ({
  publisherId,
  publisherName,
  fbGroupsPostFilterContext,
  project,
  columnSetType,
  fbGroupsColumnSetSelect,
}) => {
  const { callbacks, filterState } = React.useContext(
    fbGroupsPostFilterContext
  );

  const {
    currentPage,
    pageSize,
    metricOrderBy,
    postOrderBy,
    inclusionPolicyUser,
    order,
  } = filterState;

  const { selectedSets } = React.useContext(MetricColumnSetsContext);
  const selectedSet = selectedSets[columnSetType];
  const columnSetName = selectedSet || `ALL_${PublicationType.FBG_POST}`;
  const custom = Boolean(selectedSet);

  const allColumnSetResult = useQuery<AllColumnSetQueryT>(ALL_COLUMN_SETS, {
    variables: {
      where: {
        publicationId: PublicationType.FBG_POST,
        name: columnSetName,
        custom,
      },
    },
  });

  const {
    onChangePage,
    onChangePageSize,
    onBackPage,
    onNextPage,
    onChangePostOrder,
    onChangeMetricOrder,
  } = callbacks;

  const areColumnSetsLoaded = isDataAvailable(allColumnSetResult);
  const columnSetId =
    areColumnSetsLoaded && allColumnSetResult.data!.allColumnSets.length > 0
      ? allColumnSetResult.data!.allColumnSets[0].id
      : undefined;
  const staticIdsContext = React.useContext(StaticIdsContext);

  const wheresFilter = buildFbGroupContentFilter(
    filterState,
    publisherId,
    publisherName,
    project.idLegacy,
    inclusionPolicyUser,
    columnSetId,
    staticIdsContext.brandedId
  );

  const orderBy = buildOrderByClause(metricOrderBy, postOrderBy, order);

  const listFbGroupContentResult = useQuery<ListFbGroupContentQueryT>(
    LIST_FB_GROUP_CONTENT_METADATA,
    {
      fetchPolicy: "network-only",
      variables: {
        offset: currentPage * pageSize,
        limit: pageSize,
        orderBy,
        ...wheresFilter,
      },
    }
  );

  let posts: Array<FacebookGroupContent> = [];
  let averages: Array<Average> = [];
  let count: number = 0;
  let totalPages: number = 0;
  const loading = listFbGroupContentResult.loading;

  const updateFbGroupContent = useMutation(UPDATE_FB_GROUP_CONTENT);

  if (
    listFbGroupContentResult.data &&
    Object.keys(listFbGroupContentResult.data).length > 0
  ) {
    posts = listFbGroupContentResult.data!.listFacebookGroupContents.content;
    averages = listFbGroupContentResult.data!.listFacebookGroupContents
      .subAggregate.averages;
    count = listFbGroupContentResult.data!.listFacebookGroupContents.aggregate
      .count;
    totalPages = Math.ceil(count / pageSize);
  }

  if (!areColumnSetsLoaded || (loading && posts.length === 0)) {
    return <PlaceholderPostList />;
  }

  if (posts.length === 0 && !loading) {
    return <NotFound>No post found! :(</NotFound>;
  }

  return (
    <PostListTable
      defaultHead={defaultFacebookGroupsTableHead}
      buildColumns={buildFacebookGroupsTableColumns}
      posts={posts}
      averages={averages}
      loading={loading}
      currentPage={currentPage}
      pageSize={pageSize}
      totalPages={totalPages}
      onChangePageSize={onChangePageSize}
      onChangePage={onChangePage}
      onBackPage={onBackPage}
      onNextPage={() => onNextPage(totalPages)}
      onChangePostOrder={onChangePostOrder}
      onChangeMetricOrder={onChangeMetricOrder}
      count={count}
      publicationType={PublicationType.FBG_POST}
      platform={Platform.FBG}
      PostFilterContext={fbGroupsPostFilterContext}
      updateSocialContentMetadataMutation={updateFbGroupContent}
      ColumnSetSelect={fbGroupsColumnSetSelect}
      exportQuery={EXPORT_FB_GROUP_CONTENT_METADATA}
      columnSetType={columnSetType}
      fixedColumnNumbers={2}
      paddingLeftFirstTh={"15px"}
      publisherName={publisherName}
      publisherId={publisherId}
      project={project}
      listContentMetadataQuery={LIST_FB_GROUP_CONTENT_METADATA}
      wheresFilter={wheresFilter}
      orderBy={metricOrderBy || postOrderBy}
      order={order}
    />
  );
};
