import * as React from "react";
import styled from "styled-components";
import { Typography } from "@freeda/react-components";
import { Colors } from "@freeda/react-components/lib/theme";

interface Props {
  label: string;
  hightlighted?: boolean;
  description?: string;
  formula?: string;
}

const Wrapper = styled.div`
  padding: 0px 20px;
  @media (max-width: 480px) {
    padding: 0;
    margin: 40px 20px;
  }
`;

const DescriptionWrapper = styled.div`
  margin-top: 12px;
  max-width: 550px;
  line-height: 20px;
`;

const FormulaWrapper = styled.div`
  margin-top: 17px;
  padding: 38px 50px;
  max-width: 336px;
  background: #e7e7e7;

  @media (max-width: 480px) {
    padding: 20px;
  }
`;

const Formula = styled(Typography)`
  font-family: monospace;
`;

const MetricInfo: React.FC<Props> = ({ label, description, formula, hightlighted }) => (
  <Wrapper>
    <Typography variantName="subtitle" textColor={hightlighted ? Colors.PURPLE : Colors.GRIGIO}>
      {label}
    </Typography>
    {description && (
      <DescriptionWrapper>
        <Typography variantName="text">{description}</Typography>
      </DescriptionWrapper>
    )}
    {formula && (
      <FormulaWrapper>
        <Formula variantName="text" textAlign="center">
          {formula}
        </Formula>
      </FormulaWrapper>
    )}
  </Wrapper>
);

export { MetricInfo };
