import * as React from "react";
import { Box, Inline, useChart } from "@freeda/design-system";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  LegendProps,
  Legend,
} from "recharts";
import { formatCompactNumber } from "../pages/MetricsPage/OrganicMetrics/ResultsOverTimeGraph";


type Props<D extends string, C extends string> = {
    data: Record<D | C, unknown>[];
    categories: C[];
  };

export function BiaxialLineChart<D extends string, C extends string>(props: Props<D, C>) {
  const { makeLegendEntry, makeLineProps, containerProps, tooltip } = useChart(
    {}
  );
  const legendContent: Required<LegendProps>["content"] = ({
    payload = [],
  }) => {
    const leftPayload = payload.filter(
      (entry) =>
        entry.payload?.["yAxisId" as keyof typeof entry.payload] === "yAxis1"
    );
    const rightPayload = payload.filter(
      (entry) =>
        entry.payload?.["yAxisId" as keyof typeof entry.payload] === "yAxis2"
    );
    return (
      <Box
        paddingTop={8}
        width="full"
        display="flex"
        style={{ justifyContent: "space-between" }}
      >
        <>{leftPayload.map(makeLegendEntry)}</>
        <Inline space={0} align="right">
          {rightPayload.map(makeLegendEntry)}
        </Inline>
      </Box>
    );
  };

  const lines = props.categories.map((category, index) => (
    <Line
      key={index}
      {...makeLineProps(index)}
      type="monotone"
      yAxisId={"yAxis" + (index + 1)}
      dataKey={category}
    />
  ));

  return (
    <Box width="full">
      <ResponsiveContainer {...containerProps} width="100%" height={300}>
        <LineChart data={props.data}>
          {lines}
          <XAxis dataKey="name" />
          <YAxis yAxisId="yAxis1" tickFormatter={formatCompactNumber} />
          {props.categories.length > 0 && (
            <YAxis yAxisId="yAxis2" orientation="right" tickFormatter={formatCompactNumber} />
          )}
          {tooltip}
          <Legend content={legendContent} />
        </LineChart>
      </ResponsiveContainer>
    </Box>
  );
}
