import gql from "graphql-tag";

const LIST_PERFORMANCE_METRICS = gql`
  query listPerformanceMetrics(
    $where: PerformanceMetricsWhere!
  ) {
    listPerformanceMetrics(where: $where) {
            name,
            value,
            trend
    }
  }
`;

export { LIST_PERFORMANCE_METRICS };
