import ApolloClient from "apollo-client";
import { ALL_INSTAGRAM_STORIES_TITLE } from "../apollo/queries/AllInstagramStoriesTitle";

const titleComparison = (newTitle: string) => (t: { title: string }) => {
  return t.title.toLowerCase().trim() === newTitle.toLowerCase().trim();
};

export const getStoryTitleAvailable = async (
  client: ApolloClient<any>,
  titleStory: string,
  publisherName: string,
  oldTitleStory?: string
) => {
  const result = await client.query<{
    allInstagramStories: any;
  }>({
    query: ALL_INSTAGRAM_STORIES_TITLE,
    fetchPolicy: "no-cache",
    variables: {
      where: {
        freeSearch: titleStory.trim(),
        publisherName
      }
    }
  });

  const igStoriesTitle: Array<{ title: string }> = result.data.allInstagramStories;

  // case story creation, no old title
  if (!oldTitleStory) {
    return !Boolean(igStoriesTitle.find(titleComparison(titleStory)));
  }

  // case story edit, old title
  if (titleStory.toLowerCase().trim() !== oldTitleStory.toLowerCase().trim()) {
    return !Boolean(igStoriesTitle.find(titleComparison(titleStory)));
  }

  // edit story, but with title available, new title is equal to old one
  return true;
};
