import * as React from "react";
import styled from "styled-components";

import { Icon } from "@freeda/react-components";
import Media from "react-media";
const freedaLogo = require("../../assets/freeda_logo.png");

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: -10px;
  top: 20px;
`;

const TechQuote = styled.div`
  font-size: 12px;
  color: #000000;
`;

const FreedaLogo = styled.img`
  height: auto;
  width: 125px;
  top: 18px;
  right: 3px;
  @media (max-width: 768px) {
    position: fixed;
    width: 100px;
    top: 5px;
  }
`;

const HeaderLogoQuote: React.FC<{ style?: React.CSSProperties }> = ({ style }) => {
  return (
    <Media query={{ minWidth: 900 }}>
      {(desktop: boolean) => (
        <Wrapper style={style}>
          {desktop && (
            <TechQuote>
              Designed with
              <Icon iconName="favorite" iconSize="xsmall" iconColor="#FF0A43" style={{ marginLeft: 2 }} /> by Tech Team
            </TechQuote>
          )}
          <FreedaLogo src={freedaLogo} />
        </Wrapper>
      )}
    </Media>
  );
};

export { HeaderLogoQuote };
