import gql from "graphql-tag";

const LIST_METADATA = gql`
  query listMetadata(
    $offset: Int
    $limit: Int
    $orderBy: MetadataOrderBy
    $where: MetadataWhere
  ) {
    listMetadata(
      offset: $offset
      limit: $limit
      orderBy: $orderBy
      where: $where
    ) {
      content {
        id
        label
        active
        category {
          id
          label
          area
        }
        visibleFor {
          id
          label
          active
          category {
            id
            label
            area
          }
        }
        projects {
          id
          label
        }
        brandCategory
        macroTopic
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      aggregate {
        count
      }
    }
  }
`;

export { LIST_METADATA };
