import { Project } from "../../../../types/Project";
import { Platform } from "../../../../types/Platform";
import { PerformanceMetricsSectionContainer as PerformanceMetricsSection } from "./PerformanceMetricsSection";
import { StandAloneMetricsSectionContainer as StandAloneMetricsSection } from "./StandAloneMetricsSection";
import { ResultsOverTimeSection } from "./ResultsOverTimeSection";
import { FollowersComposition } from "./FollowersCompositionSection";
import * as React from "react";
import {
  Box,
  Column,
  Columns,
  SelectField,
  Stack,
} from "@freeda/design-system";

type Props = {
  project: Project;
  platform: Platform;
  languages: { value: string; label: string }[];
};

export function OrganicMetricsPage(props: Props) {
  const [selLanguages, setSelLanguages] = React.useState<string[]>([]);

 

  React.useEffect(() => {
    setSelLanguages(props.languages.map((language) => language.value));
  }, [props.languages]);

  const langOptions = props.languages.map((language) => ({
    label: language.label,
    value: language.value,
    kind: "single-line" as const,
  }));

  const selectLanguages = (languages: string[]) => {
    if(languages.length === 0) return
    setSelLanguages(languages)
  }

  const globalFilters = (
    langOptions.length > 1 && (
      <Box padding={16} background="brandTertiary">
        <Columns space={0}>
            <Column width="1/4">
              <SelectField
                isMulti
                name="language-filter"
                label="Languages"
                placeholder="languages placeholder"
                value={selLanguages}
                options={langOptions}
                onBlur={() => {}}
                onChange={selectLanguages}
                hint="When multiple publishers are selected, their respective performance metrics are summed up, except for “Results over time” section where performance metrics are compared."
              />
            </Column>
        </Columns>
      </Box>
    )
  );

  return (
    <Stack dividers space={24}>
      <Stack space={24}>
        {globalFilters}
        <StandAloneMetricsSection
          selectedPublisherNames={selLanguages}
          project={props.project}
          platform={props.platform}
        />
      </Stack>
      <PerformanceMetricsSection
        selectedPublisherNames={selLanguages}
        project={props.project}
        platform={props.platform}
      />
      <ResultsOverTimeSection
        selectedPublisherNames={selLanguages}
        project={props.project}
        platform={props.platform}
      />
      {(props.platform === "IG") && (
        <FollowersComposition
          selectedPublisherNames={selLanguages}
          project={props.project}
          platform={props.platform}
        />
      )}
    </Stack>
  );
}