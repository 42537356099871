import * as React from "react";
import Media from "react-media";
import { Metadata } from "../../../types/Metadata";
import { Omit } from "../../../types/utils/Omit";
import { MetadataCategory } from "../../../types/MetadataCategory";
import {
  buildDropdownMetadata,
  buildCheckboxMetadata,
  getVisibilityConditions,
  getMetadataDependencies,
  getMetadataBooleanDependencies,
  buildToogleDistribution,
} from "./utils";
import { TransitionStatus } from "react-transition-group/Transition";
import { Dialogable } from "../../../types/Dialogable";
import { BackgroundLayer, WrapperLateralPanel } from "../../ui/LateralPanel";
import {
  ButtonIcon,
  Typography,
  DividerSection,
  Button,
  DialogCard,
  Tooltip,
} from "@freeda/react-components";
import {
  SelectWrapper,
  PostInfo,
  CheckboxWrapper,
  MessageFbGroupWrapper,
} from "./styles";
import { AutocompleteKeywords } from "./AutocompleteKeywords";
import { includes, differenceBy, upperCase } from "lodash";
import { Platform } from "../../../types/Platform";
import { PostType } from "../../../types/PostType";
import { Colors } from "@freeda/react-components/lib/theme";
import { getUserIconOnlyFbGroup } from "../../../utils/getUserIconOnlyFbGroup";
import { Project } from "../../../types/Project";
import { copy, paste } from "../../../utils/metadataCopyPaste";
import { getFormatByPostType } from "../../../ui-specs/specs";
import { FeedbackContext } from "../../../providers/FeedbackProvider";
import {
  distributionIdsByDistribution,
  idByDistribution,
  distributionIdByDistribution,
} from "../../../utils/distributionIdsByDistribution";
import { Distribution } from "../../../types/Distribution";
import { useKeyUp } from "../../../hooks/useKeyUp";
import { BackButton } from "../../ui/BackButton";
import { useQuery } from "@apollo/react-hooks";
import { ALL_PUBLISHER_INFOS } from "../../../apollo/queries/AllPublisherInfos";
import AllPublisherInfosQueryT from "../../../types/graphql/ListPublisherQuery";
import { ProjectType } from "../../../types/ProjectId";
import { ImgPost } from "../PostListTable/styles";

type MetadataWithoutCategory = Omit<Metadata, "category">;

type MetadataByCategory = {
  category: Omit<MetadataCategory, "area">;
  metadata: Array<MetadataWithoutCategory>;
};

type Props = Dialogable & {
  groupedMetadata: Array<MetadataByCategory>;
  booleanMetadata: Array<MetadataWithoutCategory>;
  transitionStatus: TransitionStatus;
  postType: PostType;
  platform: Platform;
  mediaUrl: string | null;
  permalink: string | null;
  originalThumbnailUrl:string|null;
  thumbnailurlbackup:string|null,
  userDefinedMetadata: Array<Metadata>;
  captionPost: string | null;
  messageFbGroup: Array<string> | null;
  titlePost: string | null;
  userPost: string | null;
  peggyUrl:string | null;
  publisherId: string;
  loadingSave: boolean;
  onSave: ({
    connect,
    disconnect,
  }: {
    connect?: Array<string>;
    disconnect?: Array<string>;
  }) => void;
  project: Project;
  social: string;
};

interface State {
  metadataValues: Array<{ categoryId: string; value: string | null }>;
  booleanMetadataValues: Array<{ metadataId: string; value: boolean }>;
  keywords: Array<{ metadataId: string; label: string }>;
  selectedDistribution: Array<Distribution>;
}

const EditPostMetadata: React.FC<Props> = ({
  groupedMetadata,
  booleanMetadata,
  transitionStatus,
  postType,
  platform,
  mediaUrl,
  permalink,
  originalThumbnailUrl,
  thumbnailurlbackup,
  userDefinedMetadata,
  captionPost,
  messageFbGroup,
  titlePost,
  userPost,
  publisherId,
  loadingSave,
  peggyUrl,
  onSave,
  open,
  onClose,
  project,
  social,
}) => {
  const { openSnackbar } = React.useContext(FeedbackContext);
  const placeholderPost = require("../../../assets/placeholderPost.jpg");

  let isDisabled = ([ProjectType.ENGAGE, ProjectType.TRANSACT, ProjectType.HYBRID_PARTIAL].includes(project.projectType!) 
                                                                                      &&  messageFbGroup == null) ? true : false;

  const viewUrlPeggy = [ProjectType.ENGAGE, ProjectType.TRANSACT, ProjectType.HYBRID_PARTIAL].includes(project.projectType!) ? true : false;
                
  const publisherResult = useQuery<AllPublisherInfosQueryT>(
    ALL_PUBLISHER_INFOS
  );

  const selectedPublisher = publisherResult.data!.allPublisherInfos.filter(
    (publisherInfo) => publisherInfo.publisherId === publisherId
  );

  const getDistributionByPublisherName = (
    publisherName: string
  ): Distribution => {
    const allDistributions = Object.keys(Distribution);
    return allDistributions.find((d) =>
      publisherName.includes(d)
    ) as Distribution;
  };

  const selectedDistribution = selectedPublisher.map((publisher) =>
    getDistributionByPublisherName(publisher.name)
  );

  const initialState = {
    metadataValues: groupedMetadata.map((gm) => ({
      categoryId: gm.category.id,
      value: null,
    })),
    booleanMetadataValues: booleanMetadata.map((bm) => ({
      metadataId: bm.id,
      value: false,
    })),
    keywords: [],
    selectedDistribution,
  };

  const [state, setState] = React.useState<State>(initialState);

  const oldUserDefinedMetadataRef = React.useRef<State>(initialState);

  useKeyUp(onClose);

  React.useEffect(() => {
    // initializing with server data
    const newState = {
      ...state,
      metadataValues: groupedMetadata.map((gm) => {
        const metadata = userDefinedMetadata.find(
          (m) => m.category.id === gm.category.id
        );
        return {
          categoryId: gm.category.id,
          value: metadata ? metadata.id : null,
        };
      }),
      booleanMetadataValues: booleanMetadata.map((bm) => {
        const booleanMetadata = userDefinedMetadata.find((m) => m.id === bm.id);
        return {
          metadataId: bm.id,
          value: Boolean(booleanMetadata),
        };
      }),
      keywords: userDefinedMetadata
        .filter((udm) => udm.category.id === "KEYWORDS")
        .map((mf) => ({
          metadataId: mf.id,
          label: mf.label,
        })),
    };

    oldUserDefinedMetadataRef.current = { ...newState };
    setState(newState);
  }, []);

  const updateDropwdownMetadata = (option: any, gm: MetadataByCategory) => {
    const metadataCategoryId = gm.category.id;
    const metadataDependencies = getMetadataDependencies(
      postType,
      metadataCategoryId,
      platform
    );
    setState((state) => ({
      ...state,
      metadataValues: state.metadataValues.map((c) => {
        if (c.categoryId === metadataCategoryId) {
          return { ...c, value: option ? option.value : null };
        }
        if (
          includes(metadataDependencies, c.categoryId) &&
          c.categoryId !== "SERIES"
        ) {
          return { ...c, value: null };
        }
        return c;
      }),
    }));
  };

  const updateCheckboxMetadata = (bm: MetadataWithoutCategory) => {
    const dependencies = getMetadataBooleanDependencies(
      postType,
      bm.id,
      booleanMetadata,
      platform
    );
    setState((state) => ({
      ...state,
      booleanMetadataValues: state.booleanMetadataValues.map((b) => {
        if (b.metadataId === bm.id) {
          return { ...b, value: !b.value };
        }
        return b;
      }),
      metadataValues: state.metadataValues.map((c) => {
        if (includes(dependencies, c.categoryId)) {
          return { ...c, value: null };
        }
        return c;
      }),
    }));
  };

  const onChangeKeywords = (keywords: {
    metadataId: string;
    label: string;
  }) => {
    const exists = state.keywords.find(
      (m) => m.metadataId === keywords.metadataId
    );

    setState((state) => ({
      ...state,
      keywords: exists
        ? state.keywords.filter((m) => m.metadataId !== keywords.metadataId)
        : [keywords, ...state.keywords],
    }));
  };

  const save = () => {
    const initialValue: {
      connect: Array<string>;
      disconnect: Array<string>;
    } = { connect: [], disconnect: [] };
    const categoryConnectDisconnect = state.metadataValues.reduce(
      (prev, next) => {
        const newMetadata = next;
        const oldMetadata = oldUserDefinedMetadataRef.current.metadataValues.find(
          (oldM) => oldM.categoryId === newMetadata.categoryId
        )!;

        // both with a value
        if (oldMetadata.value && newMetadata.value) {
          // but with different value: the new one win!
          if (oldMetadata.value !== newMetadata.value) {
            return {
              disconnect: [...prev.disconnect, oldMetadata.value],
              connect: [...prev.connect, newMetadata.value],
            };
          }
          // we have removed the old one because the new one is null!
        } else if (oldMetadata.value && !newMetadata.value) {
          return {
            ...prev,
            disconnect: [...prev.disconnect, oldMetadata.value],
          };
          // we have added a new metadata because the old was null!
        } else if (!oldMetadata.value && newMetadata.value) {
          return { ...prev, connect: [...prev.connect, newMetadata.value] };
        }

        return prev;
      },
      initialValue
    );

    const categoryAndBooleanConnectDisconnect = state.booleanMetadataValues
      .filter((m) => !includes(Object.values(idByDistribution), m.metadataId))
      .reduce((prev, next) => {
        const newBooleanMetadata = next;
        const oldBooleanMetadata = oldUserDefinedMetadataRef.current.booleanMetadataValues.find(
          (oldM) => oldM.metadataId === newBooleanMetadata.metadataId
        )!;

        if (!oldBooleanMetadata.value && newBooleanMetadata.value) {
          return {
            ...prev,
            connect: [...prev.connect, newBooleanMetadata.metadataId],
          };
        } else if (oldBooleanMetadata.value && !newBooleanMetadata.value) {
          return {
            ...prev,
            disconnect: [...prev.disconnect, oldBooleanMetadata.metadataId],
          };
        }
        return prev;
      }, categoryConnectDisconnect);

    const keywordsConnect = differenceBy(
      state.keywords,
      oldUserDefinedMetadataRef.current.keywords,
      "metadataId"
    ).map((m) => m.metadataId);

    const keywordsDisconnect = differenceBy(
      oldUserDefinedMetadataRef.current.keywords,
      state.keywords,
      "metadataId"
    ).map((m) => m.metadataId);

    const newDistributionIds = distributionIdsByDistribution(
      state.selectedDistribution
    );
    const oldDistributionIds = distributionIdsByDistribution(
      oldUserDefinedMetadataRef.current.selectedDistribution
    );

    const distributionConnect = differenceBy(
      newDistributionIds,
      oldDistributionIds
    );
    const distributionDisconnect = differenceBy(
      oldDistributionIds,
      newDistributionIds
    );

    const finalResult = {
      connect: [
        ...categoryAndBooleanConnectDisconnect.connect,
        ...keywordsConnect,
        ...distributionConnect,
      ],
      disconnect: [
        ...categoryAndBooleanConnectDisconnect.disconnect,
        ...keywordsDisconnect,
        ...distributionDisconnect,
      ],
    };

    return finalResult;
  };

  const onClickCopy = (closeCard: () => void) => {
    const booleanMetadataCopy = state.booleanMetadataValues
      .map((m) => ({
        categoryId: "BOOLEAN",
        metadataId: m.metadataId,
        value: m.value,
      }))
      .filter((m) => !includes(Object.values(idByDistribution), m.metadataId));

    const keywordsCopy = state.keywords.map((mf) => ({
      categoryId: "KEYWORDS",
      metadataId: mf.metadataId,
      label: mf.label,
    }));

    const metadataCopy = state.metadataValues.map((m) => ({
      categoryId: m.categoryId,
      metadataId: m.value,
    }));

    const distributionMetadataCopy = state.selectedDistribution.map((m) => ({
      categoryId: "BOOLEAN",
      metadataId: distributionIdByDistribution(m),
      value: true,
      label: String(m),
    }));

    const allMetadataCopy = [
      ...booleanMetadataCopy,
      ...keywordsCopy,
      ...metadataCopy,
      ...distributionMetadataCopy,
    ];

    copy(social, project.idLegacy, allMetadataCopy);
    closeCard();
  };

  const onClickPaste = (closeCard: () => void) => {
    const copiedMetadata = paste(social, project.idLegacy);
    if (!copiedMetadata) {
      return;
    }
    const booleanMetadataValues: Array<{
      metadataId: string;
      value: boolean;
    }> = copiedMetadata
      .filter((m) => m.categoryId === "BOOLEAN")
      .map((m) => ({
        metadataId: m.metadataId!,
        value: m.value!,
      }));

    const metadataValues: Array<{
      categoryId: string;
      value: string | null;
    }> = copiedMetadata
      .filter((m) => m.categoryId !== "BOOLEAN" && m.categoryId !== "KEYWORDS")
      .map((m) => ({ categoryId: m.categoryId, value: m.metadataId }));

    const keywords: Array<{
      metadataId: string;
      label: string;
    }> = copiedMetadata
      .filter((m) => m.categoryId === "KEYWORDS")
      .map((m) => ({
        metadataId: m.metadataId!,
        label: m.label!,
      }));

    const selectedDistribution = copiedMetadata
      .filter(
        (m) =>
          includes(Object.values(idByDistribution), m.metadataId) &&
          Boolean(m.value)
      )
      .map((m) => m.label! as Distribution);

    setState((state) => ({
      ...state,
      booleanMetadataValues,
      metadataValues,
      keywords,
      //selectedDistribution,
    }));
    closeCard();
  };

  const { metadataValues, booleanMetadataValues, keywords } = state;

  const visibilityConditions = getVisibilityConditions(
    postType,
    platform,
    booleanMetadata,
    booleanMetadataValues
  );

  const dropdownMetadata = buildDropdownMetadata(
    postType,
    platform,
    groupedMetadata,
    metadataValues.map((m) => ({ ...m, projects: [{ id: project.idLegacy }] })),
    visibilityConditions,
    updateDropwdownMetadata
  );
  const checkboxMetadata = buildCheckboxMetadata(
    postType,
    platform,
    booleanMetadata,
    state.booleanMetadataValues,
    updateCheckboxMetadata
  );

  const copiedMetadata = paste(social, project.idLegacy);

  const categoryIdPostType = getFormatByPostType(postType);

  const categoryIdIsEqualToLocalStorage = copiedMetadata
    ? Boolean(
        copiedMetadata.find((cm) => cm.categoryId === categoryIdPostType.id)
      )
    : false;

  const isFbGroup = platform === "FBG";

  const disabledPasteButton = isFbGroup
    ? !Boolean(copiedMetadata)
    : !Boolean(copiedMetadata && categoryIdIsEqualToLocalStorage);

  const onSelectedOption = (selectedDistribution: Array<Distribution>) => {
    setState((state) => ({
      ...state,
      selectedDistribution: selectedDistribution.map(
        (d) => upperCase(d) as Distribution
      ),
    }));
  };


  const toogleDistribution = buildToogleDistribution(
    postType,
    platform,
    state.selectedDistribution,
    onSelectedOption
  );

  return (
    <Media query={{ maxWidth: 768 }}>
      {(matches: boolean) => (
        <>
          <BackgroundLayer
            open={open}
            onClick={onClose}
            className={`background-${transitionStatus}`}
          />
          <WrapperLateralPanel
            open={open}
            className={`wrapper-${transitionStatus}`}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <BackButton onClick={onClose} />

              

              <div  style={{ display: "flex", alignItems: "center" }}>
                
                    {viewUrlPeggy && 
                      <Tooltip
                        referenceChildren={
                          <ButtonIcon
                          onClick={ () => peggyUrl ?  window.open( localStorage.getItem("peggyUrlEdit")!.concat(peggyUrl!), '_blank') : window.open( localStorage.getItem("peggyUrl")! , '_blank' ) }
                          iconName="reply"
                          iconSize="medium"
                          iconColor={peggyUrl ? Colors.GRIGINO : Colors.ROSSINO}
                          style={{ marginBottom: 0, display: "flex", alignItems: "center" }}
                        >
                        </ButtonIcon>
                          
                        }
                        tooltipChildren={
                          <Typography
                            variantName="text"
                            textColor={Colors.DARKpurple}
                            style={{ fontWeight: 10, cursor: "pointer" }}
                            textAlign="center"
                          >
                            Go to Peggy
                          </Typography>
                        }
                        referenceStyle={{ display: "flex", alignItems: "center" }}
                        position="bottom"
                      />
                    }
                    <ButtonIcon
                      disabled={isDisabled}
                      iconName="save"
                      loading={loadingSave}
                      iconColor={Colors.ROSSINO}
                      style={{ marginLeft: 10, marginRight: 10 }}
                      onClick={async () => {
                        const { connect, disconnect } = save();
                        await onSave({ connect, disconnect });
                      }}
                    />
                    <DialogCard
                      referenceChildren={
                        <ButtonIcon
                          iconName="more-vert"
                          iconColor={Colors.GRIGINO}
                        />
                      }
                      tooltipChildren={(onCloseCard) => (
                        <div>
                          <Button
                            disabled={isDisabled}
                            style={{ width: "100%", marginBottom: 8 }}
                            flat
                            onClick={() => {
                              onClickCopy(onCloseCard);
                              openSnackbar(
                                "Labels copied correctly",
                                "notification"
                              );
                            }}
                          >
                            Copy tag
                          </Button>
                          <Button
                            style={{ width: "100%", marginTop: 8 }}
                            flat
                            disabled={disabledPasteButton || isDisabled }
                            onClick={() => {
                              onClickPaste(onCloseCard);
                              openSnackbar(
                                "Metadata pasted correctly",
                                "notification"
                              );
                            }}
                          >
                            Paste tag
                          </Button>
                        </div>
                      )}
                      position="bottom-end"
                    />
              </div>
            </div>
            <div
              style={{
                padding: 15,
                boxSizing: "border-box",
                height: !matches ? "calc(100% - 50px)" : "100%",
                flexDirection: "column",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div style={{pointerEvents: isDisabled ? 'none': 'all'} }>
                <PostInfo
                  style={{
                    flexDirection: messageFbGroup ? "column" : "row",
                    alignItems: messageFbGroup ? "flex-start" : "flex-end",
                  }}
                >
                
                    
                    <ImgPost
                        style={{
                          height: 80,
                          width: "auto",
                          marginRight: 20,
                          marginBottom: messageFbGroup ? 15 : 0,
                        }}
                        //src={platform === "IG" ? permalink! : mediaUrl!}
                        src={platform !== "IG" ? mediaUrl! : permalink ? permalink : thumbnailurlbackup!}
                        
                        onError={(e) => (e.currentTarget.src = originalThumbnailUrl ?  
                                                                  originalThumbnailUrl : thumbnailurlbackup ? 
                                                                                      thumbnailurlbackup : placeholderPost)}
                      />
                 

                  <div style={{ alignSelf: "center" }}>
                    {getUserIconOnlyFbGroup(messageFbGroup, userPost)}
                    {titlePost && (
                      <Typography
                        variantName="title"
                        style={{
                          fontSize: 20,
                          marginTop: messageFbGroup ? 10 : 0,
                        }}
                      >
                        {titlePost}
                      </Typography>
                    )}
                    {captionPost && (
                      <Typography
                        variantName="text"
                        style={{
                          fontSize: 14,
                          lineHeight: "18px",
                          color: "#777777",
                        }}
                      >
                        {captionPost}
                      </Typography>
                    )}
                    {messageFbGroup && (
                      <MessageFbGroupWrapper
                        style={{ marginTop: !titlePost ? 10 : 5 }}
                      >
                        {messageFbGroup.map((m, i) => (
                          <Typography
                            variantName="text"
                            style={{
                              fontSize: 14,
                              lineHeight: "18px",
                              color: "#777777",
                            }}
                            key={i}
                          >
                            {m}
                          </Typography>
                        ))}
                      </MessageFbGroupWrapper>
                    )}
                  </div>
                </PostInfo>
                <SelectWrapper>{dropdownMetadata}</SelectWrapper>
                <DividerSection />

                {project.idLegacy === "FREEDA" && toogleDistribution}

                <CheckboxWrapper>{checkboxMetadata}</CheckboxWrapper>
                <DividerSection />
                <AutocompleteKeywords
                  keywords={keywords}
                  onChangeKeywords={onChangeKeywords}
                  project={project}
                />
              </div>
            </div>
          </WrapperLateralPanel>
        </>
      )}
    </Media>
  );
};
//// pointerEvents: isDisabled ? 'none': 'all',
export { EditPostMetadata };
