import gql from "graphql-tag";

const PROJECTS_TO_SHOW = gql`
  {
    projectsToShow { 
      platform, hasCountries, withBrand, withPublisher, 
      project { id, idLegacy, label, projectType, active } 
  }
  }
`;

export { PROJECTS_TO_SHOW };
