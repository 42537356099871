import * as React from "react";
import { useLocation } from "react-router-dom";
import { DividerSection, ButtonIcon } from "@freeda/react-components";
import styled from "styled-components";
import { parse } from "query-string";
import Media from "react-media";
import { useQuery } from "react-apollo";
import { SearchFilter } from "../ui/SearchFilter/SearchFilter";
import { HeaderLogoQuote } from "../ui/HeaderLogoQuote";
import { Metric } from "../../types/Metric";
import { MetricInfo } from "../ui/MetricInfo";
import { ALL_METRICS } from "../../apollo/queries/AllMetrics";
import { Platform } from "../../types/Platform";
import { PlaceholdersMetricInfo } from "../ui/Placeholders/PlaceholderMetricInfo";
import { StateButton } from "../ui/StateButton";
import { Colors } from "@freeda/react-components/lib/theme";
import { useWindowSize } from "../../hooks/useWindowSize";
import { CSSTransition } from "react-transition-group";

const StyledDividerSection = styled(DividerSection)`
  margin: 40px 0;
  @media (max-width: 480px) {
    margin: 40px 20px;
  }
`;

const TopArrowButtonIcon = styled(ButtonIcon)`
  opacity: 0;
  transition: opacity 0.3s;
  && {
    & > i {
      font-size: 25px !important;
    }
  }

  &.arrowButtonIcon-entering {
    opacity: 0;
  }
  &.arrowButtonIcon-entered {
    opacity: 1;
  }
  &.arrowButtonIcon-exiting {
    opacity: 0;
  }
  &.arrowButtonIcon-exited {
    opacity: 0;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  overflow-x: overlay;
`;

const MetricsDocPage: React.FC = () => {
  const location = useLocation();
  const { platform, name } = parse(location.search);



  const [searchValue, setSearchValue] = React.useState<string>("");
  const [selectedPlatform, setSelectedPlatform] = React.useState<
    Exclude<Platform, Platform.IG_FRAME | Platform.IG_STORY>
  >(platform || Platform.FB);
  const [selectedMetricName, setSelectedMetricName] = React.useState<string | null>(name || null);
  const [showScrollTopButton, setShowScrollTopButton] = React.useState<boolean>(false);

  const firstLoading = React.useRef<boolean>(true);

  const { loading, data } = useQuery<{ allMetrics: Array<Metric> }, any>(ALL_METRICS, {
    variables: {
      where: {
        labelLike: searchValue,
        platformId: selectedPlatform,
      },
    },
  });

  React.useEffect(() => {
    const initialPathname = window.location.pathname;
    let path = `${initialPathname}?platform=${selectedPlatform}`;
    if (selectedMetricName) {
      path += `?name=${selectedMetricName}`;
    }
    window.history.replaceState(window.history.state, initialPathname, path);
  }, [selectedPlatform, selectedMetricName]);

  React.useEffect(() => {
    if (data && firstLoading.current) {
      firstLoading.current = false;

      const selectedMetricDiv = document.getElementById(name);

      if (selectedMetricDiv) {
        const { top } = selectedMetricDiv.getBoundingClientRect();
        window.scrollTo({ left: 0, top: top - 40, behavior: "smooth" });
      }
    }
  }, [data]);

  const setSelectedPlatformAndResetName = (platform: Exclude<Platform, Platform.IG_FRAME | Platform.IG_STORY>) => {
    setSelectedPlatform(platform);
    setSelectedMetricName(null);
  };

  const { windowHeight } = useWindowSize();

  React.useEffect(() => {
    const onWindowScroll = (_: Event) => {
      setShowScrollTopButton(window.scrollY > windowHeight / 2);
    };

    window.addEventListener("scroll", onWindowScroll);
    return () => window.removeEventListener("scroll", onWindowScroll);
  }, []);

  return (
    <>
      <Media query={{ minWidth: 769 }}>{(desktop: boolean) => <>{desktop && <HeaderLogoQuote />}</>}</Media>
      <SearchFilter
        placeholder="Text here a metric name"
        searchValue={searchValue}
        onTextFilterChange={(value) => setSearchValue(value)}
        clearFilter={() => setSearchValue("")}
      />
      <DividerSection style={{ position: "relative", top: 42 }} />
      <MenuWrapper>
        <StateButton
          onClick={() => setSelectedPlatformAndResetName(Platform.FB)}
          isactive={selectedPlatform === Platform.FB}
          flat
        >
          Facebook
        </StateButton>
        <StateButton
          onClick={() => setSelectedPlatformAndResetName(Platform.FBG)}
          isactive={selectedPlatform === Platform.FBG}
          flat
        >
          Facebook Group
        </StateButton>
        <StateButton
          onClick={() => setSelectedPlatformAndResetName(Platform.IG)}
          isactive={selectedPlatform === Platform.IG}
          flat
        >
          Instagram
        </StateButton>
        <StateButton
          onClick={() => setSelectedPlatformAndResetName(Platform.YT)}
          isactive={selectedPlatform === Platform.YT}
          flat
        >
          Youtube
        </StateButton>
        <StateButton
          onClick={() => setSelectedPlatformAndResetName(Platform.TK)}
          isactive={selectedPlatform === Platform.TK}
          flat
        >
          TikTok
        </StateButton>
      </MenuWrapper>
      {(loading || !data) && <PlaceholdersMetricInfo />}
      {data && (
        <div style={{ margin: "25px 0" }}>
          {data.allMetrics.length === 0 && "No metrics found :("}
          {data
            .allMetrics
            .sort( (a,b) => a.label.localeCompare(b.label) )
            .map((m, i) => (
              <div key={m.name} id={m.name}>
                <MetricInfo
                  hightlighted={m.name === selectedMetricName}
                  label={m.label}
                  description={m.description}
                  formula={m.formula}
                />
                {i < data.allMetrics.length - 1 && <StyledDividerSection />}
              </div>
          ))}
        </div>
      )}
      <CSSTransition in={showScrollTopButton} timeout={{ enter: 300, exit: 500 }} unmountOnExit>
        {(status) => (
          <TopArrowButtonIcon
            className={`arrowButtonIcon-${status}`}
            iconColor={Colors.WHITE}
            style={{
              position: "fixed",
              bottom: 20,
              right: 20,
              background: Colors.TRANSPARENTPURPLE,
            }}
            iconName="arrow-up"
            onClick={() => window.scrollTo({ left: 0, top: 0, behavior: "smooth" })}
          />
        )}
      </CSSTransition>
    </>
  );
};

export default MetricsDocPage;
