import * as React from "react";

import PostListFilter from ".";
import { AdvancedFilterPost } from "./AdvancedFilterPost";
import { FilterContextT } from "../../../providers/FilterProvider";
import { FilteredAutocompleteMetadata } from "../../../container/FilteredAutocompleteMetadata";
import { useFilterMobile } from "../../../hooks/useFilterMobile";
import { Project } from "../../../types/Project";
import { InstagramPostType } from "../../../types/InstagramPostType";
import { PostType } from "../../../types/PostType";

interface Props {
  igPostFilterContext: React.Context<FilterContextT>;
  project: Project;
  publisherSelectComponent?: (matches: boolean) => React.ReactElement<any>;
  brandedComponent?: (matches: boolean) => React.ReactElement<any>;
}

export const IgPostListFilter: React.FC<Props> = ({
  igPostFilterContext,
  project,
  brandedComponent,
  publisherSelectComponent,
}) => {
  const { onCloseFilterMobile, toggleShowFilter, showFilterMobile } = useFilterMobile();
  const { filterState, callbacks } = React.useContext(igPostFilterContext);
  const { searchValue, selectedFormat, dateRange } = filterState;
  const { clearFilter, onTextFilterChange, onChangeFormat, onChangeUpdateDateRange } = callbacks;
  
  const availablePostFormat: Array<PostType> = Object.keys(InstagramPostType).map((postType) => postType as PostType);

  
  return (
    <PostListFilter
      searchValue={searchValue}
      clearFilter={clearFilter}
      onTextFilterChange={onTextFilterChange}
      showFilter={toggleShowFilter}
      showFilterMobile={showFilterMobile}
      renderAdvancedFilter={(status) => (
        <AdvancedFilterPost
          transitionStatus={status}
          format={{
            onChangeFormat,
            availablePostFormat,
            selectedFormat,
          }}
          dateRange={dateRange}
          onChangeUpdateDateRange={onChangeUpdateDateRange}
          onCloseFilterMobile={onCloseFilterMobile}
          showFilterMobile={showFilterMobile}
          onClearFilterMobile={clearFilter}
          brandedComponent={brandedComponent}
          publisherSelectComponent={publisherSelectComponent}
          autocompleteMetadataComponent={
            <FilteredAutocompleteMetadata FilterContext={igPostFilterContext} project={project} />
          }
        />
      )}
    />
  );
};
