import * as React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  padding-top: 26px;
  @media (max-width: 768px) {
    padding: 20px;
    padding-top: 26px;
  }
`;

export const NotFound: React.FC<{ style?: React.CSSProperties }> = ({ children, style }) => {
  return <Wrapper style={style}>{children}</Wrapper>;
};
