import * as React from "react";
import { Select } from "@freeda/react-components";
import { PlaceholderInput } from "../components/ui/Placeholders/PlaceholderInput";
import { useQuery } from "@apollo/react-hooks";
import { LIST_METADATA_CATEGORIES } from "../apollo/queries/ListMetadataCategories";
import ListMetadataCategoriesQueryT from "../types/graphql/ListMetadataCategoriesQuery";

interface Props {
  onCategorySelection: (categoryId: string) => void;
  selectedCategoryId: string | null;
  width?: number | string;
  style?: React.CSSProperties;
}

const SelectCategory: React.FC<Props> = ({ onCategorySelection, selectedCategoryId, width, style }) => {
  const listMetadataCategoriesResult = useQuery<ListMetadataCategoriesQueryT>(LIST_METADATA_CATEGORIES);

  if (listMetadataCategoriesResult.loading) {
    return <PlaceholderInput style={{ marginTop: 22, marginRight: 30, flexBasis: "170px" }} />;
  }

  const metadataCategories = listMetadataCategoriesResult.data!.listMetadataCategories.content;

  const selectedCategory = metadataCategories.find(mc => mc.id === selectedCategoryId);

  return (
    <Select
      style={{ width, ...style }}
      placeholder="Select category"
      label="Label Category"
      optionsSort="asc"
      onChange={(categoryId: any) => onCategorySelection(categoryId ? categoryId.value : null)}
      options={metadataCategories.map(m => ({
        value: m.id,
        label: m.label
      }))}
      value={
        selectedCategory
          ? {
              value: selectedCategory.id,
              label: selectedCategory.label
            }
          : null
      }
    />
  );
};

export { SelectCategory };
