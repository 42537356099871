import * as React from "react";
import { Typography, Paginator, Select } from "@freeda/react-components";
import Media from "react-media";

import { TableFilterWrapper, InfoResults } from "../../ui/TableFilterWrapper";
import { FieldWrapper, MobileLabelWrapper } from "../../ui/FieldWrapper";
import { TableStyled } from "./styles";
import {
  EditPostMetadataContainer,
  PostMetadataContentInfo,
} from "../../../container/EditPostMetadata";
import { CSSTransition } from "react-transition-group";
import { FacebookContent } from "../../../types/FacebookContent";
import { InstagramContent } from "../../../types/InstagramContent";
import { PostOrderBy } from "../../../types/graphql/PostOrderBy";
import { PostExportCSVButton } from "../../../container/PostExportCSVButton";
import {
  MetricOrderBy,
  FilterContextT,
} from "../../../providers/FilterProvider";
import { getRealSizeAndOptionsSelect } from "../../../utils/getRealSizeAndOptionsSelect";
import { PaginatorStyled } from "../../ui/PaginatorStyled";
import { PublicationType } from "../../../types/Publication";
import {
  buildPostMetricTableHeader,
  ResponsiveTableHead,
} from "../../../utils/buildPostTableHeader";
import { CustomMetricsContainer } from "../../../container/CustomMetrics";
import { Average } from "../../../types/Average";
import {
  MetricColumnSetsContext,
  ColumnSetType,
} from "../../../providers/MetricColumnSetsProvider";
import { Platform } from "../../../types/Platform";
import { PostType } from "../../../types/PostType";
import { SocialContent } from "../../../types/SocialContent";
import { getColumnSetByName } from "../../../apollo/utils";
import { YoutubeContent } from "../../../types/YoutubeContent";
import { FacebookGroupContent } from "../../../types/FacebookGroupContent";
import { useWindowSize } from "../../../hooks/useWindowSize";
import { Project } from "../../../types/Project";
//import { ProjectId } from "../../../types/ProjectId";
import { Order } from "../../../types/Order";
import { TikTokContent } from "../../../types/TiktokContent";

/*import {
  ButtonIcon
} from "@freeda/react-components";*/
//import { Colors } from "@freeda/react-components/lib/theme";

interface Props {
  defaultHead: ResponsiveTableHead;
  buildColumns: (
    maxWidth: number,
    publicationType: PublicationType,
    customColumn: string | null,
    toggleEditPostMetadata: (postId: string) => void,
    project: Project,
    updateSocialContentMetadataMutation?: [any, any],
    selectedFormat?: PostType | null
  ) => Array<any>;
  posts: Array<
    | FacebookContent
    | FacebookGroupContent
    | InstagramContent
    | TikTokContent
    | YoutubeContent
  >;
  averages: Array<Average>;
  loading: boolean;
  currentPage: number;
  pageSize: number;
  totalPages: number;
  onChangePageSize: (numberPage: string) => void;
  onChangePage: (page: number) => void;
  onBackPage: () => void;
  onNextPage: () => void;
  onChangePostOrder: (orderBy: PostOrderBy, order: Order) => void;
  onChangeMetricOrder: (metricOrder: MetricOrderBy, order: Order) => void;
  orderBy: string | null;
  order: Order;
  style?: React.CSSProperties;
  count: number;
  publicationType: PublicationType;
  platform: Platform;
  updateSocialContentMetadataMutation?: [any, any];
  ColumnSetSelect: React.FC<{
    onOpenCustomPanel: () => void;
    width?: string | number;
  }>;
  PostFilterContext: React.Context<FilterContextT>;
  exportQuery: any;
  columnSetType: ColumnSetType;
  project: Project;
  fixedColumnNumbers?: number;
  paddingLeftFirstTh?: string;
  publisherName?: string;
  publisherId?: string;
  wheresFilter?: {};
  listContentMetadataQuery?: any;
}

interface State {
  showEditPostMetadataPage: boolean;
  postId: string | null;
  showCustomMetrics: boolean;
  filterWrapperTop: number | null;
}

const PostListTable = ({
  defaultHead,
  buildColumns,
  posts,
  averages,
  loading,
  currentPage,
  pageSize,
  totalPages,
  onChangePageSize,
  onChangePage,
  onBackPage,
  onNextPage,
  onChangePostOrder,
  onChangeMetricOrder,
  orderBy,
  order,
  style,
  count,
  publicationType,
  platform,
  updateSocialContentMetadataMutation,
  ColumnSetSelect,
  PostFilterContext,
  exportQuery,
  columnSetType,
  project,
  fixedColumnNumbers,
  paddingLeftFirstTh,
  publisherName,
  publisherId,
}: Props) => {
  
  const [state, setState] = React.useState<State>({
    showEditPostMetadataPage: false,
    postId: null,
    showCustomMetrics: false,
    filterWrapperTop: null,
  });

  const { selectedSets } = React.useContext(MetricColumnSetsContext);
  const { filterState } = React.useContext(PostFilterContext);

  const maxWidth = useWindowSize().windowWidth;

  const onCloseEditPostMetadataPage = () => {
    setState({ ...state, showEditPostMetadataPage: false });
  };

  const onOpenCustomMetricsPanel = () =>
    setState({ ...state, showCustomMetrics: true });
  
  const onCloseCustomMetricsPanel = () =>
    setState({ ...state, showCustomMetrics: false });

  const toggleEditPostMetadata = (postId: string) => {
    setState({
      ...state,
      postId,
      showEditPostMetadataPage: true,
    });
  };

  const buildPostContentInfo = (
    selectedPost:
      | FacebookContent
      | FacebookGroupContent
      | InstagramContent
      | TikTokContent
      | YoutubeContent
  ): PostMetadataContentInfo => {
    if ("isStory" in selectedPost) {
      return {
        caption: selectedPost.caption,
        title: selectedPost.title,
        permalink: selectedPost.permalink,
        postPreview: selectedPost.thumbnailUrl || selectedPost.mediaUrl,
        originalThumbnailUrl: selectedPost.originalThumbnailUrl,
        thumbnailurlbackup:selectedPost.thumbnailUrlBackup,
        type: selectedPost.type,
        userDefinedMetadata: selectedPost.userDefinedMetadata,
        peggyUrl:selectedPost.peggyUrl,
      };
    }
    if ("title" in selectedPost && "caption" in selectedPost) { //IG permalink
      return {
        title: selectedPost.title,
        caption: selectedPost.caption,
        permalink: selectedPost.permalink,
        originalThumbnailUrl:selectedPost.originalThumbnailUrl,
        thumbnailurlbackup:null,
        postPreview: selectedPost.mediaUrl,
        type: selectedPost.type,
        userDefinedMetadata: selectedPost.userDefinedMetadata,
        peggyUrl:selectedPost.peggyUrl,
      };
    }
    if ("message" in selectedPost) {
      return {
        title: selectedPost.title,
        userType: selectedPost.from,
        messageFbGroup: selectedPost.message,
        permalink: selectedPost.permalink,
        originalThumbnailUrl:null,
        postPreview: selectedPost.mediaUrl,
        type: selectedPost.type,
        userDefinedMetadata: selectedPost.userDefinedMetadata,
        peggyUrl:null,
        thumbnailurlbackup:null,
      };
    }
    if ("photo" in selectedPost && "url" in selectedPost) {
      //TK
      return {
        title: selectedPost.description,
        userType: selectedPost.username,
        permalink: selectedPost.url,
        originalThumbnailUrl:selectedPost.originalThumbnailUrl,
        postPreview: selectedPost.photo,
        type: "TIKTOK",
        userDefinedMetadata: selectedPost.userDefinedMetadata,
        peggyUrl:selectedPost.peggyUrl,
        thumbnailurlbackup:null
      };
    }
    return {
      title: selectedPost.title,
      caption: selectedPost.description,
      permalink: selectedPost.thumbnailsDefault,
      originalThumbnailUrl: null,
      postPreview: selectedPost.thumbnailsDefault,
      type: "YOUTUBE",
      userDefinedMetadata: selectedPost.userDefinedMetadata,
      peggyUrl:selectedPost.peggyUrl,
      thumbnailurlbackup:null
    };
  };

  const selectedPost = posts.find(
    (p: SocialContent) => p.id.contentId === state.postId
  );
  const { optionsSelect, realSize } = getRealSizeAndOptionsSelect(
    count,
    pageSize
  );

  let selectedPostWithContentInfo: PostMetadataContentInfo & {
    publisherId: string;
  };

  if (selectedPost) {
    selectedPostWithContentInfo = {
      ...buildPostContentInfo(selectedPost),
      publisherId: selectedPost.id.publisherId,
    };
  }

  const fixedColumn = fixedColumnNumbers ? fixedColumnNumbers : 1;

  const metricsTableHeader = buildPostMetricTableHeader(
    publicationType,
    selectedSets[columnSetType],
    filterState.selectedFormat,
    averages
  );

  const head = maxWidth > 768 ? defaultHead.desktop : defaultHead.mobile;
  const headWithMetrics =
    maxWidth > 768 ? [...head, ...metricsTableHeader] : head;

  const columns = buildColumns(
    maxWidth,
    publicationType,
    selectedSets[columnSetType],
    toggleEditPostMetadata,
    project,
    updateSocialContentMetadataMutation,
    filterState.selectedFormat
  );

  let story = undefined;

  if (publicationType === PublicationType.IG_FRAME) {
    story = true;
  }

  if (publicationType === PublicationType.IG_POST) {
    story = false;
  }

  const TableFilter =
    maxWidth > 768 ? (
      <TableFilterWrapper>
        <FieldWrapper>
          <Typography variantName="label" style={{ marginRight: 10 }}>
            Rows
          </Typography>
          <Select
            style={{ width: 70, marginRight: 10 }}
            isSearchable={false}
            isClearable={false}
            onChange={(pageSize: any) =>
              onChangePageSize(pageSize ? pageSize.value : null)
            }
            options={optionsSelect}
            value={{ label: String(realSize), value: String(realSize) }}
          />
         
          <Media query={{ maxWidth: 840 }}>
            {(matches: boolean) => (
              <>
                {!matches && (
                  <InfoResults>
                    {realSize} of {count}
                  </InfoResults>
                )}
              </>
            )}
          </Media>
        </FieldWrapper>
        <div style={{ display: "flex", alignItems: "center" }}>
          <ColumnSetSelect
            width={150}
            onOpenCustomPanel={onOpenCustomMetricsPanel}
          />
          <PostExportCSVButton
            publicationId={publicationType}
            query={exportQuery}
            FilterContext={PostFilterContext}
            publisherName={publisherName}
            publisherId={publisherId}
            getColumnSet={(
              publicationId: PublicationType,
              selectedFormat: PostType | null
            ) => {
              return getColumnSetByName(
                publicationId,
                selectedSets[columnSetType] ||
                  `${selectedFormat || "ALL"}_${publicationId}`,
                Boolean(selectedSets[columnSetType])
              );
            }}
            story={story}
            project={project}
          />
          <Paginator
            currentPage={currentPage}
            totalPage={totalPages}
            pageSize={realSize}
            onChangePage={onChangePage}
            onBack={onBackPage}
            onNext={onNextPage}
            style={{ marginLeft: 20 }}
          />
        </div>
      </TableFilterWrapper>
    ) : (
      <div style={{ margin: "15px 0px" }}>
        <FieldWrapper style={{ display: "flex" }}>
          <MobileLabelWrapper>
            <Typography variantName="label" style={{ marginRight: 10 }}>
              Rows
            </Typography>
          </MobileLabelWrapper>
          <Select
            style={{ marginRight: 10 }}
            isSearchable={false}
            isClearable={false}
            onChange={(pageSize: any) =>
              onChangePageSize(pageSize ? pageSize.value : null)
            }
            options={optionsSelect}
            value={{ label: String(realSize), value: String(realSize) }}
          />

         
          <InfoResults style={{ marginRight: 0 }}>
            {realSize} of {count}
          </InfoResults>
        </FieldWrapper>
        <PaginatorStyled
          currentPage={currentPage}
          totalPage={totalPages}
          pageSize={realSize}
          onChangePage={onChangePage}
          onBack={onBackPage}
          onNext={onNextPage}
          selectWidth={"100%"}
        />
      </div>
    );

  return (
    <>
    
      {updateSocialContentMetadataMutation && (
        <CSSTransition
          in={state.showEditPostMetadataPage}
          timeout={{ enter: 300, exit: 500 }}
          classNames=""
          onExited={onCloseEditPostMetadataPage}
          unmountOnExit
        >
          {(status) =>
            selectedPost && (
              <EditPostMetadataContainer
                transitionStatus={status}
                project={project}
                open={state.showEditPostMetadataPage}
                onClose={onCloseEditPostMetadataPage}
                selectedPost={selectedPostWithContentInfo}
                platform={platform}
                loadingSave={updateSocialContentMetadataMutation[1].loading}
                onSave={async ({
                  connect,
                  disconnect,
                }: {
                  connect?: Array<string>;
                  disconnect?: Array<string>;
                }) => {
                  await updateSocialContentMetadataMutation[0]({
                    variables: {
                      input: {
                        id: {
                          publisherId: selectedPost.id.publisherId,
                          contentId: selectedPost.id.contentId,
                        },
                        connect,
                        disconnect,
                      },
                    },
                  });
                }}
              />
            )
          }
        </CSSTransition>
      )}
      <CSSTransition
        in={state.showCustomMetrics}
        timeout={{ enter: 300, exit: 500 }}
        classNames=""
        onExited={onCloseCustomMetricsPanel}
        unmountOnExit
      >
        {(status) => (
          <CustomMetricsContainer
            transitionStatus={status}
            open={state.showCustomMetrics}
            onClose={onCloseCustomMetricsPanel}
            publicationId={publicationType}
            columnSetType={columnSetType}
            showCustomMetrics={false}
          />
        )}
      </CSSTransition>

      <div
        style={{
          position: "relative",
          padding: maxWidth < 768 ? "0px 15px" : 0,
          paddingBottom: maxWidth < 768 ? 25 : 0,
          boxSizing: "border-box",
          ...style,
        }}
      >
        <TableStyled
          columns={columns}
          tableData={posts}
          rowIdGenerator={(post: { id: { contentId: string } }) =>
            post.id.contentId
          }
          loading={loading}
          itemsPerPage={realSize}
          head={headWithMetrics}
          changeOrder={{
            orderBy,
            order,
            onChangeOrder: (orderBy: string | null, order: any) => {
              const isMetric = metricsTableHeader.find(
                (mth) => mth.id === orderBy
              );
              if (!isMetric && orderBy) {
                onChangePostOrder(PostOrderBy[orderBy], order);
              } else {
                onChangeMetricOrder(orderBy, order);
              }
            },
          }}
          fixedColumnNumbers={maxWidth > 768 ? fixedColumn : 0}
          bodyStyle={{ marginBottom: 25 }}
          tableFilter={TableFilter}
          fixedHeader={maxWidth > 768}
          paddingLeftFirstTh={paddingLeftFirstTh}
        />
      </div>
    </>
  );
};

export default PostListTable;
