import * as React from "react";
import { Colors } from "@freeda/react-components/lib/theme";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const FbIcon: React.FC<Props> = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 28"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M20.6154908,1 C21.9208911,1 22.9791271,2.05823605 22.9791271,3.36363636 L22.979,5.119 L24.7058824,5.11954459 C25.9200966,5.11954459 26.9139909,6.06284661 26.9947074,7.25659286 L27,7.41366224 L27,24.7058824 C27,25.9200966 26.056698,26.9139909 24.8629517,26.9947074 L24.7058824,27 L7.41366224,27 C6.19944797,27 5.2055537,26.056698 5.12483719,24.8629517 L5.11954459,24.7058824 L5.119,22.979 L3.36363636,22.9791271 C2.05823605,22.9791271 1,21.9208911 1,20.6154908 L1,3.36363636 C1,2.05823605 2.05823605,1 3.36363636,1 L20.6154908,1 Z M22.979,6.648 L22.9791271,20.6154908 C22.9791271,21.9208911 21.9208911,22.9791271 20.6154908,22.9791271 L6.648,22.979 L6.64895636,24.7058824 C6.64895636,25.0930231 6.93664267,25.4129712 7.30989618,25.4636074 L7.41366224,25.4705882 L24.7058824,25.4705882 C25.0930231,25.4705882 25.4129712,25.1829019 25.4636074,24.8096484 L25.4705882,24.7058824 L25.4705882,7.41366224 C25.4705882,7.02652146 25.1829019,6.70657342 24.8096484,6.65593722 L24.7058824,6.64895636 L22.979,6.648 Z M20.6154908,2.57575758 L3.36363636,2.57575758 L3.26480652,2.58189627 C2.87632166,2.63051359 2.57575758,2.96197473 2.57575758,3.36363636 L2.57575758,20.6154908 C2.57575758,21.0506242 2.92850292,21.4033696 3.36363636,21.4033696 L20.6154908,21.4033696 C21.0506242,21.4033696 21.4033696,21.0506242 21.4033696,20.6154908 L21.4033696,3.36363636 C21.4033696,2.92850292 21.0506242,2.57575758 20.6154908,2.57575758 Z M13.6669669,6.57142857 C14.4075094,6.57142857 15.0435309,6.62355009 15.2281106,6.64648356 L15.2281106,8.34356017 L14.1562142,8.34356017 C13.3155984,8.34356017 13.1532572,8.7188351 13.1532572,9.26715346 L13.1532572,10.5743611 L15.0502025,10.5743611 L14.7900119,12.4715844 L13.1532572,12.4715844 L13.1532572,17.2459154 L11.0628369,17.2459154 L11.0628369,12.4715844 L9.35714286,12.4715844 L9.35714286,10.5743611 L11.0628369,10.5743611 L11.0628369,9.079516 C11.0628369,7.45540951 12.1213902,6.57142857 13.6669669,6.57142857 Z"
        id="path-fb-icon"
      />
    </defs>
    <g id="sidebar-fb" stroke="none" fill="none" fillRule="evenodd">
      <mask id="mask-fb-icon" fill="white">
        <use xlinkHref="#path-fb-icon" />
      </mask>
      <use id="fb" fillRule="nonzero" xlinkHref="#path-fb-icon" />
      <g id="color-fb-icon" mask="url(#mask-fb-icon)" fill={color}>
        <rect id="Rectangle" x="0" y="0" width={width} height={height} />
      </g>
    </g>
  </svg>
);

FbIcon.defaultProps = {
  width: 30,
  height: 30,
  color: Colors.DARKpurple,
};

export default FbIcon;
