export enum PublicationType {
  FB_POST = "FB_POST",
  FB_PAGE = "FB_PAGE",
  FBG_POST = "FBG_POST",
  IG_POST = "IG_POST",
  IG_FRAME = "IG_FRAME",
  IG_STORY = "IG_STORY",
  IG_PAGE = "IG_PAGE",
  YT_POST = "YT_POST",
  TK_POST = "TK_POST"
}

export interface Publication {
  id: PublicationType;
}
