import { Order } from "./../types/Order";
import { InclusionPolicy } from "./../types/InclusionPolicy";
import {
  PostTypeFilter,
  SelectedPublisherFilter,
  SelectedMetadataFilter,
  MetricWhereFilter,
  FromPublisherIdFilter,
  SelectedDistributionCountryFilter,
} from "./../types/Filter";
import { PublicationType } from "../types/Publication";
import { ColumnSet } from "../types/ColumnSet";
import client from "./client";
import { ALL_COLUMN_SETS } from "./queries/AllColumnSets";
import AllColumnSetQueryT from "../types/graphql/AllColumnSetsQuery";
import { QueryResult } from "react-apollo";
import { FilterState, MetricOrderBy } from "../providers/FilterProvider";
import { Granularity } from "../types/Granularity";
import { getCustomDateRange } from "../utils/getDateRange";
import { PostType } from "../types/PostType";
import { PostOrderBy } from "../types/graphql/PostOrderBy";
import { DateRange } from "../types/DateRange";
import { DateRangeFilter, FreeSearchFilter } from "../types/Filter";
import { LogicalOperator } from "../types/LogicalOperator";
import { ProjectId } from "../types/ProjectId";

export type GetColumnSetByNameFn = (
  publicationId: PublicationType,
  name: string,
  custom: boolean
) => ColumnSet | null;

export const getColumnSetByName = (
  publicationId: PublicationType,
  name: string,
  custom: boolean
): ColumnSet | null => {
  try {
    const result: AllColumnSetQueryT | null = client.readQuery({
      query: ALL_COLUMN_SETS,
      variables: {
        where: {
          publicationId,
          name,
          custom,
        },
      },
    });
    if (result) {
      return result.allColumnSets.find((cs) => cs.name === name) || null;
    }
    return null;
  } catch (e) {
    return null;
  }
};

export const isDataAvailable = (...qr: Array<QueryResult>): boolean =>
  qr.reduce<boolean>(
    (acc, next) =>
      !next.error &&
      !next.loading &&
      Boolean(next.data) &&
      Object.keys(next.data).length > 0 &&
      acc,
    true
  );

export const buildOrderByClause = (
  metricOrderBy: MetricOrderBy | null,
  postOrderBy: PostOrderBy | null,
  order: Order
) => {
  const postOrderByParam = postOrderBy ? `${postOrderBy}_${order}` : null;

  const metricOrderByParam = { name: metricOrderBy, ordering: order };

  return metricOrderBy
    ? {
        measuredMetricOrderBy: {
          granularity: Granularity.Lifetime,
          key: "value",
          ...metricOrderByParam,
        },
      }
    : { postOrderBy: postOrderByParam };
};

// private methods to build a content filter

const getDateRangeFilter = (dateRange: DateRange): DateRangeFilter =>
  getCustomDateRange(dateRange);

const getFreeSearchFilter = (searchValue: string): FreeSearchFilter => ({
  freeSearch: searchValue.length === 0 ? null : searchValue,
});

const getPostTypeFilter = (
  selectedFormat: PostType | null,
  postType: PostType
): PostTypeFilter => ({
  postType: selectedFormat && postType,
});

export const getSelectedPublisherFilter = (
  selectedPublisherName: string,
  projectId: ProjectId
): SelectedPublisherFilter => {
  if (selectedPublisherName) {
    return {
      publisherName: selectedPublisherName,
    };
  }
  return {
    projectId,
  };
};

export const getSelectedDistributionCountry = (
  selectedDistributionCountry: string | null
): SelectedDistributionCountryFilter => {

  if (selectedDistributionCountry) {
    return {
      originalDistributionCountry: selectedDistributionCountry,
    };
  }
 return  {originalDistributionCountry: null}
};

export const getUserDefinedMetadataFilter = (
  selectedMetadata: Array<{ value: string; label: string }>,
  logicalOperator: LogicalOperator,
  branded: boolean | null,
  brandedId?: string
): SelectedMetadataFilter => {
  let metadataIdsFilter = selectedMetadata.length
    ? {
        idInClause: {
          seq: selectedMetadata.map((m) => m.value),
          logicalOperator,
        },
      }
    : {};

  let brandedFilter = {};
  if (branded !== null && brandedId !== undefined) {
    brandedFilter = branded
      ? {
          idIn: [brandedId],
        }
      : { idNotIn: [brandedId] };
  }

  const userDefinedMetadataWhere = {
    ...metadataIdsFilter,
    ...brandedFilter,
  };

  return {
    userDefinedMetadata: Object.keys(userDefinedMetadataWhere).length
      ? userDefinedMetadataWhere
      : undefined,
  };
};

const getMetricWhereFilter = (columnSetId?: string): MetricWhereFilter => {
  const metricWhere = {
    granularityIn: [Granularity.Lifetime],
    keyIn: ["value"],
    columnSetId,
  };

  if (columnSetId) {
    return {
      ...metricWhere,
      columnSetId,
    };
  }

  return metricWhere;
};

const getFromPublisherIdFilter = (
  publisherId: string,
  inclusionPolicy: InclusionPolicy | null
): FromPublisherIdFilter => {
  if (inclusionPolicy) {
    return {
      fromPublisherId: { publisherId, inclusionPolicy },
    };
  }
  return undefined;
};

export const buildFbContentFilter = (
  filterState: FilterState,
  postType: PostType,
  projectId: ProjectId,
  columnSetId?: string,
  brandedId?: string
) => ({
  where: {
    ...getPostTypeFilter(filterState.selectedFormat, postType),
    ...getFreeSearchFilter(filterState.searchValue),
    ...getDateRangeFilter(filterState.dateRange),
    ...getSelectedPublisherFilter(filterState.selectedPublisher, projectId),
    ...getUserDefinedMetadataFilter(
      filterState.selectedMetadata,
      filterState.logicalOperator,
      filterState.branded,
      brandedId
    ),
  },
  metricWhere: getMetricWhereFilter(columnSetId),
});

export const buildIgContentFilter = (
  filterState: FilterState,
  postType: PostType,
  projectId: ProjectId,
  columnSetId?: string,
  isStory?: boolean,
  brandedId?: string
) => ({
  where: {
    isStory,
    ...getPostTypeFilter(filterState.selectedFormat, postType),
    ...getFreeSearchFilter(filterState.searchValue),
    ...getDateRangeFilter(filterState.dateRange),
    ...getSelectedPublisherFilter(filterState.selectedPublisher, projectId),
    ...getUserDefinedMetadataFilter(
      filterState.selectedMetadata,
      filterState.logicalOperator,
      filterState.branded,
      brandedId
    ),
  },
  metricWhere: getMetricWhereFilter(columnSetId),
});

export const buildIgStoryContentFilter = (
  filterState: FilterState,
  projectId: ProjectId,
  columnSetId?: string,
  brandedId?: string
) => ({
  where: {
    ...getFreeSearchFilter(filterState.searchValue),
    ...getDateRangeFilter(filterState.dateRange),
    ...getSelectedPublisherFilter(filterState.selectedPublisher, projectId),
    ...getUserDefinedMetadataFilter(
      filterState.selectedMetadata,
      filterState.logicalOperator,
      filterState.branded,
      brandedId
    ),
  },
  metricWhere: getMetricWhereFilter(columnSetId),
});

export const buildYoutubeContentFilter = (
  filterState: FilterState,
  projectId: ProjectId,
  columnSetId?: string,
  brandedId?: string
) => ({
  where: {
    ...getFreeSearchFilter(filterState.searchValue),
    ...getDateRangeFilter(filterState.dateRange),
    ...getSelectedPublisherFilter(filterState.selectedPublisher, projectId),
    ...getSelectedDistributionCountry(filterState.selectedDistributionCountry),
    ...getUserDefinedMetadataFilter(
      filterState.selectedMetadata,
      filterState.logicalOperator,
      filterState.branded,
      brandedId
    ),
  },
  metricWhere: getMetricWhereFilter(columnSetId),
});

export const buildTiktokContentFilter = (
  filterState: FilterState,
  postType: PostType,
  projectId: ProjectId,
  columnSetId?: string,
  brandedId?: string
) => ({
  where: {
    ...getPostTypeFilter(filterState.selectedFormat, postType),
    ...getFreeSearchFilter(filterState.searchValue),
    ...getDateRangeFilter(filterState.dateRange),
    ...getSelectedPublisherFilter(filterState.selectedPublisher, projectId),
    ...getUserDefinedMetadataFilter(
      filterState.selectedMetadata,
      filterState.logicalOperator,
      filterState.branded,
      brandedId
    ),
  },
  metricWhere: getMetricWhereFilter(columnSetId),
});

export const buildFbGroupContentFilter = (
  filterState: FilterState,
  publisherId: string,
  selectedPublisherName: string,
  projectId: ProjectId,
  inclusionPolicy: InclusionPolicy | null,
  columnSetId?: string,
  brandedId?: string
) => ({
  where: {
    ...getFreeSearchFilter(filterState.searchValue),
    ...getDateRangeFilter(filterState.dateRange),
    ...getSelectedPublisherFilter(selectedPublisherName, projectId),
    ...getFromPublisherIdFilter(publisherId, inclusionPolicy),
    ...getUserDefinedMetadataFilter(
      filterState.selectedMetadata,
      filterState.logicalOperator,
      filterState.branded,
      brandedId
    ),
  },
  metricWhere: getMetricWhereFilter(columnSetId),
});
