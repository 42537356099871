import styled from "styled-components";
import { Table } from "@freeda/react-components";
import { WrapperLateralPanel } from "../../ui/LateralPanel";

const ChartFramesPanelWrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 3000;
  width: 100vw;
  height: 100vh;
  background: #f9f9f9;
`;

const ImgPost = styled.img<{ selected?: boolean }>`
  max-height: 80px;
  width: auto;
`;

const TableWrapper = styled.div`
  position: relative;
  top: 65px;
  width: calc(100% - 50px);
  height: 100vh;
  overflow-y: auto;
  padding: 25px;
  box-sizing: border-box;
`;

const RightSidebar = styled.div`
  position: absolute;
  top: 0px;
  left: -1px;
  width: 50px;
  height: calc(100vh - 65px);
  background: ${(props) => props.theme.darkPrimary};
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RightSidebarPlaceholder = styled.div`
  position: fixed;
  right: 0px;
  top: 65px;
  width: 48px;
  height: calc(100vh - 65px);
  background: ${(props) => props.theme.griginoTransparentLight};
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.16), 0 3px 5px rgba(0, 0, 0, 0.23);
`;

const TableStyled = styled(Table)`
  && {
    & > thead > tr > th:first-child {
      padding-left: 27px;
    }
    & > thead > tr > th {
      white-space: initial;
    }
    & > tbody > tr > td:first-child {
      height: 80px;
    }
  }
`;

const WrapperLateralPanelStyled = styled(WrapperLateralPanel)<{ width: string; viewportWidth: number }>`
  padding-top: 0px;
  overflow: hidden;
  && {
    right: calc(${(props) => `-${props.width}`} + 49px);

    &.wrapper-entering {
      right: calc(${(props) => `-${props.width}`} + 49px);
    }
    &.wrapper-entered {
      right: 0px;
    }
    &.wrapper-exiting {
      right: calc(${(props) => `-${props.width}`} + 49px);
    }
    &.wrapper-exited {
      right: calc(${(props) => `-${props.width}`} + 49px);
    }

    @media (max-width: 768px) {
      width: 100vw;
      right: calc(${(props) => `-${props.viewportWidth}px`} + 49px);
      transition: right 0.5s;

      &.wrapper-entering {
        right: calc(${(props) => `-${props.viewportWidth}px`} + 49px);
      }
      &.wrapper-entered {
        right: 0px;
      }
      &.wrapper-exiting {
        right: calc(${(props) => `-${props.viewportWidth}px`} + 49px);
      }
      &.wrapper-exited {
        right: calc(${(props) => `-${props.viewportWidth}px`} + 49px);
      }
    }
  }
`;

const WrapperChart = styled.div`
  width: 100%;
  height: 100%;
  padding-left: 55px;
  padding-top: 25px;
  padding-bottom: 25px;
  box-sizing: border-box;
  overflow-y: scroll;
`;

export {
  ChartFramesPanelWrapper,
  ImgPost,
  TableWrapper,
  RightSidebar,
  TableStyled,
  WrapperLateralPanelStyled,
  WrapperChart,
  RightSidebarPlaceholder,
};
