import * as React from "react";
import { Colors } from "@freeda/react-components/lib/theme";
import { TransitionStatus } from "react-transition-group/Transition";
import { ButtonIcon, Typography, Button, TitleInput, DialogCard } from "@freeda/react-components";
import { CSSTransition } from "react-transition-group";
import { sortBy } from "lodash";
import { useKeyUp } from "../../../hooks/useKeyUp";
import { Dialogable } from "../../../types/Dialogable";
import { BackgroundLayer, WrapperLateralPanel, BackWrapper } from "../../ui/LateralPanel";
import {
  OrderMetrics,
  MetricsLeftWrapper,
  SelectedWrapper,
  CheckboxStyled,
  OrderIconWrapper,
  SetNameWrapper,
  MetricsWrapper,
} from "./styles";
import OrderIcon from "../../ui/SvgIcon/OrderIcon";
import DragDropMetrics from "./DragDropMetrics";
import { MetricCategory } from "../../../types/MetricCategory";
import { Metric } from "../../../types/Metric";
import { PositionalMetric } from "../../../types/PositionalMetric";
import { zIndex } from "../../../utils/zIndex";
import { getColumnSetLabelAvailable } from "../../../utils/getColumnSetLabelAvailable";
import { PublicationType } from "../../../types/Publication";
import { ErrorWrapper } from "../../ui/ErrorWrapper";
import client from "../../../apollo/client";
import { BackButton } from "../../ui/BackButton";

export type UserSelectedMetricT = Pick<PositionalMetric, "id" | "label" | "position">;

export type Item = UserSelectedMetricT & {
  content: any;
};

type Props = Dialogable & {
  transitionStatus: TransitionStatus;
  allMetricCategories: Array<MetricCategory>;
  columnSetLabel: string;
  metricsColumnSet: Array<UserSelectedMetricT> | [];
  onCreateColumnSet: (label: string, selectedMetrics: Array<{ id: number; position: number }>) => void;
  onUpdateColumnSet: (label: string, selectedMetrics: Array<{ id: number; position: number }>) => void;
  onDeleteColumnSet: () => void;
  loadingMutations: { createUpdate: boolean; delete: boolean };
  clickedCreate: boolean;
  zIndexPanel?: number;
  publicationId: PublicationType;
};

interface State {
  selectedMetrics: Array<Item>;
  columnSetName: string;
  availableLabel: boolean | null;
}

const getMetrics = (metrics: Array<UserSelectedMetricT>) => {
  const metricsData = metrics.map(({ id, label, position }) => ({
    id,
    label,
    position,
    content: (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 2,
        }}
      >
        <OrderIconWrapper>
          <OrderIcon />
        </OrderIconWrapper>

        <Typography
          variantName="label"
          style={{ textTransform: "capitalize", marginLeft: 5, cursor: "grab", whiteSpace: "pre-wrap" }}
        >
          {label}
        </Typography>
      </div>
    ),
  }));
  return metricsData;
};

const CustomMetrics: React.FC<Props> = ({
  transitionStatus,
  onClose,
  open,
  allMetricCategories,
  onCreateColumnSet,
  loadingMutations,
  zIndexPanel,
  clickedCreate,
  onDeleteColumnSet,
  onUpdateColumnSet,
  publicationId,
  metricsColumnSet,
  columnSetLabel,
}) => {
  const initialSelectedMetrics = sortBy(getMetrics(metricsColumnSet), "position");

  const [state, setState] = React.useState<State>({
    selectedMetrics: initialSelectedMetrics,
    columnSetName: columnSetLabel,
    availableLabel: null,
  });

  useKeyUp(onClose);

  React.useEffect(() => {
    const updateCreateColumnSet = async () => {
      const label = columnSetName.trim();
      if (availableLabel) {
        if (clickedCreate) {
          await onCreateColumnSet(label, selectedMetrics.map((m) => ({ id: m.id, position: m.position })));
        } else {
          await onUpdateColumnSet(label, selectedMetrics.map((m) => ({ id: m.id, position: m.position })));
        }
      }
    };
    updateCreateColumnSet();
  }, [state.availableLabel, state.columnSetName]);

  const onCheckedMetric = (metric: Metric) => {
    setState((state) => {
      const toRemove = state.selectedMetrics.find((m) => m.id === metric.id);
      const addNewMetric = { id: metric.id, label: metric.label, position: 0 };

      const metricsWithUpdatePosition = state.selectedMetrics.map((sm) => {
        if (toRemove) {
          if (sm.position > toRemove.position) {
            return {
              ...sm,
              position: sm.position - 1,
            };
          }
        } else {
          return {
            ...sm,
            position: sm.position + 1,
          };
        }
        return sm;
      });

      const selectedMetrics = toRemove
        ? metricsWithUpdatePosition.filter((m) => m.id !== metric.id)
        : [addNewMetric, ...metricsWithUpdatePosition];

      return {
        ...state,
        selectedMetrics: getMetrics(selectedMetrics),
      };
    });
  };

  const { selectedMetrics, columnSetName, availableLabel } = state;

  const titleRightSection =
    selectedMetrics.length > 0
      ? `${selectedMetrics.length} selected metric${selectedMetrics.length === 1 ? "" : "s"}`
      : "There aren't selected metrics";

  const zIndexBkgLayer = zIndexPanel ? zIndexPanel - 1 : zIndex.LateralPanel - 1;
  const zIndexWrapperEdit = zIndexPanel ? zIndexPanel : zIndex.LateralPanel;

  return (
    <>
      <BackgroundLayer
        open={open}
        onClick={onClose}
        className={`background-${transitionStatus}`}
        style={{ zIndex: zIndexBkgLayer }}
      />
      <WrapperLateralPanel
        open={open}
        className={`wrapper-${transitionStatus}`}
        width="575px"
        style={{ zIndex: zIndexWrapperEdit, padding: 0, overflow: "hidden" }}
      >
        <SetNameWrapper>
          <BackWrapper style={{ justifyContent: "space-between" }}>
            <BackButton onClick={onClose} />
            {!clickedCreate && (
              <DialogCard
                referenceChildren={
                  <ButtonIcon iconName="delete" iconColor={Colors.GRIGINO} loading={loadingMutations.delete} />
                }
                tooltipChildren={(closeCard) => (
                  <div>
                    <Typography
                      variantName="subtitle"
                      textAlign="center"
                      textColor={Colors.PURPLE}
                      style={{ width: 200 }}
                    >
                      You are deleting the column set, you will not be able to recover this.
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: 20,
                      }}
                    >
                      <Button action="undo" style={{ marginRight: 5 }} onClick={() => closeCard()}>
                        Undo
                      </Button>
                      <Button
                        style={{ marginLeft: 5 }}
                        onClick={async () => {
                          closeCard();
                          await onDeleteColumnSet();
                        }}
                      >
                        Delete
                      </Button>
                    </div>
                  </div>
                )}
                position="bottom-end"
              />
            )}
          </BackWrapper>
          <div style={{ padding: "0px 15px" }}>
            <TitleInput
              label="Column set name"
              placeholder="Column set name"
              value={columnSetName}
              onChange={(e) => setState({ ...state, columnSetName: e.currentTarget.value, availableLabel: null })}
              styleWrapper={{ margin: "20px 0px", boxSizing: "border-box" }}
            />
            <CSSTransition
              in={!availableLabel && availableLabel !== null}
              timeout={{ enter: 300, exit: 500 }}
              classNames=""
            >
              {(status) => (
                <ErrorWrapper className={`wrapper-${status}`} style={{ position: "relative", top: -15 }}>
                  <Typography variantName="error" style={{ marginTop: 8 }}>
                    This name isn't available, it's already exists.
                  </Typography>
                </ErrorWrapper>
              )}
            </CSSTransition>
          </div>
        </SetNameWrapper>
        <MetricsWrapper>
          <MetricsLeftWrapper>
            {allMetricCategories.map((metricCategory) => {
              const metricsCheckbox = metricCategory.metrics.map((metric) => {
                const isChecked = Boolean(selectedMetrics.find((sm) => sm.id === metric.id));
                return (
                  <CheckboxStyled
                    key={metric.id}
                    style={{ display: "flex", alignItems: "center" }}
                    label={metric.label}
                    checked={isChecked}
                    onClick={() => onCheckedMetric(metric)}
                  />
                );
              });

              return <div key={metricCategory.id}>{metricsCheckbox}</div>;
            })}
          </MetricsLeftWrapper>
          <OrderMetrics>
            <div>
              <Typography variantName="label" textColor={Colors.ROSSINO} style={{ fontSize: 14 }}>
                {titleRightSection}
              </Typography>
              <SelectedWrapper>
                <DragDropMetrics
                  selectedMetrics={getMetrics(selectedMetrics)}
                  updateOrder={(reorderedMetric: Array<UserSelectedMetricT>) => {
                    setState((state) => {
                      return {
                        ...state,
                        selectedMetrics: getMetrics(reorderedMetric).map((m, i) => ({
                          ...m,
                          position: i,
                        })),
                      };
                    });
                  }}
                />
              </SelectedWrapper>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                style={{ margin: 0 }}
                loading={loadingMutations.createUpdate}
                disabled={selectedMetrics.length === 0 || columnSetName.trim().length === 0 || loadingMutations.delete}
                onClick={async () => {
                  const oldLabel = columnSetLabel;
                  const label = columnSetName.trim();
                  const isLabelAvailable = await getColumnSetLabelAvailable(client, label, oldLabel, publicationId);

                  setState({ ...state, availableLabel: isLabelAvailable });
                }}
              >
                {clickedCreate ? "Create" : "Apply"}
              </Button>
            </div>
          </OrderMetrics>
        </MetricsWrapper>
      </WrapperLateralPanel>
    </>
  );
};

export default CustomMetrics;
