import * as React from "react";
import { useQuery } from "@apollo/react-hooks";
import { ALL_PROJECT } from "../apollo/queries/AllProject";
import AllProjectQueryT from "../types/graphql/AllProjectQuery";
import { ProjectId } from "../types/ProjectId";
import { DialogCard, ButtonIcon, Typography, Icon } from "@freeda/react-components";
import { Colors } from "@freeda/react-components/lib/theme";
import { RouterNavLink as NavLink } from "../components/ui/RouterLink";
import { uniqBy , sortBy} from 'lodash';

/*import {
  pagesUnits
} from "../ui-specs/posts-pages-specs";*/
import { Project } from "../types/Project";
import { PageUnit } from "../types/PageUnit";
import { ProjectPageContext } from "../providers/ProjectPageProvider";

interface Props {
  selectedProject: ProjectId;
  onChangeProject: (project: ProjectId) => void;
  iconColor: string;
  //pageUnitsForCard: Array<PageUnit>;
}

//const CardProjects: React.FC<Props> = ({ selectedProject, onChangeProject, iconColor, pageUnitsForCard }) => {
const CardProjects: React.FC<Props> = ({ selectedProject, onChangeProject, iconColor }) => {
  
  const { loading, data } = useQuery<AllProjectQueryT, any>(ALL_PROJECT);

  const { pageUnits } = React.useContext(ProjectPageContext);

  let allProjects = loading || !data ? [] : data.allProject;

  allProjects = sortBy(allProjects, "label")


  let allProjectsFiltered:Array<Project> = []
  
  pageUnits.map((p) => (
    allProjectsFiltered.push( allProjects.filter((t) => ( t.label == p.project.label) )[0])
  ))
  allProjectsFiltered = uniqBy(allProjectsFiltered, 'label');
  allProjectsFiltered =  sortBy(allProjectsFiltered, 'label')
  
  return (
    <DialogCard
      tooltipStyle={{ paddingRight: 40 }}
      referenceChildren={<ButtonIcon iconName="more-horiz" iconColor={iconColor} />}
      tooltipChildren={(closeCard) => (
        <>
          {allProjectsFiltered.map((p) => (
            
            
            <div key={p.id} style={{ display: "flex", alignItems: "center", padding: "10px 0" }}>
              <Icon
                iconName="check"
                iconColor={Colors.AZZURRINO}
                style={{
                  visibility: selectedProject !== p.id ? "hidden" : "visible",
                  marginRight: 10,
                  position: "relative",
                  top: 1,
                  fontWeight: 900,
                }}
              />
              <NavLink to={`/${p.idLegacy.toLowerCase()}/facebook`} onClick={() => closeCard()}>
                <Typography
                  variantName="table-header"
                  textColor={Colors.PURPLE}
                  style={{ fontWeight: 600, cursor: "pointer" }}
                  onClick={() => {
                    onChangeProject(p.idLegacy);
                  }}
                >
                  {p.label}
                </Typography>
              </NavLink>
            </div>


          ))}
        </>
      )}
      position="bottom-start"
    />
  );
};

export { CardProjects };
