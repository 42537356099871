import gql from "graphql-tag";

const ALL_COLUMN_SETS_LABEL = gql`
  query allColumnSets($where: ColumnSetWhere) {
    allColumnSets(where: $where) {
      label
    }
  }
`;

export { ALL_COLUMN_SETS_LABEL };
