import * as React from "react";
import { Dialogable } from "../../../types/Dialogable";
import { Dialog } from "../../ui/Dialog/Dialog";
import { Button, Typography, Icon, DividerSection, Tooltip } from "@freeda/react-components";
import { SpinnerWrapper, Wrapper, SvgIcon, CircleBackground, StyledSpinner, StyledIcon } from "./styles";
import { Colors } from "@freeda/react-components/lib/theme";
import { CSSTransition } from "react-transition-group";
import { getMessageSteps, reloadFileMessage, randomTimeout } from "./specsCSVColumn";
import { DragDropArea } from "./DragDropArea";
import { s3Upload } from "./utilsUpload";
import { FeedbackContext } from "../../../providers/FeedbackProvider";

const uploadCSVfile = require("../../../assets/icons/upload-csv.svg");
const uploadCSVfileError = require("../../../assets/icons/upload-csv-error.svg");

const UploadIGTVDialog: React.FC<Dialogable> = ({ open, onClose }) => {
  const timeoutLoadingCheckFile = React.useRef<number>();
  const milliseconds = React.useRef<number>(randomTimeout());

  const { openSnackbar } = React.useContext(FeedbackContext);

  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [checkingFile, setCheckingFile] = React.useState<boolean>(false);
  const [validFileName, setValidFileName] = React.useState<boolean | null>(null);
  const [columnsErrorMatch, setColumnsErrorMatch] = React.useState<Array<JSX.Element>>([]);
  const [uploadCompleted, setUploadCompleted] = React.useState<boolean | null>(null);
  const [uploadError, setUploadError] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (selectedFile && validFileName) {
      setCheckingFile(true);
    }
  }, [selectedFile, validFileName]);

  React.useEffect(() => {
    if (checkingFile) {
      timeoutLoadingCheckFile.current = setTimeout(() => setCheckingFile(false), milliseconds.current);
    }
  }, [checkingFile]);

  React.useEffect(() => {
    return () => {
      if (timeoutLoadingCheckFile.current) {
        clearTimeout(timeoutLoadingCheckFile.current);
      }
    };
  }, []);

  const onBackToReload = () => {
    milliseconds.current = randomTimeout();

    setSelectedFile(null);
    setCheckingFile(false);
    setValidFileName(null);
    setColumnsErrorMatch([]);
    setUploadCompleted(null);
    setUploadError(false);
  };

  const csvHasWrongFileName = validFileName !== null && !validFileName;
  const csvColumnsNotMatch = columnsErrorMatch.length > 0;
  const csvNotMatch = !checkingFile && (csvHasWrongFileName || csvColumnsNotMatch);

  const isReadyToUpload = Boolean(!checkingFile && validFileName && !csvColumnsNotMatch);

  const uploadIsNotCompleted = uploadCompleted !== null && !uploadCompleted;

  const onChangeCompleted = (completed: boolean | null) => setUploadCompleted(completed);
  const onChangeUploadError = (isError: boolean) => setUploadError(isError);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title="Upload IGTV"
      buttonSection={
        <>
          {(csvNotMatch || uploadError) && (
            <Button action="undo" style={{ marginRight: 15 }} onClick={onBackToReload}>
              Back to reload
            </Button>
          )}
          <Button
            disabled={!isReadyToUpload || uploadError}
            onClick={() => {
              if (selectedFile) {
                if (uploadCompleted) {
                  onBackToReload();
                } else {
                  s3Upload(selectedFile, onChangeCompleted, onChangeUploadError, openSnackbar);
                }
              }
            }}
          >
            {uploadCompleted ? "Upload new file" : "Continue to upload"}
          </Button>
        </>
      }
    >
      <div style={{ width: "100%" }}>
        {!selectedFile && (
          <DragDropArea
            onChangeSelectedFile={(file) => setSelectedFile(file)}
            onChangeValidFileName={(isValid) => setValidFileName(isValid)}
            onChangeColumnsErrorMatch={(errors) => setColumnsErrorMatch(errors)}
          />
        )}
        {selectedFile && (
          <Wrapper isDragDrop={false}>
            <SpinnerWrapper>
              <CSSTransition in={checkingFile || uploadIsNotCompleted} timeout={0} unmountOnExit>
                {(status) => <StyledSpinner className={status} />}
              </CSSTransition>
              <CircleBackground style={{ borderColor: csvNotMatch || uploadError ? Colors.GRIGINO : "" }}>
                <SvgIcon src={uploadCSVfile} isVisible={checkingFile} />
                <SvgIcon src={uploadCSVfileError} isVisible={csvNotMatch || uploadError} />
                <StyledIcon
                  isVisible={isReadyToUpload && !uploadError && !uploadCompleted}
                  iconName="upload"
                  iconSize="xlarge"
                  iconColor={Colors.PURPLE}
                  style={{ fontSize: 85, height: 50 }}
                />
                <StyledIcon
                  isVisible={Boolean(uploadCompleted)}
                  iconName="check"
                  iconSize="xlarge"
                  iconColor={Colors.PURPLE}
                  style={{ fontSize: 100 }}
                />
              </CircleBackground>
            </SpinnerWrapper>
            <div style={{ marginTop: 20, width: "100%" }}>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <Typography
                  variantName="text"
                  textColor={Colors.GRIGINO}
                  style={{
                    fontWeight: 500,
                    fontSize: 12,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textDecoration: validFileName ? "" : "underline dashed red",
                  }}
                  textAlign="center"
                >
                  {selectedFile.name}
                </Typography>
                {csvHasWrongFileName && (
                  <Tooltip
                    referenceChildren={
                      <Icon iconName="info" iconColor={Colors.ROSSINO} style={{ height: 20, cursor: "pointer" }} />
                    }
                    tooltipChildren={
                      <div style={{ width: "auto", display: "grid", color: Colors.GRIGIO, lineHeight: "16px" }}>
                        <Typography variantName="text">
                          <>
                            Restore the original file name. <br />
                            Correct the file name formatting: <br />
                            e.g. 2020-04-23-12-07-18-CE*T*.csv
                          </>
                        </Typography>
                      </div>
                    }
                    referenceStyle={{ display: "flex", alignItems: "center" }}
                    position="bottom"
                  />
                )}
              </div>
              <DividerSection />
              <Typography
                variantName="text"
                textColor={!validFileName || csvNotMatch || uploadError ? Colors.ROSSINO : Colors.PURPLE}
                style={{ fontWeight: 700 }}
                textAlign="center"
              >
                {getMessageSteps(checkingFile, !csvColumnsNotMatch, validFileName, uploadCompleted, uploadError)}
              </Typography>
              {!checkingFile && csvColumnsNotMatch && (
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 5 }}>
                  <Tooltip
                    referenceChildren={
                      <Typography
                        variantName="text"
                        textColor={Colors.ROSSINO}
                        style={{ fontWeight: 700, textDecoration: "underline", cursor: "pointer" }}
                        textAlign="center"
                      >
                        View errors
                      </Typography>
                    }
                    tooltipChildren={
                      <div
                        style={{
                          width: "auto",
                          maxWidth: 300,
                          display: "flex",
                          flexDirection: "column",
                          color: Colors.GRIGIO,
                          lineHeight: "16px",
                        }}
                      >
                        {columnsErrorMatch.map((error, i) => (
                          <div key={i} style={{ marginBottom: i === columnsErrorMatch.length - 1 ? 0 : 8 }}>
                            {error}
                          </div>
                        ))}
                      </div>
                    }
                    referenceStyle={{ display: "flex", alignItems: "center" }}
                    position="bottom"
                  />
                  <Typography
                    variantName="text"
                    textColor={Colors.ROSSINO}
                    style={{ fontWeight: 700 }}
                    textAlign="center"
                  >
                    <>!&nbsp; {reloadFileMessage}</>
                  </Typography>
                </div>
              )}
            </div>
          </Wrapper>
        )}
      </div>
    </Dialog>
  );
};

export { UploadIGTVDialog };
