import * as React from "react";
import moment from "moment";
import { ButtonIcon, Typography, Dialog } from "@freeda/react-components";

import { Colors } from "@freeda/react-components/lib/theme";
import { FacebookGroupContent } from "../../types/FacebookGroupContent";
import UserIcon from "./SvgIcon/UserIcon";
import FreedaIcon from "./SvgIcon/FreedaIcon";
import Media from "react-media";
import styled from "styled-components";
import { scrollbarVerticalStyle } from "./ScrollBarStyle";

const MessageWrapper = styled.div<{ matches: boolean }>`
  max-height: ${(props) => (props.matches ? "" : "500px")};
  height: ${(props) => (props.matches ? "calc(100vh - 130px)" : "")};
  overflow-y: overlay;
  padding-right: 30px;
  ${scrollbarVerticalStyle};
`;

interface Props {
  post: FacebookGroupContent;
}
interface State {
  showInfoPanel: boolean;
}

export const FbGroupPostInfo: React.FC<Props> = ({ post }) => {
  const [state, setState] = React.useState<State>({
    showInfoPanel: false,
  });

  const onClosePanel = () => setState({ showInfoPanel: false });

  const message = post.message ? post.message.split("\n") : [];

  const { showInfoPanel } = state;

  return (
    <Media query={{ maxWidth: 768 }}>
      {(matches: boolean) => (
        <>
          <Dialog
            openDialog={Boolean(showInfoPanel && post.message)}
            onClose={onClosePanel}
            rootIdAttributes="root"
            fullScreen={matches}
            style={{
              width: !matches ? 500 : "",
              paddingLeft: 30,
              paddingRight: 10,
              paddingBottom: 30,
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <ButtonIcon onClick={onClosePanel} iconName="close" />
            </div>
            <div style={{ maxHeight: 500, overflowY: "scroll" }}>
              <div style={{ display: "flex", alignItems: "center", marginBottom: 15 }}>
                {post.from ? <FreedaIcon color={Colors.PURPLE} width={15} /> : <UserIcon color={Colors.PURPLE} />}
                <Typography variantName="table-body" style={{ fontSize: 12, marginLeft: 15 }}>
                  <>
                    {String(moment(post.publicationDate).format("ll"))}
                    <br />
                    {String(moment(post.publicationDate).format("HH:mm:ss"))}
                  </>
                </Typography>
              </div>
            </div>
            {post.title && (
              <Typography variantName="subtitle" style={{ marginBottom: 10, color: Colors.PURPLE, fontSize: 18 }}>
                {post.title}
              </Typography>
            )}
            {post.message && (
              <MessageWrapper matches={matches}>
                {message.map((m, i) => (
                  <Typography variantName="text" style={{ color: Colors.GRIGIO, lineHeight: "21px" }} key={i}>
                    {m}
                  </Typography>
                ))}
              </MessageWrapper>
            )}
          </Dialog>
          <ButtonIcon
            iconName="info"
            iconColor={Colors.GRIGINO}
            onClick={() => setState({ showInfoPanel: true })}
            disabled={!post.message}
          />
        </>
      )}
    </Media>
  );
};
