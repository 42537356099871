import * as React from "react";
import Media from "react-media";
import { CSSTransition } from "react-transition-group";
import { Dialog, Typography, ButtonIcon, TitleInput, Button } from "@freeda/react-components";

import client from "../../../apollo/client";
import { Dialogable } from "../../../types/Dialogable";
import { getStoryTitleAvailable } from "../../../utils/getStoryTitleAvailable";
import { ErrorWrapper } from "../../ui/ErrorWrapper";
import styled from "styled-components";

const ButtonWrapperMobile = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  bottom: 0px;
  right: 0px;
  padding-left: 20px;
  margin-bottom: 20px;
`;

type Props = Dialogable & {
  onCreateStory: (title: string) => void;
  loadingCreate: boolean;
  publisherName: string;
};

interface State {
  titleStory: string;
  availableStoryTitle: boolean | null;
}

const CreateStoryDialog: React.FC<Props> = ({ onCreateStory, loadingCreate, publisherName, open, onClose }) => {
  const [state, setState] = React.useState<State>({
    titleStory: "",
    availableStoryTitle: null
  });

  const checkTitleAvailable = async () => {
    const availableStoryTitle = await getStoryTitleAvailable(client, state.titleStory, publisherName);
    if (state.titleStory.length > 0) {
      setState({ ...state, availableStoryTitle });
    }
  };

  React.useEffect(() => {
    const createStory = async () => {
      if (state.availableStoryTitle) {
        await onCreateStory(state.titleStory);
        onClose();
      }
    };
    createStory();
  }, [state.availableStoryTitle, state.titleStory]);

  React.useEffect(() => {
    const handleEnter = async (e: KeyboardEvent) => {
      if (e.key === "Enter") {
        await checkTitleAvailable();
      }
    };

    window.addEventListener("keydown", handleEnter);
    return () => {
      window.removeEventListener("keydown", handleEnter);
    };
  }, [state.availableStoryTitle, state.titleStory]);

  const { availableStoryTitle, titleStory } = state;

  return (
    <Media query={{ maxWidth: 768 }}>
      {(matches: boolean) => {
        return (
          <Dialog
            rootIdAttributes="root"
            openDialog={open}
            onClose={onClose}
            fullScreen={matches}
            style={{
              width: !matches ? 350 : ""
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: 15
              }}
            >
              <Typography variantName="subtitle" textColor="#6B20DA">
                Create story
              </Typography>
              <ButtonIcon onClick={onClose} iconName="close" />
            </div>
            <TitleInput
              value={titleStory}
              onChange={e => {
                setState({ titleStory: e.currentTarget.value, availableStoryTitle: null });
              }}
              placeholder="Title story"
            />
            <CSSTransition
              in={Boolean(!availableStoryTitle && availableStoryTitle !== null)}
              timeout={{ enter: 300, exit: 500 }}
              classNames=""
            >
              {status => (
                <ErrorWrapper className={`wrapper-${status}`}>
                  <Typography variantName="error" style={{ marginTop: 8 }}>
                    This title isn't available, it's already exists.
                  </Typography>
                </ErrorWrapper>
              )}
            </CSSTransition>
            {matches ? (
              <ButtonWrapperMobile>
                <Button action="undo" onClick={onClose} style={{ width: "43%", marginLeft: 20 }}>
                  Undo
                </Button>
                <Button
                  loading={loadingCreate}
                  style={{ width: "43%", marginRight: 20 }}
                  disabled={titleStory.trim().length === 0}
                  onClick={checkTitleAvailable}
                >
                  Confirm
                </Button>
              </ButtonWrapperMobile>
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 25
                }}
              >
                <Button action="undo" onClick={onClose} style={{ marginRight: 20 }}>
                  Undo
                </Button>
                <Button loading={loadingCreate} disabled={titleStory.trim().length === 0} onClick={checkTitleAvailable}>
                  Confirm
                </Button>
              </div>
            )}
          </Dialog>
        );
      }}
    </Media>
  );
};

export { CreateStoryDialog };
