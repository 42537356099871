import ApolloClient from "apollo-client";
import { ALL_COLUMN_SETS_LABEL } from "../apollo/queries/AllColumnSetsLabel";
import { PublicationType } from "../types/Publication";

const labelComparison = (newLabel: string) => (t: { label: string }) => {
  return t.label.toLowerCase().trim() === newLabel.toLowerCase().trim();
};

export const getColumnSetLabelAvailable = async (
  client: ApolloClient<any>,
  columnSetLabel: string,
  oldColumnSetLabel: string,
  publicationId: PublicationType
) => {
  const result = await client.query<{
    allColumnSets: any;
  }>({
    query: ALL_COLUMN_SETS_LABEL,
    fetchPolicy: "no-cache",
    variables: {
      where: { publicationId, labelLike: columnSetLabel.trim(), custom: true }
    }
  });

  const costumColumnSetLabel: Array<{ label: string }> = result.data.allColumnSets;

  // case story creation, no old title
  if (!oldColumnSetLabel) {
    return !Boolean(costumColumnSetLabel.find(labelComparison(columnSetLabel)));
  }

  // case column set edit, old label
  if (columnSetLabel.toLowerCase().trim() !== oldColumnSetLabel.toLowerCase().trim()) {
    return !Boolean(costumColumnSetLabel.find(labelComparison(columnSetLabel)));
  }

  // edit column set, but with label available, new label is equal to old one
  return true;
};
