import * as React from "react";
import { Platform } from "../../../../types/Platform";
import { Project } from "../../../../types/Project";
import { useQuery } from "@apollo/react-hooks";
import FollowersCompositionQuery from "../../../../types/graphql/FollowersComposition";
import { FOLLOWERS_COMPOSITION } from "../../../../apollo/queries/FollowersComposition";
import {
  BarChart,
  Column,
  Columns,
  DonutChart,
  Stack,
  Title,
} from "@freeda/design-system";

type Props = {
  project: Project;
  platform: Platform;
  selectedPublisherNames: string[];
};

export function FollowersComposition(props: Props) {
  const followersComposition = useQuery<FollowersCompositionQuery>(
    FOLLOWERS_COMPOSITION,
    {
      fetchPolicy: "network-only",
      variables: {
        where: {
          platformId: props.platform,
          publisherNames: props.selectedPublisherNames,
        },
      },
    }
  );

  if (!followersComposition.loading && followersComposition.data) {

    const totalFollowers: number =
      followersComposition.data.followersComposition
        .map((d) => d.value)
        .reduce((p, c) => p + c, 0);

    const genderGroups = followersComposition.data.followersComposition.reduce(
      (group, product) => {
        group[product.sex] =
          group[product.sex] + product.value ?? product.value;
        return group;
      },
      { M: 0, F: 0, U: 0 }
    );

    const ageGroups = followersComposition.data.followersComposition.reduce(
      (group, product) => {
        group[product.age][product.sex] = getPercentage(totalFollowers, product.value);
        return group;
      },
      {
        "13-17": { M: 0, F: 0, U: 0 },
        "18-24": { M: 0, F: 0, U: 0 },
        "25-34": { M: 0, F: 0, U: 0 },
        "35-44": { M: 0, F: 0, U: 0 },
        "45-54": { M: 0, F: 0, U: 0 },
        "55-64": { M: 0, F: 0, U: 0 },
        "65+": { M: 0, F: 0, U: 0 },
      }
    );

    const agePercentages = Object.keys(ageGroups).map(age => ({
      age,
      Women: ageGroups[age].F,
      Men: ageGroups[age].M,
      Unspecified: ageGroups[age].U
    }))

    return (
      <Stack space={24}>
        <Title size="large">Followers composition</Title>
        <Columns space={16}>
          <Column width="1/3">
            <Stack space={16}>
              <Title size="small">Gender</Title>
              <DonutChart
                dataColors={["brightViolet", "brightJade", "brightGrey"]}
                height={300}
                data={[
                  {
                    gender: "Women",
                    percentage: getPercentage(totalFollowers, genderGroups.F),
                  },
                  {
                    gender: "Men",
                    percentage: getPercentage(totalFollowers, genderGroups.M),
                  },
                  {
                    gender: "Unspecified",
                    percentage: getPercentage(totalFollowers, genderGroups.U),
                  },
                ]}
                category="percentage"
                dataKey="gender"
              />
            </Stack>
          </Column>
          <Stack space={16}>
            <Title size="small">Age</Title>
            <BarChart
              dataColors={["brightViolet", "brightJade", "brightGrey"]}
              height={300}
              data={agePercentages}
              dataKey="age"
              categories={["Women", "Men", "Unspecified"]}
            />
          </Stack>
        </Columns>
      </Stack>
    );
  } else {
    return <Title size="medium">Loading...</Title>;
  }
}

 
function getPercentage(total: number, value: number) {
  return Math.round((value / total) * 100 * 10) / 10
}

