import * as React from "react";
import { LineChart, Title } from "@freeda/design-system";
import { BiaxialLineChart } from "../../../layout/BiaxialLineChart";

type Props = {
  data: {
    publisher: string;
    metric: string;
    value: number;
    timestamp: number;
  }[];
  kind: "metric" | "publisher";
  metricLabelMappingByPlatform: { [key: string]: string };
};

export function ResultsOverTimeGraph(props: Props) {
  const categories =
    props.kind === "metric"
      ? props.data.map((m) => props.metricLabelMappingByPlatform[m.metric])
      : props.data.map((m) => m.publisher);

  const chartCategories: string[] = categories.filter(
    (c, index) => categories.indexOf(c) === index
  );

  const groupedByTimestamp: {
    [key: string]: {
      name: string;
      value: number;
      timestamp: number;
    }[];
  } = props.data
    .sort((a, b) => a.timestamp - b.timestamp)
    .reduce((rv, x) => {
      (rv[x.timestamp] = rv[x.timestamp] || []).push({
        name:
          props.kind === "metric"
            ? props.metricLabelMappingByPlatform[x.metric]
            : x.publisher,
        value: x.value,
        timestamp: x.timestamp,
      });
      return rv;
    }, {});

  const timestamps = Object.keys(groupedByTimestamp);

  const chartData = timestamps.map((timestamp) => {
    const date = new Date(+timestamp);
    const metricsByDate = {
      name: date.toLocaleDateString("en-US", {
        month: "short",
        day: "numeric",
      }),
    };
    groupedByTimestamp[timestamp].forEach(
      (g) => (metricsByDate[g.name] = parseFloat(g.value.toFixed(2)))
    );
    return metricsByDate;
  });

  if (chartData.length > 0 && chartCategories.length > 0) {
    return props.kind === "metric" ? (
      <BiaxialLineChart categories={chartCategories} data={chartData} />
    ) : (
      <LineChart
        dataColors={["brightViolet", "brightJade", "brightRed"]}
        categories={chartCategories}
        height={300}
        data={chartData}
        dataKey="name"
        yAxisValueFormatter={formatCompactNumber}
      />
    );
  } else {
    return (
      <Title size="medium">No data found for this combination of options</Title>
    );
  }
}

export function formatCompactNumber(value: number | string): string {
  const number = Number(value);
  if (number < 0) {
    return "-" + formatCompactNumber(-1 * number);
  }
  if (number < 100000) {
    return number.toString();
  } else if (number >= 1000 && number < 1_000_000) {
    return (number / 1000).toFixed(2).replace(/\.0$/, "") + "K";
  } else if (number >= 1_000_000 && number < 1_000_000_000) {
    return (number / 1_000_000).toFixed(2).replace(/\.0$/, "") + "M";
  } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
    return (number / 1_000_000_000).toFixed(2).replace(/\.0$/, "") + "B";
  } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
    return (number / 1_000_000_000_000).toFixed(2).replace(/\.0$/, "") + "T";
  } else {
    return number.toString();
  }
}
