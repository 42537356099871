import * as React from "react";
import styled from "styled-components";
import { Typography, Icon, Table, DividerSection } from "@freeda/react-components";
import { HeaderLogoQuote } from "../ui/HeaderLogoQuote";
import { Colors } from "@freeda/react-components/lib/theme";
import { helpPageColumns, helpPageData, helpPageHead } from "../../utils/csvHelpPageData";
import { scrollbarVerticalStyle } from "../ui/ScrollBarStyle";

const WrapperPage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 3000;
  width: 100vw;
  height: 100vh;
  background: #f9f9f9;
  overflow-x: hidden;
`;

const Header = styled.div`
  background: #ffffff;
  width: 100vw;
  height: 65px;
  display: flex;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 6px 0px;
  padding: 0px 20px;
  padding-right: 10px;
  z-index: 1;
`;

const WrapperInstructions = styled.div`
  padding: 20px;
  height: 100vh;
  overflow-y: overlay;
  overflow-x: hidden;
  ${scrollbarVerticalStyle};
`;

const StepNumber = styled.span`
  color: ${(props) => props.theme.primary};
`;

const UploadCSVTikTokHelpPage: React.FC = () => {
  return (
    <WrapperPage>
      <Header>
        <HeaderLogoQuote style={{ position: "relative", marginLeft: "auto", top: 0 }} />
      </Header>
      <WrapperInstructions>
        <Typography variantName="subtitle" textColor={Colors.PURPLE} style={{ marginBottom: 20 }}>
          Prepare to import
        </Typography>
        <Typography variantName="text" style={{ letterSpacing: 0.3, marginBottom: 20 }}>
          To prepare your file to import, follow these steps.
        </Typography>
        <Typography variantName="text" style={{ lineHeight: "20px", letterSpacing: 0.3 }}>
          <>
            <h3><StepNumber>1.</StepNumber> Create a new .csv file and give it today date, in the form YYYY-MM-DD-hh-mm-ss-YOUR_TIME_ZONE.csv, as file name</h3>          
            <b>YOUR_TIME_ZONE must be one of the following:</b>
            <br />
            <br /><b>CEST:</b> Central European Summer Time: this is the time zone of counties like Italy and Spain during daylight saving (usually from end March to end October)
            <br /><b>CET:</b> Central European Time: this is the time zone of counties like Italy and Spain when daylight saving is off (usually from end October to end March)
            <br /><b>BST:</b> British Summer Time (when daylight saving is on)
            <br /><b>GMT:</b> British Time (when daylight saving if off)
            <br />
            <br/>
            <b>Example file name: 2021-04-02-15-00-00-CEST.csv</b>
            <br/>
            <br/>
            <h3><StepNumber>2.</StepNumber>File first line must always be:</h3>
            <b>Account,User Name,Followers at Posting,Created,Type,Reach,PaidReach,Views,Likes,Comments,Shares,AVG Watch time,Watch full video,Total Play Time,URL,Description,Sponsor Id,Sponsor Name,Length,Saved,Photo</b>
            <br/>
            <div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
              <Icon iconName="info" iconSize="small" iconColor={Colors.GRIGINO} />
              <Typography variantName="label" textColor={Colors.GRIGINO} style={{ marginLeft: 5 }}>
                  (Please don't change order of columns !!!!!)
              </Typography>
            </div>
            <h3><StepNumber>3.</StepNumber> All following lines are comma separated recorded metrics.</h3>
            <b>Example: Freeda,freeda_es,97000,2021-04-23 10:00:00 CEST,TK,8000,500,10001,6316,53,20,4.9,2,00:15:00,https://www.tiktok.com/@freeda_es/video/6954397615832272134,Soy todas en realidad..¡Feliz día del libro!📚 @mimigranizo #Freeda #BookTok #DiaDelLibro #TiposDePersonas,,,00:15,https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/ebc591b13b12444aa4d5f3fe34b9e902?x-expires=1620666000&x-signature=MUToL%2BLuETXwoN%2BeMGqV63GTtTg%3D</b>
            <br />
            <br />
            Field 'type' is always 'TK'. 
            <br/>
            Fields 'Sponsor Id','Sponsor Name' are optional
            <br />
            Field 'Created' must have the same time zone as the file name. This is the date and time the content was published
            <br />
            Field 'User Name' must be (case sensitive)
            <br/>
            freeda_it/_es/_en or the name project obtained by the Data Tech team
            <br/>
            <br />
            
            <h3><StepNumber>4.</StepNumber>Complete example (Spain summer time):</h3>
            <b> Account,User Name,Followers at Posting,Created,Type,Reach,PaidReach,Views,Likes,Comments,Shares,AVG Watch time,Watch full video,Total Play Time,URL,Description,Sponsor Id,Sponsor Name,Length,Photo
              <br />
              Freeda,freeda_es,97700,2021-05-08 11:00:00 CEST,TK,100000,5000,200000,7728,17,18,4.8,1.79,22:37:23,https://www.tiktok.com/@freeda_es/video/6959582386950589701,Invitamos a un 🍧 a @anamchef8_ para que nos cuente algún secreto. #sensodyne #ad #LaVidaesMuyCortaPara #IngredienteSecreto #MasterChef,,,00:15,23,https://p16-sign-va.tiktokcdn.com/tos-maliva-p-0068/06d64672845c45009e33bef6da77e95d_1620404057~tplv-dmt-logom:tos-maliva-p-0000/a45f29c9a7a04fdf87c15763d1ea6572.image?x-expires=1620666000&x-signature=%2FWsqWM5xcDJdgwkwEiy3vojIiR0%3D
            <br/>
              Freeda,freeda_es,97000,2021-04-23 10:00:00 CEST,TK,8000,2500,10001,6316,53,20,4.9,2,00:15:00,https://www.tiktok.com/@freeda_es/video/6954397615832272134,Soy todas en realidad.. ¡Feliz día del libro! 📚 @mimigranizo #Freeda #BookTok #DiaDelLibro #TiposDePersonas,,,00:10,10,https://p16-sign-va.tiktokcdn.com/obj/tos-maliva-p-0068/ebc591b13b12444aa4d5f3fe34b9e902?x-expires=1620666000&x-signature=MUToL%2BLuETXwoN%2BeMGqV63GTtTg%3D
            </b>
            <br />
          </>
        </Typography>
        <div style={{ width: 220, padding: 10 }}>
          <Typography
            variantName="text"
            textColor={Colors.PURPLE}
            style={{ lineHeight: "20px", letterSpacing: 0.3, fontWeight: 500 }}
          >
            Formatting data:
          </Typography>
          <Typography variantName="label" style={{ lineHeight: "20px", letterSpacing: 0.3 }}>
            <>
              <strong>Length</strong>
              <br />
              ✌🏻 Correct: 0:53
              <br />
              ⛔️ Wrong: 53
              <br />
              {/* <DividerSection />
              <strong>AVG Watched</strong>
              <br />
              ✌🏻 Correct: 0,23
              <br />
              ⛔️ Wrong: 23%
              <br /> */}
            </>
          </Typography>
        </div>
        <Typography variantName="text" style={{ lineHeight: "20px", letterSpacing: 0.3, marginBottom: 40 }}>
          <>
            <h3><StepNumber>5.</StepNumber> Save the file in .CSV format with comma like separator</h3>
            Nice job! Now that you've prepared your .CSV file, you're ready to import it to Elinor.
          </>
        </Typography>
        <h3>Example :</h3>
        <div>
          <a href="/2021-05-20-15-30-00-CEST.csv" download>Spain CSV Example File (Summer Time)</a>
        </div>
        <br />
       
     
      </WrapperInstructions>
    </WrapperPage>
  );
};

export { UploadCSVTikTokHelpPage };
