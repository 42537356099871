import client from "../../../apollo/client";
import { GET_IGREEL_FILE_DESTINATION_URL } from "../../../apollo/mutations/GetIGReelFileDestinationUrl";
import { MessageType } from "../../../providers/FeedbackProvider";
import { asyncDataMessage } from "./specsCSVColumn";

const getS3Url = (fileName: string) =>
  client.mutate<{ getIGReelFileDestinationUrl: { url: string } }, { input: { fileName: string } }>({
    mutation: GET_IGREEL_FILE_DESTINATION_URL,
    variables: { input: { fileName } },
  });

const upload = (
  s3Url: string,
  selectedFile: File,
  onChangeCompleted: (completed: boolean | null) => void,
  onChangeUploadError: (isError: boolean) => void,
  openSnackbar: (message: string | React.ReactElement<any>, type: MessageType) => void
) => {
  const xhr = new XMLHttpRequest();
  xhr.open("put", s3Url, true);
  xhr.setRequestHeader("Content-Type", "text/csv");
  xhr.setRequestHeader("Content-Disposition", "attachment");

  xhr.upload.onprogress = (e) => {
    if (e.lengthComputable) {
      const percentComplete = Math.trunc((e.loaded / e.total) * 100);
      onChangeCompleted(percentComplete === 100);
    }
  };

  xhr.onreadystatechange = () => {
    const { readyState, status, response } = xhr;
    if (readyState === 4) {
      if (status === 200) {
        openSnackbar(asyncDataMessage, "notification");
        console.log("Upload Successful", response);
      } else {
        onChangeUploadError(true);
        onChangeCompleted(null);
        console.log("[Status error]:", status);
        console.log("[Response]:", response);
      }
    }
  };

  const blob = new Blob([selectedFile], { type: selectedFile.type });
  xhr.send(blob);
};

export const s3Upload = async (
  selectedFile: File,
  onChangeCompleted: (completed: boolean | null) => void,
  onChangeUploadError: (isError: boolean) => void,
  openSnackbar: (message: string | React.ReactElement<any>, type: MessageType) => void
) => {
  try {
    onChangeCompleted(false);
    const result = await getS3Url(selectedFile.name);

    if (result.errors) {
      openSnackbar("Cannot generate a signed url :(", "error");
      throw new Error("Cannot generate a signed url :(");
    }
    if (!result.data) {
      openSnackbar("Cannot generate a signed url :(", "error");
      throw new Error("Cannot generate a signed url :(");
    }

    const s3Url = result.data.getIGReelFileDestinationUrl.url;
    upload(s3Url, selectedFile, onChangeCompleted, onChangeUploadError, openSnackbar);
  } catch (err) {
    console.log(err);
  }
};
