import * as React from "react";
import styled from "styled-components";
import { useQuery } from "@apollo/react-hooks";
import { Autocomplete, Chip } from "@freeda/react-components";
import { debounce } from "lodash";
import { scrollbarHorizontalStyle } from "../../ui/ScrollBarStyle";
import ListMetadataQueryT from "../../../types/graphql/ListMetadataQuery";
import { MetadataOrderBy } from "../../../types/graphql/MetadataOrderBy";
import { Project } from "../../../types/Project";
import { LIST_METADATA } from "../../../apollo/queries/ListMetadata";
import { PlaceholderChip } from "../../ui/Placeholders/PlaceholderChip";

const ChipWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow-x: auto;
  flex-wrap: wrap;
  & > div {
    margin-bottom: 5px;
  }
  @media (max-width: 768px) {
    margin-bottom: 20px;
  }
  ${scrollbarHorizontalStyle};
`;

interface Props {
  keywords: Array<{ metadataId: string; label: string }>;
  onChangeKeywords: (
    metadata: { metadataId: string; label: string },
    clearFilter?: () => void
  ) => void;
  project: Project;
}

const AutocompleteKeywords: React.FC<Props> = ({
  keywords: selectedKeywords,
  onChangeKeywords,
  project,
}) => {
  const [textValue, setTextValue] = React.useState("");

  const debouncedUpdatedInputValue = debounce(
    (textValue: string) => setTextValue(textValue),
    500
  );

  const listMetadataResult = useQuery<ListMetadataQueryT>(LIST_METADATA, {
    variables: {
      limit: 20,
      orderBy: `${MetadataOrderBy.label}_ASC`,
      where: {
        categoryId: "KEYWORDS",
        labelLike: textValue,
        project: {
          idIn: [project.id],
          alsoWithoutProjects: true,
        },
      },
    },
  });
  const metadata =
    listMetadataResult.loading || !listMetadataResult.data
      ? []
      : listMetadataResult.data.listMetadata.content;

  return (
    <div style={{ marginTop: 10 }}>
      <Autocomplete
        label="keywords"
        loading={listMetadataResult.loading}
        style={{ width: "100%", marginTop: 5, marginBottom: 10 }}
        onInputChange={(textValue) => debouncedUpdatedInputValue(textValue)}
        placeholder="Select label"
        flexBasis="100%"
        currentSelectedItem={selectedKeywords.map((m) => ({
          value: String(m.metadataId),
          label: m.label,
        }))}
        items={metadata.map((m) => ({
          value: String(m.id),
          label: m.label,
        }))}
        onSelect={(metadata) =>
          onChangeKeywords(
            { metadataId: metadata.value, label: metadata.label },
            () => setTextValue("")
          )
        }
        onUnselect={(metadata) =>
          onChangeKeywords({
            metadataId: metadata.value,
            label: metadata.label,
          })
        }
        renderSelections={(selectedMetadata: Array<any>, handleDelete) => (
          <ChipWrapper>
            {selectedMetadata.length === 0 && <PlaceholderChip />}
            {selectedMetadata.map((metadata: any) => {
              return (
                <Chip
                  key={metadata.value}
                  label={metadata.label}
                  style={{ marginRight: 5 }}
                  onClick={() => {
                    handleDelete(metadata);
                  }}
                  isClearable
                />
              );
            })}
          </ChipWrapper>
        )}
        internalOpen
      />
    </div>
  );
};

export { AutocompleteKeywords };
