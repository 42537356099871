import {
  Column,
  Columns,
  Stack,
  Tabs,
  Tiles,
  Title,
} from "@freeda/design-system";
import * as React from "react";
import ListPerformanceMetricsQuery from "../../../../types/graphql/ListPerformanceMetricsQuery";
import { PerfMetricsTimeSpan } from "../../../../types/PerfMetricsTimeSpan";
import { MetricCard } from "../../../ui/MetricCard";
import { useQuery } from "@apollo/react-hooks";
import { LIST_PERFORMANCE_METRICS } from "../../../../apollo/queries/ListPerformanceMetrics";
import { Project } from "../../../../types/Project";
import { Platform } from "../../../../types/Platform";

type Props = {
  platform: Platform;
  span: PerfMetricsTimeSpan;
  metrics: Array<{ name: string; value: number; trend: number }>;
};

function PerformanceMetricsSection(props: Props) {
  const components = props.metrics.map((metric, index) => (
    <MetricCard
      key={index}
      title={metricsByPlatform[props.platform][metric.name]}
      value={metric.value}
      performace={{ diff: metric.trend, span: props.span }}
    />
  ));
  if (components.length > 0) {
    return (
      <Tiles space={16} columns={4}>
        {components}
      </Tiles>
    );
  } else {
    return <Title size="medium">No metrics</Title>;
  }
}

type ContainerProps = {
  project: Project;
  platform: Platform;
  selectedPublisherNames: string[];
};

export function PerformanceMetricsSectionContainer(props: ContainerProps) {
  const publisherFilter =
    props.selectedPublisherNames.length > 0
      ? {
          publisherNames: props.selectedPublisherNames,
        }
      : { projectId: props.project.idLegacy };

  const [perfMetricsTimeSpan, setPerfMetricsTimeSpan] =
    React.useState<PerfMetricsTimeSpan>("last_week");

  const computeMetrics = () => {
    switch (props.platform) {
      case "FB":
        return perfMetricsTimeSpan === "last_3_months"
          ? Object.keys(metricsByPlatform[props.platform]).filter(
              (metric) => metric !== "page_impressions_organic_unique"
            )
          : Object.keys(metricsByPlatform[props.platform]);
      default:
        return Object.keys(metricsByPlatform[props.platform]);
    }
  };

  const listPerformanceMetrics = useQuery<ListPerformanceMetricsQuery>(
    LIST_PERFORMANCE_METRICS,
    {
      fetchPolicy: "network-only",
      variables: {
        where: {
          span: perfMetricsTimeSpan,
          metrics: computeMetrics(),
          platformId: props.platform,
          ...publisherFilter,
        },
      },
    }
  );

  const getContent = () => {
    if (!listPerformanceMetrics.loading && listPerformanceMetrics.data) {
      return (
        <PerformanceMetricsSection
          platform={props.platform}
          span={perfMetricsTimeSpan}
          metrics={listPerformanceMetrics.data.listPerformanceMetrics}
        />
      );
    } else {
      return <Title size="medium">Loading...</Title>;
    }
  };

  return (
    <Stack space={24}>
      <Columns space={0}>
        <Title size="large">Performance metrics</Title>
        <Column width="content">
          <Tabs
            size="medium"
            value={perfMetricsTimeSpan}
            onChange={setPerfMetricsTimeSpan}
            tabs={perfMetricsTimeSpanOptions}
          />
        </Column>
      </Columns>
      {getContent()}
    </Stack>
  );
}

const perfMetricsTimeSpanOptions: {
  value: PerfMetricsTimeSpan;
  label: string;
}[] = [
  {
    value: "last_week",
    label: "Last week",
  },
  {
    value: "last_month",
    label: "Last month",
  },
  {
    value: "last_3_months",
    label: "Last 3 months",
  },
];

const metricsByPlatform = {
  FB: {
    page_impressions_organic_unique: "Reach",
    page_video_views_organic: "Page Video Views",
    page_follows: "Page Followers",
    page_post_engagements: "Interactions",
  },
  IG: {
    reach: "Reach (Organic + Paid)",
    followers_count: "Followers",
  },
  YT: {
    views: "Views",
    subscribers: "Subscribers",
    engagement: "Interactions",
  },
  TK: {
    follower_count: "Followers",
    likes_count: "Total Likes",
  }
};
