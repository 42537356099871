import * as React from "react";

import { FeedbackContext } from "../../providers/FeedbackProvider";
import { Snackbar } from "@freeda/react-components";

const FeedbackSnackbar: React.FC = () => {
  const { message, messageType, open, onClose } = React.useContext(FeedbackContext);
  return <Snackbar typology={messageType} open={open} onClose={onClose} label={<span>{message}</span>} />;
};

export { FeedbackSnackbar };
