import * as React from "react";

import { ProjectPageContext } from "./ProjectPageProvider";

const CREATE = "create";

export type ColumnSetType = string;
export type SelectedSets = { [csType in string]: string | null };




type ColumnSetCtx = {
  selectSet: (columnSetType: ColumnSetType, selectedSetName: string | null, onOpenCustomPanel: () => void) => void;
  selectedSets: SelectedSets;
};

const initialContext: ColumnSetCtx = {
  selectSet: (columnSetType: ColumnSetType, selectedSetName: string | null, onOpenCustomPanel: () => void) =>
    console.warn("Please implement selectSet"),
  //selectedSets: pagesUnits.map(({ id }) => id).reduce((acc, next) => ({ ...acc, [next]: null }), {})
  selectedSets: {}
};

const MetricColumnSetsContext = React.createContext<ColumnSetCtx>(initialContext);

type State = Pick<ColumnSetCtx, "selectedSets">;

const MetricColumnSetsProvider: React.FC = ({ children }) => {

  const { pageUnits } = React.useContext(ProjectPageContext);
  
  const [state, setState] = React.useState<State>({
    selectedSets: pageUnits.map(({ id }) => id).reduce((acc, next) => ({ ...acc, [next]: null }), {}) //initialContext.selectedSets               
                                
  });



  const selectSet = (columnSetType: ColumnSetType, selectedSetName: string | null, onOpenCustomPanel: () => void) => {
    if (selectedSetName === CREATE) {
      setState(state => ({ selectedSets: { ...state.selectedSets, [columnSetType]: null } }));
      onOpenCustomPanel();
    } else {
      setState(state => ({
        ...state,
        selectedSets: { ...state.selectedSets, [columnSetType]: selectedSetName }
      }));
    }
  };

  return (
    <MetricColumnSetsContext.Provider value={{ ...state, selectSet: selectSet }}>
      {children}
    </MetricColumnSetsContext.Provider>
  );
};

export { MetricColumnSetsProvider, MetricColumnSetsContext };
