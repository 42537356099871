import * as React from "react";
import * as d3 from "d3";
import { v4 } from "uuid";
import "./style.css";
import { Colors } from "@freeda/react-components/lib/theme";
import { ConversionUnit } from "../../../types/ConversionUnit";
import { d3formatNumber } from "../../../utils/d3FormatNumber";

export interface ChartData {
  label: string;
  valueY: number;
  conversionUnit: ConversionUnit | null;
  tooltipValue?: string;
}

interface Props {
  dataArray: Array<ChartData>;
}
interface State {
  heightChart: number;
  widthWrapper: number;
  dataArrayState: Array<ChartData>;
  conversionUnit: ConversionUnit | null;
}

const Linechart: React.FC<Props> = ({ dataArray }) => {
  const chartIdRef = React.useRef<string>(v4());
  const gIdRef = React.useRef<string>(v4());

  const dataArrayState = dataArray.map((d) => {
    const tooltipFormatNumber = d3formatNumber(d.conversionUnit);

    return {
      ...d,
      tooltipValue: `${tooltipFormatNumber(d.valueY)}`,
    };
  });

  const [state, setState] = React.useState<State>({
    heightChart: 0,
    widthWrapper: 0,
    dataArrayState,
    conversionUnit: dataArray.map((d) => d.conversionUnit)[0],
  });

  const getElementsSvg = () => {
    const { dataArrayState, conversionUnit } = state;

    const height = 500;
    const width = state.widthWrapper - 50;
    const multiplyNumber = width / (dataArrayState.length - 1);

    const allValue = dataArrayState.map((d) => d.valueY);
    const maxValue = Math.max(...allValue) === 0 ? 10 : Math.max(...allValue);

    const formatNumber = d3formatNumber(conversionUnit, true);

    const allName = dataArrayState.map((d, i) => d.label);
    const xLine = d3.scalePoint().domain(allName).range([0, width]);

    const xAxisLine = d3.axisBottom(xLine).tickPadding(10);

    const customXAxis = (g: any) => {
      g.call(xAxisLine);
      g.selectAll(".tick:not(:first-of-type) line").attr("stroke", Colors.GRIGINO).attr("stroke-dasharray", "5,5");
      g.selectAll(".tick text").attr("x", 4).attr("dy", -4);
    };

    const yLine = d3.scaleLinear().domain([0, maxValue]).range([height, 0]).nice();
    const yAxisLine = d3.axisRight(yLine).tickSize(width).tickFormat(formatNumber);

    const customYAxis = (g: any) => {
      g.call(yAxisLine);
      g.selectAll(".tick:not(:first-of-type) line").attr("stroke", Colors.GRIGINO).attr("stroke-dasharray", "5,5");
      g.selectAll(".tick text").attr("x", 4).attr("dy", -4);
    };

    const lineGenerator = d3
      .line<ChartData>()
      .x((d: ChartData, i) => i * multiplyNumber)
      .y((d: ChartData) => {
        return formatNumber(yLine(d.valueY));
      })
      .curve(d3.curveMonotoneX);

    return {
      xLine,
      xAxisLine,
      customXAxis,
      yLine,
      yAxisLine,
      customYAxis,
      lineGenerator,
    };
  };

  const drawChart = () => {
    const { dataArrayState } = state;

    const height = 500;
    const width = state.widthWrapper - 50;
    const multiplyNumber = width / (dataArrayState.length - 1);

    const { customYAxis, customXAxis } = getElementsSvg();

    const divLinechart = document.getElementById(chartIdRef.current);

    if (divLinechart) {
      const svg = d3.select(divLinechart).append("svg").attr("height", "100%").attr("width", "100%");

      const defs = svg.append("defs");
      const linearGradientV = defs.append("linearGradient").attr("id", "linear-gradient-h");

      linearGradientV.attr("x1", "0%").attr("y1", "0%").attr("x2", "0%").attr("y2", "100%");

      //Set the color for the start (0%)
      linearGradientV.append("stop").attr("offset", "0%").attr("stop-color", "rgb(107,32,218)");

      //Set the color for the end (100%)
      linearGradientV.append("stop").attr("offset", "100%").attr("stop-color", "#f0aeff");

      const linechartGrp = svg
        .append("g")
        .attr("class", "linechart-group")
        .attr("transform", "translate(20,20)")
        .attr("id", `${gIdRef.current}`);

      linechartGrp.append("g").attr("class", "y-axis-line").call(customYAxis);

      linechartGrp
        .append("g")
        .attr("class", "x-axis-line")
        .call(customXAxis)
        .attr("transform", `translate(0,${height})`);

      const divTooltip = d3.select(divLinechart).append("div").attr("id", "linechart-tooltip").style("opacity", 0);

      const initialLinePath = d3
        .line<ChartData>()
        .x((d: ChartData, i) => i * multiplyNumber)
        .y(height)
        .curve(d3.curveNatural);

      linechartGrp
        .append("path")
        .datum(dataArrayState)
        .attr("d", initialLinePath)
        .attr("class", "linechart-line")
        .attr("fill", "none")
        .attr("stroke", "url(#linear-gradient-h)")
        .attr("stroke-width", 2);

      const secondaryCircle = linechartGrp
        .selectAll("circle.secondary-circle")
        .data(dataArrayState)
        .enter()
        .append("circle")
        .style("cursor", "pointer")
        .attr("class", "secondary-circle");

      secondaryCircle
        .attr("cx", (d, i) => i * multiplyNumber)
        .attr("cy", height)
        .attr("r", 6)
        .attr("fill", "none")
        .attr("stroke", "#ffffff")
        .attr("stroke-width", 7);

      const primaryCircle = linechartGrp
        .selectAll("circle.primary-circle")
        .data(dataArrayState)
        .enter()
        .append("circle")
        .style("cursor", "pointer")
        .attr("class", (d, i) => `primary-circle`)
        .attr("id", (d, i) => `circle${i}`);

      primaryCircle
        .attr("cx", (d, i) => i * multiplyNumber)
        .attr("cy", height)
        .attr("r", 6)
        .attr("fill", Colors.PURPLE)
        .on("mouseover", (d, i) => {
          const tooltip = document.getElementById("linechart-tooltip");
          const hoveredCircle = document.getElementById(`circle${i}`);
          let tooltipWidth: number;
          if (tooltip && hoveredCircle) {
            tooltipWidth = tooltip.getBoundingClientRect().width;

            const { x, y } = hoveredCircle.getBoundingClientRect();

            divTooltip
              .style("opacity", 0)
              .style("background", "#ffffff")
              .style("box-shadow", "0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)")
              .style("color", "#666")
              .transition()
              .duration(300)
              .style("opacity", 1);
            divTooltip
              .html("value" + "<br/>" + d.tooltipValue)
              .style("left", x - tooltipWidth / 3 + "px")
              .style("top", y + 28 + "px");

            linechartGrp
              .select(`#circle${i}`)
              .classed("selected-circle", true)
              .transition()
              .ease(d3.easeBounce)
              .duration(300)
              .attr("r", 12);
          }
        })
        .on("mouseout", (d, i) => {
          divTooltip.transition().duration(300).style("opacity", 0);
          linechartGrp
            .select(`#circle${i}`)
            .classed("selected-circle", false)
            .transition()
            .ease(d3.easeBounce)
            .duration(300)
            .attr("r", 8);
        });
    }
  };

  const onResizePage = () => {
    const divLinechart = document.getElementById(chartIdRef.current);
    if (divLinechart) {
      const widthWrapper = Math.round(divLinechart.getBoundingClientRect().width);
      setState((state) => ({ ...state, widthWrapper }));
    }
  };

  React.useEffect(() => {
    drawChart();
    onResizePage();

    window.addEventListener("resize", onResizePage);

    return () => {
      window.removeEventListener("resize", onResizePage);
    };
  }, []);

  const updateChart = () => {
    const { dataArrayState, conversionUnit } = state;
    const width = state.widthWrapper - 50;
    const multiplyNumber = width / (dataArrayState.length - 1);

    const formatNumber = d3formatNumber(conversionUnit, true);

    const allValue = dataArrayState.map((d) => d.valueY);
    const maxValue = Math.max(...allValue) === 0 ? 10 : Math.max(...allValue);

    const divLinechart = document.getElementById(chartIdRef.current);
    const linechart = d3.select(divLinechart).selectAll(".linechart-line");
    const primaryCircle = d3.select(divLinechart).selectAll(".primary-circle");
    const secondaryCircle = d3.select(divLinechart).selectAll(".secondary-circle");
    const group = d3.select(divLinechart).selectAll(".linechart-group");

    const { yLine, lineGenerator, customYAxis, xLine, customXAxis } = getElementsSvg();

    const allName = dataArrayState.map((d, i) => d.label);

    xLine.domain(allName).range([0, width]);
    group.selectAll(".x-axis-line").call(customXAxis);

    yLine.domain([0, maxValue]).nice();
    group.selectAll(".y-axis-line").call(customYAxis);

    linechart
      .datum(dataArrayState)
      .transition()
      .ease(d3.easeCubic)
      .duration(800)
      .attr("stroke-width", 6)
      .attr("d", lineGenerator);

    primaryCircle
      .data(dataArrayState)
      .transition()
      .ease(d3.easeCubic)
      .duration(800)
      .attr("r", 8)
      .attr("cx", (d, i) => i * multiplyNumber)
      .attr("cy", (d) => formatNumber(yLine(d.valueY)));

    secondaryCircle
      .data(dataArrayState)
      .transition()
      .ease(d3.easeCubic)
      .duration(800)
      .attr("r", 8)
      .attr("cx", (d, i) => i * multiplyNumber)
      .attr("cy", (d) => formatNumber(yLine(d.valueY)));
  };

  const buildChartWithNewData = (dataArray: Array<ChartData>) => {
    const conversionUnit = dataArray.map((d) => d.conversionUnit)[0];

    const newDataArray = dataArray.map((d) => {
      const tooltipFormatNumber = d3formatNumber(d.conversionUnit);

      return {
        ...d,
        tooltipValue: `${tooltipFormatNumber(d.valueY)}`,
      };
    });
    setState((state) => ({ ...state, conversionUnit, dataArrayState: newDataArray }));
  };

  React.useEffect(() => {
    updateChart();
    const groupSvg = document.getElementById(gIdRef.current);
    if (groupSvg) {
      const heightG = Math.round(groupSvg.getBoundingClientRect().height);
      setState((state) => ({ ...state, heightChart: heightG + 30 }));
    }
  }, [state.widthWrapper]);

  // componentDidUpdate
  React.useEffect(() => {
    buildChartWithNewData(dataArray);
  }, [dataArray]);

  React.useEffect(() => {
    updateChart();
  }, [state]);

  return <div id={chartIdRef.current} style={{ height: state.heightChart }} />;
};

export { Linechart };
