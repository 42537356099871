import { Project } from "../../../../types/Project";
import { Platform } from "../../../../types/Platform";
import * as React from "react";
import { useQuery } from "@apollo/react-hooks";
import PaidResultsOverTimeQuery from "../../../../types/graphql/ResultsOverTimeQuery";
import {
  Column,
  Columns,
  DateField,
  LocalizedString,
  SelectField,
  Stack,
  Title,
} from "@freeda/design-system";
import { endOfWeek, add } from "date-fns";
import { PAID_RESULTS_OVER_TIME } from "../../../../apollo/queries/PaidResultsOverTime";
import { ResultsOverTimeGraph } from "../OrganicMetrics/ResultsOverTimeGraph";
import { paidResultsOverTimeMetricsByPlatform } from "./PaidMetricsPage";

type Props = {
  position?: string;
  objective?: string;
  project: Project;
  platform: Platform;
  selectedPublisherNames: string[];
};

export function PaidResultsOverTimeSection(props: Props) {
  const platformMetrics: string[] = Object.keys(paidResultsOverTimeMetricsByPlatform);

  const [selectedMetrics, setSelectedMetrics] = React.useState<string[]>([
    platformMetrics[0],
  ]);

  const selectMetrics = (metrics: string[]) => {
    // if only one language is selected then the user can select up to 2 different metrics
    // if more than one language is selected then the user can select just 1 metric
    if (
      metrics.length === 0 ||
      (selectedMetrics.length === 2 && metrics.length > 1)
    )
      return;
    if (props.selectedPublisherNames.length > 1) {
      const diff = metrics.filter((x) => !selectedMetrics.includes(x));
      setSelectedMetrics(diff);
    } else {
      setSelectedMetrics(metrics);
    }
  };

  React.useEffect(() => {
    setSelectedMetrics([Object.keys(paidResultsOverTimeMetricsByPlatform)[0]]); //reset selected metrics
  }, [props.selectedPublisherNames, props.platform]);

  const now = new Date();

  const oneMonthAgo = new Date(
    now.getFullYear(),
    now.getMonth() - 1,
    now.getDate()
  );
  const threeMonthsAgo = new Date(
    now.getFullYear(),
    now.getMonth() - 3,
    now.getDate()
  );
  const sixMonthsAgo = new Date(
    now.getFullYear(),
    now.getMonth() - 6,
    now.getDate()
  );

  const [period, setPeriod] = React.useState<[Date, Date]>([
    sixMonthsAgo,
    now,
  ]);

  const selectPeriod = (period: [Date, Date]) => {
    setPeriod([
      period[0],
      period[1]
    ]);
  };

  const paidResultsOverTime = useQuery<PaidResultsOverTimeQuery>(
    PAID_RESULTS_OVER_TIME,
    {
      fetchPolicy: "network-only",
      variables: {
        where: {
          metrics: selectedMetrics,
          spanFrom: period[0].valueOf(),
          spanTo: period[1].valueOf(),
          platformId: props.platform,
          publisherNames: props.selectedPublisherNames,
          positionId: props.position,
          objective: props.objective,
        },
      },
    }
  );

  const buildContent = () => {
    if (!paidResultsOverTime.loading && paidResultsOverTime.data) {
      if (
        paidResultsOverTime.data.paidResultsOverTime.length > 0
      ) {
        const flattenedMetrics =
          paidResultsOverTime.data.paidResultsOverTime.flatMap((r) =>
            r.metrics.flatMap((m) =>
              m.values.flatMap((v) => ({
                publisher: r.publisher,
                metric: m.name,
                value: v.value,
                timestamp: v.timestamp,
              }))
            )
          ).map(x => ({
            publisher: x.publisher,
                metric: x.metric,
                value: x.value,
                timestamp: removeTime(x.timestamp)
          }));

        fillMetricGapsInPeriod(flattenedMetrics, period)

        return (
          <ResultsOverTimeGraph
            data={flattenedMetrics}
            kind={
              props.selectedPublisherNames.length > 1 ? "publisher" : "metric"
            }
            metricLabelMappingByPlatform={paidResultsOverTimeMetricsByPlatform}
          />
        );
      } else {
        return <Title size="medium">No data to show!</Title>;
      }
    } else {
      return <Title size="medium">Loading...</Title>;
    }
  };

  const metricsOptions = Object.keys(paidResultsOverTimeMetricsByPlatform).map((metric) => ({
    label: paidResultsOverTimeMetricsByPlatform[metric],
    value: metric,
    kind: "single-line" as const,
  }));

  const shortcuts: {
    label: LocalizedString;
    value: [Date, Date];
  }[] = [
    {
      label: "Last Month",
      value: [oneMonthAgo, now],
    },
    {
      label: "Last Three Months",
      value: [threeMonthsAgo, now],
    },
    {
      label: "Last Six Months",
      value: [sixMonthsAgo, now],
    },
  ];

  const minDate = new Date(
    now.getFullYear() -1,
    0,
    1
  );

  return (
    <Stack space={24}>
      <Title size="large">Results over time</Title>
      <Columns space={0}>
        <Column width="1/3">
          <SelectField
            isMulti
            name="metrics-filter"
            label="Metrics"
            placeholder="Select metrics"
            value={selectedMetrics}
            options={metricsOptions}
            onBlur={() => {}}
            onChange={selectMetrics}
            hint="Choose up to two metrics to compare. When multiple publishers are selected, just one metric can be chosen."
          />
        </Column>
        <Stack space={0}>
          <></>
        </Stack>
        <Column width="1/3">
          <DateField
            name="date"
            label="Period"
            type="range"
            value={period}
            onChange={(date: [Date | null, Date | null]) => {
              selectPeriod([
                date[0] ? date[0] : endOfWeek(oneMonthAgo),
                date[1] ? date[1] : endOfWeek(now),
              ]);
            }}
            shortcuts={shortcuts}
            minDate={minDate}
            maxDate={now}
          />
        </Column>
      </Columns>
      {buildContent()}
    </Stack>
  );
}

/**
 * 
 * Adds zero valued metrics in case api returned data series 
 * don't cover user selected time period.
 * 
 * @param metrics metrics as return by the api call
 * @param period time range selected through the date picker
 */
function fillMetricGapsInPeriod(metrics: {
  publisher: string;
  metric: string;
  value: number;
  timestamp: number;
}[], period: [Date, Date]) {

  const publishers = new Set(metrics.map(m => m.publisher))
  const metricNames = new Set(metrics.map(m => m.metric))

  publishers.forEach(publisher => {
    metricNames.forEach(metricName => {
      const currentMetrics = metrics.filter(m => m.publisher === publisher && m.metric === metricName)

      const startDate = new Date(Date.UTC(period[0].getFullYear(), period[0].getMonth(), period[0].getDate()))
      const endDate = new Date(Date.UTC(period[1].getFullYear(), period[1].getMonth(), period[1].getDate()))

      for(let currentDate = startDate; currentDate <= endDate; currentDate = add(currentDate, {days: 1})){
        if(currentMetrics.findIndex((m) => compareMetrics(m, currentDate) ) === -1) {
          metrics.push({publisher: publisher, metric: metricName, timestamp: removeTime(currentDate.getTime()), value: 0})
        }
      }
    })
  })
}

function removeTime(sourceEpoch: number): number{

  const sourceDate = new Date(sourceEpoch)

  const newDate = new Date(Date.UTC(sourceDate.getFullYear(), sourceDate.getMonth(), sourceDate.getDate()))

  return newDate.getTime()

}

function compareMetrics(metric: {
  publisher: string;
  metric: string;
  value: number;
  timestamp: number;
}, filler: Date) {

  const leftDate = new Date(metric.timestamp)

  const res = 
    (leftDate.getFullYear() === filler.getFullYear()) &&
    (leftDate.getMonth() === filler.getMonth()) &&
    (leftDate.getDate() === filler.getDate())

    return res
}
