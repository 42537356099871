import * as React from "react";
import styled from "styled-components";
import { DividerSection } from "@freeda/react-components";

const Title = styled.div`
  width: 200px;
  height: 12px;
  border-radius: 6px;
  background: #e7e7e7;
`;

const Row = styled.div<{ width?: number; background?: string }>`
  width: ${props => (props.width ? `${props.width}%` : "100%")};
  height: 4px;
  border-radius: 6px;
  background: ${props => props.background || "#e7e7e7"};
  margin-bottom: 13px;
`;

const Formula = styled.div<{ width?: number; background?: string }>`
  width: ${props => (props.width ? `${props.width}%` : "80%")};
  height: 8px;
  border-radius: 6px;
  background: #dedede;
`;

const FormulaWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 337px;
  height: 95px;
  padding: 20px;
  background: #ededed;
  border-radius: 6px;

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const StyledDividerSection = styled(DividerSection)`
  margin: 40px 0;
  @media (max-width: 480px) {
    margin: 40px 20px;
  }
`;

const Wrapper = styled.div`
  padding: 0px 20px;
  @media (max-width: 480px) {
    padding: 0;
    margin: 40px 20px;
  }
`;

const PlaceholderMetricInfo: React.FC = () => {
  return (
    <Wrapper>
      <Title />
      <Row style={{ marginTop: 24 }} width={80} />
      <Row width={65} />
      <Row style={{ marginBottom: 21 }} width={70} />
      <FormulaWrapper>
        <Formula width={40} />
        <Formula width={25} />
      </FormulaWrapper>
    </Wrapper>
  );
};

const PlaceholdersMetricInfo: React.FC = () => (
  <div style={{ marginTop: 40 }}>
    <PlaceholderMetricInfo />
    <StyledDividerSection />
    <PlaceholderMetricInfo />
    <StyledDividerSection />
    <PlaceholderMetricInfo />
    <StyledDividerSection />
    <PlaceholderMetricInfo />
    <StyledDividerSection />
    <PlaceholderMetricInfo />
  </div>
);

export { PlaceholdersMetricInfo };
