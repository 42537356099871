import * as React from "react";
import { Colors } from "@freeda/react-components/lib/theme";
import moment from "moment";
import {
  ButtonIcon,
  Typography,
  Tooltip,
  DialogCard,
  DividerSection,
} from "@freeda/react-components";

import { PublicationType } from "../types/Publication";
import { PostType } from "../types/PostType";
import { getColumnSetByName } from "../apollo/utils";
import { buildMetricColumns } from "./buildMetricColumns";
import { FacebookContent } from "../types/FacebookContent";
import {
  ActionsIcon,
  ImgPost,
  ActionsWrapper,
} from "../components/layout/PostListTable/styles";
import { isComplete } from "../ui-specs/specs";
import { InstagramContent } from "../types/InstagramContent";
import { YoutubeContent } from "../types/YoutubeContent";
import { FbGroupPostInfo } from "../components/ui/FbGroupPostInfo";
import { FacebookGroupContent } from "../types/FacebookGroupContent";

import UserIcon from "../components/ui/SvgIcon/UserIcon";
import FreedaIcon from "../components/ui/SvgIcon/FreedaIcon";
import { Metadata } from "../types/Metadata";
import { ProjectId, ProjectType } from "../types/ProjectId";
import { Project } from "../types/Project";
import { Platform } from "../types/Platform";
import { CopyMetadataButton } from "../container/CopyMetadataButton";
import { PasteSaveMetadataButton } from "../container/PasteSaveMetadataButton";
import { capitalize, flatMap, map } from "lodash";
import { TikTokContent } from "../types/TiktokContent";
import { padStart } from 'lodash';
import { UserGroupType } from "../types/ProjectId";


const placeholderPost = require("../assets/placeholderPost.jpg");
const placeholderStory = require("../assets/placeholderStory.jpg");


export const defaultFacebookTableHead = {
  desktop: [
    { id: "post", label: "Post", sortable: false, width: "155px" },
    {
      id: "title",
      label: "Title",
      sortable: true,
      resizable: true,
      width: "220px",
    },
    
    {
      id: "original",
      label: "Publication Date",
      sortable: true,
      width: "120px",
    },
    {
      id: "originalVideoLength",
      label: "Original Video Length",
      sortable: false,
      width: "120px",
    },
  ],
  mobile: [
    { id: "post", label: "Post", sortable: false, width: "155px" },
    { id: "title", label: "Title", sortable: true, resizable: true },
    {
      id: "publicationDate",
      label: "Publication Date",
      sortable: true,
      width: "120px",
    },
  ],
};

export const defaultFacebookGroupsTableHead = {
  desktop: [
    { id: "actions", label: "Actions", sortable: false, width: "160px" },
    {
      id: "message",
      label: "Message",
      sortable: false,
      resizable: true,
      width: "280px",
    },
    { id: "type", label: "Type", sortable: false, width: "80px" },
    { id: "post", label: "Post", sortable: false, width: "155px" },
    { id: "user", label: "User", sortable: false, width: "80px" },
    {
      id: "publicationDate",
      label: "Publication Date",
      sortable: true,
      width: "120px",
    },
  ],
  mobile: [
    { id: "actions", label: "Actions", sortable: false, width: "160px" },
    {
      id: "message",
      label: "Message",
      sortable: false,
      resizable: true,
      width: "280px",
    },
    { id: "type", label: "Type", sortable: false, width: "80px" },
    { id: "post", label: "Post", sortable: false, width: "155px" },
    { id: "user", label: "User", sortable: false, width: "80px" },
    {
      id: "publicationDate",
      label: "Publication Date",
      sortable: true,
      width: "120px",
    },
  ],
};

export const defaultInstagramTableHead = {
  desktop: [
    { id: "post", label: "Post", sortable: false, width: "155px" },
    {
      id: "caption",
      label: "Caption",
      sortable: true,
      resizable: true,
      width: "220px",
    },
    {
      id: "publicationDate",
      label: "Publication Date",
      sortable: true,
      width: "120px",
    },
    {
      id: "originalVideoLength",
      label: "Original Video Length",
      sortable: false,
      width: "120px",
    },
  ],
  mobile: [
    { id: "post", label: "Post", sortable: false, width: "155px" },
    { id: "caption", label: "Caption", sortable: true, resizable: true },
    {
      id: "publicationDate",
      label: "Publication Date",
      sortable: true,
      width: "120px",
    },
  ],
};

export const defaultTiktokTableHead = {
  desktop: [
    { id: "post", label: "Post", sortable: false, width: "155px" },
    {
      id: "description",
      label: "Description",
      sortable: true,
      resizable: true,
      width: "220px",
    },
    {
      id: "publicationDate",
      label: "Publication Date",
      sortable: true,
      width: "120px",
    },
    {
      id: "post_video_length",
      label: "Video Length",
      sortable: false,
      width: "120px",
    },
  ],
  mobile: [
    { id: "post", label: "Post", sortable: false, width: "155px" },
    {
      id: "description",
      label: "Description",
      sortable: true,
      resizable: true,
    },
    {
      id: "publicationDate",
      label: "Publication Date",
      sortable: true,
      width: "120px",
    },
  ],
};


const formatVideoLength = (videoLength: number|null) => {
 
  if (videoLength) {
    
    return `${padStart(String(Math.floor(videoLength / 60)), 2, '0')}:${padStart(
      String(videoLength % 60),
      2,
      '0'
    )}`;
  }

  return null;
};

export const checkLabelsTag = (userDefinedMetadata: Array<Metadata>): boolean => {
  let topic = false
  let serie = false
  let temperature = false
  let objective = false
  //let genre = false
  let type = false
  
  userDefinedMetadata.map(m => {
      switch (m.category.id) {
        case 'TOPIC':
          topic=true
          break;
        case 'SERIES':
          serie=true
          break
        case 'TEMPERATURE':
          temperature=true
          break;
        case 'OBJECTIVE':
          objective = true
          break;
        /*case 'GENRE':
          genre = true
          break;*/
        case 'VIDEO':
        case 'IMAGE':
        case 'INSTANT_ARTICLES':
        case 'INSTAGRAM_STORY':
        case 'CAROUSEL':
          type = true
          break;
        default:
          console.log();
      }
  });
  return topic && serie && temperature && objective /*&& genre*/ && type
};

export const defaultYtTableHead = {
  desktop: [
    { id: "Video", label: "Video", sortable: false, width: "155px" },
    {
      id: "title",
      label: "Title",
      sortable: true,
      resizable: true,
      width: "220px",
    },
    {
      id: "publicationDate",
      label: "Publication Date",
      sortable: true,
      width: "120px",
    },
    {
      id: "originalVideoLength",
      label: "Original Video Length",
      sortable: true,
      width: "120px",
    },
  ],
  mobile: [
    { id: "video", label: "Video", sortable: false, width: "155px" },
    { id: "title", label: "Title", sortable: true, resizable: true },
    {
      id: "publicationDate",
      label: "Publication Date",
      sortable: true,
      width: "120px",
    },
  ],
};

export const buildActionsIcon = (

  toggleEditPostMetadata: (postId: string) => void,
  postContentId: string,
  postPublisherId: string,
  postType: PostType,
  platform: Platform,
  project: Project,
  userDefinedMetadata: Array<Metadata>,
  postLinkUrl: string,
  peggyUrl: string | null,
  updateSocialContentMetadataMutation?: [any, any]
  

) => {

  const isDisabled = [ProjectType.ENGAGE, ProjectType.TRANSACT, ProjectType.HYBRID_PARTIAL].includes(project.projectType!) ? true : false;

  const viewUrlPeggy = [ProjectType.ENGAGE, ProjectType.TRANSACT, ProjectType.HYBRID_PARTIAL].includes(project.projectType!) ? true : false;

  const handleCopyClick = (link:string, contentId:string) => {
    copyToClipBoard(link)
      .then(() => {
        const el = document.getElementById(contentId);

        el!.style.display = 'block';

        setTimeout(() => {
          el!.style.display = 'none';
        }, 900);

      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function copyToClipBoard(link:string) {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(link);
      } else {
        return document.execCommand('copy', true, link);
      }
    }

  return (
    <ActionsIcon>
      <ButtonIcon
        onClick={() => toggleEditPostMetadata(postContentId)}
        iconName="label"
        iconSize="small"
        iconColor={
          userDefinedMetadata.length > 0 ? (checkLabelsTag(userDefinedMetadata) ? Colors.GRIGINO : Colors.ROSSINO) : Colors.ROSSINO
        }
        style={{ marginBottom: 1 }}
      />

      
      <Tooltip
        referenceChildren={
          <ButtonIcon
          onClick={ () => handleCopyClick(postLinkUrl, postContentId ) }
          iconName="media"
          iconSize="small"
          iconColor={Colors.GRIGINO}
          style={{ marginBottom: 0 }}
        >
        </ButtonIcon> 
        }
        tooltipChildren={
          <Typography
            variantName="text"
            textColor={Colors.DARKpurple}
            style={{ fontWeight: 10, cursor: "pointer" }}
            textAlign="center"
          >
            Copy Url
          </Typography>
        }
        referenceStyle={{ display: "flex", alignItems: "center" }}
        position="bottom"
      />
      <span id = {postContentId}  style={{display:'none', fontSize:10, margin:0}}>Copied!</span>

      {viewUrlPeggy && 
        <Tooltip
          referenceChildren={
            <ButtonIcon
            onClick={ () => peggyUrl ?  window.open( localStorage.getItem("peggyUrlEdit")!.concat(peggyUrl!), '_blank') : window.open( localStorage.getItem("peggyUrl")! , '_blank' ) }
            iconName="reply"
            iconSize="small"
            iconColor={peggyUrl ? Colors.GRIGINO : Colors.ROSSINO}
            style={{ marginBottom: 0 }}
          >
          </ButtonIcon>
            
          }
          tooltipChildren={
            <Typography
              variantName="text"
              textColor={Colors.DARKpurple}
              style={{ fontWeight: 10, cursor: "pointer" }}
              textAlign="center"
            >
              Go to Peggy
            </Typography>
          }
          referenceStyle={{ display: "flex", alignItems: "center" }}
          position="bottom"
        />
      }

      <DialogCard
        referenceStyle={{ pointerEvents: isDisabled ? 'none': 'all'}}
        referenceChildren={
          <ButtonIcon
            iconName="more-vert"
            iconSize="small"
            style={{ marginTop: 1 }}
            iconColor={Colors.GRIGINO}
          />
        }
        tooltipChildren={(closeCard) => (
          <div>
            <CopyMetadataButton
              userDefinedMetadata={userDefinedMetadata}
              postType={postType}
              platform={platform}
              project={project}
              closeCard={closeCard}
              style={{ marginBottom: 8 }}
            />
            {updateSocialContentMetadataMutation && (
              <PasteSaveMetadataButton
                userDefinedMetadata={userDefinedMetadata}
                postType={postType}
                platform={platform}
                project={project}
                closeCard={closeCard}
                style={{ marginTop: 8 }}
                contentId={postContentId}
                publisherId={postPublisherId}
                updateSocialContentMetadataMutation={
                  updateSocialContentMetadataMutation
                }
              />
            )}
          </div>
        )}
        position="right-start"
      />
    </ActionsIcon>
  );
};

export const buildFacebookTableColumns = (
  maxWidth: number,
  publicationType: PublicationType,
  customColumn: string | null,
  toggleEditPostMetadata: (postId: string) => void,
  project: Project,
  updateSocialContentMetadataMutation?: [any, any],
  selectedFormat?: PostType | null
) => {
  const columnSet = getColumnSetByName(
    publicationType,
    customColumn
      ? customColumn
      : `${selectedFormat || "ALL"}_${publicationType}`,
    Boolean(customColumn)
  );
  const columnSetMetrics = columnSet ? columnSet.metrics : [];
  const metricColumns =
    maxWidth > 768 ? buildMetricColumns(columnSetMetrics) : [];

  return [
    {
      id: "post",
      render: (post: FacebookContent) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          {buildActionsIcon(
            toggleEditPostMetadata,
            post.id.contentId,
            post.id.publisherId,
            post.type,
            Platform.FB,
            project,
            post.userDefinedMetadata,
            post.link,
            post.peggyUrl,
            updateSocialContentMetadataMutation
           
          )}
          <a
            href={post.permalink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ position: "relative", left: 9 }}
          >
            <Tooltip
              position="right"
              tooltipBackground="rgb(242, 242, 242)"
              tooltipStyle={{
                boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 6px 0px",
                padding: 8,
              }}
              tooltipChildren={
                <ImgPost
                  style={{ maxWidth: 250 }}
                  src={post.mediaUrl!}
                  onError={(e) => (e.currentTarget.src =  post.originalThumbnailUrl ? post.originalThumbnailUrl :placeholderPost)}
                />
              }
              referenceChildren={
                <ImgPost
                  src={post.mediaUrl!}
                  onError={(e) => (e.currentTarget.src = post.originalThumbnailUrl ? post.originalThumbnailUrl :placeholderPost)}
                />
              }
            />
          </a>
        </div>
      ),
    },
    {
      id: "title",
      render: (post: FacebookContent) => (
        <>
          {post.title && (
            <Typography variantName="table-body" style={{ marginBottom: 7 }}>
              {post.title}
            </Typography>
          )}
          {post.caption && (
            <Typography
              variantName="caption"
              style={{ fontSize: 11, paddingRight: 15 }}
            >
              {maxWidth > 420
                ? `${post.caption.substring(0, 200)}${
                    post.caption.length >= 200 ? " ..." : ""
                  }`
                : `${post.caption.substring(0, 60)}${
                    post.caption.length >= 60 ? " ..." : ""
                  }`}
            </Typography>
          )}
        </>
      ),
    },
    {
      id: "publicationDate",
      render: (post: FacebookContent) => (
        <Typography variantName="table-body" style={{ fontSize: 12 }}>
          <>
            {String(moment(post.publicationDate).format("ll"))}
            <br />
            {String(moment(post.publicationDate).format("HH:mm:ss"))}
          </>
        </Typography>
      ),
    },
    {
      id: "originalVideoLength",
      render: (post: FacebookContent) => (
        <Typography variantName="table-body" style={{ fontSize: 12 }}>
          <>
            {post.originalVideoLength != null ? formatVideoLength(post.originalVideoLength): "0"}
          </>
        </Typography>
      ),
    },
    ...metricColumns,
  ];
};

export const buildFacebookGroupsTableColumns = (
  maxWidth: number,
  publicationType: PublicationType,
  customColumn: string | null,
  toggleEditPostMetadata: (postId: string) => void,
  project: Project,
  updateSocialContentMetadataMutation?: [any, any]
) => {
  const columnSet = getColumnSetByName(
    publicationType,
    customColumn ? customColumn : `ALL_${publicationType}`,
    Boolean(customColumn)
  );

  const columnSetMetrics = columnSet ? columnSet.metrics : [];
  const metricColumns =
    maxWidth > 768 ? buildMetricColumns(columnSetMetrics) : [];

  return [
    {
      id: "actions",
      render: (post: FacebookGroupContent) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              position: "relative",
              left: -15,
            }}
          >
            <ActionsWrapper>
              <ButtonIcon
                iconName="label"
                onClick={() => toggleEditPostMetadata(post.id.contentId)}
                iconColor={
                  isComplete(post.type, Platform.FBG, post.userDefinedMetadata)
                    ? Colors.GRIGINO
                    : Colors.ROSSINO
                }
              />
              <FbGroupPostInfo post={post} />
            </ActionsWrapper>
            <DividerSection
              dividerColor={Colors.GRIGINOtransparentLight}
              style={{ width: "100%" }}
            />
            <ActionsWrapper>
              <a
                href={post.permalink}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <ButtonIcon iconName="open-link" iconColor={Colors.GRIGINO} />
              </a>
              <DialogCard
                referenceChildren={
                  <ButtonIcon iconName="more-vert" iconColor={Colors.GRIGINO} />
                }
                tooltipChildren={(closeCard) => (
                  <div>
                    <CopyMetadataButton
                      userDefinedMetadata={post.userDefinedMetadata}
                      postType={post.type}
                      platform={Platform.FBG}
                      project={project}
                      closeCard={closeCard}
                      style={{ marginBottom: 8 }}
                    />
                    {updateSocialContentMetadataMutation && (
                      <PasteSaveMetadataButton
                        userDefinedMetadata={post.userDefinedMetadata}
                        postType={post.type}
                        platform={Platform.FBG}
                        project={project}
                        closeCard={closeCard}
                        style={{ marginTop: 8 }}
                        updateSocialContentMetadataMutation={
                          updateSocialContentMetadataMutation
                        }
                        contentId={post.id.contentId}
                        publisherId={post.id.publisherId}
                      />
                    )}
                  </div>
                )}
                position="right-start"
              />
            </ActionsWrapper>
          </div>
        );
      },
    },

    {
      id: "message",
      render: (post: FacebookGroupContent) => {
        const message = post.message
          ? maxWidth > 420
            ? `${post.message.substring(0, 200)}${
                post.message.length >= 200 ? " ..." : ""
              }`
            : `${post.message.substring(0, 60)}${
                post.message.length >= 60 ? " ..." : ""
              }`
          : null;

        const splitMessage = message ? message.split("\n") : [];

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
              left: -17,
            }}
          >
            <div style={{ position: "relative", left: 9 }}>
              {post.title && (
                <Typography
                  variantName="table-body"
                  style={{ marginBottom: 7 }}
                >
                  {post.title}
                </Typography>
              )}
              {post.message && (
                <div>
                  {splitMessage.map((m, i) => (
                    <Typography
                      variantName="caption"
                      style={{ fontSize: 11, paddingRight: 15 }}
                      key={i}
                    >
                      {m}
                    </Typography>
                  ))}
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      id: "type",
      render: (post: FacebookGroupContent) => {
        return (
          <Typography variantName="caption" style={{ fontSize: 11 }}>
            {capitalize(post.type)}
          </Typography>
        );
      },
    },
    {
      id: "post",
      render: (post: FacebookGroupContent) => (
        <a href={post.permalink} target="_blank" rel="noopener noreferrer">
          {post.mediaUrl && (
            <Tooltip
              position="right"
              tooltipBackground="rgb(242, 242, 242)"
              tooltipStyle={{
                boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 6px 0px",
                padding: 8,
              }}
              tooltipChildren={
                <ImgPost
                  style={{ maxWidth: 250 }}
                  src={post.mediaUrl ? post.mediaUrl : placeholderPost}
                  onError={(e) => (e.currentTarget.src = placeholderPost)}
                />
              }
              referenceChildren={
                <ImgPost
                  src={post.mediaUrl ? post.mediaUrl : placeholderPost}
                  onError={(e) => (e.currentTarget.src = placeholderPost)}
                />
              }
            />
          )}
        </a>
      ),
    },
    {
      id: "user",
      render: (post: FacebookGroupContent) => {
        return (
          <>
            {post.from ? (
              <FreedaIcon color={Colors.PURPLE} width={15} />
            ) : (
              <UserIcon color={Colors.PURPLE} heightWidth={15} />
            )}
          </>
        );
      },
    },
    {
      id: "publicationDate",
      render: (post: FacebookGroupContent) => (
        <Typography variantName="table-body" style={{ fontSize: 12 }}>
          <>
            {String(moment(post.publicationDate).format("ll"))}
            <br />
            {String(moment(post.publicationDate).format("HH:mm:ss"))}
          </>
        </Typography>
      ),
    },
    ...metricColumns,
  ];
};

export const buildInstagramTableColumns = (
  maxWidth: number,
  publicationType: PublicationType,
  customColumn: string | null,
  toggleEditPostMetadata: (postId: string) => void,
  project: Project,
  updateSocialContentMetadataMutation?: [any, any],
  selectedFormat?: PostType | null
) => {
  const columnSet = getColumnSetByName(
    publicationType,
    customColumn
      ? customColumn
      : `${selectedFormat || "ALL"}_${publicationType}`,
    Boolean(customColumn)
  );
  const columnSetMetrics = columnSet ? columnSet.metrics : [];
  const metricColumns =
    maxWidth > 768 ? buildMetricColumns(columnSetMetrics) : [];

  return [
    {
      id: "post",
      render: (post: InstagramContent) => {
        const imagePost = getPostImage(post);

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            
            {buildActionsIcon(
              toggleEditPostMetadata,
              post.id.contentId,
              post.id.publisherId,
              post.type,
              Platform.IG,
              project,
              post.userDefinedMetadata,
              post.permalink,
              post.peggyUrl,
              updateSocialContentMetadataMutation
              
            )}

            <a
              href={post.permalink}
              target="_blank"
              rel="noopener noreferrer"
              style={{ position: "relative", left: 9 }}
            >
              <Tooltip
                position="right"
                tooltipBackground="rgb(242, 242, 242)"
                tooltipStyle={{
                  boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 6px 0px",
                  padding: 8,
                }}
                tooltipChildren={
                  <ImgPost
                    style={{ maxWidth: 250 }}
                    src={imagePost!}
                    onError={(e) => (e.currentTarget.src = post.originalThumbnailUrl ?  post.originalThumbnailUrl : placeholderPost)}
                  />
                }
                referenceChildren={
                  <ImgPost
                    src={imagePost!}
                    onError={(e) => (e.currentTarget.src = post.originalThumbnailUrl ?  post.originalThumbnailUrl : placeholderPost)}
                  />
                }
              />
            </a>
          </div>

        );
      },
    },
    {
      id: "caption",
      render: (post: InstagramContent) => (
        <>
          {post.title && (
            <Typography variantName="table-body" style={{ marginBottom: 7 }}>
              {post.title}
            </Typography>
          )}
          {post.caption && (
            <Typography
              variantName="caption"
              style={{ fontSize: 11, paddingRight: 15 }}
            >
              {maxWidth > 420
                ? `${post.caption.substring(0, 200)}${
                    post.caption.length >= 200 ? " ..." : ""
                  }`
                : `${post.caption.substring(0, 60)}${
                    post.caption.length >= 60 ? " ..." : ""
                  }`}
            </Typography>
          )}
        </>
      ),
    },
    {
      id: "publicationDate",
      render: (post: InstagramContent) => (
        <Typography variantName="table-body" style={{ fontSize: 12 }}>
          <>
            {String(moment(post.publicationDate).format("ll"))}
            <br />
            {String(moment(post.publicationDate).format("HH:mm:ss"))}
          </>
        </Typography>
      ),
    },
    {
      id: "originalVideoLength",
      render: (post: FacebookContent) => (
        <Typography variantName="table-body" style={{ fontSize: 12 }}>
          <>
            {post.originalVideoLength != null ? formatVideoLength(post.originalVideoLength): "0"}
          </>
        </Typography>
      ),
    },
    ...metricColumns,
  ];
};

export const buildTiktokTableColumns = (
  maxWidth: number,
  publicationType: PublicationType,
  customColumn: string | null,
  toggleEditPostMetadata: (postId: string) => void,
  project: Project,
  updateSocialContentMetadataMutation?: [any, any],
  selectedFormat?: PostType | null
) => {
  selectedFormat=null
  const columnSet = getColumnSetByName(
    publicationType,
    customColumn
      ? customColumn
      : `${selectedFormat || "ALL"}_${publicationType}`,
    Boolean(customColumn)
  );
  const columnSetMetrics = columnSet ? columnSet.metrics : [];
  const metricColumns =
    maxWidth > 768 ? buildMetricColumns(columnSetMetrics) : [];

  return [
    {
      id: "post",
      render: (post: TikTokContent) => {
        const imagePost = getTiktokPostImage(post);

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {buildActionsIcon(
              toggleEditPostMetadata,
              post.id.contentId,
              post.id.publisherId,
              "TIKTOK",
              Platform.TK,
              project,
              post.userDefinedMetadata,
              post.url,
              post.peggyUrl,
              updateSocialContentMetadataMutation,
              
            )}
            <a
              href={post.url}
              target="_blank"
              rel="noopener noreferrer"
              style={{ position: "relative", left: 9 }}
            >
              <Tooltip
                position="right"
                tooltipBackground="rgb(242, 242, 242)"
                tooltipStyle={{
                  boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 6px 0px",
                  padding: 8,
                }}
                tooltipChildren={
                  <ImgPost
                    style={{ maxWidth: 250 }}
                    src={imagePost}
                    onError={(e) => (e.currentTarget.src = placeholderPost)}
                  />
                }
                referenceChildren={
                  <ImgPost
                    src={imagePost}
                    onError={(e) => (e.currentTarget.src = placeholderPost)}
                  />
                }
              />
            </a>
          </div>
        );
      },
    },
    {
      id: "description",
      render: (post: TikTokContent) => (
        <>
          {post.description && (
            <Typography
              variantName="caption"
              style={{ fontSize: 11, paddingRight: 15 }}
            >
              {maxWidth > 420
                ? `${post.description.substring(0, 200)}${
                    post.description.length >= 200 ? " ..." : ""
                  }`
                : `${post.description.substring(0, 60)}${
                    post.description.length >= 60 ? " ..." : ""
                  }`}
            </Typography>
          )}
        </>
      ),
    },
    {
      id: "publicationDate",
      render: (post: TikTokContent) => (
        <Typography variantName="table-body" style={{ fontSize: 12 }}>
          <>
            {String(moment(post.publicationDate).format("ll"))}
            <br />
            {String(moment(post.publicationDate).format("HH:mm:ss"))}
          </>
        </Typography>
      )
    },
   /* {
      id: "originalVideoLength",
      render: (post: FacebookContent) => (
        <Typography variantName="table-body" style={{ fontSize: 12 }}>
          <>
            {post.originalVideoLength != null ? formatVideoLength(post.originalVideoLength): "0"}
          </>
        </Typography>
      )
    },*/
    {
      id: "post_video_length",
      render: (post: TikTokContent) => (
        <Typography variantName="table-body" style={{ fontSize: 12 }}>
          <>
            {post.post_video_length !== null && post.post_video_length !== 0 ? formatVideoLength(post.post_video_length): "00:00"}
          </>
        </Typography>
      )
    },
    ...metricColumns,
  ];
};

export const buildYoutubeTableColumns = (
  maxWidth: number,
  publicationType: PublicationType,
  customColumn: string | null,
  toggleEditPostMetadata: (postId: string) => void,
  project: Project,
  updateSocialContentMetadataMutation?: [any, any],
  selectedFormat?: PostType | null
) => {
  const columnSet = getColumnSetByName(
    publicationType,
    customColumn ? customColumn : `ALL_${publicationType}`,
    Boolean(customColumn)
  );
  const columnSetMetrics = columnSet ? columnSet.metrics : [];
  const metricColumns =
    maxWidth > 768 ? buildMetricColumns(columnSetMetrics) : [];

  return [
    {
      id: "video",
      render: (video: YoutubeContent) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          
          {buildActionsIcon(
            toggleEditPostMetadata,
            video.id.contentId,
            video.id.publisherId,
            "YOUTUBE",
            Platform.YT,
            project,
            video.userDefinedMetadata,
            video.permalink,
            video.peggyUrl,
            updateSocialContentMetadataMutation,
           
          )}

          <a
            href={ video.permalink}
            target="_blank"
            rel="noopener noreferrer"
            style={{ position: "relative", left: 9 }}
          >
            <Tooltip
              position="right"
              tooltipStyle={{
                boxShadow: "rgba(0, 0, 0, 0.3) 0px 0px 6px 0px",
                padding: 8,
              }}
              tooltipBackground="rgb(242, 242, 242)"
              tooltipChildren={
                <ImgPost
                  style={{ maxWidth: "100%", height: 180 }}
                  src={
                    video.thumbnailsMaxRes
                      ? video.thumbnailsMaxRes
                      : video.thumbnailsDefault || placeholderPost
                  }
                  onError={(e) => (e.currentTarget.src = placeholderPost)}
                />
              }
              referenceChildren={
                <ImgPost
                  src={
                    video.thumbnailsDefault
                      ? video.thumbnailsDefault
                      : placeholderPost
                  }
                  onError={(e) => (e.currentTarget.src = placeholderPost)}
                />
              }
            />
          </a>
        </div>
      ),
    },
    {
      id: "title",
      render: (video: YoutubeContent) => (
        <>
          {video.title && (
            <Typography variantName="table-body" style={{ marginBottom: 7 }}>
              {video.title}
            </Typography>
          )}
        </>
      ),
    },
    {
      id: "publicationDate",
      render: (video: YoutubeContent) => (
        <Typography variantName="table-body" style={{ fontSize: 12 }}>
          <>
            {String(moment(video.publicationDate).format("ll"))}
            <br />
            {String(moment(video.publicationDate).format("HH:mm:ss"))}
          </>
        </Typography>
      ),
    },
    {
      id: "originalVideoLength",
      render: (post: FacebookContent) => (
        <Typography variantName="table-body" style={{ fontSize: 12 }}>
          <>
            {post.originalVideoLength != null ? formatVideoLength(post.originalVideoLength): "0"}
          </>
        </Typography>
      )
    },
    ...metricColumns,
  ];
};

const getTiktokPostImage = (post: TikTokContent) => {

  if (post.originalThumbnailUrl) return post.originalThumbnailUrl;
  else return placeholderPost;
};

const getPostImage = (post: InstagramContent) => {

  
    if (post.type === "IGTV" || post.type === "IGREEL") {
        if (post.thumbnailUrl) {
          return post.thumbnailUrl;
        } else if (post.mediaUrl) {
          return post.mediaUrl;
        } else {
          return post.originalThumbnailUrl;
        }
      } else if (post.type === "VIDEO" || post.type === "REELS") {
        if (post.thumbnailUrl) return post.thumbnailUrl;
        else return post.originalThumbnailUrl;
      } else {
        if (post.mediaUrl) return post.mediaUrl;
        else return post.originalThumbnailUrl;
      }
  
      /**
       *   if (post.type === "IGTV" || post.type === "IGREEL") {
        if (post.thumbnailUrl) {
          return post.thumbnailUrl;
        } else if (post.mediaUrl) {
          return post.mediaUrl;
        } else {
          return placeholderStory;
        }
      } else if (post.type === "VIDEO" || post.type === "REELS") {
        if (post.thumbnailUrl) return post.thumbnailUrl;
        else return placeholderPost;
      } else {
        if (post.mediaUrl) return post.mediaUrl;
        else return placeholderPost;

       */
 
};

/**
  dati_platform
  dati_executive
  tech
  il resto FreedaMedia
*/

export const userGroup = () => {
  
  let groups: any = localStorage.getItem('groups')
  groups = groups?.split('|')
  
  if(groups){
    if( groups.includes("dati_platform") ){
      return UserGroupType.PLATFORM
    }
    else if( groups.includes("dati_executive") || groups.includes("tech") || groups.includes("data science")){
      return UserGroupType.EXECUTIVE
    }
    else
      return UserGroupType.FREEDA
     // return UserGroupType.EXECUTIVE

  }
  return UserGroupType.FREEDA
}
