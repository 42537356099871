import styled, { css } from "styled-components";
import { ListItem } from "@freeda/react-components";
import { zIndex } from "../../../utils/zIndex";

const FirstPart = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
`;

const SidebarListItem = styled(ListItem)<{
  open?: boolean;
  backgroundHover?: string;
}>`
  && {
    transition: all 0.3s;
    padding-left: 18px;
    padding-right: 18px;
    background: ${(props) => props.theme.primary};
    &:hover {
      background: ${(props) => (props.backgroundHover ? props.backgroundHover : "rgb(97, 32, 195)")};
    }
    ${(props) =>
      !props.open &&
      css`
        padding-left: 0;
        padding-right: 0;
        justify-content: center;
      `}
  }
`;

const WrapperLabel = styled.div`
  display: flex;
  align-items: center;
  transition: opacity 0.4s;
  &.wrapper-entering {
    opacity: 0;
  }
  &.wrapper-entered {
    opacity: 1;
  }
  &.wrapper-exiting {
    opacity: 0;
  }
  &.wrapper-exited {
    opacity: 0;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const SubMenuIconTransition = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding: 5px 18px;
  transition: top 0.3s ease-out, margin-bottom 0.3s;
  top: -60px;
  margin-bottom: 0px;

  &.entering {
    top: -60px;
    margin-bottom: 0px;
  }
  &.entered {
    top: 0px;
    margin-bottom: 10px;
  }
  &.exiting {
    top: -60px;
    margin-bottom: 0px;
  }
  &.exited {
    top: -60px;
    margin-bottom: 0px;
  }
`;

const ProjectPlatformWrapper = styled.div`
  position: relative;
  transition: top 0.3s ease-out;
  height: 100%;
`;

const GooviWrapper = styled.div`
  position: absolute;
  width: 100%;
  transition: top 0.8s;
`;

const SocialIconWrapper = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  top: -20px;
  transform-origin: top;
  transform-style: flat;
  transform: scaleY(0);

  transition: transform 0.5s;

  &.socialIcon-entering {
    transform: scaleY(0);
  }
  &.socialIcon-entered {
    transform: scaleY(1);
  }
  &.socialIcon-exiting {
    transform: scaleY(0);
  }
  &.socialIcon-exited {
    transform: scaleY(0);
  }
`;

const AppBar = styled.header`
  position: fixed;
  width: 100%;
  top: 0;
  left: auto;
  right: 0;
  background: #ffffff;
  z-index: ${zIndex.AppBar};
  min-height: 56px;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
  box-sizing: border-box;
  display: flex;
`;

const FirstPartMobile = styled.div`
  /* height: 230px; */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  & > button {
    margin: 0px;
  }
`;

const FreedaLogo = styled.img`
  @media (max-width: 768px) {
    position: fixed;
    width: 100px;
    top: 5px;
    right: 5px;
  }
`;

const MobileLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 15px;
`;

export {
  FirstPart,
  SidebarListItem,
  LinkWrapper,
  SubMenuIconTransition,
  AppBar,
  FirstPartMobile,
  FreedaLogo,
  ProjectPlatformWrapper,
  SocialIconWrapper,
  GooviWrapper,
  MobileLinkWrapper,
  WrapperLabel,
};
