import * as d3 from "d3";
import { ConversionUnit } from "./../types/ConversionUnit";

const IT = d3.formatLocale({
  decimal: ",",
  thousands: ".",
  grouping: [3],
  currency: ["€", ""]
});

const d3ConversionMap = (valueOnAxis?: boolean) => ({
  [ConversionUnit.PERCENTAGE]: valueOnAxis ? IT.format("0.0%") : IT.format("0.2%"),
  [ConversionUnit.RAW]: valueOnAxis ? IT.format(",.0f") : IT.format(",.0f")
});

const d3formatNumber = (conversionUnit: ConversionUnit | null, valueOnAxis?: boolean) => {
  if (!conversionUnit) {
    return IT.format("");
  }
  return d3ConversionMap(valueOnAxis)[conversionUnit];
};

export { d3formatNumber };
