import * as React from "react";
import { TransitionStatus } from "react-transition-group/Transition";
import { useQuery } from "@apollo/react-hooks";

import { buildQuery } from "../components/queries/GroupedMetadataByCatsQuery";
import { EditPostMetadata } from "../components/layout/EditPostMetadata";
import { Dialogable } from "../types/Dialogable";
import { PlaceholderLateralPanel } from "../components/ui/Placeholders/PlaceholderLateralPanel";
import { Platform } from "../types/Platform";
import { FeedbackContext } from "../providers/FeedbackProvider";
import { Metadata } from "../types/Metadata";
import { PostType } from "../types/PostType";
import { PublicationType } from "../types/Publication";
import { Project } from "../types/Project";
import { LIST_METADATA_CATEGORIES } from "../apollo/queries/ListMetadataCategories";
import ListMetadataCategoriesQueryT from "../types/graphql/ListMetadataCategoriesQuery";

export type PostMetadataContentInfo = {
  title?: string | null;
  peggyUrl: string | null;
  userType?: string | null;
  caption?: string | null;
  messageFbGroup?: string | null;
  postPreview: string | null;
  userDefinedMetadata: Array<Metadata>;
  permalink: string | null;
  originalThumbnailUrl:string |null,
  thumbnailurlbackup:string|null,
  type: PostType;
};

type Props = Dialogable & {
  transitionStatus: TransitionStatus;
  selectedPost: PostMetadataContentInfo & {
    publisherId: string;
  };
  platform: Platform;
  loadingSave: boolean;
  onSave: ({
    connect,
    disconnect,
  }: {
    connect?: Array<string>;
    disconnect?: Array<string>;
  }) => void;
  project: Project;
};

export const EditPostMetadataContainer = ({
  transitionStatus,
  open,
  onClose,
  selectedPost,
  platform,
  loadingSave,
  onSave,
  project,
}: Props) => {
  const {
    postPreview,
    userDefinedMetadata,
    type,
    permalink,
    originalThumbnailUrl,
    thumbnailurlbackup,
    title,
    peggyUrl,
    caption,
    userType,
    messageFbGroup,
  } = selectedPost;



  const titlePost = title
    ? `${title.substring(0, 50)}${title.length >= 50 ? " ..." : ""}`
    : null;
  const captionPost = caption
    ? `${caption.substring(0, 50)}${caption.length >= 50 ? " ..." : ""}`
    : null;

  const userPost = userType ? userType : null;
  const messagePost = messageFbGroup ? messageFbGroup.split("\n") : null;

  const isIgtvPost = selectedPost.type === "IGTV";

  const imgPost =
    selectedPost.type === "INSTAGRAM_STORY" ? permalink : postPreview;

  const permalinkPost = isIgtvPost ? postPreview : imgPost;

  const { openSnackbar } = React.useContext(FeedbackContext);

  const social =
    selectedPost.type === "INSTAGRAM_STORY"
      ? PublicationType.IG_STORY
      : platform;

  const QUERY = buildQuery(type, platform, project.label);
  const groupedMetadataResult = useQuery<{
    [key: string]: Array<Omit<Metadata, "category">>;
  }>(QUERY, {
    fetchPolicy: "cache-and-network",
  });

  const categoriesResult = useQuery<ListMetadataCategoriesQueryT>(
    LIST_METADATA_CATEGORIES
  );

  if (
    !groupedMetadataResult.loading &&
    !categoriesResult.loading &&
    groupedMetadataResult.data &&
    categoriesResult.data
  ) {
    const metadataCategories =
      categoriesResult.data.listMetadataCategories.content;
    const groupedMetadata = groupedMetadataResult.data;
    const metadataT = Object.keys(groupedMetadata);
    const metadata = metadataT
      .filter(
        (categoryId) =>
          categoryId !== "BOOLEAN" &&
          metadataCategories.find((c) => c.id === categoryId) !== undefined
      )
      .map((categoryId) => {
        return {
          category: metadataCategories.find((c) => c.id === categoryId)!,
          metadata: groupedMetadata[categoryId],
        };
      });

    const booleanMetadata = groupedMetadata["BOOLEAN"]!;

    return (
      <EditPostMetadata
        groupedMetadata={metadata}
        booleanMetadata={booleanMetadata}
        transitionStatus={transitionStatus}
        open={open}
        onClose={onClose}
        postType={type}
        platform={platform}
        mediaUrl={postPreview}
        permalink={permalinkPost}
        originalThumbnailUrl={originalThumbnailUrl}
        thumbnailurlbackup={thumbnailurlbackup}
        userDefinedMetadata={userDefinedMetadata}
        titlePost={titlePost}
        userPost={userPost}
        publisherId={selectedPost.publisherId}
        captionPost={captionPost}
        messageFbGroup={messagePost}
        peggyUrl={peggyUrl}
        loadingSave={loadingSave}
        onSave={({
          connect,
          disconnect,
        }: {
          connect?: Array<string>;
          disconnect?: Array<string>;
        }) => {
          onSave({ connect, disconnect });
          onClose();
          openSnackbar("Post edited correctly!", "notification");
        }}
        social={social}
        project={project}
      />
    );
  } else {
    return <PlaceholderLateralPanel transitionStatus={transitionStatus} />;
  }
};
