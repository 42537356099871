import * as React from "react";
import {
  List,
  ButtonIcon,
  DrawerTemporary,
  ListItemText,
  Typography,
  DividerSection,
} from "@freeda/react-components";
import { zIndex } from "../../../utils/zIndex";
import {
  SidebarListItem,
  AppBar,
  FirstPartMobile,
  FreedaLogo,
  ProjectPlatformWrapper,
} from "./styles";
import MetadataIcon from "../../ui/SvgIcon/MetadataIcon";
import { RouterNavLink as NavLink } from "../../ui/RouterLink";
import LogoutButton from "../../ui/LogoutButton";
import { Colors } from "@freeda/react-components/lib/theme";
import DashboardIcon from "../../ui/SvgIcon/DashboardIcon";
import { capitalize, groupBy } from "lodash";
import { CardProjects } from "../../../container/CardProjects";
import {
  iconByPlatform,
  buildPathByPlatform,
  //pagesUnits,
} from "../../../ui-specs/posts-pages-specs";
import { getLabelColor } from ".";
import { ProjectId } from "../../../types/ProjectId";
import { emojiList } from "./emoji";
import MetricIcon from "../../ui/SvgIcon/MetricIcon";
import { ProjectPageContext } from "../../../providers/ProjectPageProvider";
const freedaLogo = require("../../../assets/freeda_logo.png");

interface Props {
  open: boolean;
  selectedProject: ProjectId;
  onOpen: () => void;
  changeOpenSidebar: (isOpen: boolean) => void;
  onChangeProject: (project: ProjectId) => void;
  indexEmojiList: number;
  //pageUnitsForSidebarMobile: Array<PageUnit>;
}

const SidebarMobile: React.FC<Props> = ({
  open,
  selectedProject,
  onOpen,
  changeOpenSidebar,
  onChangeProject,
  indexEmojiList,
  //pageUnitsForSidebarMobile
}) => {

  const { pageUnits } = React.useContext(ProjectPageContext);
  const filteredPages = pageUnits.filter(
    (page) => page.project.idLegacy === selectedProject
  );

  const selectedProjectWithRelativePlatform = groupBy(filteredPages, "path");

  const firstAvailablePath = Object.entries(
    selectedProjectWithRelativePlatform
  )[0][0];

  const isMetadataClicked = window.location.pathname === "/";
  const isMetricsClicked = window.location.pathname === "/metrics";
  const mobileLabelColor = "rgba(255, 255, 255, 0.5)";

  const initialPathname = window.location.pathname;

  React.useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [open]);

  return (
    <>
      <AppBar>
        <div>
          <ButtonIcon
            iconName="menu"
            aria-label="open drawer"
            onClick={() => onOpen()}
            style={{ width: 48, height: 48 }}
          />
          <FreedaLogo src={freedaLogo} />
        </div>
      </AppBar>
      <DrawerTemporary
        isOpen={open}
        openWidth={300}
        closeWidth={0}
        zIndex={zIndex.SidebarMobile}
        changeOpenSidebar={(isOpen) => changeOpenSidebar(isOpen)}
      >
        <div style={{ overflowY: "scroll", height: "100%" }}>
          <FirstPartMobile>
            <SidebarListItem
              backgroundHover={Colors.PURPLE}
              open={open}
              style={{ height: 80, marginTop: 25, userSelect: "none" }}
            >
              <Typography variantName="title" style={{ fontSize: 30 }}>
                {emojiList[indexEmojiList]}
              </Typography>
              <ListItemText
                primary={
                  <>
                    <Typography
                      variantName="text"
                      textColor={mobileLabelColor}
                      style={{ paddingBottom: 2 }}
                    >
                      Welcome,
                    </Typography>
                    <Typography
                      variantName="text"
                      textColor={mobileLabelColor}
                      style={{
                        marginRight: 15,
                        fontSize: 16,
                        fontWeight: 600,
                        paddingTop: 2,
                      }}
                    >
                      {localStorage.getItem("name")!}
                    </Typography>
                  </>
                }
              />
            </SidebarListItem>
            <SidebarListItem open={open} style={{ marginBottom: 10 }}>
              <LogoutButton>
                <ButtonIcon
                  iconName="logout"
                  iconColor={mobileLabelColor}
                  style={{ cursor: "pointer", margin: 0, padding: 0 }}
                />
                <ListItemText
                  primary={
                    <Typography
                      variantName="text"
                      textColor={mobileLabelColor}
                      style={{ cursor: "pointer" }}
                    >
                      Logout
                    </Typography>
                  }
                />
              </LogoutButton>
            </SidebarListItem>
            <DividerSection
              dividerColor={Colors.WHITE}
              style={{ width: "calc(100% - 35px)" }}
            />
          </FirstPartMobile>
          <List style={{ margin: 0, padding: "10px 0px" }}>
            <NavLink onClick={() => changeOpenSidebar(false)} to="/">
              <SidebarListItem open={open} style={{ zIndex: 1 }}>
                <MetadataIcon
                  color={getLabelColor(isMetadataClicked, mobileLabelColor)}
                />
                <ListItemText
                  primary={
                    <Typography
                      variantName="table-header"
                      textColor={getLabelColor(
                        isMetadataClicked,
                        mobileLabelColor
                      )}
                    >
                      Labels
                    </Typography>
                  }
                />
              </SidebarListItem>
            </NavLink>
            <ProjectPlatformWrapper
              style={{ top: window.location.pathname === "/" ? 5 : 0 }}
            >
              <SidebarListItem open={open}>
                <NavLink
                  onClick={() => changeOpenSidebar(false)}
                  to={firstAvailablePath}
                  style={{ width: "auto" }}
                >
                  <DashboardIcon
                    color={getLabelColor(
                      !isMetadataClicked && !isMetricsClicked,
                      mobileLabelColor
                    )}
                  />
                </NavLink>
                <ListItemText
                  primary={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <NavLink
                        onClick={() => changeOpenSidebar(false)}
                        to={firstAvailablePath}
                        style={{ width: "auto" }}
                      >
                        <Typography
                          variantName="table-header"
                          textColor={getLabelColor(
                            !isMetadataClicked && !isMetricsClicked,
                            mobileLabelColor
                          )}
                          style={{
                            marginRight: 15,
                            fontSize: 16,
                            fontWeight: 600,
                            cursor: "pointer",
                          }}
                        >
                          {capitalize(selectedProject)}
                        </Typography>
                      </NavLink>
                      <CardProjects
                        selectedProject={selectedProject}
                        onChangeProject={onChangeProject}
                        iconColor={getLabelColor(
                          !isMetadataClicked,
                          mobileLabelColor
                        )}
                        //pageUnitsForCard={pageUnitsForSidebarMobile}
                      />
                    </div>
                  }
                />
              </SidebarListItem>
              {Object.entries(selectedProjectWithRelativePlatform).map(
                ([path, pages], i) => {
                  const { platform } = pages[0];
                  const activeLabelColor = getLabelColor(
                    initialPathname === path,
                    mobileLabelColor
                  );

                  return (
                    <NavLink
                      onClick={() => changeOpenSidebar(false)}
                      to={path}
                      key={i}
                    >
                      <SidebarListItem open={open}>
                        {iconByPlatform(platform, activeLabelColor)}

                        <ListItemText
                          primary={
                            <Typography
                              variantName="table-header"
                              style={{ cursor: "pointer" }}
                              textColor={activeLabelColor}
                            >
                              {capitalize(
                                buildPathByPlatform(platform).replace("-", " ")
                              )}
                            </Typography>
                          }
                        />
                      </SidebarListItem>
                    </NavLink>
                  );
                }
              )}
            </ProjectPlatformWrapper>
            <NavLink onClick={() => changeOpenSidebar(false)} to="/metrics">
              <SidebarListItem open={open} style={{ zIndex: 1 }}>
                <MetricIcon
                  color={getLabelColor(isMetricsClicked, mobileLabelColor)}
                />
                <ListItemText
                  primary={
                    <Typography
                      variantName="table-header"
                      textColor={getLabelColor(
                        isMetricsClicked,
                        mobileLabelColor
                      )}
                    >
                      Metrics
                    </Typography>
                  }
                />
              </SidebarListItem>
            </NavLink>
          </List>
        </div>
      </DrawerTemporary>
    </>
  );
};

export default SidebarMobile;
