import { Project } from "../../../../types/Project";
import { Platform } from "../../../../types/Platform";
import { Column, Columns, Title } from "@freeda/design-system";
import { MetricCard } from "../../../ui/MetricCard";
import * as React from "react";
import ListStandAloneMetricsQuery, {
  StandAloneMetric,
} from "../../../../types/graphql/ListStandAloneMetricsQuery";
import { useQuery } from "@apollo/react-hooks";
import { LIST_STAND_ALONE_METRICS } from "../../../../apollo/queries/ListStandAloneMetrics";

type Props = {
  platform: Platform;
  metrics: StandAloneMetric[];
};

function StandAloneMetricsSection(props: Props) {
  return (
    <Columns space={16}>
      {props.metrics.map((metric, index) => (
        <Column width="1/4" key={index}>
          <MetricCard
            title={metricsByPlatform[props.platform][metric.name]}
            value={metric.value}
          />
        </Column>
      ))}
    </Columns>
  );
}

type ContainerProps = {
  project: Project;
  platform: Platform;
  selectedPublisherNames: string[];
};

export function StandAloneMetricsSectionContainer(props: ContainerProps) {
  const publisherFilter =
    props.selectedPublisherNames.length > 0
      ? {
          publisherNames: props.selectedPublisherNames,
        }
      : { projectId: props.project.idLegacy };

  const listStandAloneMetrics = useQuery<ListStandAloneMetricsQuery>(
    LIST_STAND_ALONE_METRICS,
    {
      fetchPolicy: "network-only",
      variables: {
        where: {
          metrics: Object.keys(metricsByPlatform[props.platform]),
          platformId: props.platform,
          ...publisherFilter,
        },
      },
    }
  );
  if (!listStandAloneMetrics.loading && listStandAloneMetrics.data) {
    return (
      <StandAloneMetricsSection
        metrics={listStandAloneMetrics.data?.listStandAloneMetrics}
        platform={props.platform}
      />
    );
  } else {
    return <Title size="medium">Loading...</Title>;
  }
}

const metricsByPlatform = {
  FB: {page_follows: "Page Followers"},
  IG: {
    followers_count: "Followers",
  },
  YT: { subscriberCount: "Subscribers Count" },
  TK: { follower_count: "Followers" },
};