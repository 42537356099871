import gql from "graphql-tag";
import { SOCIAL_CONTENT_ID_FRAGMENT } from "../fragments/SocialContentIdFragment";

const UPDATE_TK_CONTENT = gql`
  mutation updateTikTokContent($input: UpdateTikTokContentInput!) {
    updateTikTokContent(input: $input) {
      id {
        ...socialContentId
      }
      userDefinedMetadata {
        id
        label
        active
        category {
          id
          label
          area
        }
      }
    }
  }
  ${SOCIAL_CONTENT_ID_FRAGMENT}
`;

export { UPDATE_TK_CONTENT };
