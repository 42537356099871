
import "@freeda/design-system/lib/index.css";
import { Children, DesignSystemProvider } from "@freeda/design-system";
import * as React from "react";
type Props = {
  children: Children;
};

export function FreedaDesignSystem({ children }: Props) {
  return (
    <DesignSystemProvider
      toastDismissAfterMs={5000}
      defaultMessages={{
        Banner: {
          dismissButtonLabel: "Close",
        },
        Chip: {
          dismissButtonLabel: "Remove",
        },
        Loader: {
          loadingMessage: "Loading",
        },
        Modal: {
          closeButtonLabel: "Close",
        },
        SearchBar: {
          clearButtonLabel: "Clear",
        },
        SelectField: {
          multiOptionsSelected: (count: number) => {
            if (count === 1) {
              return "one selected";
            } else {
              return count + " selected";
            }
          },
          noOptionsMessage: "SelectField.NoOptions",
          selectAllButtonLabel: "SelectAll",
          clearAllButtonLabel: "ClearAll",
        },
        Table: {
          noResultsTitle: "Table.NoResultsTitle",
          noResultsDescription: "Table.NoResultsDescription",
          missingValue: "Table.MissingValue",
        },
        DateField: {
          previousMonthLabel: "DateField.PreviousMonthLabel",
          nextMonthLabel: "DateField.NextMonthLabel",
        },
        TextField: {
          showPasswordLabel: "show",
          hidePasswordLabel: "hide",
        },
      }}
    >
      {children}
    </DesignSystemProvider>
  );
}
