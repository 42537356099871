import * as React from "react";

import PostListFilter from ".";
import { AdvancedFilterPost } from "./AdvancedFilterPost";
import { InstagramPostType } from "../../../types/InstagramPostType";
import { useFilterMobile } from "../../../hooks/useFilterMobile";
import { FilterContextT } from "../../../providers/FilterProvider";

interface Props {
  igFrameFilterContext: React.Context<FilterContextT>;
  publisherSelectComponent?: (matches: boolean) => React.ReactElement<any>;
}

export const IgFramesPostListFilter: React.FC<Props> = ({ igFrameFilterContext, publisherSelectComponent }) => {
  const { onCloseFilterMobile, toggleShowFilter, showFilterMobile } = useFilterMobile();
  const { callbacks, filterState } = React.useContext(igFrameFilterContext);
  const { searchValue, selectedFormat, dateRange } = filterState;
  const { clearFilter, onTextFilterChange, onChangeFormat, onChangeUpdateDateRange } = callbacks;

  return (
    <PostListFilter
      searchValue={searchValue}
      clearFilter={clearFilter}
      onTextFilterChange={onTextFilterChange}
      showFilter={toggleShowFilter}
      showFilterMobile={showFilterMobile}
      renderAdvancedFilter={(status) => (
        <AdvancedFilterPost
          transitionStatus={status}
          dateRange={dateRange}
          showFilterMobile={showFilterMobile}
          onCloseFilterMobile={onCloseFilterMobile}
          onClearFilterMobile={clearFilter}
          format={{
            onChangeFormat,
            availablePostFormat: [InstagramPostType.VIDEO, InstagramPostType.IMAGE],
            selectedFormat,
          }}
          onChangeUpdateDateRange={onChangeUpdateDateRange}
          publisherSelectComponent={publisherSelectComponent}
        />
      )}
    />
  );
};
