import * as React from "react";
import PostListTable from "../components/layout/PostListTable";
import {
  defaultYtTableHead,
  buildYoutubeTableColumns,
} from "../utils/postTableUtils";
import {
  ColumnSetType,
  MetricColumnSetsContext,
} from "../providers/MetricColumnSetsProvider";
import { PublicationType } from "../types/Publication";
import { YoutubeContent } from "../types/YoutubeContent";
import { Average } from "../types/Average";
import {
  isDataAvailable,
  buildOrderByClause,
  buildYoutubeContentFilter,
} from "../apollo/utils";
import { PlaceholderPostList } from "../components/ui/Placeholders/PlaceholderPostList";
import { NotFound } from "../components/ui/NotFound";
import { EXPORT_YT_CONTENT_METADATA } from "../apollo/queries/ExportYtContentMetadata";
import { LIST_YT_CONTENT_METADATA } from "../apollo/queries/ListYtContentMetadata";
import ListYtContentQueryT from "../types/graphql/ListYtContentQuery";
import { FilterContextT } from "../providers/FilterProvider";
import { Project } from "../types/Project";
import { Platform } from "../types/Platform";
import { ALL_COLUMN_SETS } from "../apollo/queries/AllColumnSets";
import AllColumnSetQueryT from "../types/graphql/AllColumnSetsQuery";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { UPDATE_YT_CONTENT } from "../apollo/mutations/UpdateYtContent";
import { StaticIdsContext } from "../providers/StaticIdsProvider";

interface Props {
  project: Project;
  ytPostFilterContext: React.Context<FilterContextT>;
  ytColumnSetSelect: React.FC<{
    onOpenCustomPanel: () => void;
    width?: string | number;
  }>;
  columnSetType: ColumnSetType;
}

const YtPostListTableContainer: React.FC<Props> = ({
  project,
  ytPostFilterContext,
  ytColumnSetSelect,
  columnSetType,
}) => {
  const { callbacks, filterState } = React.useContext(ytPostFilterContext);

  const {
    currentPage,
    pageSize,
    metricOrderBy,
    postOrderBy,
    order,
  } = filterState;

  const {
    onChangePage,
    onChangePageSize,
    onBackPage,
    onNextPage,
    onChangePostOrder,
    onChangeMetricOrder,
  } = callbacks;

  const { selectedSets } = React.useContext(MetricColumnSetsContext);
  const selectedSet = selectedSets[columnSetType];
  const columnSetName = selectedSet || `ALL_${PublicationType.YT_POST}`;
  const custom = Boolean(selectedSet);

  const allColumnSetResult = useQuery<AllColumnSetQueryT>(ALL_COLUMN_SETS, {
    variables: {
      where: {
        publicationId: PublicationType.YT_POST,
        name: columnSetName,
        custom,
      },
    },
  });

  const areColumnSetsLoaded = isDataAvailable(allColumnSetResult);

  const columnSetId =
    areColumnSetsLoaded && allColumnSetResult.data!.allColumnSets.length > 0
      ? allColumnSetResult.data!.allColumnSets[0].id
      : undefined;

  const staticIdsContext = React.useContext(StaticIdsContext);

  const wheresFilter = buildYoutubeContentFilter(
    filterState,
    project.idLegacy,
    columnSetId,
    staticIdsContext.brandedId
  );

  const orderBy = buildOrderByClause(metricOrderBy, postOrderBy, order);


  const listYtContentResult = useQuery<ListYtContentQueryT>(
    LIST_YT_CONTENT_METADATA,
    {
      fetchPolicy: "network-only",
      variables: {
        offset: currentPage * pageSize,
        limit: pageSize,
        orderBy,
        ...wheresFilter,
      },
    }
  );

  let posts: Array<YoutubeContent> = [];
  let averages: Array<Average> = [];
  let count: number = 0;
  let totalPages: number = 0;
  const loading = listYtContentResult.loading;

  const updateYtContentMetadataMutation = useMutation(UPDATE_YT_CONTENT);

  if (
    listYtContentResult.data &&
    Object.keys(listYtContentResult.data).length > 0
  ) {
    posts = listYtContentResult.data!.listYoutubeContents.content;
    averages = listYtContentResult.data!.listYoutubeContents.subAggregate
      .averages;
    count = listYtContentResult.data!.listYoutubeContents.aggregate.count;
    totalPages = Math.ceil(count / pageSize);
  }

  if (!areColumnSetsLoaded || (loading && posts.length === 0)) {
    return <PlaceholderPostList />;
  }

  if (posts.length === 0 && !loading) {
    return <NotFound>No post found! :(</NotFound>;
  }

  return (
    <PostListTable
      defaultHead={defaultYtTableHead}
      buildColumns={buildYoutubeTableColumns}
      posts={posts}
      averages={averages}
      loading={loading}
      currentPage={currentPage}
      pageSize={pageSize}
      totalPages={totalPages}
      onChangePageSize={onChangePageSize}
      onChangePage={onChangePage}
      onBackPage={onBackPage}
      onNextPage={() => onNextPage(totalPages)}
      onChangePostOrder={onChangePostOrder}
      onChangeMetricOrder={onChangeMetricOrder}
      count={count}
      publicationType={PublicationType.YT_POST}
      platform={Platform.YT}
      PostFilterContext={ytPostFilterContext}
      updateSocialContentMetadataMutation={updateYtContentMetadataMutation}
      ColumnSetSelect={ytColumnSetSelect}
      exportQuery={EXPORT_YT_CONTENT_METADATA}
      columnSetType={columnSetType}
      project={project}
      listContentMetadataQuery={LIST_YT_CONTENT_METADATA}
      wheresFilter={wheresFilter}
      orderBy={metricOrderBy || postOrderBy}
      order={order}
    />
  );
};

export { YtPostListTableContainer };
