import { HttpLink } from "apollo-link-http";
import { InMemoryCache, defaultDataIdFromObject } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL,
  fetch: (uri: string, options: any) => {
    if (options) {
      const token = localStorage.getItem("access_token");
      options.headers = {
        ...options.headers,
        Authorization: `Bearer ${token}`
      };
    }
    return fetch(uri, options);
  }
});

const cache = new InMemoryCache({
  dataIdFromObject: (object: any) => {
    switch (object.__typename) {
      case "FacebookContent":
      case "FacebookGroupContent":
      case "InstagramContent":
      case "InstagramStory":
      case "YoutubeContent":
      case "TikTokContent":
        return `${object.__typename}:${object.id.platformId}-${object.id.publisherId}-${object.id.contentId}`;
      case "AggregatedMeasuredMetric":
        return `${object.__typename}:${object.id.name}-${object.id.key}-${object.id.granularity}`;
      case "PositionalMetric":
        return `${object.__typename}:${object.id}-${object.position}`;
      case "MeasuredMetric":
        return `${object.__typename}:${object.id.publisherId}-${object.id.contentId}-${object.id.platformId}-${
          object.id.name
        }`;
      case "CustomContentMetric":
        return `${object.__typename}:${object.id.publisherId}-${object.id.platformId}-${object.id.contentId}-${
          object.id.name
        }`;
      default:
        return defaultDataIdFromObject(object);
    }
  }
});

const client = new ApolloClient({
  link: httpLink,
  cache
});

export default client;
