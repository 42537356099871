import gql from "graphql-tag";

const ALL_CUSTOM_FRAME_METRICS = gql`
  {
    allMetrics(where: { publicationIdsIn: ["IG_FRAME"], custom: true }) {
      id
      name
      platformId
      label
      publications {
        id
      }
    }
  }
`;

export { ALL_CUSTOM_FRAME_METRICS };
