import * as React from "react";
import { Dialogable } from "../types/Dialogable";
import EditStory from "../components/layout/EditStory";
import { TransitionStatus } from "react-transition-group/Transition";
import { InstagramStoryContent } from "../types/InstagramStoryContent";
import { FeedbackContext } from "../providers/FeedbackProvider";
import { isDataAvailable } from "../apollo/utils";
import { PlaceholderLateralPanel } from "../components/ui/Placeholders/PlaceholderLateralPanel";
import { Granularity } from "../types/Granularity";
import { ALL_CUSTOM_CONTENT_METRICS } from "../apollo/queries/AllCustomContentMetrics";
import { DataProxy } from "apollo-cache";
import { CustomContentMetric } from "../types/CustomContentMetric";
import { CustomContentMetricId } from "../types/CustomContentMetricId";
import { INSTAGRAM_STORY_OOF } from "../apollo/queries/InstagramStoryOOF";
import { FetchResult } from "apollo-link";
import PatchCustomContentMetricsPayload from "../types/graphql/PatchCustomContentMetricsPayload";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { ALL_CUSTOM_FRAME_METRICS } from "../apollo/queries/AllCustomFrameMetrics";
import { Metric } from "../types/Metric";
import PatchCustomContentMetricsPayloadT from "../types/graphql/PatchCustomContentMetricsPayload";
import { PATCH_CUSTOM_CONTENT_METRICS } from "../apollo/mutations/PatchCustomContentMetrics";
import { UPDATE_INSTAGRAM_STORY } from "../apollo/mutations/UpdateInstagramStory";
import { Project } from "../types/Project";

type Props = Dialogable & {
  transitionStatus: TransitionStatus;
  selectedStory: InstagramStoryContent;
  publisherName: string;
  isDisabled:Boolean;
};

const customContentMetricEq = (id1: CustomContentMetricId, id2: CustomContentMetricId) =>
  id1.contentId === id2.contentId &&
  id1.publisherId === id2.publisherId &&
  id1.platformId === id2.platformId &&
  id1.name === id2.name;

export const EditStoryContainer: React.FC<Props> = ({
  isDisabled,
  open,
  onClose,
  transitionStatus,
  selectedStory,
  publisherName
}) => {
  const { openSnackbar } = React.useContext(FeedbackContext);
  const customContentMetricsResult = useQuery<{ allCustomContentMetrics: Array<CustomContentMetric> }>(
    ALL_CUSTOM_CONTENT_METRICS,
    {
      variables: {
        where: {
          publisherId: selectedStory.id.publisherId,
          platformId: selectedStory.id.platformId,
          contentIdIn: selectedStory.frames.map(f => f.id.contentId)
        }
      }
    }
  );

  const customFrameMetricsResult = useQuery<{ allMetrics: Array<Metric> }>(ALL_CUSTOM_FRAME_METRICS);

  const [patchCustomContentMetrics, patchResult] = useMutation<{
    patchCustomContentMetrics: PatchCustomContentMetricsPayloadT;
  }>(PATCH_CUSTOM_CONTENT_METRICS, {
    refetchQueries: [
      {
        query: INSTAGRAM_STORY_OOF,
        variables: {
          id: {
            publisherId: selectedStory.id.publisherId,
            contentId: selectedStory.id.contentId
          }
        }
      }
    ],
    update: (
      cache: DataProxy,
      result: FetchResult<{ patchCustomContentMetrics: PatchCustomContentMetricsPayload }>
    ) => {
      const q = {
        query: ALL_CUSTOM_CONTENT_METRICS,
        variables: {
          where: {
            publisherId: selectedStory.id.publisherId,
            platformId: selectedStory.id.platformId,
            contentIdIn: selectedStory.frames.map(f => f.id.contentId)
          }
        }
      };

      const cachedQuery = cache.readQuery<{ allCustomContentMetrics: Array<CustomContentMetric> }>(q);

      if (cachedQuery && result.data) {
        const { deletedIds, upsertedEntities } = result.data.patchCustomContentMetrics;

        const updatedQuery = {
          allCustomContentMetrics: [
            // removing deleted metric values and update old ones
            ...cachedQuery.allCustomContentMetrics
              .filter(c => !deletedIds.find(deletedId => customContentMetricEq(deletedId, c.id)))
              .map(existentValue =>
                upsertedEntities.find(upsertedEntity => customContentMetricEq(existentValue.id, upsertedEntity.id))
              ),
            // new values
            ...result.data.patchCustomContentMetrics.upsertedEntities.filter(
              upsertedValue =>
                !cachedQuery.allCustomContentMetrics
                  .map(c => c.id)
                  .find(cId => customContentMetricEq(cId, upsertedValue.id))
            )
          ]
        };

        cache.writeQuery({
          ...q,
          data: updatedQuery
        });
      }
    }
  });

  const [updateInstagramStory, updateInstagramStoryResult] = useMutation<{
    updateInstagramStory: InstagramStoryContent;
  }>(UPDATE_INSTAGRAM_STORY);

  const dataLoaded: boolean = isDataAvailable(customFrameMetricsResult, customContentMetricsResult);

  if (!dataLoaded) {
    return <PlaceholderLateralPanel transitionStatus={transitionStatus} />;
  }

  const availableCustomMetrics = !dataLoaded ? [] : customFrameMetricsResult.data!.allMetrics;
  const metricValues = !dataLoaded ? [] : customContentMetricsResult.data!.allCustomContentMetrics;

  const customMetricValues = selectedStory.frames.map(f => ({
    frameId: f.id.contentId,
    customMetrics: metricValues
      .filter(mv => mv.id.contentId === f.id.contentId)
      .map(mv => ({ name: mv.id.name, value: String(mv.value) }))
  }));

  return (
    <EditStory
      open={open}
      onClose={onClose}
      isDisabled={isDisabled}
      transitionStatus={transitionStatus}
      publisherName={publisherName}
      selectedStory={selectedStory}
      loadingUpdate={updateInstagramStoryResult.loading || patchResult.loading}
      availableCustomMetrics={availableCustomMetrics}
      customMetricsValues={customMetricValues}
      onSave={async (
        title: string,
        frameMetrics: Array<{ frameId: string; name: string; value: number | null }>,
        removedFrames?: Array<string>
      ) => {
        await patchCustomContentMetrics({
          variables: {
            input: {
              publisherId: selectedStory.id.publisherId,
              platformId: selectedStory.id.platformId,
              customMetrics: frameMetrics.map(fm => ({
                contentId: fm.frameId,
                name: fm.name,
                value: fm.value,
                granularity: Granularity.Lifetime,
                key: "value"
              }))
            }
          }
        });

        await updateInstagramStory({
          variables: {
            input: {
              id: {
                publisherId: selectedStory.id.publisherId,
                contentId: selectedStory.id.contentId
              },
              title,
              removedFrames
            }
          }
        });

        onClose();
        openSnackbar("Story edited correctly!", "notification");
      }}
    />
  );
};
