import gql from "graphql-tag";

export const COLUMN_SET_FRAGMENT = gql`
  fragment columnSet on ColumnSet {
    id
    name
    label
    createdBy
    metrics {
      id
      name
      platformId
      conversionUnit
      label
      category {
        id
      }
      position
    }
  }
`;
