import * as React from "react";
import { TransitionStatus } from "react-transition-group/Transition";
import { WrapperLateralPanel } from "../../../ui/LateralPanel";
import { MobileFilterHeader } from "./MobileFIlterHeader";
import { Colors } from "@freeda/react-components/lib/theme";
import styled from "styled-components";
import { Button } from "@freeda/react-components";

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  box-sizing: border-box;
  position: fixed;
  bottom: 0px;
  margin-bottom: 20px;
  z-index: 2000;
`;

interface Props {
  showFilterMobile: boolean;
  onCloseFilter: () => void;
  onClearFilter: () => void;
  filter: JSX.Element;
  transitionStatus?: TransitionStatus;
}

export const AdvancedFilterPostMobile: React.FC<Props> = ({
  transitionStatus,
  showFilterMobile,
  onCloseFilter,
  filter,
  onClearFilter,
}) => {
  return (
    <WrapperLateralPanel
      open={showFilterMobile}
      className={`wrapper-${transitionStatus}`}
      style={{ padding: 0, justifyContent: "flex-start" }}
    >
      <MobileFilterHeader onClose={onCloseFilter} />
      <div style={{ height: "100%", background: Colors.AZZURRINO, position: "relative", top: 52 }}>
        {filter}
        <ButtonWrapper>
          <Button
            style={{ width: "45%" }}
            action="undo"
            onClick={() => {
              onClearFilter();
              onCloseFilter();
            }}
          >
            Clear
          </Button>
          <Button style={{ width: "45%" }} onClick={onCloseFilter}>
            Apply
          </Button>
        </ButtonWrapper>
      </div>
    </WrapperLateralPanel>
  );
};
