import * as React from "react";
import { Colors } from "@freeda/react-components/lib/theme";

interface Props {
  width?: number;
  height?: number;
  color?: string;
}

const MetadataIcon: React.FC<Props> = ({ width, height, color }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 28"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <defs>
      <path
        d="M22.4394538,6.00460994 C20.4324414,5.89633018 18.9826483,7.71349768 18.8825156,9.5617376 C18.9542284,10.8839254 19.5579615,11.8216869 20.3847907,12.3804219 C19.5834447,13.5842883 18.7814355,14.7888178 17.9795211,15.9928736 C17.5312447,15.7585043 17.0313389,15.6165943 16.5282122,15.5896901 C15.8177151,15.5512286 15.1881199,15.7912819 14.681488,16.1934233 C13.763431,15.1678443 12.8450897,14.1425496 11.9275064,13.1168759 C12.0855209,12.7878683 12.1831906,12.4279778 12.202895,12.0655296 C12.2771657,10.684702 11.0265012,9.68583777 9.75509027,9.61725111 C8.37445221,9.54288574 7.37530376,10.7941186 7.3070013,12.0655296 C7.32765308,12.447777 7.42409123,12.7955417 7.57348509,13.1067394 C6.81922132,13.9813141 6.06467335,14.8556045 5.31040957,15.7301791 C4.97704431,15.4944835 4.56912422,15.3492579 4.10805893,15.3241537 C2.92077083,15.2603037 2.06182714,16.3363753 2.00271378,17.429783 C1.93876904,18.6169764 3.01550378,19.4757306 4.10805893,19.5343703 C5.29534704,19.5984098 6.15448019,18.5222434 6.21293042,17.429783 C6.19171024,17.0309573 6.07509397,16.6754245 5.89368037,16.3695318 C6.46851993,15.703275 7.04345422,15.0366392 7.61876745,14.3700981 C7.77611888,14.1872635 7.93356505,14.0049973 8.09110595,13.8227311 C8.51683584,14.2253462 9.08807555,14.47762 9.75461661,14.5137133 C10.4197367,14.5493329 10.9950499,14.2758388 11.4212535,13.8424356 C12.3088065,14.8346685 13.1971174,15.8265225 14.0845757,16.8184713 C13.6846132,17.3663119 13.4383075,18.0378739 13.40193,18.7161618 C13.4974209,20.4834998 14.7604006,21.7458164 16.5278333,21.8415914 C18.291382,21.9363243 19.5651612,20.3396951 19.6532629,18.7161618 C19.7018609,17.8112726 19.3038878,17.0373044 18.6867973,16.484064 C19.5103109,15.2472305 20.3334455,14.0104918 21.1569591,12.7739426 C23.1802655,13.503481 25.8400826,12.4335671 25.9953499,9.56221127 C26.1041033,7.55453579 24.28722,6.10417428 22.4394538,6.00460994 Z"
        id="path-metadata-icon"
      />
    </defs>
    <g id="metadata" stroke="none" fill="none" fillRule="evenodd">
      <mask id="mask-metadata" fill="white">
        <use xlinkHref="#path-metadata-icon" />
      </mask>
      <use id="analytics-chart-symbol" fillRule="nonzero" xlinkHref="#path-metadata-icon" />
      <g id="color-metadata-icon" mask="url(#mask-metadata)" fill={color}>
        <rect id="Rectangle" x="0" y="0" width={width} height={height} />
      </g>
    </g>
  </svg>
);

MetadataIcon.defaultProps = {
  width: 35,
  height: 35,
  color: Colors.DARKpurple,
};

export default MetadataIcon;
