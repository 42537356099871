import gql from "graphql-tag";

const LIST_LINKED_LABELS = gql`
  query listLinkedLabels(
    $id: String!
  ) {
    listLinkedLabels(
      id: $id
    ) {
      id
      label
      active
      category {
        id
        label
        area
      }
    }
  }
`;

export { LIST_LINKED_LABELS };
