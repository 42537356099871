import styled from "styled-components";

interface PropsSelectWrapper {
  basis?: string | number;
  mobileBasis?: string | number;
  style?: React.CSSProperties;
}

const FieldWrapper = styled.div<PropsSelectWrapper>`
  margin: 0;
  border: 0;
  display: inline-flex;
  padding: 0;
  position: relative;
  min-width: 0;
  width: auto;
  margin-left: 0px;
  align-items: center;
  flex-direction: row;

  flex-basis: ${props => props.basis};
  @media (min-width: 769px) {
    align-self: center;
  }
`;

const MobileLabelWrapper = styled.div`
  width: 125px;
`;

export { FieldWrapper, MobileLabelWrapper };
