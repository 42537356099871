import * as React from "react";
import { useQuery } from "@apollo/react-hooks";

import MetadataTable from "../components/layout/MetadataTable";
import { MetadataOrderBy } from "../types/graphql/MetadataOrderBy";
import { Metadata } from "../types/Metadata";
import { NotFound } from "../components/ui/NotFound";
import ListMetadataQueryT from "../types/graphql/ListMetadataQuery";
import { LIST_METADATA } from "../apollo/queries/ListMetadata";
import { Order } from "../types/Order";

interface Props {
  searchValue: string;
  selectedCategoryId: string | null;
  metadataActive: boolean | null;
  style?: React.CSSProperties;
  currentPage: number;
  pageSize: number;
  orderBy: MetadataOrderBy | null;
  order: Order;
  onChangePageSize: (numberPage: string) => void;
  onChangePage: (page: number) => void;
  onBackPage: () => void;
  onNextPage: (totalPages: number) => void;
  onChangeOrder: (orderBy: MetadataOrderBy | null, order: Order) => void;
  selectedProjects: Array<{ value: string; label: string }>;
}

const MetadataTableContainer: React.FC<Props> = ({
  searchValue,
  selectedCategoryId,
  metadataActive,
  style,
  currentPage,
  pageSize,
  orderBy,
  order,
  onChangePageSize,
  onChangePage,
  onBackPage,
  onNextPage,
  onChangeOrder,
  selectedProjects
}) => {
  const projectParams =
    selectedProjects.length > 0
      ? { project: { idIn: selectedProjects.map(p => p.label), alsoWithoutProjects: true } }
      : undefined;

  const orderByParam = orderBy && order ? `${orderBy}_${order}` : null;

  const listMetadataResult = useQuery<ListMetadataQueryT>(LIST_METADATA, {
    variables: {
      offset: currentPage * pageSize,
      limit: pageSize,
      orderBy: orderByParam,
      where: {
        labelLike: searchValue,
        categoryId: selectedCategoryId,
        active: metadataActive,
        ...projectParams
      }
    }
  });

  let metadata: Array<Metadata> = [];
  let count: number = 0;
  let totalPages: number = 0;
  const loading = listMetadataResult.loading;

  if (listMetadataResult.data && Object.keys(listMetadataResult.data).length > 0) {
    metadata = listMetadataResult.data!.listMetadata.content;
    count = listMetadataResult.data!.listMetadata.aggregate.count;
    totalPages = Math.ceil(count / pageSize);
  }

  if (metadata.length === 0 && !loading) {
    return <NotFound style={style}>No metadata found! :(</NotFound>;
  }

  if (listMetadataResult.error) {
    console.log("errore!");
  }

  return (
    <MetadataTable
      loading={loading}
      metadata={metadata}
      currentPage={currentPage}
      pageSize={pageSize}
      orderBy={orderBy}
      order={order}
      totalPages={totalPages}
      count={count}
      style={style}
      onChangePageSize={pageSize => onChangePageSize(pageSize)}
      onChangePage={page => onChangePage(page)}
      onBackPage={onBackPage}
      onNextPage={() => onNextPage(totalPages)}
      onChangeOrder={(orderBy, order) => onChangeOrder(orderBy, order)}
      searchValue={searchValue}
      selectedCategoryId={selectedCategoryId}
      metadataActive={metadataActive}
      selectedProjects={selectedProjects}
    />
  );
};

export { MetadataTableContainer };
