import * as React from "react";
import { Typography } from "@freeda/react-components";

import { sortBy } from "lodash";
import { PositionalMetric } from "../types/PositionalMetric";
import { formatNumber } from "./formatNumber";
import { SocialContent } from "../types/SocialContent";
import { InstagramStoryContent } from "../types/InstagramStoryContent";

export type PostTableMeasuredMetric = {
  name: string;
  value: number;
  position: number;
};

export const buildMetricColumns = (
  positionalMetrics: Array<PositionalMetric>
): Array<{ id: string; render: (post: SocialContent | InstagramStoryContent) => JSX.Element }> => {
  return sortBy(positionalMetrics, "position").map(pm => ({
    id: pm.name,
    render: (post: SocialContent | InstagramStoryContent) => {
      const mm = post.measuredMetrics.find(mm => pm.name === mm.id.name);
      if (!mm) {
        return (
          <Typography variantName="table-body" key={pm.name} style={{ fontSize: 15, letterSpacing: 0.5 }}>
            0
          </Typography>
        );
      }
      return (
        <Typography variantName="table-body" key={pm.name} style={{ fontSize: 15, letterSpacing: 0.5 }}>
          <>{formatNumber(mm.value, mm.conversionUnit)}</>
        </Typography>
      );
    }
  }));
};
