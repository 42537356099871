import * as React from "react";
import Media from "react-media";
import { Select, Typography, Paginator, Icon } from "@freeda/react-components";
import { TableFilterWrapper, InfoResults } from "../../ui/TableFilterWrapper";
import { FieldWrapper } from "../../ui/FieldWrapper";
import { PostOrderBy } from "../../../types/graphql/PostOrderBy";
import { PaginatorStyled } from "../../ui/PaginatorStyled";
import { TableHead } from "../../../utils/buildPostTableHeader";
import { MetricOrderBy, FilterContextT } from "../../../providers/FilterProvider";
import { IgStoriesTableStyled, SyncLegend } from "./styles";
import { InstagramStoryContent } from "../../../types/InstagramStoryContent";
import { PublicationType } from "../../../types/Publication";
import { PostExportCSVButton } from "../../../container/PostExportCSVButton";
import { getColumnSetByName } from "../../../apollo/utils";
import { PostType } from "../../../types/PostType";
import { Colors } from "@freeda/react-components/lib/theme";
import { MetricColumnSetsContext, ColumnSetType } from "../../../providers/MetricColumnSetsProvider";
import { Order } from "../../../types/Order";
import {Project} from "../../../types/Project";

interface Props {
  stories: Array<InstagramStoryContent>;
  columns: Array<{ id: string; render: (story: InstagramStoryContent) => JSX.Element }>;
  style?: React.CSSProperties;
  loading: boolean;
  maxWidth: number;
  realSize: number;
  count: number;
  currentPage: number;
  totalPages: number;
  optionsSelect: Array<{ value: string; label: string }>;
  headWithMetrics: TableHead;
  metricsTableHeader: TableHead;
  StoryFilterContext: React.Context<FilterContextT>;
  exportQuery: any;
  ColumnSetSelect: React.FC<{ onOpenCustomPanel: () => void; width?: string | number }>;
  columnSetType: ColumnSetType;
  onOpenCustomPanel: () => void;
  onChangePageSize: (numberPage: string) => void;
  onChangePage: (page: number) => void;
  onBackPage: () => void;
  onNextPage: () => void;
  onChangePostOrder: (orderBy: PostOrderBy, order: Order) => void;
  onChangeMetricOrder: (metricOrder: MetricOrderBy, order: Order) => void;
  orderBy: string | null;
  order: Order;
  project: Project;
}

const StoryTable: React.FC<Props> = ({
  stories,
  columns,
  style,
  loading,
  maxWidth,
  realSize,
  count,
  currentPage,
  totalPages,
  optionsSelect,
  headWithMetrics,
  metricsTableHeader,
  exportQuery,
  ColumnSetSelect,
  onOpenCustomPanel,
  onChangePageSize,
  onChangePage,
  onBackPage,
  onNextPage,
  onChangePostOrder,
  onChangeMetricOrder,
  StoryFilterContext,
  columnSetType,
  orderBy,
  order,
  project,
}) => {
  const { selectedSets } = React.useContext(MetricColumnSetsContext);
  const customColumn = selectedSets[columnSetType];
  const selectedColumnSet = Boolean(customColumn);

  const TableFilter =
    maxWidth > 768 ? (
      <TableFilterWrapper>
        <FieldWrapper>
          <Typography variantName="label" style={{ marginRight: 10 }}>
            Rows
          </Typography>
          <Select
            style={{ width: 70, marginRight: 10 }}
            isSearchable={false}
            isClearable={false}
            onChange={(pageSize: any) => onChangePageSize(pageSize ? pageSize.value : null)}
            options={optionsSelect}
            value={{ label: String(realSize), value: String(realSize) }}
          />
          <Media query={{ maxWidth: 840 }}>
            {(matches: boolean) => (
              <>
                {!matches && (
                  <InfoResults>
                    {realSize} of {count}
                  </InfoResults>
                )}
              </>
            )}
          </Media>
        </FieldWrapper>
        <div style={{ display: "flex", alignItems: "center" }}>
          <SyncLegend selectedColumnSet={selectedColumnSet}>
            <Icon iconName="update" style={{ marginRight: 8 }} />
            <Typography variantName="text" style={{ fontSize: 11, color: Colors.GRIGINO }}>
              <>
                Custom metrics aren't sync
                <br />
                with data warehouse
              </>
            </Typography>
          </SyncLegend>
          <ColumnSetSelect width={150} onOpenCustomPanel={() => onOpenCustomPanel()} />
          <PostExportCSVButton
            publicationId={PublicationType.IG_STORY}
            query={exportQuery}
            FilterContext={StoryFilterContext}
            getColumnSet={(publicationId: PublicationType, selectedFormat: PostType | null) =>
              getColumnSetByName(publicationId, customColumn ? customColumn : "IG_STORIES", selectedColumnSet)
            }
            project={project}
          />
          <Paginator
            currentPage={currentPage}
            totalPage={totalPages}
            pageSize={realSize}
            onChangePage={onChangePage}
            onBack={onBackPage}
            onNext={onNextPage}
            style={{ marginLeft: 20 }}
          />
        </div>
      </TableFilterWrapper>
    ) : (
      <div style={{ margin: "15px 0px" }}>
        <FieldWrapper style={{ display: "flex" }}>
          <Typography variantName="label" style={{ marginRight: 10, width: "110px" }}>
            Rows
          </Typography>
          <Select
            style={{ marginRight: 10 }}
            isSearchable={false}
            isClearable={false}
            onChange={(pageSize: any) => onChangePageSize(pageSize ? pageSize.value : null)}
            options={optionsSelect}
            value={{ label: String(realSize), value: String(realSize) }}
          />
          <InfoResults style={{ marginRight: 0 }}>
            {realSize} of {count}
          </InfoResults>
        </FieldWrapper>
        <PaginatorStyled
          currentPage={currentPage}
          totalPage={totalPages}
          pageSize={realSize}
          onChangePage={onChangePage}
          onBack={onBackPage}
          onNext={onNextPage}
          selectWidth={"100%"}
        />
      </div>
    );

  return (
    <div style={{ position: "relative", padding: maxWidth < 768 ? "0px 15px" : 0, marginBottom: 25, ...style }}>
      <IgStoriesTableStyled
        columns={columns}
        tableData={stories}
        rowIdGenerator={(story: InstagramStoryContent) => story.id.contentId}
        itemsPerPage={realSize}
        loading={loading}
        head={headWithMetrics}
        changeOrder={{
          order,
          orderBy,
          onChangeOrder: (orderBy: string | null, order: any) => {
            const isMetric = metricsTableHeader.find((mth) => mth.id === orderBy);
            if (!isMetric && orderBy) {
              onChangePostOrder(PostOrderBy[orderBy], order);
            } else {
              onChangeMetricOrder(orderBy, order);
            }
          },
        }}
        fixedColumnNumbers={maxWidth > 768 ? 2 : 0}
        bodyStyle={{ marginBottom: 25 }}
        tableFilter={TableFilter}
        fixedHeader={maxWidth > 768}
      />
    </div>
  );
};

export { StoryTable };
