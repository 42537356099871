import {
  Column,
  Columns,
  Stack,
  Tabs,
  Tiles,
  Title,
} from "@freeda/design-system";
import * as React from "react";
import { PerfMetricsTimeSpan } from "../../../../types/PerfMetricsTimeSpan";
import { MetricCard } from "../../../ui/MetricCard";
import { useQuery } from "@apollo/react-hooks";
import { Project } from "../../../../types/Project";
import { Platform } from "../../../../types/Platform";
import ListPaidPerformanceMetricsQuery from "../../../../types/graphql/ListPaidPerformanceMetricsQuery";
import { LIST_PAID_PERFORMANCE_METRICS } from "../../../../apollo/queries/ListPaidPerformanceMetrics";
import { paidMetricsByPlatform } from "./PaidMetricsPage";

type Props = {
  platform: Platform;
  span: PerfMetricsTimeSpan;
  metrics: Array<{ name: string; value: number; trend: number }>;
};

function PerformanceMetricsSection(props: Props) {
  const components = props.metrics.map((metric, index) => (
    <MetricCard
      key={index}
      title={paidMetricsByPlatform[metric.name]}
      value={metric.value}
      performace={{ diff: metric.trend, span: props.span }}
    />
  ));
  return (
    <>
      {components.length > 0 && (
        <Tiles space={16} columns={4}>
          {components}
        </Tiles>
      )}
    </>
  );
}

type ContainerProps = {
  project: Project;
  platform: Platform;
  selectedPublisherNames: string[];
  position?: string;
  objective?: string;
};

export function PaidPerformanceMetricsSectionContainer(props: ContainerProps) {
  const [perfMetricsTimeSpan, setPerfMetricsTimeSpan] =
    React.useState<PerfMetricsTimeSpan>("last_week");

  const publisherFilter =
    props.selectedPublisherNames.length > 0
      ? {
          publisherNames: props.selectedPublisherNames,
        }
      : { projectId: props.project.idLegacy };

  const listPaidPerformanceMetrics = useQuery<ListPaidPerformanceMetricsQuery>(
    LIST_PAID_PERFORMANCE_METRICS,
    {
      fetchPolicy: "network-only",
      variables: {
        where: {
          positionId: props.position,
          objective: props.objective,
          span: perfMetricsTimeSpan,
          metrics: Object.keys(paidMetricsByPlatform),
          platformId: props.platform,
          ...publisherFilter,
        },
      },
    }
  );

  const getContent = () => {
    if (
      !listPaidPerformanceMetrics.loading &&
      listPaidPerformanceMetrics.data
    ) {
      return (
        <PerformanceMetricsSection
          platform={props.platform}
          span={perfMetricsTimeSpan}
          metrics={listPaidPerformanceMetrics.data.listPaidPerformanceMetrics}
        />
      );
    } else {
      return <Title size="medium">Loading...</Title>;
    }
  };

  return (
    <Stack space={24}>
      <Columns space={0}>
        <Title size="large">Performance metrics</Title>
        <Column width="content">
          <Tabs
            size="medium"
            value={perfMetricsTimeSpan}
            onChange={setPerfMetricsTimeSpan}
            tabs={perfMetricsTimeSpanOptions}
          />
        </Column>
      </Columns>
      {getContent()}
    </Stack>
  );
}

const perfMetricsTimeSpanOptions: {
  value: PerfMetricsTimeSpan;
  label: string;
}[] = [
  {
    value: "last_week",
    label: "Last week",
  },
  {
    value: "last_month",
    label: "Last month",
  },
  {
    value: "last_3_months",
    label: "Last 3 months",
  },
];
