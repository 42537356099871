import gql from "graphql-tag";
import { SOCIAL_CONTENT_ID_FRAGMENT } from "../fragments/SocialContentIdFragment";

const DELETE_INSTAGRAM_STORY = gql`
  mutation deleteInstagramStory($input: DeleteInstagramStoryInput!) {
    deleteInstagramStory(input: $input) {
      id {
        ...socialContentId
      }
    }
  }
  ${SOCIAL_CONTENT_ID_FRAGMENT}
`;

export { DELETE_INSTAGRAM_STORY };
