import styled from "styled-components";
import { Table } from "@freeda/react-components";

const RelationIcon = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
`;

const MetadataTableStyled = styled(Table)`
  && {
    & > thead > tr > th:first-child {
      padding-left: 40px;
    }
    & > tbody > tr > td:first-child {
      padding-left: 40px;
    }
  }
`;

export { RelationIcon, MetadataTableStyled };
