import gql from "graphql-tag";
import { getPostMetadataSpec } from "../../ui-specs/specs";
import { PostType } from "../../types/PostType";
import { Platform } from "../../types/Platform";
import { ProjectId } from "../../types/ProjectId";

export const buildQuery = (postType: PostType, platform: Platform, projectId: string) => {
  const queries = `${getPostMetadataSpec(postType, platform).categories.map(c => {
    return `
      ${c.id}: allMetadata(where: { categoryId: "${
      c.id
    }", active: true, project: { idIn: ["${projectId}"], alsoWithoutProjects: true } }) {
        id
        label
        active
        category {
          id
          label
          area
        }
        visibleFor {
          id
          label
          active
          category {
            id
            label
            area
          }
        }
        projects {
          id
          label
        }
      }
    `;
  })}`;
  return gql`{
    ${queries}
    BOOLEAN: allMetadata(where: { categoryId: "BOOLEAN", project: { idIn: ["${projectId}"], alsoWithoutProjects: true } }) {
      id
      label
      active
      category {
        id
        label
        area
      }
      visibleFor {
        id
        label
        active
        category {
          id
          label
          area
        }
      }
      projects {
        id
        label
      }
    }
  }`;
};
