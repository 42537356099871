import gql from "graphql-tag";
import { SOCIAL_CONTENT_ID_FRAGMENT } from "../fragments/SocialContentIdFragment";

const UPDATE_INSTAGRAM_STORY = gql`
  mutation updateInstagramStory($input: UpdateInstagramStoryInput!) {
    updateInstagramStory(input: $input) {
      id {
        ...socialContentId
      }
      title
      publicationDate
      frames {
        id {
          contentId
          publisherId
          platformId
        }
      }
      userDefinedMetadata {
        id
        label
        active
        category {
          id
          label
          area
        }
      }
    }
  }
  ${SOCIAL_CONTENT_ID_FRAGMENT}
`;

export { UPDATE_INSTAGRAM_STORY };
