export const zIndex = {
  Header: 3010,
  Spinnerone: 2015,
  SidebarMobile: 2010,
  LateralPanel: 2006,
  BarWrapper: 2005,
  Sidebar: 1999,
  Monogramma: 1097,
  AppBar: 1096
};
