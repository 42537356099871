import styled from "styled-components";
import { Spinner, Icon } from "@freeda/react-components";

const Wrapper = styled.div<{ isDragDrop: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: ${(props) => (props.isDragDrop ? 150 : "auto")}px;
  transition: height 0.3s;
`;

const DragDropZoneWrapper = styled(Wrapper)`
  border: 2px dashed ${(props) => props.theme.lightViolet};
  outline-offset: 0px;
  border-radius: 25px;
  padding: 45px 20px;
  justify-content: center;
`;

const SpinnerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

const SvgIcon = styled.img<{ isVisible: boolean }>`
  top: ${(props) => (props.isVisible ? 30 : -65)}px;
  user-select: none;
  position: absolute;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.3s;
`;

const StyledIcon = styled(Icon)<{ isVisible: boolean }>`
  user-select: none;
  position: absolute;
  top: ${(props) => (props.isVisible ? 34 : -65)}px;
  opacity: ${(props) => (props.isVisible ? 1 : 0)};
  transition: top 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.3s;
`;

const CircleBackground = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 5;

  border-width: 4px;
  height: 130px;
  width: 130px;
  border-radius: 50%;
  border: 4px solid ${(props) => props.theme.lightViolet};
`;

const StyledSpinner = styled(Spinner)`
  && {
    border-width: 4px;
    height: 130px;
    width: 130px;
    z-index: 10;

    transition: opacity 0.4s;
    position: absolute;

    &.entering {
      opacity: 0;
    }
    &.entered {
      opacity: 1;
    }
    &.exiting {
      opacity: 0;
    }
    &.exited {
      opacity: 0;
    }
  }
`;

export { Wrapper, DragDropZoneWrapper, SpinnerWrapper, StyledSpinner, SvgIcon, CircleBackground, StyledIcon };
