import * as React from "react";
import { DateRange } from "../types/DateRange";
import { LogicalOperator } from "../types/LogicalOperator";
import { PostType } from "../types/PostType";
import { Order } from "../types/Order";
import { PostOrderBy } from "../types/graphql/PostOrderBy";
import { Omit } from "../types/utils/Omit";
import { LanguageCountry } from "../types/LanguageCountry";
import { FixedDateRange } from "../types/FixedDateRange";
import { InclusionPolicy } from "../types/InclusionPolicy";
import { capitalize } from "lodash";
import { Page, PlatformName } from "../ui-specs/posts-pages-specs";

export type MetricOrderBy = string | null;

export interface FilterState {
  searchValue: string;
  dateRange: DateRange;
  selectedMetadata: Array<{ value: string; label: string }>;
  currentPage: number;
  pageSize: number;
  postOrderBy: PostOrderBy | null;
  metricOrderBy: string | null;
  order: Order;
  logicalOperator: LogicalOperator;
  selectedPublisher: string;
  selectedDistributionCountry:string |null;
  selectedFormat: PostType | null;
  branded: boolean | null;
  inclusionPolicyUser: InclusionPolicy | null;
  selectedCategoryId: string | null;
}

const initialState: Omit<FilterState, "selectedPublisher"> = {
  searchValue: "",
  dateRange: FixedDateRange.LAST_24H,
  selectedMetadata: [],
  currentPage: 0,
  pageSize: 25,
  postOrderBy: PostOrderBy.publicationDate,
  metricOrderBy: null,
  order: "DESC",
  logicalOperator: LogicalOperator.AND,
  selectedFormat: null,
  branded: null,
  inclusionPolicyUser: null,
  selectedCategoryId: null,
  selectedDistributionCountry:null

};
const backToFirstPage = { currentPage: 0 };

const userCountry = localStorage.getItem("country") as LanguageCountry;

const realUserCountry = LanguageCountry[userCountry] || LanguageCountry.IT;

export type FilterContextT = {
  filterState: FilterState;
  callbacks: {
    clearFilter: () => void;
    onTextFilterChange: (value: string) => void;
    onChangeUpdateDateRange: (dateRange: DateRange) => void;
    onChangeMetadata: (
      metadata: { value: string; label: string },
      clearFilter?: () => void
    ) => void;
    onCategoryMetadataSelection: (categoryMetadataId: string) => void;
    onChangePageSize: (numberPage: string) => void;
    onChangePage: (page: number) => void;
    onBackPage: () => void;
    onNextPage: (totalPages: number) => void;
    onChangePostOrder: (postOrderBy: PostOrderBy, order: Order) => void;
    onChangeMetricOrder: (metricOrderBy: MetricOrderBy, order: Order) => void;
    onChangeLogicalOperator: (logicalOperator: LogicalOperator) => void;
    onChangePublisher: (selectedPublisher: string) => void;
    onChangeDistributionCountry: (selectedDistributionCountry: string) => void;
    onChangeFormat: (selectedFormat: PostType) => void;
    onChangeInclusionPolicyUser: (
      inclusionPolicyUser: InclusionPolicy | null
    ) => void;
    onChangeBranded: (brandedEditorialContent: boolean | null) => void;
  };
};

const defaultCallbacks = {
  clearFilter: () => console.log("clearFilter"),
  onTextFilterChange: (value: string) => console.log("onTextFilterChange"),
  onChangeUpdateDateRange: (dateRange: DateRange) =>
    console.log("onChangeUpdateDateRange"),
  onChangeMetadata: (
    metadata: { value: string; label: string },
    clearFilter?: () => void
  ) => console.log("onChangeMetadata"),
  onCategoryMetadataSelection: (categoryMetadataId: string) =>
    console.log("onCategoryMetadataSelection"),
  onChangePageSize: (numberPage: string) => console.log("onChangePageSize"),
  onChangePage: (page: number) => console.log("onChangePage"),
  onBackPage: () => console.log("onBackPage"),
  onNextPage: (totalPages: number) => console.log("onNextPage"),
  onChangePostOrder: (postOrderBy: PostOrderBy, order: Order) =>
    console.log("onChangePostOrder"),
  onChangeMetricOrder: (metricOrderBy: MetricOrderBy, order: Order) =>
    console.log("onChangeMetricOrder"),
  onChangeLogicalOperator: (logicalOperator: LogicalOperator) =>
    console.log("onChangeLogicalOperator"),
  onChangePublisher: (selectedPublisher: string) =>
    console.log("onChangePublisher"),
  onChangeDistributionCountry: (selectedDistributionCountry: string) =>
    console.log("selectedDistributionCountry"),
  onChangeFormat: (selectedFormat: PostType) => console.log("onChangeFormat"),
  onChangeBranded: (brandedEditorialContent: boolean | null) => console.log(),
  onChangeInclusionPolicyUser: (inclusionPolicyUser: InclusionPolicy | null) =>
    console.log("onChangeInclusionPolicyUser"),
};

const createInitialState = ({
  project,
  platform,
  hasCountries,
  withPublisher,
}: Page): FilterState => ({
  ...initialState,
  selectedPublisher: `${capitalize(project.id)} ${PlatformName[platform]} ${
    hasCountries===true ? realUserCountry : ""
  }`.trim(),
});
const buildInitialContext = (
  initialState: FilterState
): React.Context<FilterContextT> =>
  React.createContext({
    filterState: {
      ...initialState,
    },
    callbacks: defaultCallbacks,
  });

const getPostFilterProvider = (
  name: string,
  FilterCtx: React.Context<FilterContextT>,
  initialState: FilterState
) => {
  //console.log('getPostFilterProvider',name,FilterCtx,initialState)
  const FilterProvider: React.FC = (props) => {
    const [state, setState] = React.useState<FilterState>({ ...initialState });

    
  const onChangePageSize = (numberPage: string) => {
      setState((state) => ({ ...state, pageSize: Number(numberPage) }));
    };

    const onChangeLogicalOperator = (logicalOperator: LogicalOperator) =>
      setState((state) => ({
        ...state,
        logicalOperator: logicalOperator,
        ...backToFirstPage,
      }));

    const onChangePublisher = (selectedPublisher: string) => {
      setState((state) => ({
        ...state,
        selectedPublisher,
        ...backToFirstPage,
      }));
    };

    const onChangeDistributionCountry = (selectedDistributionCountry: string) => {
      setState((state) => ({
        ...state,
        selectedDistributionCountry,
        ...backToFirstPage,
      }));
    };

    const onChangeFormat = (selectedFormat: PostType) =>
      setState((state) => ({ ...state, selectedFormat, ...backToFirstPage }));

    const onChangePage = (page: number) =>
      setState((state) => ({ ...state, currentPage: page }));

    const onBackPage = () =>
      setState((state) => ({
        ...state,
        currentPage: state.currentPage === 0 ? 0 : state.currentPage - 1,
      }));

    const onNextPage = (totalPages: number) =>
      setState((state) => ({
        ...state,
        currentPage:
          state.currentPage === totalPages - 1
            ? state.currentPage
            : state.currentPage + 1,
      }));

    const onChangePostOrder = (postOrderBy: PostOrderBy, order: Order) => {
      setState((state) => ({
        ...state,
        postOrderBy,
        metricOrderBy: null,
        order,
      }));
    };

    const onChangeMetricOrder = (
      metricOrderBy: MetricOrderBy,
      order: Order
    ) => {
      setState((state) => ({
        ...state,
        postOrderBy: null,
        metricOrderBy,
        order,
      }));
    };

    const clearFilter = () => setState({ ...initialState });

    const onTextFilterChange = (searchValue: string) =>
      setState((state) => ({ ...state, searchValue, ...backToFirstPage }));

    const onChangeUpdateDateRange = (dateRange: DateRange) => {
      setState((state) => ({
        ...state,
        dateRange,
        ...backToFirstPage,
      }));
    };

    const onCategoryMetadataSelection = (categoryId: string) => {
      setState((state) => ({ ...state, selectedCategoryId: categoryId }));
    };

    const onChangeMetadata = (
      metadata: { value: string; label: string },
      clearFilter?: () => void
    ) => {
      const exists = state.selectedMetadata.find(
        (m) => m.value === metadata.value
      );

      setState((state) => ({
        ...state,
        selectedMetadata: exists
          ? state.selectedMetadata.filter((m) => m.value !== metadata.value)
          : [metadata, ...state.selectedMetadata],
        ...backToFirstPage,
      }));

      if (clearFilter) {
        clearFilter();
      }
    };

    const onChangeBranded = (brandedEditorialContent: boolean | null) => {
      setState((state) => ({
        ...state,
        branded: brandedEditorialContent,
        ...backToFirstPage,
      }));
    };

    const onChangeInclusionPolicyUser = (
      inclusionPolicyUser: InclusionPolicy | null
    ) => {
      setState((state) => ({
        ...state,
        inclusionPolicyUser,
        ...backToFirstPage,
      }));
    };

    return (
      <FilterCtx.Provider
        value={{
          filterState: { ...state },
          callbacks: {
            clearFilter: clearFilter,
            onTextFilterChange: onTextFilterChange,
            onChangeUpdateDateRange: onChangeUpdateDateRange,
            onChangeMetadata: onChangeMetadata,
            onCategoryMetadataSelection: onCategoryMetadataSelection,
            onBackPage: onBackPage,
            onNextPage: onNextPage,
            onChangePostOrder: onChangePostOrder,
            onChangeMetricOrder: onChangeMetricOrder,
            onChangePage: onChangePage,
            onChangePageSize: onChangePageSize,
            onChangeLogicalOperator: onChangeLogicalOperator,
            onChangePublisher: onChangePublisher,
            onChangeDistributionCountry: onChangeDistributionCountry,
            onChangeFormat: onChangeFormat,
            onChangeInclusionPolicyUser: onChangeInclusionPolicyUser,
            onChangeBranded: onChangeBranded,
          },
        }}
      >
        {props.children}
      </FilterCtx.Provider>
    );
  };

  FilterProvider.displayName = name;
  return FilterProvider;
};

export const buildFilterProvidersContext: (page: Page) => {
  Provider: React.FC;
  ctx: React.Context<FilterContextT>;
  state: FilterState;
} = (page) => {
  const name = `${capitalize(page.project.id)}${page.platform
    .split("_")
    .map(capitalize)
    .join("")}FilterProvider`;
    
  
  const state: FilterState = createInitialState(page);
  const ctx: React.Context<FilterContextT> = buildInitialContext(state);
  const Provider: React.FC = getPostFilterProvider(name, ctx, state);
  return { state, ctx, Provider };
};
