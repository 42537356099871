import * as React from "react";
import styled from "styled-components";

const Input = styled.div`
  flex-basis: 20%;
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  box-sizing: border-box;
  background: #eeeeee;
  position: relative;
`;

const PlaceholderInput: React.FC<{ style?: React.CSSProperties }> = ({
  style,
  children
}) => <Input style={{ ...style }}>{children}</Input>;

export { PlaceholderInput };
